import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import contentAdmin from 'helpers/apis/contentAdmin';
import { isValidEmail } from 'helpers';
import { useNotify } from 'ra-core';

const ForwardEmailDialog = ({ email, loadingEmail }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [recipients, setRecipients] = useState('');
  const [recipientsEmailsError, setRecipientsEmailsError] = useState(false);

  const handleCustomEmailsChange = event => {
    const emails = event.target.value.split(',');
    const includeInvalide = emails.some(item => !isValidEmail(item));
    if (includeInvalide) {
      setRecipients(event.target.value);
      setRecipientsEmailsError(true);
    } else {
      setRecipients(event.target.value);
      setRecipientsEmailsError(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRecipients('');
    setRecipientsEmailsError(false);
  };

  // eslint-disable-next-line no-shadow
  const handleSubmit = useCallback(({ recipients, email }) => {
    const onSuccess = response => {
      notify('Email sent Successfully', 'success');
    };
    const onError = error => {
      notify('sending Email failed please try again!', 'error');
    };

    contentAdmin
      .forwardEmail({ email, recipients: recipients.split(',') })
      .then(onSuccess)
      .catch(onError);

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <IconButton disabled={loadingEmail} variant="outlined" color="primary" onClick={handleClickOpen}>
        <SendIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Forward Email</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter recipients &quot;comma seperated emails&quot;</DialogContentText>
          <TextField
            error={recipientsEmailsError}
            id="outlined-basic"
            label="recipients"
            variant="outlined"
            value={recipients}
            onChange={handleCustomEmailsChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={recipients.length === 0 || recipientsEmailsError}
            onClick={() => handleSubmit({ recipients, email: email.id })}
            color="primary"
          >
            Forward
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ForwardEmailDialog.propTypes = {
  email: PropTypes.string.isRequired,
  loadingEmail: PropTypes.bool.isRequired
};

export default ForwardEmailDialog;
