// @flow
import React from 'react';
import ClientsEditNotification from './pushNotifications/pushNotifications';

const PagePushNotifications = ({ clientId, trackGA, partnerId, isOnPartnerProfile = false }) => {
  return (
    <ClientsEditNotification
      clientId={clientId}
      trackGA={trackGA}
      partner_id={partnerId}
      isOnPartnerProfile={isOnPartnerProfile}
    />
  );
};

export default PagePushNotifications;
