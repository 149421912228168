import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_13;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const newsfeed = {};
const jsonContentTypeValue = 'application/json';

newsfeed.getRequestHeaders = (contentType = jsonContentTypeValue, includeAuthorization = true) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
  };
};

//General

newsfeed.checkLicenseAgreement = () => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/LicenseAgreement`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.acceptLicenseAgreement = () => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/LicenseAgreement`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.getHashtags = hashtag_name => {
  const requestOptions = {
    url: `${baseURL}hashtags`,
    params: { hashtag_name },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getPopularHashtags = (limit, offset) => {
  const requestOptions = {
    url: `${baseURL}popular_hashtags`,
    params: { limit, offset },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getMentionUsers = first_name => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/mention/users`,
    params: { first_name },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getNewsfeedUsers = name => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Users`,
    params: { name },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getFlaggingReasons = () => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/FlaggingReasons`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getFeedPicklist = (types, filters) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/PickList`,
    params: {
      types: JSON.stringify(types),
      ...(filters && {filters: {...filters}}),
    },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getUsersPicklist = (types) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Users/PickList`,
    params: {
      types: JSON.stringify(types),
    },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

//Feeds

newsfeed.getFeed = (userId, offset, orderBy = null, searchParam = null, filter = null, searchFilter = null) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${userId}/Feed`,
    params: {
      offset,
      ...(orderBy && {order_by_field: orderBy}),
      ...(searchFilter && {...searchFilter}),
      ...(searchParam && {...searchParam}),
      ...(filter && {...filter})
    },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.getFeedSingle = (userId, postId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${userId}/Feed`,
    params: {
      offset: 0,
      post_id: postId
    },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.addPost = (userId, postData) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${userId}/Feed`,
    headers: newsfeed.getRequestHeaders(),
    data: postData
  };
  return apiClient.post(requestOptions);
};

newsfeed.getPresignedPost = filename => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/GetPresignedPost`,
    params: { filename },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.uploadFile = (url, formData, onUploadProgress, cancelToken) => {
  const requestOptions = {
    url: url,
    headers: newsfeed.getRequestHeaders('multipart/form-data', false),
    data: formData,
    cancelToken,
    onUploadProgress
  };
  return apiClient.post(requestOptions);
};

newsfeed.getLinkPreview = url => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/GetLinkPreview`,
    data: { url },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

newsfeed.likePost = (postId, userId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/Likes`,
    data: { user_id: userId },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

newsfeed.unLikePost = (postId, userId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/Likes`,
    data: { user_id: userId },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

newsfeed.getPostsLikers = (postId, offset) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/Likes`,
    params: { offset },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.deletePost = postId => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

newsfeed.updatePost = (postId, postData) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}`,
    data: postData,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.flagPost = (postId, reason) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/flag`,
    data: { reason },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

newsfeed.favoritePost = postId => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/Favorites`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

newsfeed.unFavoritePost = postId => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${postId}/Favorites`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

//Comments

newsfeed.getComments = (post_id, offset, user_id) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${post_id}/Comments`,
    params: { offset, user_id },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.addComment = comment => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Posts/${comment.post_id}/Comments`,
    headers: newsfeed.getRequestHeaders(),
    data: { comment }
  };
  return apiClient.post(requestOptions);
};

newsfeed.deleteComment = (commentId, postId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${commentId}`,
    headers: newsfeed.getRequestHeaders(),
    data: { post_id: postId }
  };
  return apiClient.delete(requestOptions);
};

newsfeed.updateComment = comment => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${comment.id}`,
    data: { comment },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.likeComment = (commentId, userId, postId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${commentId}/Likes`,
    data: { user_id: userId, post_id: postId },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

newsfeed.unLikeComment = (commentId, userId, postId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${commentId}/Likes`,
    data: { user_id: userId, post_id: postId },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

newsfeed.getCommentsLikers = (commentId, offset) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${commentId}/Likes`,
    params: { offset },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.checkAccess = userId => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${userId}/access`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.flagComment = (commentId, reason) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Comments/${commentId}/flag`,
    data: { reason },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

//Notifications

newsfeed.getNotifications = (user_id, offset) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${user_id}/Notifications`,
    params: { offset, user_id },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

newsfeed.markOpened = (notificationType, notificationId) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Notifications/${notificationId}/MarkOpened`,
    data: { type: notificationType },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.markAllOpened = () => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Notifications/MarkAllOpened`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.markAllSeen = () => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/Notifications/MarkAllSeen`,
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

newsfeed.SetDevicesToken = (userId, token) => {
  const requestOptions = {
    url: `${baseURL}NewsFeed/${userId}/SetDevicesTokens`,
    data: { device_token: token },
    headers: newsfeed.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

export default newsfeed;
