import React from 'react';
import { Box, Stack, Typography, styled } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';

const StrokedTypography = styled(Typography)({
  '-webkit-text-stroke': '1px #28BDE9'
});

const Section = ({ imagePath, id, title, description, isReversed }) => {
  return (
    <Stack
      direction={{ xs: 'column', md: isReversed ? 'row-reverse' : 'row' }}
      spacing={5}
      useFlexGap
      alignItems={{ md: 'center' }}
      justifyContent="space-between"
    >
      <Stack spacing={{ xs: 3, md: 2 }} maxWidth={426}>
        <Stack spacing={1}>
          <StrokedTypography
            color="common.transparent"
            variant="heading"
            fontSize={{ md: 80 }}
            lineHeight={{ xs: '56px', md: '67.2px' }}
          >
            {id}
          </StrokedTypography>
          <Typography
            variant="heading"
            color="common.white"
            fontSize={{ xs: 24, md: 40 }}
            lineHeight={{ xs: '24px', md: '67.2px' }}
          >
            {title}
          </Typography>
        </Stack>
        <Typography variant="subtitle1" color="common.white">
          {description}
        </Typography>
      </Stack>
      <Box minWidth={{ md: 610 }} width="100%" maxWidth={610}>
        <img src={imagePath} alt={title} width="100%" />
      </Box>
    </Stack>
  );
};

Section.propTypes = {
  description: PropTypes.string.isRequired,
  isReversed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default Section;
