import React, { useState, useEffect, useCallback } from 'react';
import contentAdmin from 'helpers/apis/contentAdmin';
import pushNotifications from 'helpers/apis/pushNotifications';
import { convertToAmPmFormat, convert12HourTo24HourFormat } from 'helpers';
import { useId } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  MenuItem,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  TextField,
  enqueueAlertSnackbar,
  ArrowReloadIcon,
  InternetIcon,
  ArrowDownIcon,
  ArrowUpIcon
} from '@trustsecurenow/components-library';

const HOURS = [
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12am',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
  '12pm'
];

const SENDING_TIMES = {
  start_time: null,
  end_time: null,
  timezone_id: null
};

const SendingTimesAccordion = ({ isOnPartnerProfile = false }) => {
  const partnerId = useId({ key: 'partnerId' });
  const clientId = useId({ key: 'clientId' });
  const [isOpen, setIsOpen] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [initialSendingTimes, setInitialSendingTimes] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedSendingTimes, setSelectedSendingTimes] = useState(SENDING_TIMES);

  const fetchData = useCallback(async () => {
    try {
      const timeZones = await contentAdmin.getPickList('timezones');
      const initialSendingTimes = await pushNotifications.getSendingTimes(
        partnerId,
        !isOnPartnerProfile ? clientId : null
      );
      setTimeZoneList(timeZones.data.timezones);
      setSelectedSendingTimes({
        ...selectedSendingTimes,
        start_time: convertToAmPmFormat(initialSendingTimes.data.start_sending_time),
        end_time: convertToAmPmFormat(initialSendingTimes.data.end_sending_time),
        timezone_id: initialSendingTimes.data.timezone_id
      });
      setInitialSendingTimes(initialSendingTimes.data);
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    }
  }, [partnerId, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const checkIsDisabled = () => {
      if (initialSendingTimes) {
        if (isOnPartnerProfile) {
          if (
            initialSendingTimes.is_system_config ||
            initialSendingTimes.timezone_id !== selectedSendingTimes.timezone_id ||
            convertToAmPmFormat(initialSendingTimes.start_sending_time) !== selectedSendingTimes.start_time ||
            convertToAmPmFormat(initialSendingTimes.end_sending_time) !== selectedSendingTimes.end_time
          ) {
            setIsDisabled(false);
            return;
          }
          setIsDisabled(true);
        } else {
          if (
            initialSendingTimes.is_system_config ||
            initialSendingTimes.is_partner_config ||
            (!initialSendingTimes.is_system_config &&
              !initialSendingTimes.is_partner_config &&
              initialSendingTimes.timezone_id !== selectedSendingTimes.timezone_id) ||
            convertToAmPmFormat(initialSendingTimes.start_sending_time) !== selectedSendingTimes.start_time ||
            convertToAmPmFormat(initialSendingTimes.end_sending_time) !== selectedSendingTimes.end_time
          ) {
            setIsDisabled(false);
            return;
          }
          setIsDisabled(true);
        }
      }
    };

    checkIsDisabled();
  }, [initialSendingTimes, selectedSendingTimes, isOnPartnerProfile]);

  const handleTimeChange = (type, direction) => {
    const isStartTime = type === 'start';
    const currentKey = isStartTime ? 'start_time' : 'end_time';

    if (direction === 'up') {
      if (selectedSendingTimes[currentKey] !== '12pm') {
        setSelectedSendingTimes(prevState => ({
          ...prevState,
          [currentKey]: HOURS[HOURS.indexOf(prevState[currentKey]) + 1]
        }));
      }
    } else {
      if (selectedSendingTimes[currentKey] !== '1am') {
        setSelectedSendingTimes(prevState => ({
          ...prevState,
          [currentKey]: HOURS[HOURS.indexOf(prevState[currentKey]) - 1]
        }));
      }
    }
  };

  const handlePostSendingTimes = async (revertSettings = false) => {
    const modifiedSendingTimes = { ...selectedSendingTimes };
    modifiedSendingTimes.start_time = convert12HourTo24HourFormat(selectedSendingTimes.start_time);
    modifiedSendingTimes.end_time = convert12HourTo24HourFormat(selectedSendingTimes.end_time);

    try {
      const response = await pushNotifications.postSendingTimes(
        partnerId,
        !isOnPartnerProfile ? clientId : null,
        revertSettings ? SENDING_TIMES : modifiedSendingTimes
      );

      enqueueAlertSnackbar(response.data.description, {
        props: { severity: 'success' }
      });
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
        props: { severity: 'error' }
      });
    }
  };

  const handleSave = () => {
    handlePostSendingTimes();
    fetchData();
  };

  const handleRevertSettings = () => {
    handlePostSendingTimes(true);
    fetchData();
  };

  if (!initialSendingTimes?.show_sending_times_section) return null;

  return (
    <Box mb={2}>
        <Accordion expanded={isOpen} onChange={() => setIsOpen(prev => !prev)}>
          <AccordionSummary>
          <Typography color={isOpen && 'info.main'}>Configure Sending Times</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" spacing={3}>
              <Stack>
                <Typography variant="body2">
                  Customize the notification schedule for your platform. Tailor the timing for Micro Training and
                  Newsletter emails, Manager Notifications, <br />
                  Employee Reminders and Automated Reports to suit your preferences.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack minWidth={140}>
                  <Typography variant="subtitle2">Choose Time Zone</Typography>
                </Stack>
                <Stack minWidth={270}>
                  <TextField
                    select
                    SelectProps={{
                      IconComponent: () => <InternetIcon sx={{ pr: 1 }} />
                    }}
                    defaultValue={selectedSendingTimes.timezone_id}
                    fullWidth
                    sx={{
                      '& .Mui-focused svg': {
                        color: 'info.main'
                      }
                    }}
                    onChange={e => setSelectedSendingTimes({ ...selectedSendingTimes, timezone_id: e.target.value })}
                  >
                    {timeZoneList.map(option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{
                          color: option.id === selectedSendingTimes.timezone_id && 'info.main',
                          backgroundColor: 'none'
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                {!isOnPartnerProfile &&
                  !initialSendingTimes.is_system_config &&
                  !initialSendingTimes.is_partner_config && (
                    <Stack flexDirection="row" alignItems="center" gap={0.25}>
                      <IconButton onClick={handleRevertSettings}>
                        <ArrowReloadIcon sx={{ fontSize: 20 }} />
                        <Typography ml={1} variant="link2" sx={{ textDecoration: 'underline' }}>
                          Revert to Default Settings
                        </Typography>
                      </IconButton>
                    </Stack>
                  )}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack minWidth={140}>
                  <Typography variant="subtitle2">Send Between</Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={0.5} minWidth={270}>
                  <TextField
                    select
                    label="Start"
                    SelectProps={{
                      IconComponent: () => <></>
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack pr={0.5}>
                            <IconButton sx={{ p: 0 }}>
                              <ArrowUpIcon sx={{ fontSize: 10 }} onClick={() => handleTimeChange('start', 'up')} />
                            </IconButton>
                            <IconButton sx={{ p: 0 }}>
                              <ArrowDownIcon sx={{ fontSize: 10 }} onClick={() => handleTimeChange('start', 'down')} />
                            </IconButton>
                          </Stack>
                        </InputAdornment>
                      )
                    }}
                    defaultValue={selectedSendingTimes.start_time}
                    value={selectedSendingTimes.start_time}
                    onChange={e => setSelectedSendingTimes({ ...selectedSendingTimes, start_time: e.target.value })}
                    fullWidth
                  >
                    {HOURS.map(hour => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="body2">and</Typography>
                  <TextField
                    select
                    label="Ends"
                    SelectProps={{
                      IconComponent: () => <></>
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack pr={0.5}>
                            <IconButton sx={{ p: 0 }} onClick={() => handleTimeChange('end', 'up')}>
                              <ArrowUpIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                            <IconButton sx={{ p: 0 }} onClick={() => handleTimeChange('end', 'down')}>
                              <ArrowDownIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                          </Stack>
                        </InputAdornment>
                      )
                    }}
                    defaultValue={selectedSendingTimes.end_time}
                    value={selectedSendingTimes.end_time}
                    onChange={e => setSelectedSendingTimes({ ...selectedSendingTimes, end_time: e.target.value })}
                    fullWidth
                  >
                    {HOURS.map(hour => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                <Button disabled={isDisabled} onClick={handleSave}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
    </Box>
  );
};

export default SendingTimesAccordion;
