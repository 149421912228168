// @flow

import React, { type ComponentType, useReducer, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient, QueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MenuItem, MenuList, Popper, Paper, Grow, ClickAwayListener } from '@material-ui/core';
import { Button, UserIcon, TagIcon, FilterIcon, CSVIcon, PencilIcon, Box } from '@trustsecurenow/components-library';
import { LazyIcon } from 'components/icons';
import { useLocation, useId } from 'hooks';
import { snakeToCamelTitle, useApp, dataFetch, dataQueryName, arrayOfObjectsEqual } from 'helpers';
import {
  CSV_BULK_UPLOAD_CLIENTS,
  CSV_BULK_UPLOAD_MYCOMPANY,
  WELCOME_MESSAGE_CLIENTS,
  WELCOME_MESSAGE_MYCOMPANY
} from 'apps/contentadmin/constants';
import { updateNestedMutable } from 'utils/update';
import { BSN_SET_ANY, BSN_SET_TARGET } from 'conf';
import ClientsUsersAddButton from './ClientsUsersAddButton';
import ClientsUsersSelect from './ClientsUsersSelect';
import ClientsUsersDownloadReportButton from './ClientsUsersDownloadReportButton';
import CustomTooltip from 'components/common/CustomTooltip';

const ConatainerOuter: ComponentType<*> = styled.div`
  position: relative;
`;

const ContainerGrow: ComponentType<*> = styled(Popper)`
  && {
    z-index: 1;
    top: initial !important;
    left: initial !important;
    transform: translate3d(0, 0, 0) !important;
  }
`;

const ToolbarRightBtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContainerToolbarItemLeft: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ContainerToolbarItemRight: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -2px 0;
  > div {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

const ContainerToolbarFilters: ComponentType<*> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 4px;
  flex-wrap: wrap;
`;

type ClientsUsersToolbarStateTypes = {
  filters: {
    group_role: {
      active: boolean,
      value: Array<string>,
      multiple: boolean
    },
    tag: {
      active: boolean,
      value: '',
      multiple: boolean
    },
    ess: {
      active: boolean,
      value: Array<string>,
      multiple: boolean
    },
    policy: {
      active: boolean,
      value: '',
      multiple: boolean
    },
    other_policy: {
      active: boolean,
      value: '',
      multiple: boolean
    },
    activated: {
      active: boolean,
      value: '',
      multiple: boolean
    },
    locked: {
      active: boolean,
      value: '',
      multiple: boolean
    },
    deleted: {
      active: boolean,
      value: '',
      multiple: boolean
    }
  },
  anchor: any
};

type ClientsUsersToolbarDispatchTypes = {
  type: string,
  payload: any
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});

const initialState: ClientsUsersToolbarStateTypes = {
  filters: {
    group_role: {
      active: false,
      value: [],
      multiple: true
    },
    tag: {
      active: false,
      value: [],
      multiple: true
    },
    ess: {
      active: false,
      value: [],
      multiple: true
    },
    policy: {
      active: false,
      value: '',
      multiple: false
    },
    other_policy: {
      active: false,
      value: '',
      multiple: false
    },
    activated: {
      active: false,
      value: '',
      multiple: false
    },
    locked: {
      active: false,
      value: '',
      multiple: false
    },
    deleted: {
      active: false,
      value: '',
      multiple: false
    },
    welcome_email_received: {
      active: false,
      value: '',
      multiple: false
    }
  },
  anchor: null
};

const ClientsUsersToolbar = ({
  enableTags,
  enableFilters,
  enableActions,
  setDisabeldActions,
  setIsSelectedDeletedFilter,
  transformBeforeSubmit,
  setRefetch
}: {
  enableTags: boolean,
  enableFilters: Object,
  enableActions: Object,
  setDisabeldActions: Function
}) => {
  const { dispatch: dispatchApp } = useApp();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const tableFilter = useSelector(state => state.bsn.system.tableFilter);
  const clientId = useId({ key: 'clientId' });
  const user_profile = useSelector(s => s?.bsn?.user?.profile);
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  const { app, item } = useLocation();
  const client_id = item || clientId;
  const history = useHistory();
  const [choicesTags, setChoicesTag] = useState([]);
  const [choicesGroups, setChoicesGroup] = useState([]);
  const [{ filters, anchor }, setState] = useReducer<ClientsUsersToolbarStateTypes, ClientsUsersToolbarDispatchTypes>(
    reducer,
    initialState
  );

  const userRole = useSelector(({ bsn }) => bsn?.user?.profile?.user_role);
  useEffect(() => {
    if (filters.tag.active) fetch('tags', client_id, choicesTags, setChoicesTag);
    if (filters.group_role.active) fetch('userGroups', client_id, choicesGroups, setChoicesGroup);
  });

  useEffect(() => {
    setState({ type: 'EMPTYFILTERS', payload: {} });
    dispatchApp.set('system', 'tableFilter', {});
    queryClient.clear();
  }, [queryClient]);

  useEffect(() => {
    if (filters?.deleted?.active) {
      dispatchApp.set('system', 'tableFilter', { deleted: 'true' });
      setState({ type: 'SETVALUE', payload: { name: 'deleted', value: 'true' } });
    }
  }, [filters?.deleted?.active]);

  useEffect(() => {
    setRedux();
  }, [dispatchApp, filters]);

  const filterKeys = enableFilters && Object.keys(enableFilters);
  const newKeys = enableFilters && filterKeys.filter(row => enableFilters[row] === true);
  const newValues = enableFilters && newKeys.map(row => filters[row]);
  const newFilters = enableFilters && Object.fromEntries(newKeys.map((_, i) => [newKeys[i], newValues[i]]));

  React.useEffect(() => {
    if (filters?.deleted?.value === 'true') {
      const disabledList = Object.keys(enableActions).filter(
        action => enableActions[action] && action !== 'reset_deleted' && action !== 'permanent_delete'
      );
      setDisabeldActions(disabledList);
    } else {
      setDisabeldActions(['reset_deleted', 'permanent_delete']);
    }
    // setDisabeldActions is a dispatch function that should not be a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableActions, filters?.deleted?.value]);

  const setRedux = () => {
    const activeFilters = tableFilter;
    Object.keys(filters).forEach(f => {
      activeFilters[f] = filters[f].value;
    });
    dispatchApp.set('system', 'tableFilter', activeFilters);
  };

  const handleClose = name => {
    setState({ type: 'SETCLOSE', payload: name });

    dispatch({
      type: BSN_SET_TARGET,
      payload: {
        path: ['system'],
        value: old => {
          const { [name]: _n, ...rest } = old.tableFilter;
          return {
            ...old,
            tableRowSelected: [],
            tableRowSelectedList: [],
            tableFilter: rest
          };
        }
      }
    });
    setIsSelectedDeletedFilter(false);
  };

  const onChange = ({ target: { name, value, checked } }, isMultiple) => {
    const isWithoutEmptyValue = isMultiple ? value.every(val => val !== '') : true;
    if (isWithoutEmptyValue) {
      if (name === 'deleted' && value === 'true') {
        setIsSelectedDeletedFilter(true);
      } else {
        setIsSelectedDeletedFilter(false);
      }

      setState({ type: 'SETVALUE', payload: { name, value } });
      dispatch({ type: BSN_SET_ANY, payload: { system: { tableFilter: { [name]: value } } } });
    }
  };

  const onClick = name => {
    setState({ type: 'SETFILTER', payload: name });
  };

  const setAnchor = el => {
    setState({ type: 'SETANCHOR', payload: el });
  };

  const Select = ({ name }: { name: string }) =>
    filters[name].active ? (
      <ClientsUsersSelect
        key={name}
        state={filters[name]}
        handleClose={handleClose}
        onChange={e => onChange(e, filters[name].multiple)}
        name={name}
        choicesTags={choicesTags}
        choicesGroups={choicesGroups}
      />
    ) : null;

  const isNoFiltersActive = useCallback(() => {
    for (const filterKey in newFilters) {
      if (newFilters[filterKey].active) {
        return false;
      }
    }
    return true;
  }, [newFilters]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      flexWrap="wrap-reverse"
      padding="12px"
      gap="8px"
      marginTop={isNoFiltersActive() ? '12px' : '64px'}
    >
      <ContainerToolbarItemLeft>
        <ContainerToolbarFilters>
          <Select name="group_role" />
          <Select name="tag" />
          <Select name="ess" />
          <Select name="policy" />
          <Select name="other_policy" />
          <Select name="activated" />
          <Select name="locked" />
          <Select name="deleted" />
          <Select name="welcome_email_received" />
        </ContainerToolbarFilters>
      </ContainerToolbarItemLeft>
      <ContainerToolbarItemRight>
        <ConatainerOuter>
          {enableFilters ? (
            <Button onClick={e => setAnchor(e.currentTarget)} sx={{ mr: 1 }} startIcon={<FilterIcon />}>
              Add Filter
            </Button>
          ) : (
            <LazyIcon component="Loading" mr={1} color="colorDefault" size={3} />
          )}
          <ContainerGrow open={Boolean(anchor)} anchorEl={anchor} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setAnchor(null)}>
                    <MenuList autoFocusItem={Boolean(anchor)} id="simple-menu-filter">
                      {enableFilters &&
                        Object.keys(newFilters).map(newMenu => {
                          if (!newFilters[newMenu].active) {
                            return (
                              <MenuItem onClick={() => onClick(newMenu)}>
                                <LazyIcon component="Plus" color="colorDefault" mr={1} size={0.7} />
                                {getMenuTitle(newMenu)}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </ContainerGrow>
        </ConatainerOuter>

        <ClientsUsersAddButton
          tab="addUser"
          label="New User"
          setRefetch={setRefetch}
          transformBeforeSubmit={transformBeforeSubmit}
          startIcon={<UserIcon />}
        />
        {enableTags && (
          <ClientsUsersAddButton tab="tags" label="New Tag" setRefresh={setRefetch} startIcon={<TagIcon />} />
        )}
        {app === 'myCompany' && <ClientsUsersDownloadReportButton client_id={client_id} />}
      </ContainerToolbarItemRight>
      {userRole != 'Manager' &&
        userRole != 'User' &&
        userRole != 'Pending' &&
        !(userRole == 'Manager Admin' && !user_profile.dir_sync) && (
          <ToolbarRightBtnsContainer>
            <CustomTooltip
              title={
                buttonsState?.directory_sync_enabled ? (
                  <span style={{ whiteSpace: 'nowrap' }}>
                    CSV Bulk Upload cannot be used when
                    <br /> Directory Sync is enabled
                  </span>
                ) : (
                  ''
                )
              }
              placement="top"
            >
              <div>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: CSV_BULK_UPLOAD_CLIENTS.includes(app)
                        ? `${CSV_BULK_UPLOAD_CLIENTS}/${client_id}`
                        : `${CSV_BULK_UPLOAD_MYCOMPANY}`,
                      state: {
                        isActive: true,
                        buttonsState: buttonsState
                      }
                    });
                  }}
                  sx={{ mr: 1 }}
                  startIcon={<CSVIcon />}
                  disabled={buttonsState?.directory_sync_enabled}
                >
                  CSV Bulk Upload
                </Button>
              </div>
            </CustomTooltip>
            <Button
              onClick={() => {
                history.push({
                  pathname: `${app !== 'myCompany' ? WELCOME_MESSAGE_CLIENTS : WELCOME_MESSAGE_MYCOMPANY}/${client_id}`,
                  search: `?${Object.entries({
                    id: client_id,
                    test_message_id: client_id,
                    new_welcome_message_seen: '1',
                    email: user_profile.email,
                    location: `${history.location.pathname}`
                  })
                    .map(v => v.join('='))
                    .join('&')}`
                });
              }}
              sx={{ mr: 1 }}
              startIcon={<PencilIcon />}
            >
              Edit Welcome message
            </Button>
          </ToolbarRightBtnsContainer>
        )}
    </Box>
  );
};

function reducer(prevState: ClientsUsersToolbarStateTypes, { type, payload }: ClientsUsersToolbarDispatchTypes) {
  const newState = prevState;
  switch (type) {
    case 'SETVALUE': {
      return updateNestedMutable(prevState, { filters: { [payload.name]: { value: payload.value } } });
    }

    case 'SETCLOSE': {
      const resetValue = payload === 'group_role' || payload === 'ess' || payload === 'tag' ? [] : '';
      const updatedFilters = {
        ...newState.filters,
        [payload]: {
          ...newState.filters[payload],
          value: resetValue,
          active: false
        }
      };

      const updatedState = { ...newState, filters: updatedFilters };
      return updatedState;
    }

    case 'SETFILTER': {
      newState.filters[payload].active = true;
      newState.anchor = null;
      return { ...newState };
    }

    case 'EMPTYFILTERS': {
      const defaultFilters = {
        filters: {
          group_role: {
            active: false,
            value: [],
            multiple: true
          },
          tag: {
            active: false,
            value: [],
            multiple: true
          },
          ess: {
            active: false,
            value: [],
            multiple: true
          },
          policy: {
            active: false,
            value: '',
            multiple: false
          },
          other_policy: {
            active: false,
            value: '',
            multiple: false
          },
          activated: {
            active: false,
            value: '',
            multiple: false
          },
          locked: {
            active: false,
            value: '',
            multiple: false
          },
          deleted: {
            active: false,
            value: '',
            multiple: false
          },
          welcome_email_received: {
            active: false,
            value: '',
            multiple: false
          }
        },
        anchor: null
      };
      return {
        ...newState,
        ...defaultFilters
      };
    }

    case 'SETANCHOR': {
      newState.anchor = payload;
      return { ...newState };
    }

    default: {
      return { ...prevState, ...payload };
    }
  }
}

function getMenuTitle(title) {
  switch (title) {
    case 'ess':
      return title.toUpperCase();

    case 'activated':
      return 'Status';

    case 'welcome_email_received':
      return 'Welcome Email Status';

    default:
      return snakeToCamelTitle(title);
  }
}

async function fetch(tab: string, item: string, tags, set) {
  const app = 'clients';
  const params = tab === 'tags' ? { _start: 0, _end: 250 } : {};

  try {
    const queryName = dataQueryName({ app, tab, item });
    const query = await queryClient.fetchQuery(`${queryName + Date.now()}`, async () => {
      const { data } = await dataFetch({ app, tab, item, params });
      return data.map(({ name: n, id, ...rest }) => ({ label: n, value: rest.group_id ? rest.group_id : id }));
    });
    if (!arrayOfObjectsEqual(tags, query)) set(query);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default ClientsUsersToolbar;
