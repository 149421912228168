import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { EditIcon, DeleteIcon } from 'components/icons';

const useStyles = makeStyles(theme => ({
  menuBtn: {
    padding: '5px',
    '& svg': {
      fontSize: 30,
      color: 'var(--colorIcon)'
    }
  },
  menuLink: {
    fontSize: 14,
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    },
    '& svg': {
      marginRight: '12px',
      fontSize: 24
    },
    '&.danger:hover': {
      color: 'var(--colorSystemDanger)'
    }
  }
}));

const HeaderActions = ({ recommendation, setCurrentRecommendation, setOpenAddEditModal, setOpenDeleteModal }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const { id, admin_created } = recommendation;
  const isPartnerAdmin = userRole === 'Partner Administrator';
  const disableDelete = isPartnerAdmin && admin_created;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editRecommendation = () => {
    setCurrentRecommendation(recommendation);
    setOpenAddEditModal(true);
    handleClose();
  };

  const deleteRecommendation = () => {
    setCurrentRecommendation(recommendation);
    setOpenDeleteModal(true);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`long-menu${id}`}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menuBtn}
        component="span"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`long-menu${id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // keepMounted={openEditModal || openDeleteModal}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            boxShadow: '0px 18px 20px 15px rgba(8, 47, 73, 0.03)',
            transform: 'translateY(50px)'
          }
        }}
      >
        <MenuItem onClick={editRecommendation} className={classes.menuLink}>
          <EditIcon size={1.7} /> Edit Recommendation
        </MenuItem>

        {!disableDelete ? (
          <MenuItem onClick={deleteRecommendation} className={`${classes.menuLink} danger`}>
            <DeleteIcon size={1.7} /> Delete
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default HeaderActions;
