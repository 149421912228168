// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import newsfeed from 'helpers/apis/newsfeed';
import { updateNotifications, deleteFeed, setHasPinnedPost } from 'helpers/action';
import { DeleteConfirmationModal } from 'components';

const DeletePostModal = ({ open, close, postId, isPinned }) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const deletePost = () => {
    dispatch(deleteFeed(postId));
    if (isPinned) dispatch(setHasPinnedPost(false));

    newsfeed
      .deletePost(postId)
      .then(res => {
        if (res?.status && res.status === 200) dispatch(updateNotifications(true));
        notify(res?.data?.message);
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      });
    close();
  };

  return (
    <DeleteConfirmationModal
      open={open}
      close={close}
      onDelete={deletePost}
      title="Delete Post?"
      description="This post will be deleted and cannot be recovered."
    />
  );
};

export default DeletePostModal;
