import React from 'react';
import { AlertCampaignIcon, Avatar, Stack, Typography, styled } from '@trustsecurenow/components-library';

const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: '#154D5C',
  width: 80,
  height: 80,
  borderRadius: 12
}));

const AccountDependence = () => {
  return (
    <Stack
      py={11}
      px={3.75}
      maxWidth={400}
      margin="auto"
      spacing={{ xs: 4, md: 3 }}
      alignItems="center"
      textAlign="center"
    >
      <StyledAvatar>
        <AlertCampaignIcon sx={{ fontSize: 40 }} />
      </StyledAvatar>
      <Stack spacing={{ xs: 3, md: 2 }}>
        <Typography
          color="common.white"
          variant="heading"
          fontSize={{ xs: 24, md: 40 }}
          lineHeight={{ xs: '24px', md: '67.2px' }}
        >
          Account Dependence
        </Typography>
        <Typography color="common.white" variant="subtitle1">
          Access to this app requires an account in a qualifying PII Protect product. Please reach out to your IT
          provider to inquire about gaining access.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AccountDependence;
