import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';
import { Link as RouterLink } from 'react-router-dom';

const UsersBouncedEmail = ({ usersPercent = '', onClick }) => {
  if (isNA(usersPercent)) return <Typography variant="body2">N/A</Typography>;

  if (usersPercent === 0) {
    return (
      <Tooltip title="No bounced emails to be removed" placement="top">
        <Typography variant="body2" color="error.main">{usersPercent}%</Typography>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Click here to remove bounced emails from users" placement="top">
      <Link
        underline="hover"
        hoverColor="info.main"
        onClick={onClick}
        component="button"
        variant="body2"
      >
        {usersPercent}%
      </Link>
    </Tooltip>
  );
};

UsersBouncedEmail.propTypes = {
  usersPercent: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default UsersBouncedEmail;
