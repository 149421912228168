// @flow
import React, { useState, useEffect } from 'react';
import {
  TextField,
  CircularProgress,
  Grid,
  Card,
  Box,
  CardHeader,
  Divider,
  CardContent,
  Typography as MuiTypography,
  CardActions
} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { dataProvider, getStorage, isValidEmail, trackingUtils } from 'helpers';
import PartnerApi from 'helpers/apis/partner';
import { Button, Container, SelectField, Switch, Typography, LoadingStyled, LazyIcon } from 'components';
import { useNotify } from 'react-admin';
import catchPhish from 'helpers/apis/catchPhish';
import { InfoButtons, ActionButtons } from './shared';
import { SaveButton, ContainerTitle, ContainerFooter } from './ComponentTypes';
import { useSelector } from 'react-redux';

const ForwardToMSPMessageTextChoices = [
  { key: 'Send to IT Administrator', value: 'Send to IT Administrator' },
  { key: 'Send to IT Support', value: 'Send to IT Support' },
  { key: 'Send to Service Provider', value: 'Send to Service Provider' }
];

const AUTO_PUBLISH_OPTIONS = [
  { label: 'Instant', value: 0 },
  { label: '1 day', value: 1 },
  { label: '2 days', value: 2 },
  { label: '3 days', value: 3 },
  { label: '4 days', value: 4 },
  { label: '5 days', value: 5 },
  { label: '6 days', value: 6 },
  { label: '7 days', value: 7 },
  { label: '8 days', value: 8 },
  { label: '9 days', value: 9 },
  { label: '10 days', value: 10 },
  { label: '11 days', value: 11 },
  { label: '12 days', value: 12 },
  { label: '13 days', value: 13 },
  { label: '14 days', value: 14 }
];

const PartnerNotifConfiguration = () => {
  const [emails, setEmails] = useState(null);
  const [msp, setMsp] = useState(null);
  const [sraConfigs, setSraConfigs] = useState({
    auto_publish_reports: false,
    auto_publish_delay: 2,
    communication_emails_enabled: true,
    communication_emails: ''
  });
  const [isSraConfigEmailsValid, setIsSraConfigEmailsValid] = useState(true);
  const [loadingSraConfigData, setLoadingSraConfigData] = useState(false);
  const [loadingSraConfig, setLoadingSraConfig] = useState(false);
  const [initialState, setInitialState] = useState({
    emails: null,
    msp: null
  });
  const [isMspLoading, setIsMspLoading] = useState(true);
  const [isMspDisabled, setIsMspDisabled] = useState(false);
  const [isMspEmailsValid, setIsMspEmailsValid] = useState(false);
  const [validateEmails, setValidateEmails] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEmailAddressesFieldDisabled, setIsEmailAddressesFieldDisabled] = useState(false);
  const {
    user: { profile }
  } = useSelector(rxState => rxState.bsn);
  const isNewSRA = useSelector(({ bsn }) => bsn?.user?.access?.features?.new_sra);
  const partner_id = getStorage('partnerId', true);
  const notify = useNotify();

  const validateEmailsMulti = event => {
    // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const result = event.target.value.replace(/\s/g, '').split(/;/);
    if (event.target.name === 'contact_us_emails') setEmails({ ...emails, contact_us: result });
    else setEmails({ ...emails, directory_sync: event.target.value });
    let val = false;
    if (event.target.name === 'contact_us_emails') {
      for (const element of result) {
        if (
          (element === '' && event.target.value !== '') ||
          (!isValidEmail(element) && element !== '') ||
          element.length > 199
        ) {
          val = true;
        }
      }
      if (event.target.value === '') val = true;
    } else if (result.length > 1 || !isValidEmail(result[0])) val = true;

    if (event.target.name === 'contact_us_emails') setValidateEmails(val);
    else setValidateEmail(val);
  };

  const saveEmails = () => {
    setLoading(true);
    dataProvider.update('partnerProfile', `notificationEmails/${partner_id}`, emails).then(
      res => {
        setLoading(false);
        notify('Successfully Updated!');

        if (!isEqual(emails.contact_us, initialState?.emails?.contact_us)) {
          trackingUtils.customEvent('completed_subtask', {
            sendUserName: true,
            sendPartnerName: true,
            label: 'Contact Us Notifications'
          });
        }

        if (emails.directory_sync !== initialState?.emails?.directory_sync) {
          trackingUtils.customEvent('completed_subtask', {
            sendUserName: true,
            sendPartnerName: true,
            label: 'Directory Sync Notifications'
          });
        }
      },
      res => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (emails === null) {
      dataProvider.getOne('partnerProfile', `notificationEmails/${partner_id}`).then((res = {}) => {
        setEmails(res.data);
        setIsEmailAddressesFieldDisabled(
          profile?.partner_distributor === 'MP' && res.data.contact_us.filter(email => email)?.length > 0
        );
        setInitialState(prevState => ({ ...prevState, emails: cloneDeep(res.data) }));
        setLoading(false);
      });
    }
  }, [emails]);

  useEffect(() => {
    setIsMspLoading(true);
    catchPhish
      .getFeatures()
      .then(res => {
        const mspData = {
          ...msp,
          EnableForwardToMSPMessage: res.data.filter(
            feature => feature.feature_name === 'EnableForwardToMSPMessage'
          )[0],
          ForwardedEmails: res.data.filter(feature => feature.feature_name === 'ForwardedEmails')[0],
          ForwardToMSPMessageText: res.data.filter(feature => feature.feature_name === 'ForwardToMSPMessageText')[0]
        };
        setMsp(mspData);
        setInitialState(prevState => ({ ...prevState, msp: cloneDeep(mspData) }));
        setIsMspLoading(false);
      })
      .catch(err => {
        setIsMspLoading(false);
      });
  }, []);

  // Get SRA configuration
  useEffect(() => {
    setLoadingSraConfigData(true);

    PartnerApi.getSraAutoPublishSettings(partner_id)
      .then(res => {
        const data = res?.data;
        const { auto_publish_delay, communication_emails_enabled, communication_emails } = data || {};
        const isAutoPublishDisabled = auto_publish_delay === null;

        setSraConfigs({
          auto_publish_reports: !isAutoPublishDisabled,
          auto_publish_delay: isAutoPublishDisabled ? 2 : auto_publish_delay,
          communication_emails_enabled,
          communication_emails: communication_emails || ''
        });
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Error in getting Security Risk Assessment Configuration', 'error');
      })
      .finally(() => {
        setLoadingSraConfigData(false);
      });
  }, [partner_id, notify]);

  const submitMsp = async () => {
    try {
      // removing empty emails strings before sending the request
      const mspCopy = {
        ...msp,
        ForwardedEmails: {
          ...msp.ForwardedEmails,
          feature_value: msp.ForwardedEmails.feature_value && msp.ForwardedEmails.feature_value.filter(email => email)
        }
      };
      const payload = { features: Object.values(mspCopy) };
      setIsMspLoading(true);
      const response = await catchPhish.setFeatures(payload);
      notify('CatchPhish configurations have been successfully updated!', 'success');

      if (!isEqual(initialState.msp, mspCopy)) {
        trackingUtils.customEvent('completed_subtask', {
          sendUserName: true,
          sendPartnerName: true,
          label: 'Catch Phish Configuration'
        });
      }

      setInitialState(prevState => ({ ...prevState, msp: cloneDeep(mspCopy) }));
      setIsMspLoading(false);
    } catch (err) {
      notify(err.response?.data?.description || err.msessage, 'error');
      setIsMspLoading(false);
    }
  };

  // On change SRA configuration
  const changeSraConfigs = (name, value) => {
    setSraConfigs(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  // Set SRA configuration
  const submitSraConfigs = () => {
    setLoadingSraConfig(true);

    const newData = {
      auto_publish_delay: sraConfigs.auto_publish_reports ? sraConfigs.auto_publish_delay : null,
      communication_emails_enabled: sraConfigs.communication_emails_enabled,
      communication_emails: sraConfigs.communication_emails || null
    };

    PartnerApi.setSraAutoPublishSettings(partner_id, newData)
      .then(res => {
        const message = res?.data?.description;
        if (message) notify(message);
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Error in updating Security Risk Assessment Configuration', 'error');
      })
      .finally(() => {
        setLoadingSraConfig(false);
      });
  };

  if (loading || !emails) return <LoadingStyled />;

  return (
    <>
      {/* Email Notifications */}
      <Container.Paper mt={2} radius={1}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                Email Notifications
              </Typography.h6>
              <InfoButtons name="partner_notifications" />
            </ContainerTitle>

            <Container.Grid item md={12} pl={1.5} pr={1.5} flexWrap="wrap" mb={2}>
              <Container.Grid p={1} item xs={12} sm={12} md={12} lg={4}>
                <TextField
                  error={validateEmails}
                  helperText={validateEmails && 'Please enter a valid email address'}
                  value={emails.contact_us.join(';')}
                  name="contact_us_emails"
                  label="Email addresses to receive contact us notifications (separated by semicolon)"
                  variant="outlined"
                  onChange={validateEmailsMulti}
                  fullWidth
                  required
                  disabled={isEmailAddressesFieldDisabled}
                  inputProps={{ style: { cursor: isEmailAddressesFieldDisabled ? 'not-allowed' : 'auto' } }}
                />
              </Container.Grid>

              <Container.Grid p={1} item xs={12} sm={12} md={12} lg={4}>
                <TextField
                  error={validateEmail}
                  helperText={validateEmail && 'Please enter a valid email address'}
                  value={emails.directory_sync}
                  name="clients_direc_emails"
                  label="Email address to receive directory synchronization errors"
                  variant="outlined"
                  onChange={validateEmailsMulti}
                  fullWidth
                  required
                />
              </Container.Grid>
            </Container.Grid>

            <Container.Grid item xs={12}>
              <ContainerFooter>
                <SaveButton disabled={validateEmail || validateEmails} onClick={saveEmails}>
                  Save
                </SaveButton>
              </ContainerFooter>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>

      {/* CatchPhish Configuration */}
      <Card component={Box} mt={2}>
        <CardHeader
          title={
            <Box p={1.5}>
              <MuiTypography variant="h6">CatchPhish Configuration</MuiTypography>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          {msp === null ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={25} thickness={2} />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Switch
                  label="Forward to MSP"
                  value={
                    msp.EnableForwardToMSPMessage.feature_value &&
                    Boolean(Number(msp.EnableForwardToMSPMessage.feature_value))
                  }
                  checked={
                    msp.EnableForwardToMSPMessage.feature_value &&
                    Boolean(Number(msp.EnableForwardToMSPMessage.feature_value))
                  }
                  name="EnableForwardToMSPMessage"
                  onChange={(event, value) => {
                    setMsp(prevState => {
                      return {
                        ...prevState,
                        EnableForwardToMSPMessage: {
                          ...prevState.EnableForwardToMSPMessage,
                          feature_value: value ? '1' : '0'
                        }
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectField
                  id="ForwardToMSPMessageText"
                  name="ForwardToMSPMessageText"
                  label="Button text"
                  choices={ForwardToMSPMessageTextChoices}
                  fullWidth
                  labelKey="key"
                  valueKey="value"
                  value={msp.ForwardToMSPMessageText.feature_value}
                  onChange={e => {
                    setMsp({
                      ...msp,
                      ForwardToMSPMessageText: {
                        ...msp.ForwardToMSPMessageText,
                        feature_value: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  error={isMspEmailsValid}
                  helperText={isMspEmailsValid && 'Please enter a valid email address'}
                  value={msp.ForwardedEmails.feature_value ? msp.ForwardedEmails.feature_value.join(';') : ''}
                  label="Email addresses to receive email analysis (Separated by semicolon)"
                  variant="outlined"
                  onChange={e => {
                    if (e.target.value.startsWith(';')) return;
                    setIsMspDisabled(false);
                    setIsMspEmailsValid(false);
                    const emails = e.target.value.replace(/\s/g, '').split(';');
                    const areaEmailsValid = emails.every(email => isValidEmail(email));
                    setMsp({
                      ...msp,
                      ForwardedEmails: {
                        ...msp.ForwardedEmails,
                        feature_value: emails
                      }
                    });
                    // accepting emapty emails
                    if ((emails.length === 1) & (emails[0] === '')) {
                      return;
                    }
                    if (!areaEmailsValid) {
                      setIsMspDisabled(true);
                      setIsMspEmailsValid(true);
                    }
                  }}
                  name="ForwardedEmails"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  justifyContent="left"
                  onClick={() => {
                    const url =
                      'https://www.breachsecurenow.com/wp-content/uploads/2021/02/Deploying-Catch-Phish-Plug-In-in-O365-Partner-Guide-Ver.2021.02.12.pdf';
                    window.open(url, '_blank').focus();
                  }}
                >
                  <LazyIcon
                    style={{ fontSize: `var(--fontSize)` }}
                    color="var(--colorCommonWhite)"
                    component="Welcome"
                  />
                  &nbsp;&nbsp; Download Catch Phish instructions here
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Box marginLeft="auto" p={1.5}>
            <SaveButton onClick={submitMsp} disabled={isMspDisabled || isMspLoading || loading}>
              {(loading || isMspLoading) && (
                <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
              )}
              Save
            </SaveButton>
          </Box>
        </CardActions>
      </Card>

      {/* Security Risk Assessment Configuration */}
      {isNewSRA && (
        <Card component={Box} mt={2}>
          <CardHeader
            title={
              <Box p={1.5}>
                <MuiTypography variant="h6">Security Risk Assessment Configuration</MuiTypography>
              </Box>
            }
          />

          <Divider />

          {loadingSraConfigData ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress size={25} thickness={2} />
            </Box>
          ) : (
            <CardContent>
              <Grid container spacing={2}>
                {/* Auto Publish Reports */}
                <Grid item xs={12} lg={5}>
                  <Box p={2}>
                    <Switch
                      label="Auto-Publish Reports"
                      value={sraConfigs.auto_publish_reports}
                      checked={sraConfigs.auto_publish_reports}
                      name="auto_publish_reports"
                      onChange={e => {
                        const { name, checked } = e.target;
                        changeSraConfigs(name, checked);
                      }}
                    />
                  </Box>

                  <SelectField
                    label="Choose to instantly publish reports or delay publishing to the client up to 14 days"
                    name="auto_publish_delay"
                    choices={AUTO_PUBLISH_OPTIONS}
                    fullWidth
                    labelKey="label"
                    valueKey="value"
                    disabled={!sraConfigs.auto_publish_reports}
                    value={sraConfigs.auto_publish_delay}
                    onChange={e => {
                      const { name, value } = e.target;
                      changeSraConfigs(name, value);
                    }}
                  />
                </Grid>

                {/* Receive mark complete email */}
                <Grid item xs={12} lg={5}>
                  <Box p={2}>
                    <Switch
                      label="Receive mark complete email"
                      value={sraConfigs.communication_emails_enabled}
                      checked={sraConfigs.communication_emails_enabled}
                      name="communication_emails_enabled"
                      onChange={e => {
                        const { name, checked } = e.target;
                        changeSraConfigs(name, checked);
                      }}
                    />
                  </Box>

                  <TextField
                    name="communication_emails"
                    label="Email address to receive all Risk Assessment communication emails (Separated by semicolon)"
                    variant="outlined"
                    fullWidth
                    error={!isSraConfigEmailsValid}
                    helperText={!isSraConfigEmailsValid && 'Please enter a valid email address'}
                    value={sraConfigs.communication_emails}
                    onChange={e => {
                      const { value } = e.target;
                      if (value.startsWith(';')) return;

                      setIsSraConfigEmailsValid(true);

                      const newEmails = value.replace(/\s/g, '').split(';');
                      const allEmailsValid = newEmails.every(email => isValidEmail(email));

                      changeSraConfigs('communication_emails', newEmails.join(';'));

                      if (newEmails.length === 1 && newEmails[0] === '') return;
                      if (!allEmailsValid) setIsSraConfigEmailsValid(false);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}

        <Divider />

        <CardActions>
          <Box marginLeft="auto" p={1.5}>
            <SaveButton
              onClick={submitSraConfigs}
              disabled={!isSraConfigEmailsValid || loadingSraConfigData || loadingSraConfig}
            >
              {loadingSraConfig && (
                <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
              )}
              Save
            </SaveButton>
          </Box>
        </CardActions>
      </Card>        
      )}

      {/* Action buttons */}
      <ActionButtons />
    </>
  );
};

export default PartnerNotifConfiguration;
