import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  Link,
  MenuItem,
  TextField,
  Box,
  Stack,
  InputAdornment,
  TrashIcon,
  SearchIcon,
  CloseIcon,
  CircularProgress,
  UpArrowIcon,
  DownArrowIcon
} from '@trustsecurenow/components-library';

const ALL = 'all';
const ASC = 'asc';
const DESC = 'desc';

const filterUsers = (users, query, role) => {
  return users.filter(({ first_name, last_name, group_role }) => {
    const fullName = `${first_name} ${last_name}`.toLowerCase();
    return fullName.includes(query.toLowerCase()) && (role === ALL || group_role === role);
  });
};

const sortUsers = (users, sortField, sortDirection) => {
  return users.sort((a, b) => {
    const fieldA = a[sortField] || '';
    const fieldB = b[sortField] || '';

    return sortDirection === ASC
      ? fieldA.localeCompare(fieldB, undefined, { sensitivity: 'base' })
      : fieldB.localeCompare(fieldA, undefined, { sensitivity: 'base' });
  });
};

function TeamsAppEngagementReminderDialog({ state, resetModal, onSubmit, getTeamsAppUnregisteredUsers }) {
  const [usersList, setUsersList] = useState(state.usersList);
  const [roleFilter, setRoleFilter] = useState(ALL);
  const [nameFilter, setNameFilter] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState(ASC);
  const SortIcon = sortDirection === ASC ? UpArrowIcon : DownArrowIcon;

  useEffect(() => {
    getTeamsAppUnregisteredUsers();
  }, []);

  useEffect(() => {
    setUsersList(state.usersList);
  }, [state.usersList]);

  const toggleSortDirection = field => {
    setSortField(prevField => (prevField === field && sortDirection === DESC ? '' : field));
    setSortDirection(prevDirection => {
      if (field !== sortField) return prevDirection;
      return prevDirection === DESC || !sortField ? ASC : DESC;
    });
  };

  const renderSortIcon = field => {
    if (field === sortField) {
      return <SortIcon sx={{ fontSize: '1rem' }} />;
    }
    return null;
  };

  const displayedUsers = useMemo(() => {
    const filtered = filterUsers(usersList, nameFilter, roleFilter);
    return sortUsers(filtered, sortField, sortDirection);
  }, [usersList, nameFilter, roleFilter, sortField, sortDirection]);

  const extractGroupRoles = data => {
    const roles = [];
    data.forEach(item => {
      if (!roles.includes(item.group_role)) {
        roles.push(item.group_role);
      }
    });
    return roles;
  };

  return (
    <Dialog open={state.open} fullWidth maxWidth="sm" PaperProps={{ sx: { height: state.loading ? 'auto' : 800 } }}>
      <DialogTitle>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Typography variant="h3">PII Protect Teams App Activation Reminder</Typography>
          {!state.loading && (
            <IconButton onClick={resetModal}>
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 200px)'
        }}
      >
        {state.loading ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress shaded size={75} />
          </Box>
        ) : (
          <>
            <Typography variant="subtitle1" mb={3}>
              Notifications from the PII Protect Teams app will be activated for users once they access the app for the
              first time. The following list includes users who haven't accessed the app yet.
            </Typography>
            <Typography variant="subtitle1" mb={4}>
              To inform the users below about the availability of the PII Protect Teams app, click the 'Send Reminder'
              button. This action will trigger the reminder email to send again, encouraging them to access the app for
              the first time. You can remove a user from the list by clicking the trashcan button.
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                borderBottom: theme => `1px solid ${theme.palette.grey[100]}`
              }}
            >
              <TextField
                label="Enter the user name"
                variant="outlined"
                fullWidth
                sx={{ flex: '1 1 70%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                value={nameFilter}
                onChange={e => setNameFilter(e.target.value)}
              />
              <TextField
                value={roleFilter}
                onChange={e => setRoleFilter(e.target.value)}
                select
                label="Group role"
                sx={{ flex: '1 1 30%' }}
              >
                <MenuItem value="all">Show All</MenuItem>
                {extractGroupRoles(state.usersList).map((role, index) => (
                  <MenuItem key={`${role + index}`} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              padding={2.5}
              border={theme => `1px solid ${theme.palette.grey[100]}`}
              borderRadius={theme => theme.shape.borderRadius}
              mt={3.5}
            >
              <Link
                component="button"
                underline="none"
                flex="1 1 80%"
                onClick={() => toggleSortDirection('first_name')}
                startIcon={renderSortIcon('first_name')}
              >
                Name
              </Link>
              <Link
                component="button"
                underline="none"
                flex="1 1 20%"
                onClick={() => toggleSortDirection('group_role')}
                startIcon={renderSortIcon('group_role')}
              >
                Group Role
              </Link>
            </Box>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto'
              }}
            >
              {displayedUsers.map(user => (
                <Box
                  key={user.user_id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding={2.5}
                  borderBottom={theme => `1px solid ${theme.palette.grey[100]}`}
                >
                  <Typography flex="1 1 80%" variant="body2">
                    {user.first_name} {user.last_name}
                  </Typography>
                  <Box display="flex" alignItems="center" flex="1 1 20%">
                    <Typography flex="1 1 65%" variant="body2">
                      {user.group_role}
                    </Typography>
                    <Box flex="1 1 35%">
                      <IconButton
                        onClick={() => setUsersList(list => list.filter(({ user_id }) => user_id !== user.user_id))}
                      >
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </DialogContent>

      {!state.loading && (
        <DialogActions sx={{ gap: 2 }}>
          <Link variant="link2" component="button" onClick={resetModal}>
            Cancel
          </Link>
          <Button onClick={() => onSubmit(displayedUsers.map(user => user.user_id))} color="info">
            Send Reminder
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

TeamsAppEngagementReminderDialog.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    usersList: PropTypes.string.isRequired
  }).isRequired,
  resetModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getTeamsAppUnregisteredUsers: PropTypes.func.isRequired
};

export default TeamsAppEngagementReminderDialog;
