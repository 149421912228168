import React, { useState } from 'react';
import { RHFTextField as TextField, Modal, RichTextEditorController } from 'components';
import LanguageIcon from '@material-ui/icons/Language';
import { getContentAdminFormTitle } from 'helpers';
import { getRichTextEdiorConfig, useTheme } from '@trustsecurenow/components-library';
import {
  ContentAdminFormGrid,
  ContentAdminFormLayoutHeaderContainer,
  ContentAdminFormRow,
  ContentAdminFormRowWithCard,
  ContentAdminFormTitle,
  ImportButton
} from '../../style';
import { useContentAdminFormContext } from '../../context';
import LandingPagesModal from './LandingPagesModal';

const defaultConfig = getRichTextEdiorConfig();

const disabledRichTextEditorButtons = ['eraser', 'print', 'copyformat', 'hr', 'fullsize', 'font', 'table'];

const LandingPagesForm = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { method, page } = useContentAdminFormContext();

  const title = getContentAdminFormTitle(page, method);

  const richTextConfig = React.useMemo(() => {
    const config = defaultConfig;
    config.iframe = false;
    config.uploader.insertImageAsBase64URI = false;
    config.buttons = config.buttons.filter(v => !disabledRichTextEditorButtons.includes(v));
    config.toolbarSticky = false;
    config.allowResizeY = true;
    config.maxHeight = undefined;
    config.style = { color: theme.palette.text.primary };
    return config;
  }, [theme.palette.text.primary]);

  const richTextEditor = React.useMemo(() => {
    return <RichTextEditorController name="html" hideMentions config={richTextConfig}/>;
  }, [richTextConfig]);

  return (
    <>
      <ContentAdminFormRowWithCard alignItems="center">
        <ContentAdminFormLayoutHeaderContainer>
          <ContentAdminFormTitle>{title}</ContentAdminFormTitle>
        </ContentAdminFormLayoutHeaderContainer>

        <ContentAdminFormRow alignItems="flex-start">
          <ContentAdminFormGrid md={3}>
            <TextField name="name" label="Page Name" fullWidth required />
          </ContentAdminFormGrid>
          <ContentAdminFormGrid md={3} py={0.8}>
            <ImportButton onClick={() => setOpenModal(true)}>
              <LanguageIcon />
              Import Site
            </ImportButton>
          </ContentAdminFormGrid>
        </ContentAdminFormRow>
      </ContentAdminFormRowWithCard>

      <ContentAdminFormRowWithCard>
        <ContentAdminFormGrid md={12} withoutPadding>
          <ContentAdminFormRow withoutPadding>
            <RichTextEditorController name="html" config={richTextConfig} />
          </ContentAdminFormRow>
        </ContentAdminFormGrid>
      </ContentAdminFormRowWithCard>

      <Modal
        data={<LandingPagesModal onClose={() => setOpenModal(false)} disabled={disabled} onDisabled={setDisabled} />}
        dismissOutside
        title="Import Site"
        opened={openModal}
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default LandingPagesForm;
