// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { IconButton, Menu, makeStyles } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { CopyLink, EditIcon, DeleteIcon, Flag, DownloadIcon, Star, StarFilled, Pin, PinFilled } from 'components';
import { AddUpdatePostModal, DeletePostModal, FlagModal, OverridePinnedPostModal } from '../modals';
import { getStorage, decodeId, fromUTCtoLocal, encodeId, copyTextToClipboard } from 'helpers';
import { useLocation } from 'hooks';
import { MenuLink } from '../common';
import newsfeed from 'helpers/apis/newsfeed';
import { updateFeeds, updateFeed, deleteFeed, setHasPinnedPost } from 'helpers/action';
import { getAssetUrl } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  menuBtn: {
    padding: '5px',
    '& svg': {
      fontSize: 30,
      color: 'var(--colorIcon)'
    }
  }
}));

const PostHeaderActions = ({ feed, isOwnPost, resetFeedList }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { item } = useLocation();
  const user = { id: decodeId(getStorage('userId')) };
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddUpdatePostModal, setOpenAddUpdatePostModal] = useState(false);
  const [openFlagModal, setOpenFlagModal] = useState(false);
  const [openOverridePinPostModal, setOpenOverridePinPostModal] = useState(false);
  const { canFlag, canPinPost, hasPinnedPost, filter } = useSelector(state => state?.newsfeed);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const isPA = userRole === 'Partner Administrator';
  const postMediaType = feed?.content?.type;
  const canDownload = isPA && (postMediaType === 'image' || postMediaType === 'video');
  const disableVideoActions = postMediaType === 'video' && !feed.file_ready;
  const isFavorite = feed.favorited;
  const isPinned = feed.pinned;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = () => {
    const postId = encodeId(feed.id);
    const postLink = `${window.location.origin}/#/newsfeed/dashboard/${postId}`;
    copyTextToClipboard(postLink);
    notify('Link has been copied to your clipboard');
    handleClose();
  };

  const editPost = () => {
    setOpenAddUpdatePostModal(true);
    handleClose();
  };

  const flagPost = () => {
    setOpenFlagModal(true);
    handleClose();
  };

  const togglePinPostRequest = postIsPinned => {
    let feedFileName = null;
    let feedThumbnailMetadata = null;

    if (feed?.content?.data) {
      if (feed.content.type === 'thumbnail') {
        feedThumbnailMetadata = feed.content.data;
      } else {
        feedFileName = feed.content.data.filename;
      }
    }

    const postData = {
      post: {
        text: feed.text,
        filename: feedFileName,
        thumbnail_metadata: feedThumbnailMetadata,
        expiration_datetime: feed.expiration_datetime,
        show_datetime: feed.show_datetime,
        audience_id: feed?.audience?.id,
        user_id: feed.user.id.toString(),
        pinned: postIsPinned
      },
      allowed_groups: feed.allowed_groups,
      included_clients: feed.included_clients,
      security_newsletter_id: feed?.security_newsletter?.id || '',
      microtraining_id: feed?.microtraining?.id || ''
    };

    newsfeed
      .updatePost(feed.id, postData)
      .then(res => {
        notify(
          postIsPinned
            ? 'Post has been pinned to the top of the feed'
            : 'Post has been unpinned from the top of the feed'
        );
        resetFeedList();
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      });
  };

  const togglePinPost = () => {
    const newIsPinned = !isPinned;

    if (newIsPinned && hasPinnedPost) {
      setOpenOverridePinPostModal(true);
    } else {
      togglePinPostRequest(newIsPinned);
      if (!newIsPinned) dispatch(setHasPinnedPost(false));
    }

    handleClose();
  };

  const handleOverridePinnedPost = () => {
    togglePinPostRequest(true);
    setOpenOverridePinPostModal(false);
  };

  const deletePost = () => {
    setOpenDeleteModal(true);
    handleClose();
  };

  const toggleFavorite = () => {
    const newIsFavorite = !isFavorite;
    handleClose();
    dispatch(updateFeed(feed.id, { favorited: newIsFavorite }));

    if (newIsFavorite) {
      newsfeed
        .favoritePost(feed.id)
        .then(res => {
          notify(res?.data?.description);
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        });
    } else {
      newsfeed
        .unFavoritePost(feed.id)
        .then(res => {
          notify(res?.data?.description);
          if (filter?.favorites) {
            dispatch(deleteFeed(feed.id));
            dispatch(updateFeeds(true));
          }
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        });
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`long-menu${feed.id}`}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={feed.disableActions}
        className={classes.menuBtn}
        component="span"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`long-menu${feed.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={openAddUpdatePostModal || openFlagModal || openDeleteModal}
        PaperProps={{
          style: { boxShadow: '0 6px 25px 0 rgba(83, 83, 83, 0.07)' }
        }}
      >
        {/* Edit */}
        {isOwnPost && (
          <MenuLink onClick={editPost}>
            <EditIcon size={1.7} /> Edit Post
          </MenuLink>
        )}
        {openAddUpdatePostModal && (
          <AddUpdatePostModal
            open={openAddUpdatePostModal}
            close={() => setOpenAddUpdatePostModal(false)}
            title="Edit post"
            postInfo={{
              post: {
                user_id: user.id,
                postId: feed.id,
                expiration_datetime:
                  feed.expiration_datetime !== '9999-12-31 23:59:59' ? fromUTCtoLocal(feed.expiration_datetime) : null,
                show_datetime: feed.show_datetime !== '1000-01-01 00:00:00' ? fromUTCtoLocal(feed.show_datetime) : null,
                text: feed.text,
                thumbnail_metadata: feed.content && feed.content.type === 'thumbnail' ? feed.content.data : null,
                filename: feed.content && feed.content.type !== 'thumbnail' ? feed.content.data.filename : null,
                filePreview: feed.content && feed.content.type !== 'thumbnail' ? feed.content : null,
                push_notifications: feed.push_notifications,
                email_notifications: feed.email_notifications
              },
              allowed_groups: feed.allowed_groups,
              included_clients: feed.included_clients,
              audience: feed.audience,
              security_newsletter: feed.security_newsletter,
              microtraining: feed.microtraining
            }}
          />
        )}

        {/* Favorite */}
        <MenuLink onClick={toggleFavorite}>
          {isFavorite ? <StarFilled size={1.7} /> : <Star size={1.7} />}
          Favorite
        </MenuLink>

        {/* Pin Post */}
        {canPinPost && isOwnPost && !item && (
          <MenuLink onClick={togglePinPost} disabled={disableVideoActions}>
            {isPinned ? (
              <>
                <PinFilled size={1.7} /> Unpin Post
              </>
            ) : (
              <>
                <Pin size={1.7} /> Pin Post
              </>
            )}
          </MenuLink>
        )}

        {/* Copy Link */}
        <MenuLink onClick={copyToClipboard}>
          <CopyLink size={1.7} /> Copy Link
        </MenuLink>

        {/* Download */}
        {canDownload && (
          <MenuLink onClick={handleClose} className="withLink" disabled={disableVideoActions}>
            <a
              href={getAssetUrl(feed?.content?.data?.url)}
              download={feed?.content?.data?.filename?.replace(/.*?_/, '')}
            >
              <DownloadIcon size={1.7} /> Download
            </a>
          </MenuLink>
        )}

        {/* Flag */}
        {canFlag && !isOwnPost && (
          <MenuLink onClick={flagPost}>
            <Flag size={1.7} /> Flag
          </MenuLink>
        )}
        {openFlagModal && (
          <FlagModal close={() => setOpenFlagModal(false)} open={openFlagModal} type="post" postId={feed.id} />
        )}

        {/* Delete */}
        {isOwnPost && (
          <MenuLink onClick={deletePost} className="danger">
            <DeleteIcon size={1.7} /> Delete
          </MenuLink>
        )}
        {openDeleteModal && (
          <DeletePostModal
            close={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            postId={feed.id}
            isPinned={isPinned}
          />
        )}
      </Menu>

      {openOverridePinPostModal && (
        <OverridePinnedPostModal
          open={openOverridePinPostModal}
          close={() => setOpenOverridePinPostModal(false)}
          onConfirm={handleOverridePinnedPost}
        />
      )}
    </>
  );
};

export default PostHeaderActions;
