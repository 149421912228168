import React from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Typography, RadioField, ButtonCancel } from 'components';
import { ArrowForwardIosSharp } from '@material-ui/icons';
import { Save2 } from 'components/icons';
import { GreenButton, BlueButton } from '../../style';

const EMAIL_VENDOR_CHOICES = [
  { value: 'Office365 (Exchange Online)', label: 'Office365 (Exchange Online)' },
  { value: 'GSuite', label: 'GSuite' },
  { value: 'Microsoft Exchange', label: 'Microsoft Exchange' },
  { value: 'Google Gmail', label: 'Google Gmail' },
  { value: 'Hotmail', label: 'Hotmail' },
  { value: 'Yahoo! Mail', label: 'Yahoo! Mail' },
  { value: 'Other', label: 'Other' }
];

const EMAIL_SERVER_LOCATION_CHOICES = [
  { value: 'Onsite', label: 'Onsite' },
  { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
  { value: 'Cloud(Google, Yahoo!, Hotmail)', label: 'Cloud(Google, Yahoo!, Hotmail)' },
  { value: 'Other', label: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const Email = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };

    if (name === 'email_vendor' && value !== 'Other' && state.email_vendor_other?.length !== 0) {
      newState.email_vendor_other = '';
    }

    setState(newState);
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <Container.Grid container spacing={4}>
      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container.Paper fullWidth radius={2} pb={2}>
          <Container.Grid container>
            {/* Do you utilize email in your organization? */}
            <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Grid container spacing={4} justify="space-between">
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {/* eslint-disable-next-line react/jsx-pascal-case */}
                  <Typography.h3 p={2} m={0.1} fontSize={16}>
                    1.Do you utilize email in your organization? *
                  </Typography.h3>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>

            {/* Email */}
            <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioField
                label=""
                name="email"
                choices={YES_NO_CHOICES}
                value={state.email}
                mt={2}
                mb={1}
                row
                labelPlacement="End"
                onChange={handleChangeState}
              />
            </Container.Grid>

            {state.email === 'Yes' ? (
              <>
                {/* Email accounts information */}
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        {isHSN
                          ? '2.Do you have patient information on any email accounts? *'
                          : '2.Do you have PII or Sensitive Data in any email accounts? *'}
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>

                {/* pi on email */}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name={isHSN ? 'phi_on_email' : 'pii_on_email'}
                    choices={YES_NO_CHOICES}
                    value={isHSN ? state.phi_on_email : state.pii_on_email}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeState}
                  />
                </Container.Grid>

                {/* What Email vendor and product do you use? */}
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        3.What Email vendor and product do you use? *
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>

                {/* Vendor */}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="email_vendor"
                    choices={EMAIL_VENDOR_CHOICES}
                    value={state.email_vendor}
                    mt={2}
                    mb={2}
                    row
                    labelPlacement="End"
                    onChange={handleChangeState}
                  />
                </Container.Grid>

                {/* Other Email Vendor */}
                {state.email_vendor === 'Other' && (
                  <>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.email_vendor_other}
                        name="email_vendor_other"
                        label="Other Email Vendor"
                        fullWidth
                        onChange={handleChangeState}
                        mb={1}
                      />
                    </Container.Grid>
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6} />
                  </>
                )}

                {/* Email Vendor Details */}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={state.email_vendor_details}
                    name="email_vendor_details"
                    label="Email Vendor Details"
                    fullWidth
                    rows={4}
                    multiline
                    onChange={handleChangeState}
                    mb={1}
                  />
                </Container.Grid>

                {/* Where is your Email Server? */}
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        4.Where is your Email Server? *
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>

                {/* Email server location */}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioField
                    label=""
                    name="email_server_location"
                    choices={EMAIL_SERVER_LOCATION_CHOICES}
                    value={state.email_server_location}
                    mt={2}
                    mb={1}
                    row
                    labelPlacement="End"
                    onChange={handleChangeState}
                  />
                </Container.Grid>

                {/* Please provide us with any additional details regarding your email */}
                <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Grid container spacing={4} justify="space-between">
                    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Typography.h3 p={2} m={0.1} fontSize={16}>
                        5.Please provide us with any additional details regarding your email
                      </Typography.h3>
                    </Container.Grid>
                  </Container.Grid>
                </Container.Grid>

                {/* Email details */}
                <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={state.email_details}
                    name="email_details"
                    placeholder="Ex: Office365(Exchange Online) is configured with Exchange Online
              Protection(EOP) and Data Loss Prevention(DLP)"
                    fullWidth
                    rows={4}
                    multiline
                    onChange={handleChangeState}
                  />
                </Container.Grid>
              </>
            ) : null}

            {/* Action buttons */}
            <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Grid container spacing={4} justify="flex-end">
                <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <ButtonCancel variant="text" onClick={e => handleClose()}>
                    Exit
                  </ButtonCancel>
                  <BlueButton startIcon={<Save2 />} variant="outlined" disableElevation onClick={save}>
                    Save
                  </BlueButton>
                  <GreenButton
                    startIcon={<ArrowForwardIosSharp />}
                    variant="outlined"
                    disableElevation
                    ml="30px"
                    onClick={() => save('next')}
                  >
                    Save and Next
                  </GreenButton>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
        </Container.Paper>
      </Container.Grid>
    </Container.Grid>
  );
};

Email.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default Email;
