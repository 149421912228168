import { getParams } from 'helpers/dataOptions';
import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_11;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const sra = {};

sra.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

sra.getClientOrganizationProfile = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/OrganizationProfiles`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * This endpoint gets information for the main SRA page
 * such as the Available Assesments, Compliance Center Summary stats, and Reports sections
 */
sra.getDashboardInfo = clientID => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${clientID}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getOrganizationProfile = (clientID, tab) => {
  const requestOptions = {
    url: `${baseURL}sra/organization_profile/${clientID}?tab=${tab}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.setOrganizationProfile = (clientID, tabData, tab) => {
  const requestOptions = {
    url: `${baseURL}sra/organization_profile/${clientID}`,
    headers: sra.getRequestHeaders(),
    data: { ...tabData, tab }
  };
  return apiClient.put(requestOptions);
};

sra.getClientSraStatus = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/Status`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};
sra.setClientOrganizationProfile = (clientID, clientOrganizationalProfile) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/OrganizationProfiles`,
    data: { organization_profile: clientOrganizationalProfile },
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.setClientSraStatus = (clientID, sraStatus) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/Status`,
    headers: sra.getRequestHeaders(),
    data: sraStatus
  };
  return apiClient.post(requestOptions);
};

sra.getClientSraQA = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/result`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getClientInfo = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/info`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.migrateClientSRA = clientID => {
  const requestOptions = {
    url: `${baseURL}quizzes/migrate/${clientID}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.setClientSraQA = (clientID, clientSraQA) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/result`,
    data: { questions: clientSraQA },
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.getRevisionRecommendations = ({ revision }) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/recommendations/${revision}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.updateRevisionRecommendation = ({ revision, revision_recommendation_id, date_completed, notes }) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/recommendations/${revision}`,
    data: { revision_recommendation_id, date_completed, notes },
    headers: sra.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

sra.getWorkplanInfo = revision_id => {
  const requestOptions = {
    url: `${baseURL}sra/workplan/information/${revision_id}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getWorkplanRecommendations = (params, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/workplan/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

sra.createWorkplanRecommendation = (data, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/workplan/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

sra.updateWorkplanRecommendation = (data, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/workplan/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

sra.deleteWorkPlanRecommendation = (data, revision_id) => {
  // TODO: get the right endpoint for recommendation deletion
  const requestOptions = {
    url: `${baseURL}sra/workplan/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.delete(requestOptions);
};

sra.getRevisionId = client_id => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/revision/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getRecommendations = (params, client_id, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    params: getParams({ ...params, keepInitialFilter: true })
  };
  return apiClient.get(requestOptions);
};

sra.createRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

sra.updateRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

sra.deleteRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/recommendations/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.delete(requestOptions);
};

sra.getSraReports = (params, client_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/risk-assessment-reports/`,
    headers: sra.getRequestHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

sra.getSraReportDetails = (client_id, report_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/risk-assessment-reports/${report_id}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.deleteSraReports = (client_id, report_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/risk-assessment-reports/${report_id}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

sra.restoreSraReports = (client_id, report_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/restore-risk-assessment-reports/${report_id}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.updateSraReport = (client_id, report_id, data) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/risk-assessment-reports/${report_id}/`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.patch(requestOptions);
};

sra.getSraTaskList = client_id => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/task_list/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.retakeSra = (client_id, revision_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/retake/${revision_id}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.completeSra = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/complete_sra/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

sra.getSraThreats = (client_id, revision_id, params) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/threats/${revision_id}`,
    headers: sra.getRequestHeaders(),
    params
  }
  return apiClient.get(requestOptions);
};

sra.getSRAQuizId = clientId => {
  const requestOptions = {
    url: `${baseURL}quizzes/sra-training/${clientId}`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.setSraThreat = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/threats/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

sra.deleteSraThreat = (client_id, revision_id, threat_id) => {
  const requestOptions = {
    url: `${baseURL}sra/${client_id}/threats/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data: {
      excluded: 1,
      id: threat_id
    }
  };
  return apiClient.delete(requestOptions);
}

sra.publishSraReport = (client_id, report_id) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/publish-sra-report/${report_id}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.setSraEditMode = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${baseURL}sra/dashboard/${client_id}/edit_mode/${revision_id}`,
    headers: sra.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

/**
 * @param {string} revisionID for which to download the wrok plan report
 */
sra.getWorkPlanReport = revisionID => {
  const requestOptions = {
    url: `${baseURL}reports/work-plan-report/${revisionID}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * @param {string} revisionID for which to download the executive summary report
 */
sra.getExecutiveSummaryReport = revisionID => {
  const requestOptions = {
    url: `${baseURL}reports/executive-summary-report/${revisionID}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getDetailedReport = revisionID => {
  const requestOptions = {
    url: `${baseURL}reports/detailed-report/${revisionID}/`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

export default sra;
