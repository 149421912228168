import apiClient from '../../apiClient/apiClient';
import { updateNestedMutable } from '../../../utils/update';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${api}.${base}/${env}`;

// const localURL = 'http://127.0.0.1:5000';

const userPreferences = {};

userPreferences.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

userPreferences.updateRequest = preferences => {
  const requestOptions = {
    url: `${baseURL}/user/preferences`,
    headers: userPreferences.getHeaders(),
    data: preferences
  };
  return apiClient.put(requestOptions);
};

userPreferences.setInitialValue = async preferences => {
  localStorage.setItem('preferences', JSON.stringify(preferences ?? {}));
};

userPreferences.get = () => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  return preferences;
};

userPreferences.getTableSettings = (app, tab) => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  const order = preferences?.tables?.[app]?.[tab]?.order;
  const orderBy = preferences?.tables?.[app]?.[tab]?.orderBy;
  const pagination = preferences?.tables?.[app]?.[tab]?.pagination;
  // const filter
  return { order, orderBy, pagination };
};

userPreferences.update = async pref => {
  const response = await userPreferences.updateRequest(pref);
  if (response.status === 200) {
    localStorage.setItem('preferences', JSON.stringify(pref));
  }
};

userPreferences.updateTableSettings = (app, tab, settings, preferences) => {
  return updateNestedMutable(preferences ?? {}, { tables: { [app]: { [tab]: settings } } });
};

userPreferences.setTableSettings = (app, tab, settings) => {
  let preferences = userPreferences.get();
  preferences = userPreferences.updateTableSettings(app, tab, settings, preferences);
  userPreferences.update(preferences);
};

userPreferences.getSfTableSettings = tableName => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  return preferences?.sftable?.[tableName] || {};
};
userPreferences.setSfTableSettings = async (tableName, settings) => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  const updatedPreferences = { ...preferences, sftable: { ...preferences?.sftable, [tableName]: settings } };
  userPreferences.update(updatedPreferences);
};

userPreferences.getMuiDataGridSettings = tableName => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  return preferences?.muiDataGrid?.[tableName] || null;
};

userPreferences.setMuiDataGridSettings = async (tableName, settings) => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  const updatedPreferences = { ...preferences, muiDataGrid: { ...preferences?.muiDataGrid, [tableName]: settings } };
  userPreferences.update(updatedPreferences);
};

userPreferences.setMuiDataGrid = async obj => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  const updatedPreferences = {
    ...preferences,
    muiDataGrid: {
      ...preferences?.muiDataGrid,
      ...obj
    }
  };
  userPreferences.update(updatedPreferences);
};

export default userPreferences;
