import apiClient from 'helpers/apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_18;
const baseURL = `https://${api}.${base}/${env}`;

const checkExternalPage = () => {
  // the following is partial hostname. full url.hostname example: dev-portal.yourcyberassessment.com
  // we will only use the common part regardless of environment
  const externalHostnames = ['yourcyberassessment'];
  // include the forward slash please for future pathnames
  const externalPathnames = ['/PreAssessment'];

  const url = new URL(window.location.href.replace('/#', ''));

  if (url.hostname === 'localhost') {
    // it may be an external page running in localhost, we need to check pathname...
    return externalPathnames.includes(url.pathname);
  } else {
    return externalHostnames.some(name => url.hostname.includes(name));
  }
};

const options = ({ path, data, headers, quiz_token }) => {
  const isExternalPage = checkExternalPage();
  const authKey = isExternalPage ? 'external-authorization' : 'Authorization';

  return {
    url: `${baseURL}${path}`,
    headers: {
      'Content-Type': 'application/json',
      [authKey]: quiz_token,
      ...headers
    },
    data
  };
};

const quiz = {};

quiz.getUserState = ({ quiz_token, quiz_id }) => {
  return apiClient.get(options({ quiz_token, path: `/quiz-user-state/quiz/${quiz_id}` }));
};
quiz.createQuizRevision = ({ quiz_token, quiz_id }) => {
  return apiClient.post(options({ quiz_token, path: `/quiz-user-revisions/create`, data: { quiz_id } }));
};
quiz.retakeQuizRevision = ({ quiz_token, quiz_id }) => {
  return apiClient.post(options({ quiz_token, path: `/quiz-user-revisions/retake`, data: { quiz_id } }));
};
quiz.resumeQuizRevision = ({ quiz_token, revision_id }) => {
  return apiClient.post(options({ quiz_token, path: `/quiz-user-revisions/resume`, data: { revision_id } }));
};
quiz.getCurrentQuestion = ({ quiz_token, revision_id }) => {
  return apiClient.get(options({ quiz_token, path: `/quizzes-questions/revision/${revision_id}/current-question` }));
};
quiz.setCurrentQuestion = ({ quiz_token, revision_id, question_id }) => {
  return apiClient.put(
    options({ quiz_token, path: `/quizzes-questions/revision/${revision_id}/set-question`, data: { question_id } })
  );
};
quiz.answerQuestion = ({ quiz_token, revision_id, question_id, answer_id }) => {
  return apiClient.post(
    options({
      quiz_token,
      path: `/quiz-user-answer/answer-question/${revision_id}/${question_id}`,
      data: { answer_id }
    })
  );
};
quiz.setNextQuestion = ({ quiz_token, revision_id }) => {
  return apiClient.put(options({ quiz_token, path: `/quizzes-questions/revision/${revision_id}/next-question` }));
};
quiz.endQuiz = ({ quiz_token, revision_id }) => {
  return apiClient.post(options({ quiz_token, path: `/quiz-user-revisions/end-quiz`, data: { revision_id } }));
};

export default quiz;
