import React from 'react';
import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CONFIGURE_DMD } from 'apps/shared/phishingReports/phishingTable/constants';
import { isNA } from '../helpers';

const DMDCell = ({ enabled, azureEnabled, isHIPAAComp, clientId }) => {
  if (isNA(enabled))
    return (
      <Tooltip
        placement="top"
        title={
          isHIPAAComp
            ? 'This account does not have phishing and cannot utilize Direct Mail Delivery'
            : !azureEnabled
            ? 'Azure AD Sync must be enabled in order to configure Direct Mail Delivery'
            : ''
        }
      >
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  const tooltipTitle = enabled ? '' : 'Click here to configure Direct Mail Delivery';
  const Icon = enabled ? CircleCheckIcon : CircleCloseIcon;
  const IconButtonProps = enabled
    ? {
        color: 'success',
        disableRipple: true
      }
    : {
        color: 'error',
        LinkComponent: Link,
        to: `/clients/phishingReports/${clientId}?redirectionTarget=${CONFIGURE_DMD}`
      };

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton {...IconButtonProps}>
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

DMDCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  azureEnabled: PropTypes.bool.isRequired,
  isHIPAAComp: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired
};

export default DMDCell;
