import { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import contentAdmin from '../../../helpers/apis/contentAdmin';
import {
  CONTENT_ADMIN_CAMPAIGN,
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED,
  CONTENT_ADMIN_ROOT_PATH,
  CONTENT_ADMIN_SCENARIO,
  CONTENT_ADMIN_SENDING_PROFILES
} from '../constants';
import { getContentAdminPath, getPickListLabel } from '../../../helpers';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  getEmailTemplateData,
  getEmailTemplateDefaultValues,
  getLandingPagesData,
  getLandingPagesDefaultValues,
  getPickListArr,
  getPickLists,
  getScenarioData,
  getScenariosDefaultValues,
  getSendingProfilesData,
  getSendingProfilesDefaultValues,
  setEmailTemplateData,
  setLandingPagesData,
  setScenarioData,
  setSendingProfilesData
} from '../helpers';
import apiClient from '../../../helpers/apiClient/apiClient';
import map from 'lodash/map';

function getTemplateData(page, record) {
  switch (page) {
    case CONTENT_ADMIN_EMAIL_TEMPLATES:
      return getEmailTemplateData(record);

    case CONTENT_ADMIN_LANDING_PAGES:
      return getLandingPagesData(record);

    case CONTENT_ADMIN_SENDING_PROFILES:
      return getSendingProfilesData(record);

    case CONTENT_ADMIN_SCENARIO:
      return getScenarioData(record);

    default:
      return record;
  }
}

function getDefaultValues(page) {
  switch (page) {
    case CONTENT_ADMIN_EMAIL_TEMPLATES:
      return getEmailTemplateDefaultValues;

    case CONTENT_ADMIN_LANDING_PAGES:
      return getLandingPagesDefaultValues;

    case CONTENT_ADMIN_SENDING_PROFILES:
      return getSendingProfilesDefaultValues;

    case CONTENT_ADMIN_SCENARIO:
      return getScenariosDefaultValues;

    default:
      return {};
  }
}

export const useContentAdminForm = (page, id, hasAccess) => {
  const notify = useNotify();
  const history = useHistory();

  const [defaultValues, setDefaultValues] = useState(getDefaultValues(page));

  const [loading, setLoading] = useState(false);
  const [bindingParams, setBindingParams] = useState([]);
  const [pickLists, setPickLists] = useState({});
  const [record, setRecord] = useReducer(reducer, {
    disabled: false,
    isSubmitted: false,
    errors: {},
    isLoading: false
  });

  const [errors, setErrors] = useState(null);

  const pickListQuery = useQuery('picklists-2', () => Promise.all(getPickListArr(page)), {
    keepPreviousData: true,
    staleTime: 10,
    cacheTime: false,
    refetchOnWindowFocus: false,
    enabled: hasAccess
  });

  const difficultiesRes = useQuery(
    'difficulties',
    () => contentAdmin.getPickList(CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED),
    {
      keepPreviousData: true,
      staleTime: 10,
      cacheTime: false,
      refetchOnWindowFocus: false,
      enabled: hasAccess
    }
  );

  useEffect(() => {
    (async () => {
      if (!hasAccess) return;
      dispatch.onChange('isLoading', true);

      // GET SENDING PROFILE TEMPLATE

      if (!id && page === CONTENT_ADMIN_SENDING_PROFILES) {
        try {
          const sendingProfileData = await contentAdmin.getSendingProfileTemplate();
          const { data } = sendingProfileData;
          dispatch.loadData(data);
        } catch (e) {
          console.log('err');
        }
      }

      // GET SINGLE TEMPLATES

      if (id) {
        try {
          const path = getContentAdminPath(page);
          const singleTemplateData = await contentAdmin.getSingleTemplate(path, id);
          const { data } = singleTemplateData;
          dispatch.loadData(data);
        } catch (e) {
          notify('Something went wrong');
        }
      }

      dispatch.onChange('isLoading', false);
    })();
  }, [hasAccess]);

  useEffect(() => {
    (async () => {
      if (!hasAccess) return;
      if (page === CONTENT_ADMIN_EMAIL_TEMPLATES || page === CONTENT_ADMIN_LANDING_PAGES) {
        setLoading(true);
        try {
          const data = await contentAdmin.getBindingParams();
          setBindingParams(data.data.params);
        } catch (e) {
          console.log('error', e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [hasAccess]);

  useEffect(() => {
    if (pickListQuery.status === 'success') {
      const pickLists = getPickLists(pickListQuery.data);

      setPickLists(prev => ({ ...prev, ...pickLists }));
    }
  }, [pickListQuery.isLoading]);

  useEffect(() => {
    if (difficultiesRes.status === 'success') {
      setPickLists(prev => ({
        ...prev,
        difficulties: difficultiesRes?.data?.data
          ? map(difficultiesRes?.data?.data, ({ difficulty_level, id }) => ({
              label: difficulty_level,
              value: id
            }))
          : []
      }));
    }
  }, [difficultiesRes.isLoading]);

  const setTemplate = async (method, ...params) => {
    try {
      const response = await contentAdmin[method](...params);
      const message = response.data.description;
      notify(message);
      history.push(`${CONTENT_ADMIN_ROOT_PATH}/${page}`);
    } catch (e) {
      if (e.response?.data) {
        if (e.response.data.error) {
          const errors = Object.fromEntries(
            Object.entries(e.response.data.error).map(([name, value]) => {
              return [name, { type: 'manual', message: value }];
            })
          );

          setErrors(errors);
        } else {
          notify(e.response.data.message, 'warning');
        }
      } else {
        notify('Something went wrong', 'warning');
      }
    } finally {
      dispatch && dispatch.disabled(false);
    }
  };

  const saveAttachmentsOnAWS = async (fileNames, presignedUrlType, attachments) => {
    const request = (url, data) => apiClient.post({ url, data });
    const presignedData = await contentAdmin.generatePresignedUrl({ filenames: fileNames }, presignedUrlType);

    const awsData = presignedData.data.presigned_data.map(({ aws_data, filename: presignedFileName }) => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(aws_data.fields)) {
        formData.append(key, value);
      }

      let file;

      if (Array.isArray(attachments)) {
        const { attachment } = attachments.find(({ attachment: { name } }) => name === presignedFileName);
        file = attachment;
      } else {
        file = attachments;
      }

      formData.append('file', file);

      return {
        formData,
        url: aws_data.url
      };
    });

    const awsDataRequest = awsData.map(({ url, formData }) => {
      return request(url, formData);
    });

    const data = await Promise.allSettled(awsDataRequest);

    const attachmentKeys = data.map(({ value, status }) => {
      if (status === 'fulfilled') {
        const formData = value.config.data;
        return formData.get('key');
      }
    });

    return attachmentKeys;
  };

  const getAttachmentsKeys = async data => {
    try {
      if (!data?.attachments) return [];
      const newAttachments = data.attachments.filter(({ isNew }) => isNew);

      const fileDisplayNames = newAttachments.map(({ attachment: { name } }) => ({ filename: name }));
      const filenames = data.attachments.filter(({ isNew }) => !isNew).map(({ filename }) => filename);

      if (fileDisplayNames.length) {
        const attachmentKeys = await saveAttachmentsOnAWS(fileDisplayNames, 'template-attachments', newAttachments);
        return [...filenames, ...attachmentKeys];
      }

      return filenames;
    } catch (e) {
      notify('Something went wrong', 'warning');
    }
  };
  const saveLogoOnAWS = async logo => {
    try {
      if (!logo) return;
      if (!logo.isNew) return logo.path;
      if (!logo.file) return '';
      const { name } = logo.file;
      const attachmentKeys = await saveAttachmentsOnAWS([{ filename: name }], 'template-logo', logo.file);

      return attachmentKeys[0];
    } catch (e) {
      notify('Something went wrong', 'warning');
    }
  };

  const dispatch = {};

  dispatch.onChange = (name, value) => {
    setRecord({
      type: 'change_field',
      payload: {
        name,
        value
      }
    });
  };

  dispatch.bulkAdd = value => {
    setRecord({
      type: 'bulkAdd',
      payload: value
    });
  };

  dispatch.loadData = value => {
    switch (page) {
      case CONTENT_ADMIN_EMAIL_TEMPLATES:
        setDefaultValues(setEmailTemplateData(value));
        break;

      case CONTENT_ADMIN_LANDING_PAGES:
        setDefaultValues(setLandingPagesData(value));
        break;

      case CONTENT_ADMIN_SENDING_PROFILES:
        setDefaultValues(setSendingProfilesData(value));
        break;

      case CONTENT_ADMIN_SCENARIO:
        setDefaultValues(setScenarioData(value));
        break;

      default:
        dispatch.bulkAdd(value);
    }
  };

  dispatch.setError = (name, value) => {
    setRecord({
      type: 'set_error',
      payload: {
        name,
        value
      }
    });
  };

  dispatch.onSubmit = async values => {
    dispatch.disabled(true);
    const path = getContentAdminPath(page);
    const attachmentKeys = await getAttachmentsKeys(values);
    const logo = await saveLogoOnAWS(values?.logo);
    const newRecord = { ...values, attachmentKeys, logo };
    const templateData = getTemplateData(page, newRecord);

    if (id) {
      await setTemplate('editTemplate', path, id, templateData);
    } else {
      await setTemplate('createTemplate', path, templateData);
    }
  };

  dispatch.disabled = value => {
    setRecord({
      type: 'disabled',
      payload: value
    });
  };

  return {
    bindingParams,
    pickLists,
    ...pickListQuery,
    isLoading: record.isLoading || pickListQuery.isLoading || loading,
    record,
    dispatch,
    defaultValues,
    errors
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_error': {
      const { name, value } = action.payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          [name]: value
        }
      };
    }
    case 'change_field':
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value
      };
    case 'bulkAdd':
      return {
        ...state,
        ...action.payload
      };
    case 'disabled':
      return {
        ...state,
        disabled: action.payload
      };
  }
}
