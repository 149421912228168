// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { QueryClient } from 'react-query';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import {
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { Eye, EyeClose, LazyIcon, Info, Enable } from 'components/icons';
import {
  Container,
  TextField,
  SelectField,
  Typography,
  Button,
  LoadingStyled,
  Switch,
  ButtonPulseAnimate,
  Dialog,
  ConfirmLeavePageModal
} from 'components';
import { useApp, hasKey, dataFetch, dataQueryName, USER_ROLES, getActiveAvailableTab } from 'helpers';
import { useId, useDataGet, useBreakpoint } from 'hooks';
import { BSN_SET_ANY, BSN_UPDATE_USER_PROFILE } from 'conf';
import CustomSelectField from 'components/newForms/SelectField';
import clients from 'helpers/apis/clients';
import { updateNestedMutable } from 'utils/update';
import { ButtonCancel, MuiPhoneNumberInput } from './ComponentTypes';
import PasswordMeter from '../../components/forms/PasswordMeter';
import profileAPI from '../../helpers/apis/UsersAPI/updateProfile';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});

const ConfirmedPassword = styled(OutlinedInput)`
  & .Mui-disabled {
    cursor: not-allowed;
  }
`;

const FormControlAlign = styled(FormControl)`
  && {
    margin-top: calc(var(--spacing) * 0.9);
  }
`;

function transform(data) {
  return data.map(({ id, name }) => {
    return {
      value: id,
      label: name
    };
  });
}
const useHelperTextStyles = makeStyles(() => ({
  // for helper text in password text field
  root: {
    margin: 0
  }
}));

const Section = ({ header, children, hide }) => {
  return (
    !hide && (
      <Container.Paper mt={2} radius={1}>
        <Container.Grid>
          <Container.Grid px={3} bb={1} xs={12}>
            <Typography.h3 fontSize={16}>{header}</Typography.h3>
          </Container.Grid>
          <Container.Grid px={3} py={2} xs={12}>
            {children}
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
    )
  );
};

const initialState = {
  jobFunctionList: null,
  data: null,
  showPassword: false,
  verifyPassword: undefined,
  errorMessage: '',
  newError: false,
  initialScreenName: '',
  industry_id: ''
};

// this also exist in UserContactUs.js
const validatePhone = arg =>
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\s?(\(*\d\)*\s*\-*){7,15}$/.test(
    arg
  );
const UserProfile = ({ record, open, setOpen, ...props }) => {
  const dispatchRx = useDispatch();
  const notify = useNotify();
  const mobileView = useBreakpoint('sm');
  const { dispatch } = useApp();
  const userId = useId({ key: 'userId' });
  const clientId = useId({ key: 'clientId' });

  const { client_id, client_industry, user_role, product_name } = useSelector(s => s?.bsn?.user?.profile ?? {});
  const { client_industries } = useSelector(s => s?.bsn?.client ?? {});

  const userAccess = useSelector(({ bsn }) => bsn?.user?.access);
  const userTabs = useSelector(rxState => rxState.bsn.tabs);

  const hideProfile = ['Unlimited Cybersecurity Training', 'HIPAA Compliance'].includes(product_name);

  const [screenNameStatus, setScreenNameStatus] = useState({ invalid: false, message: '' });

  const [state, setStateAUX] = useState(initialState);
  const [updateData, setUpdateData] = useState({});

  const [passwordScore, setPasswordScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validationState, setValidationState] = React.useState({
    valid_phoneNumber: true,
    valid_mobileNumber: true,
    phoneNumber_dialCode: '+1',
    mobileNumber_dialCode: '+1'
  });
  const [jobFuncitonOpen, setJobFuncitonOpen] = useState(false);

  const [time, setTime] = useState(Date.now());
  const { data: getProfile, refetch: refetchProfile } = useDataGet({
    app: 'user',
    tab: 'profile',
    item: userId,
    params: { _callId: time },
    options: { refetchOnWindowFocus: false }
  });
  const { data: getJobFunctionList } = useDataGet({
    app: 'user',
    tab: 'jobFunctionList',
    item: clientId,
    transform,
    options: { refetchOnWindowFocus: false }
  });

  const setState = React.useCallback(obj => {
    setStateAUX(old => updateNestedMutable(old, obj));
  }, []);

  const { jobFunctionList, data, verifyPassword, showPassword, errorMessage, newError, initialScreenName } = state;

  const is_valid_industry_user_role = [
    USER_ROLES.MANAGER_ADMIN,
    USER_ROLES.PARTNER_ADMINISTRATOR,
    USER_ROLES.MANGER
  ].includes(user_role);

  // get client inustries + caching guard
  React.useEffect(() => {
    // get pick list if MA,M,PA
    if (!user_role || !is_valid_industry_user_role) {
      return;
    }
    if (client_industries && client_industries.length) {
      return;
    }
    if (!client_id) {
      return;
    }
    clients
      .getPickList({
        client_id,
        types: 'industries'
      })
      .then(industriesRes => {
        if (industriesRes.data) {
          dispatchRx({
            type: BSN_SET_ANY,
            payload: {
              client: {
                client_industries: industriesRes?.data?.industries?.map(v => ({ value: v.id, label: v.name } ?? []))
              }
            }
          });
        }
      })
      .catch(err => {
        notify(err.message, 'error');
      });
  }, [client_id, client_industries, dispatchRx, is_valid_industry_user_role, notify, user_role]);

  useEffect(() => {
    setState({ data: { industry_id: client_industry?.id } });
  }, [client_industry?.id, setState]);

  useEffect(() => {
    if (!!getProfile && userId) {
      setState({
        data: { ...getProfile } ?? {},
        initialScreenName: getProfile?.screenName
      });
    }
  }, [userId, getProfile, setState]);

  useEffect(() => {
    if (!hideProfile && jobFunctionList === null && getJobFunctionList !== null && clientId) {
      setState({ jobFunctionList: getJobFunctionList });
    }
  }, [clientId, hideProfile, jobFunctionList, getJobFunctionList, setState]);

  const onChange = useCallback(
    (name, value) => {
      setState({
        data: { [name]: value }
      });
      setUpdateData(s => ({ ...s, [name]: value }));
    },
    [setState]
  );

  const onCheckAvailability = (ev, screenName) => {
    ev.preventDefault();

    if (!screenName) {
      setScreenNameStatus({ invalid: true, message: 'Please fill out this field' });
      return;
    }

    const isCurrentScreenName = screenName?.toLowerCase() === initialScreenName.toLowerCase();
    if (isCurrentScreenName) {
      setScreenNameStatus({ invalid: false, message: 'This is your current screen name' });
      return;
    }

    if (screenName.toLowerCase().includes('user-')) {
      setScreenNameStatus({ invalid: true, message: 'Your screen name cannot include "user-"' });
      return;
    }

    if (screenName.length < 3 || screenName.length > 15) {
      setScreenNameStatus({ invalid: true, message: 'Screen name must be 3-15 characters long' });
      return;
    }

    const isValidScreenName = /^[A-Za-z0-9._-]+$/.test(screenName);
    if (!isValidScreenName) {
      setScreenNameStatus({ invalid: true, message: 'Screen name cannot contain special characters' });
      return;
    }

    // backend validation for profanity and validity
    fetch(screenName, userId, setScreenNameStatus);
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!hasKey(updateData, 'password')) {
      if (data) data.password = '';
      setState({ verifyPassword: '' });
    }
    setLoading(true);
    profileAPI
      .update(userId, {
        ...data,
        phoneNumber: data.phoneNumber,
        mobileNumber: data.mobileNumber
      })
      .then(res => {
        dispatch.setItem('user', 'profile', userId, data);
        dispatchRx({ type: BSN_UPDATE_USER_PROFILE, payload: data });
        // add industry to redux on success
        const industry = client_industries?.find(v => v.value === data?.industry_id);
        dispatchRx({
          type: BSN_SET_ANY,
          payload: {
            user: {
              profile: {
                client_industry: industry ? { id: industry.value, name: industry.label } : { id: data?.industry_id }
              }
            }
          }
        });
        notify('Successfully Updated!', 'success');
      })
      .catch(err => {
        notify(err?.response?.data?.description || 'an error occurred while updating profile', 'warning');
      })
      .finally(() => {
        refetchProfile();
        setLoading(false);
        setScreenNameStatus({ invalid: false, message: '' });
        setUpdateData({});
      });
  };

  const handleValid = e => {
    if (e.target.value) {
      setState({ errorMessage: '', newError: false });
    }
  };

  const handleInvalid = e => {
    e.preventDefault();
    setState({
      errorMessage: e.target.validationMessage,
      newError: !e.target.validity.valid
    });
  };

  const customHelperTextStyles = useHelperTextStyles(); // for helper text in password text field

  const userProfileUpdateMsg = useMemo(() => {
    const { jobFunctionId, screenNameUpdated } = getProfile || {};
    const showMsg = !jobFunctionId || !screenNameUpdated;
    return (
      showMsg && (
        <Typography.p fontSize={14} component="p" color="var(--colorSystemDanger)">
          <b>
            Please update your Job Function and Screen Name to complete your profile and improve your Employee Secure
            Score (ESS)
          </b>
        </Typography.p>
      )
    );
  }, [getProfile]);

  const getFieldStatusIcon = condition => {
    return condition ? (
      <Enable color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
    ) : (
      <Info color="colorSystemDanger" />
    );
  };

  const isValidForm = useMemo(() => {
    const { firstName, lastName, screenName } = data || {};
    return firstName?.trim().length > 0 && lastName?.trim().length > 0 && screenName?.trim().length > 0;
  }, [data?.firstName, data?.lastName, data?.screenName]);

  const unmatchingPasswords = useMemo(() => {
    return !!data?.password && data?.password !== verifyPassword && verifyPassword?.length > 0;
  }, [data?.password, verifyPassword]);

  const failedPasswordValidation = useMemo(() => {
    const weakPassword = !!data?.password && passwordScore < 3;
    const isVerifyEmpty = !!data?.password && !verifyPassword;
    return unmatchingPasswords || weakPassword || isVerifyEmpty;
  }, [data?.password, passwordScore, unmatchingPasswords, verifyPassword]);

  const screenNameUpdatedButTaken = useMemo(() => {
    return !!updateData?.screenName && screenNameStatus?.invalid !== false;
  }, [updateData?.screenName, screenNameStatus?.invalid]);

  const isDataUpdated = React.useCallback(() => {
    return Object.entries(updateData).some(([key, value]) => {
      return value !== getProfile[key];
    });
  }, [getProfile, updateData]);

  const isUpdateBtnDisabled = useMemo(() => {
    return !isDataUpdated() || !isValidForm || screenNameUpdatedButTaken || failedPasswordValidation;
  }, [isDataUpdated, screenNameUpdatedButTaken, isValidForm, failedPasswordValidation]);

  const cancelRedirectUrl = useMemo(() => {
    if (userAccess?.apps?.myDashboard && userTabs?.myDashboard) {
      const userAvailableTab = getActiveAvailableTab(userAccess?.apps?.myDashboard, userTabs?.myDashboard);
      return `/myDashboard/${userAvailableTab}`;
    }
    return '/';
  }, [userAccess?.apps?.myDashboard, userTabs?.myDashboard]);

  const validatingPhoneEffect = useCallback((key, oldValue, wasValid) => {
    if (typeof oldValue === 'string') {
      // if phone number is empty its valid
      // if not empty string, then it's tested againist the regex
      const isValid = !oldValue?.length || validatePhone(oldValue);
      // changing validation state only when
      // the new value validation state (isValid)
      //  is different from the current one
      if (wasValid !== isValid) {
        setValidationState(old => {
          return {
            ...old,
            [`valid_${key}`]: isValid
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    validatingPhoneEffect('phoneNumber', data?.phoneNumber, validationState?.valid_phoneNumber);
  }, [data?.phoneNumber, validatingPhoneEffect, validationState?.valid_phoneNumber]);

  useEffect(() => {
    validatingPhoneEffect('mobileNumber', data?.mobileNumber, validationState?.valid_mobileNumber);
  }, [data?.mobileNumber, validatingPhoneEffect, validationState?.valid_mobileNumber]);

  const handlePhoneChangex = useCallback(
    (key, oldDialCode, oldValue, newValue, loc) => {
      const newDialCode = `+${loc.dialCode}`;
      const numberWithoutCode = newValue.replace(newDialCode, '');
      const isEmpty = !numberWithoutCode.length;
      const endValue = isEmpty ? '' : newValue;
      // preventing unecessary updates to the state
      if (oldValue !== endValue) {
        onChange(key, endValue);
      }
      // preventing unecessary updates to the state
      if (oldDialCode !== newDialCode) {
        setValidationState(old => {
          return {
            ...old,
            [`${key}_dialCode`]: newDialCode
          };
        });
      }
    },
    [onChange]
  );

  const MuiPhoneNumberInputMemo = React.useMemo(
    () => (
      <MuiPhoneNumberInput
        variant="outlined"
        value={data?.phoneNumber || validationState?.phoneNumber_dialCode}
        name="phoneNumber"
        label="Phone number"
        onChange={debounce(
          (value, loc) => {
            handlePhoneChangex(
              // key
              'phoneNumber',
              // old dial code
              validationState?.phoneNumber_dialCode,
              // old value
              data.phoneNumber,
              // new value
              value,
              // new location Object
              loc
            );
          },
          10,
          { trailing: true }
        )}
        error={!validationState?.valid_phoneNumber}
        defaultCountry="us"
        disableAreaCodes
        autoFormat={false}
        countryCodeEditable={false}
        fullWidth
      />
    ),
    [data?.phoneNumber, handlePhoneChangex, validationState?.phoneNumber_dialCode, validationState?.valid_phoneNumber]
  );

  const MuiMobileNumberInputMemo = useMemo(
    () => (
      <MuiPhoneNumberInput
        variant="outlined"
        value={data?.mobileNumber || validationState?.mobileNumber_dialCode}
        name="mobileNumber"
        label="Mobile Number"
        onChange={debounce(
          (value, loc) => {
            handlePhoneChangex(
              // key
              'mobileNumber',
              // old dial code
              validationState?.mobileNumber_dialCode,
              // old value
              data?.mobileNumber,
              // new value
              value,
              // new location Object
              loc
            );
          },
          10,
          { trailing: true }
        )}
        error={!validationState.valid_mobileNumber}
        defaultCountry="us"
        disableAreaCodes
        autoFormat={false}
        countryCodeEditable={false}
        fullWidth
      />
    ),
    [
      data?.mobileNumber,
      handlePhoneChangex,
      validationState?.mobileNumber_dialCode,
      validationState?.valid_mobileNumber
    ]
  );

  return (
    <>
      {data === null || (data && !hasKey(data, 'firstName')) || loading ? (
        <LoadingStyled />
      ) : (
        <>
          <form onSubmit={onUpdate}>
            <Section header="User Information">
              <Container.Grid py={1} pr={3} xs={6} md={4} alignItems="center">
                <TextField
                  value={data.firstName}
                  name="firstName"
                  label="First name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid py={1} pr={3} xs={6} md={4} alignItems="center">
                <TextField
                  value={data.lastName}
                  name="lastName"
                  label="Last name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid
                py={1}
                pr={3}
                xs={6}
                md={4}
                alignItems="center"
                style={{
                  ...(is_valid_industry_user_role
                    ? {}
                    : mobileView
                    ? { diplay: 'none' }
                    : {
                        visibility: 'hidden'
                      })
                }}
              >
                <CustomSelectField
                  fullWidth
                  allowEmpty={false}
                  id="selectIdentityProviders"
                  label="Industry"
                  required
                  choices={client_industries} // Todo api should return clientIndustries as id name
                  name="selectIdentityProviders"
                  value={(state.data.industry_id ? data.industry_id : client_industry?.id) || ''}
                  onChange={({ target: { name, value } }) => {
                    onChange('industry_id', value);
                  }}
                />
              </Container.Grid>
              <Container.Grid py={1} pr={3} xs={6} md={4} alignItems="center">
                {MuiPhoneNumberInputMemo}
              </Container.Grid>
              <Container.Grid py={1} pr={3} xs={6} md={4} alignItems="center">
                {MuiMobileNumberInputMemo}
              </Container.Grid>
              <Container.Grid py={1} pr={3} xs={6} md={4} alignItems="center">
                <TextField
                  value={data.email}
                  name="email"
                  label="Ex: mail@mail.com"
                  disabled
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value) }}
                  required
                  fullWidth
                />
              </Container.Grid>
            </Section>
            <Section header="User Profile" hide={hideProfile}>
              <Container.Grid xs={12}>{userProfileUpdateMsg}</Container.Grid>
              <Container.Grid my={1} xs={6} md={4}>
                {jobFunctionList && (
                  <SelectField
                    allowEmpty
                    fullWidth
                    id="jobFunctionId"
                    label="Job Function"
                    choices={jobFunctionList}
                    name="jobFunctionId"
                    value={data.jobFunctionId}
                    inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value) }}
                    open={jobFuncitonOpen}
                    onClose={() => setJobFuncitonOpen(false)}
                    onOpen={() => setJobFuncitonOpen(true)}
                    IconComponent={() => <></>}
                    endAdornment={
                      <InputAdornment position="end">
                        {!hasKey(updateData, 'jobFunctionId') && getFieldStatusIcon(!!data.jobFunctionId)}
                        <div
                          onClick={() => setJobFuncitonOpen(s => !s)}
                          style={{ margin: '4px 0 0 6px', cursor: 'pointer' }}
                        >
                          {jobFuncitonOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </div>
                      </InputAdornment>
                    }
                  />
                )}
              </Container.Grid>
              <Container.Grid mt={1} xs={12}>
                <Container.Grid mb={2} direction="column" xs={12}>
                  <Typography.h3 title fontSize={14}>
                    Screen Name
                  </Typography.h3>
                  <Typography.p style={{ margin: 0, marginBottom: 10 }} fontSize={14} component="p">
                    <b>Note:</b>
                    <br />
                    Your screen name is visible to other users of the system, therefore we recommend that you do not use
                    your real name or company name.
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    <b>Examples:</b>
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    SecuritylsForMe
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    JessiesGirl
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    WorkinForALiving
                  </Typography.p>
                </Container.Grid>

                <Container.Grid xs={6} md={4} mr={3.5}>
                  <TextField
                    value={data.screenName}
                    name="screenName"
                    label="Screen Name"
                    inputProps={{
                      onChange: ({ target: { name, value } }) => {
                        setScreenNameStatus({ invalid: null, message: '' });
                        onChange(name, value);
                      },
                      maxLength: 60
                    }}
                    InputProps={{
                      endAdornment: !hasKey(updateData, 'screenName') && (
                        <InputAdornment position="end">{getFieldStatusIcon(data.screenNameUpdated)}</InputAdornment>
                      )
                    }}
                    required
                    fullWidth
                    error={screenNameStatus.invalid}
                    helperText={screenNameStatus.message}
                  />
                </Container.Grid>
                <ButtonPulseAnimate
                  disabled={!hasKey(updateData, 'screenName')}
                  animation={hasKey(updateData, 'screenName') && screenNameStatus.invalid === null}
                  onClick={e => onCheckAvailability(e, data.screenName)}
                  style={{
                    alignSelf: 'flex-start',
                    marginTop: '12px',
                    height: '40px'
                  }}
                >
                  Check Availability
                </ButtonPulseAnimate>
              </Container.Grid>
            </Section>
            <Section header="Update Password" hide={!data?.show_password}>
              <Container.Grid pr={3} xs={6} md={4}>
                <input
                  type="text"
                  name="checks"
                  required={data.password !== verifyPassword || (data.password && passwordScore < 3)}
                  style={{ display: 'none' }}
                />
                <TextField
                  source={data.password}
                  value={data.password}
                  name="password"
                  label="Password"
                  onChange={({ target: { name, value } }) => onChange(name, value)}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  autoComplete="new-password"
                  inputProps={{
                    maxLength: 128
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: customHelperTextStyles.root
                    }
                  }}
                  error={data.password && passwordScore < 3}
                  helperText={
                    data.password && (
                      <PasswordMeter
                        password={data.password}
                        onChangePassword={e => {
                          setPasswordScore(e);
                        }}
                      />
                    )
                  }
                />
              </Container.Grid>
              <Container.Grid pr={3} xs={6} md={4}>
                <FormControlAlign
                  variant="outlined"
                  fullWidth
                  required={data.password && true}
                  error={unmatchingPasswords || newError}
                >
                  <InputLabel error={unmatchingPasswords || newError}>Verify password</InputLabel>
                  <ConfirmedPassword
                    type={showPassword ? 'text' : 'password'}
                    value={verifyPassword}
                    name="check_password"
                    onChange={({ target: { value } }) => setState({ verifyPassword: value })}
                    labelWidth={130}
                    autoComplete="new-password"
                    // error={data.password !== verifyPassword}
                    onInvalid={handleInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setState({ showPassword: !showPassword })}>
                          {showPassword ? <Eye /> : <EyeClose />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onInput={e => handleValid(e)}
                    inputProps={{
                      maxLength: 128
                    }}
                  />
                  <FormHelperText error={unmatchingPasswords || newError}>
                    {unmatchingPasswords && !newError && 'Passwords do not match.'}
                    {newError && errorMessage}
                  </FormHelperText>
                </FormControlAlign>
              </Container.Grid>
            </Section>
            <Container.Paper>
              <Container.Grid mb={2} xs={12} bt={1}>
                <Container.Grid justify="flex-end" mt={2} mr={3} xs={12}>
                  <ButtonCancel to={cancelRedirectUrl} primaryText="Cancel" />
                  <Button
                    mr={3}
                    backgroundColor="colorSystemSuccess"
                    backgroundColorHover="colorSystemSuccessHover"
                    disabled={
                      isUpdateBtnDisabled || !validationState.valid_mobileNumber || !validationState.valid_phoneNumber
                    }
                    type="submit"
                  >
                    <LazyIcon style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }} component="Done" />
                    &nbsp;&nbsp;Update
                  </Button>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </form>
          {isDataUpdated() && <ConfirmLeavePageModal />}
        </>
      )}
    </>
  );
};

async function fetch(screenName, user_id, setState) {
  const settings = {
    app: 'user',
    tab: 'checkScreenName',
    item: screenName
  };

  try {
    const queryName = dataQueryName(settings);
    const { invalid, message } = await queryClient.fetchQuery(queryName, async () => {
      return (await dataFetch({ ...settings, params: { user_id } }))?.data;
    });
    setState({ invalid, message });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default UserProfile;
