// @flow
import {
  BSN_GET_LIST,
  BSN_SET_ITEM,
  BSN_CLEAR,
  BSN_SET_USER,
  BSN_UPDATE_USER_PROFILE,
  BSN_SET_USER_APPS,
  BSN_UPDATE_DARK_MODE,
  BSN_SET_GET_STARTED_NOTIFY,
  BSN_SET_CLIENT_ACCESS,
  BSN_SET_CLIENT_NEW_ACCESS,
  BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
  BSN_SET_CLIENT_LOGO,
  BSN_SET_USER_NEWSFEED_APP_ACCESS,
  BSN_SET_ANY,
  BSN_SET_TARGET,
  BSN_SET_APP
} from 'conf/constants';
import { initialState, type initialStateType } from 'conf/initialState';
import { toArray } from 'helpers/utils';

import { type bsnActionType } from '../action';
import { updateMutable, updateNestedMutable } from '../../utils/update';

function mergeState(previous, next) {
  if (JSON.stringify(previous) === JSON.stringify(next)) return previous;
  return Array.isArray(previous) ? [...previous, ...next] : next;
}

export default (
  previousState: initialStateType = initialState,
  { type, resource, tab, item, key, payload, atBeginning }: bsnActionType
): Object | bsnActionType => {
  switch (type) {
    case 'set_color_scheme': {
      return {
        ...previousState,
        themeSettings: payload
      };
    }

    case BSN_CLEAR: {
      return { ...previousState, ...payload };
    }

    case BSN_SET_USER: {
      const newState = previousState;
      newState.user.profile = payload.profile;
      newState.user.access = payload.access;
      newState.user.theme = payload.theme;
      newState.user = { ...payload };
      return newState;
    }

    case BSN_SET_USER_ALL_NOTIFICATIONS_SEEN: {
      const newState = previousState;
      newState.user.profile.all_newsfeed_notifications_seen = payload;
      sessionStorage.setItem('allNewsfeedNotificationsSeen', payload);
      return newState;
    }

    case BSN_UPDATE_USER_PROFILE: {
      const newState = { ...previousState };
      newState.user.profile.first_name = payload.firstName;
      newState.user.profile.last_name = payload.lastName;
      return newState;
    }

    case BSN_UPDATE_DARK_MODE: {
      const newState = { ...previousState };
      newState.user.profile.dark_mode = payload;
      return newState;
    }

    case BSN_SET_GET_STARTED_NOTIFY: {
      const newState = {
        ...previousState,
        user: {
          ...previousState.user,
          profile: {
            ...previousState.user.profile,
            get_started_notify: payload
          }
        }
      };
      return newState;
    }

    case BSN_GET_LIST: {
      const newState = previousState;

      if (item) {
        if (newState[resource][tab] === null) newState[resource][tab] = {};
        newState[resource][tab][item] = mergeState(previousState[resource][tab][item], payload);
      } else newState[resource][tab] = mergeState(previousState[resource][tab], payload);
      return newState;
    }

    case BSN_SET_ITEM: {
      const newState = {...previousState};
      if (item) newState[resource][tab][item][key] = payload;
      else if (key) newState[resource][tab][key] = payload;
      else newState[resource][tab] = payload;
      return newState;
    }

    case 'BSN_SET_CONFIG_MODAL': {
      const newPayload = previousState;
      if (!(resource in newPayload)) newPayload[resource] = {};
      newPayload[resource][tab] = payload;

      return { ...newPayload };
    }

    case 'BSN_CLEAR_CONFIG_MODAL': {
      const newPayload = previousState;
      Reflect.deleteProperty(newPayload, resource);
      return newPayload;
    }

    case BSN_SET_CLIENT_ACCESS: {
      const newState = previousState;
      const { access } = payload;
      const { is_hsn } = payload;

      // setting access
      if (access) newState.clients.clientUpdatedAccess = access;
      else newState.clients.clientUpdatedAccess = null;

      // setting is_hsn
      if (is_hsn != null) newState.myCompany.is_hsn = is_hsn;

      return { ...newState };
    }

    case BSN_SET_CLIENT_NEW_ACCESS: {
      const newState = previousState;
      newState.clients.clientNewAccess = payload;
      return { ...newState };
    }

    case BSN_SET_USER_APPS: {
      const newState = previousState;
      newState.user.access.apps = payload;
      return { ...newState };
    }

    case BSN_SET_CLIENT_LOGO: {
      return updateNestedMutable(previousState, { user: { profile: { logo_partner: payload.logo } } });
    }

    case BSN_SET_USER_NEWSFEED_APP_ACCESS: {
      return {
        ...previousState,
        user: {
          ...previousState.user,
          access: {
            ...previousState.user.access,
            apps: (() => {
              if (payload.access === false) {
                const appsWithoutNewsFeed = Object.fromEntries(
                  Object.entries(previousState.user.access.apps).filter(([appName]) => appName !== 'newsfeed')
                );
                return { ...appsWithoutNewsFeed };
              }
              return {
                ...previousState.user.access.apps,
                newsfeed: {
                  ...previousState.user.access.apps.newsfeed,
                  dashboard: true
                }
              };
            })()
          }
        }
      };
    }
    // responsable for any bulk updates and direct access to redux objects
    case BSN_SET_ANY: {
      // update is immutable but it leave objects mutable
      return updateNestedMutable(previousState, payload);
    }
    case BSN_SET_TARGET: {
      return updateMutable(previousState, payload.path, payload.value);
    }

    case BSN_SET_APP: {
      const { appName, tabName, record } = payload;
      const newState = previousState;
      newState[appName][tabName] = record;
      return newState;
    }
    default: {
      const newPayload = previousState;
      if (resource && tab) {
        const arrayTab = toArray(tab);
        const arrayPayload = [];
        arrayPayload.push(payload);
        const arrayItem = toArray(item);
        // console.log(resource);
        // console.log(tab);
        // console.log(payload);
        // console.log(arrayTab);
        // console.log(arrayPayload);
        arrayTab.forEach((k, i) => {
          // console.log(k);
          // console.log(arrayPayload[i]);
          // console.log(newPayload[resource][k]);
          !arrayItem[i]
            ? (newPayload[resource][k] = arrayPayload[i])
            : (newPayload[resource][k] = {
                ...newPayload[resource][k],
                [arrayItem[i]]: arrayPayload[i]
              });
        });
      } else {
        newPayload[resource] = {
          ...previousState[resource],
          ...payload
        };
      }
      // console.log(newPayload);
      return newPayload;
    }
  }
};
