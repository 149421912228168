import React, { useState } from 'react';
import { Close, Settings } from 'components/icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import { LazyIcon, Dialog as DeleteSubDialog } from 'components';
import { dataProvider } from 'helpers';
import { useId, useLocation } from 'hooks';
import { useNotify } from 'ra-core';
import { useTableList } from 'components/tablelist/TableListContext';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const StyledButton = styled(Button)`
  && {
    width: 90%;
    background-color: ${props => !props.disabled && '#082F49'};
    text-transform: none;
    &:focus,
    &:hover {
      background-color: ${props => !props.disabled && '#082F49'};
      text-transform: none;
    }
  }
`;

const DialogTitleContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const actions = [
  {
    label: 'Active',
    method: 'activate',
    icon: 'Enable',
    name: 'active'
  },
  {
    label: 'Inactive',
    method: 'deactivate',
    icon: 'Disable',
    name: 'inactive'
  },
  {
    label: 'Welcome Message',
    method: 'sendWelcomeMessages',
    icon: 'WelcomeMessage',
    name: 'welcome_message'
  },
  {
    label: 'Clear Bounce Email',
    method: 'removeBounceEmail',
    icon: 'Clear',
    name: 'clear_bounce_email'
  },
  {
    label: 'Reset MFA',
    method: 'resetMFA',
    icon: 'UserChecked',
    name: 'reset_mfa'
  },
  {
    label: 'Reset Password',
    method: 'sendResetPasswordMessages',
    icon: 'Refresh',
    name: 'reset_password'
  },
  {
    label: 'Unlock',
    method: 'unlock',
    icon: 'Lock',
    name: 'unlock'
  },
  {
    label: 'Delete',
    method: 'delete',
    icon: 'Delete',
    name: 'delete'
  },
  {
    label: 'Reset Deleted User',
    method: 'resetDeletedUser',
    icon: 'Refresh',
    name: 'reset_deleted'
  }
];

const ActionsDialog = ({ open, handleClose, handleClick, selectedUsers, loading }) => {
  const [isSubDialogOpen, setIsSubDialogOpen] = useState(false);
  const { disabeldActions: disabledActions } = useTableList();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ backgroundColor: 'rgba(8, 47, 73, 0.80)' }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="actions-dialog-title">
        <DialogTitleContainer>
          <Typography variant="h4">Actions</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <List aria-label="main actions buttons">
          {actions.map((action, index) => {
            return (
              <>
                <ListItem
                  button
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  disabled={selectedUsers.length === 0 || loading || disabledActions.includes(action.name)}
                  onClick={
                    action.method === 'delete' ? () => setIsSubDialogOpen(true) : () => handleClick(action.method)
                  }
                >
                  <ListItemIcon>
                    <LazyIcon
                      component={action.icon}
                      strokeColor={action.icon === 'Enable' && 'colorDefault'}
                      color="#535353"
                    />
                  </ListItemIcon>
                  <ListItemText primary={action.label} />
                </ListItem>
                {action.method === 'delete' && (
                  <DeleteSubDialog
                    title="Delete"
                    message="This informations will be deleted. Please confirm if you would like to delete?"
                    buttonSubmit="Yes, I want to delete"
                    open={isSubDialogOpen || false}
                    setOpen={setIsSubDialogOpen}
                    maxWidth="xs"
                    onSubmit={() => {
                      handleClick(action.method);
                      setIsSubDialogOpen(false);
                    }}
                  />
                )}
              </>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

ActionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired
};

const UsersListActionsMobile = ({ selectedUsers = [], setSelected, setRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { app, tab, item } = useLocation();
  const clientId = useId();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = method => {
    if (['clients', 'myCompany'].includes(app) && ['users', 'employees'].includes(tab)) {
      setLoading(true);
      dataProvider
        .post('clients', `usersListActions/${String(item || clientId)}`, null, { type: method, ids: selectedUsers })
        .then(res => {
          if (method === 'delete') setSelected([]);
          if (method === 'deactivate' || method === 'delete') {
            notify(`${res.data?.description}`, 'warning');
          } else {
            notify(`${res.data?.description}`);
          }
        })
        .catch(error => {
          notify(`${error?.response?.data?.description}`, 'warning');
        })
        .finally(() => {
          setSelected([]);
          setLoading(false);
          setRefresh(false);
          setRefresh(true);
          handleClose();
        });
    }
  };

  return (
    <Container>
      <StyledButton
        variant="contained"
        color="secondary"
        startIcon={<Settings />}
        // eslint-disable-next-line no-shadow
        onClick={() => setOpen(open => !open)}
      >
        Actions
      </StyledButton>
      <ActionsDialog
        open={open}
        handleClose={handleClose}
        handleClick={handleClick}
        selectedUsers={selectedUsers}
        loading={loading}
      />
    </Container>
  );
};

UsersListActionsMobile.propTypes = {
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelected: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default UsersListActionsMobile;
