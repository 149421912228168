import { useAccess, useClientAccess, useMobileAccess, useLocation, useBreakpoint } from 'hooks';
import { useSelector } from 'react-redux';

function useTabsAllowed({
  layout: propLayout,
  app: propApp,
  item: propItem,
  accessMerger,
  isMobile = false,
  clientAccess
}) {
  const { app: locApp, item: locItem } = useLocation('clientId');

  const app = propApp || locApp;
  const item = propItem || locItem;
  const mobileView = useBreakpoint('sm');

  const UseLayout = () => {
    const tabs = useSelector(rxState => rxState.bsn.tabs) || {};
    return tabs[app];
  };
  const layout = propLayout || UseLayout();

  const isClient = app === 'clients' && !!item;

  const UseProfileAccess = useAccess({ type: 'tabs', app, merger: accessMerger });
  const UseClientAccess = useClientAccess({ id: item, defaultAccess: clientAccess });
  const UseMobileAccess = access => useMobileAccess({ app, access });

  let access = isClient ? clientAccess || UseClientAccess : UseProfileAccess;
  access = isMobile || mobileView ? UseMobileAccess(access) : access;

  const allowedLayout = Object.keys(layout).reduce((acc, tabname) => {
    if (access[tabname]) acc[tabname] = layout[tabname];
    return acc;
  }, {});

  // console.log('amrooo', { app, isClient, isMobile, access, allowedLayout });
  return allowedLayout;
}

export default useTabsAllowed;
