import React from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Typography, RadioField, ButtonCancel } from 'components';
import { ArrowForwardIosSharp } from '@material-ui/icons';
import { Save2 } from 'components/icons';
import { GreenButton, BlueButton } from '../../style';

const EMR_EHR_OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const EMR_EHR_LOCATION_CHOICES = [
  { label: 'Running on a server in office', value: 'Running on a server in office' },
  { label: 'Hosted by vendor(Cloud/SaaS)', value: 'Hosted by vendor(Cloud/SaaS)' },
  {
    label: 'Hosted by third party(Not EMR vendor)',
    value: 'Hosted by third party(Not EMR vendor)'
  },
  { label: 'Other', value: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const EmrAndEhr = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };

    if (name === 'emr_ehr_os' && value !== 'Other' && state.emr_ehr_os_other) {
      newState.emr_ehr_os_other = '';
    }
    if (name === 'emr_ehr_location' && value !== 'Other' && state.emr_ehr_other_location) {
      newState.emr_ehr_other_location = '';
    }

    setState({ ...newState });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      {/* Title */}
      <Container.Grid container spacing={4}>
        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Paper fullWidth radius={2}>
            <Container.Grid container>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography.p p={1} pl={2}>
                  In this section we will ask you questions about your Electronic Medical Records (EMR) or Eletronic
                  Health Records (EHR).
                </Typography.p>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>

      <Container.Grid container spacing={4} mt={2}>
        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Paper fullWidth radius={2} pb={2}>
            <Container.Grid container>
              {/* Do you have an EMR/EHR implemented? */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      Do you have an EMR/EHR implemented? *
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>


              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioField
                  label=""
                  name="emr_ehr_implemented"
                  choices={YES_NO_CHOICES}
                  value={state.emr_ehr_implemented}
                  mt={2}
                  mb={1}
                  row
                  labelPlacement="End"
                  onChange={handleChangeState}
                />
              </Container.Grid>

              {state.emr_ehr_implemented === 'Yes' ? (
                <>
                  {/* What is the name of your EMR/EHR Vendor? */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          What is the name of your EMR/EHR Vendor?
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr vendor */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      value={state.emr_ehr_vendor}
                      name="emr_ehr_vendor"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Container.Grid>

                  {/* What is the internal name that you use to refer to your EMR/EHR? */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          What is the internal name that you use to refer to your EMR/EHR?
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr internal name */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      value={state.emr_ehr_internal_name}
                      name="emr_ehr_internal_name"
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      onChange={handleChangeState}
                    />
                  </Container.Grid>

                  {/* What server operating system does your EMR/EHR run on? */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          What server operating system does your EMR/EHR run on? *
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr os */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioField
                      label=""
                      name="emr_ehr_os"
                      choices={EMR_EHR_OS_CHOICES}
                      value={state.emr_ehr_os}
                      mt={2}
                      mb={2}
                      row
                      labelPlacement="End"
                      onChange={handleChangeState}
                    />
                  </Container.Grid>

                  {/* Other EMR/EHR OS */}
                  {state.emr_ehr_os === 'Other' && (
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.emr_ehr_os_other}
                        name="emr_ehr_os_other"
                        label="Other EMR/EHR OS"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  )}

                  {/* Please enter EMR/EHR Other operating system or provide more details: */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          Please enter EMR/EHR Other operating system or provide more details:
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr details */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      value={state.emr_ehr_details}
                      name="emr_ehr_details"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Container.Grid>

                  {/* Where is your EMR/EHR located? */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          Where is your EMR/EHR located? *
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr location */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioField
                      label=""
                      name="emr_ehr_location"
                      choices={EMR_EHR_LOCATION_CHOICES}
                      value={state.emr_ehr_location}
                      mt={2}
                      mb={2}
                      row
                      labelPlacement="End"
                      onChange={handleChangeState}
                    />
                  </Container.Grid>

                  {/* Other EMR/EHR Location */}
                  {state.emr_ehr_location === 'Other' && (
                    <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        value={state.emr_ehr_other_location}
                        name="emr_ehr_other_location"
                        label="Other EMR/EHR Location"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Container.Grid>
                  )}

                  {/* Please describe where your EMR/EHR is located: */}
                  <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid container spacing={4} justify="space-between">
                      <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography.h3 p={2} m={0.1} fontSize={16}>
                          Please describe where your EMR/EHR is located:
                        </Typography.h3>
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Grid>

                  {/* emr/ehr description */}
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      value={state.emr_ehr_description}
                      name="emr_ehr_description"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Container.Grid>
                </>
              ) : null}

              {/* Action buttons */}
              <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="flex-end">
                  <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ButtonCancel variant="text" onClick={e => handleClose()}>
                      Exit
                    </ButtonCancel>
                    <BlueButton startIcon={<Save2 />} variant="outlined" disableElevation onClick={save}>
                      Save
                    </BlueButton>
                    <GreenButton
                      startIcon={<ArrowForwardIosSharp />}
                      variant="outlined"
                      disableElevation
                      ml="30px"
                      onClick={() => save('next')}
                    >
                      Save and Next
                    </GreenButton>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

EmrAndEhr.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default EmrAndEhr;
