import React from 'react';
import { Box, Stack } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';

const Header = ({ onContactUsClick }) => {
  return (
    <Stack flexWrap="wrap" useFlexGap py={2} px={2.5} spacing={1} direction="row">
      <img src="/media/teams/logo.svg" alt="PII Protect" />
      <Box ml="auto">
        <CustomButton onClick={onContactUsClick} variant="outlined" size="medium">
          Contact Us
        </CustomButton>
      </Box>
    </Stack>
  );
};

Header.propTypes = {
  onContactUsClick: PropTypes.func.isRequired
};

export default Header;
