import {
  Link,
  Tooltip,
  Typography,
  CircleCheckIcon,
  CircleCloseIcon,
  IconButton
} from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNA } from '../helpers';


const TeamsAppCell = ({
  teamsAppDeploymentStats,
  productAllowed,
  aadEnabled,
  onDeployInstructionsClicked,
  onEngagementReminderClicked
}) => {
  const teamsAppDeployed = teamsAppDeploymentStats !== null && teamsAppDeploymentStats !== 0;
  const teamsAppDeployedToolTipTitle = `The PII Protect Teams app has been deployed, 
    but some users are not receiving notifications. 
    Click here to view these users.`;

    const color = !teamsAppDeployed
      ? 'error' : (teamsAppDeploymentStats > 0 && teamsAppDeploymentStats <= 25) 
      ? 'error': (teamsAppDeploymentStats > 25 && teamsAppDeploymentStats <= 75)
      ? 'warning': (teamsAppDeploymentStats > 75 && teamsAppDeploymentStats < 100) 
      ? 'success' : null;

  const { Icon, iconColor, tooltipTitle, onClick } = teamsAppDeployed
    ? {
        Icon: CircleCheckIcon,
        iconColor: 'success',
        tooltipTitle:
          'The PII Protect Teams app has been deployed, and all users should now be receiving notifications.',
      }
    : {
        Icon: CircleCloseIcon,
        iconColor: 'error',
        tooltipTitle: 'Click here to deploy the PII Protect Teams app.',
        onClick: onDeployInstructionsClicked
      };

  const notAvailableTooltipTitle = !productAllowed
    ? 'The PII Protect Teams app is not available for this product.' : !aadEnabled
    ? 'Azure Active Directory Sync is required to deploy the PII Protect Teams app.' : '';

  if (isNA(teamsAppDeploymentStats) || !productAllowed || !aadEnabled) {
    return (
      <Tooltip placement="top" title={notAvailableTooltipTitle}>
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );
  }

  return (
    <>
      {!teamsAppDeployed || (teamsAppDeployed && teamsAppDeploymentStats === 100) ? (
        <Tooltip title={tooltipTitle} placement="top">
          <IconButton color={iconColor} onClick={onClick}>
            <Icon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={teamsAppDeployedToolTipTitle} placement="top">
          <Link
            color={`${color}.main`}
            underline="hover"
            hoverColor="info.main"
            variant="body2"
            onClick={onEngagementReminderClicked}
            component={RouterLink}
            to={false}
          >
            {`${teamsAppDeploymentStats}%`}
          </Link>
        </Tooltip>
      )}
    </>
  );
};

TeamsAppCell.propTypes = {
  teamsAppDeploymentStats: PropTypes.number.isRequired,
  productAllowed: PropTypes.bool.isRequired,
  aadEnabled: PropTypes.bool.isRequired,
  onDeployInstructionsClicked: PropTypes.func.isRequired,
  onEngagementReminderClicked: PropTypes.func.isRequired,
};

export default TeamsAppCell;
