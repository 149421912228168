import React from 'react';
import { TableRow, TableSortLabel, Checkbox } from '@material-ui/core';
import { TableCellWrapper, TableHeadStyled } from '../style';
import { useTableContext } from '../context';

const CustomTableHead = ({ className }) => {
  const { tableData, sortname, order, isSelectAll, dispatch, selectedRows, hideColumns } = useTableContext();

  const tableHead = tableData.head.filter(column => !(Array.isArray(hideColumns) && hideColumns.includes(column.id)));

  const isSelectingAll = tableData?.selectingAll ?? true;

  return (
    <TableHeadStyled className={className}>
      <TableRow>
        {tableData?.selecting &&
          (isSelectingAll ? (
            <TableCellWrapper align="left" padding="checkbox">
              <Checkbox
                onChange={dispatch.handleSelectAll}
                checked={isSelectAll}
                indeterminate={!isSelectAll && selectedRows.length > 0}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </TableCellWrapper>
          ) : (
            <TableCellWrapper align="left" padding="checkbox" />
          ))}
        {tableData?.collapse && !tableData?.selecting && <TableCellWrapper align="left" padding="checkbox" />}
        {tableData?.selectingRadio && <TableCellWrapper align="left" padding="checkbox" />}
        {tableHead.map(({ label, align, id }) => {
          if (tableData.sort.includes(id)) {
            return (
              <TableCellWrapper key={id} align={align}>
                <TableSortLabel
                  active={sortname === id}
                  direction={sortname === id ? order : 'asc'}
                  onClick={e => dispatch.handleRequestSort(e, id)}
                >
                  {label}
                </TableSortLabel>
              </TableCellWrapper>
            );
          }

          return (
            <TableCellWrapper key={id} align={align}>
              {label}
            </TableCellWrapper>
          );
        })}
      </TableRow>
    </TableHeadStyled>
  );
};

export default CustomTableHead;
