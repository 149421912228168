// @flow
import React, { useEffect, useState } from 'react';
import { Container, LoadingStyled, PDFViewer } from 'components';
import { useNotify } from 'react-admin';
import { ButtonSubmit } from './ComponentTypes';
import trainings from '../../helpers/apis/TestAuthoringSystem/trainings';

const DashboardCertificateModal = ({ trainingId, userId = null }) => {
  const notify = useNotify();
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const [certificate, setCertificate] = useState(null);
  useEffect(() => {
    if (!certificate) {
      trainings
        .getCertificate(trainingId, userId)
        .then(res => {
          setCertificate(res?.data?.url);
        })
        .catch(err => {
          notify('an error occurred while getting the certificate', 'warning');
        });
    }
  }, [certificate]);

  if (!certificate) return <LoadingStyled />;
  return (
    <Container.Grid p={2} sm={12} xs={12}>
      {certificate !== null ? (
        <Container.Grid justify="center">
          <PDFViewer
            url={certificate}
            divWrap={false}
            onDocumentLoad={() => {
              setIsPdfLoaded(true);
            }}
          />
        </Container.Grid>
      ) : (
        <Container.Grid justify="center">
          <p>There is no image</p>
        </Container.Grid>
      )}
      <Container.Grid item sm={12} xs={12} mb={2} justify="flex-end">
        {certificate && (
          <a
            style={{ textDecoration: 'None' }}
            download="Certificate"
            href={isPdfLoaded ? certificate : 'javascript:void(0)'}
          >
            <ButtonSubmit disabled={!isPdfLoaded} variant="contained">
              Download
            </ButtonSubmit>
          </a>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};
export default DashboardCertificateModal;
