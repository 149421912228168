import {
  CircularProgress,
  IconButton,
  SpreadsheetIcon,
  Stack,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React, { useState } from 'react';
import clients from 'helpers/apis/clients';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const ESSReportCell = ({ clientId, essReport }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isNA(essReport)) return <Typography variant="body2">N/A</Typography>;

  if (isLoading)
    return (
      <Stack p={1}>
        <CircularProgress size={20} />
      </Stack>
    );

  const handleDownloadReport = async () => {
    try {
      setIsLoading(true);
      const res = await clients.getESSReport(clientId);
      window.open(res.data.url);
    } catch (error) {
      enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton onClick={handleDownloadReport}>
      <SpreadsheetIcon sx={{ fontSize: '2rem' }} />
    </IconButton>
  );
};

ESSReportCell.propTypes = {
  essReport: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};

export default ESSReportCell;
