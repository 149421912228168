import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '../types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Tags selector, pro
 * @param {[{label: string, key: string}]} options : ararys of all availble options,
 * each option is an object with a label, a key properties which are strings,
 * key property is unique for each option
 * @param {Function} onChange - a function that receives the selected options once they are changed
 */
const CheckboxesTagsGeneric = props => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [open, setOpen] = useState(false);

  // updating local state when props.options change
  useEffect(() => {
    const state = [{ label: 'Select All', key: 'Select All' }, ...props.options];
    setOptions([...state]);
    setSelectedOptions([...state]);
    return () => {
      setOptions([]);
      setSelectedOptions([]);
    };
  }, [props.options]);

  useEffect(() => {
    // closing the dropdown on scrolling the page
    // so it doesn't float over the top header
    document.addEventListener('scroll', () => {
      setOpen(false);
    });
  }, []);

  /**
   * It sends empty array, if "Select All" option is selected
   * @param {Array} value - currently selected options
   */
  const exportSelectedOptions = value => {
    if (value.some(option => option.key === 'Select All')) {
      console.log('selctected all');
      console.log({ value: [] });
      props.onChange([]);
    } else {
      console.log("didn't select all");
      console.log({ value });
      props.onChange([...value]);
    }
  };

  const handleOnChange = (event, value, reason) => {
    if (reason === 'select-option') {
      const selectedOption = value[value.length - 1];
      // selecting "select all" option
      if (selectedOption.key === 'Select All') {
        setIsAllSelected(true);
        setSelectedOptions([...options]);
        exportSelectedOptions([...options]);
      } else {
        // selecting an option other than "select all"
        setSelectedOptions([...value]);
        exportSelectedOptions([...value]);
      }
    } else if (reason === 'remove-option') {
      // keys of selected options
      const keys = value.map(option => {
        return option.key;
      });
      // deselecting an option while "select all" is selected
      if (keys.includes('Select All') && isAllSelected) {
        value = value.filter(option => {
          return option.key !== 'Select All';
        });
        setIsAllSelected(false);
        setSelectedOptions([...value]);
        exportSelectedOptions([...value]);
        // deselecting "select all" option
      } else if (!keys.includes('Select All') && isAllSelected) {
        setIsAllSelected(false);
        setSelectedOptions([]);
        exportSelectedOptions([]);
        // deselecting an option while "select all" is deselected
      } else {
        setSelectedOptions([...value]);
        exportSelectedOptions([...value]);
      }
      // clearing all options
    } else if (reason === 'clear') {
      setSelectedOptions([...value]);
      exportSelectedOptions([...value]);
    }
  };

  return (
    <span style={{ position: 'relative' }}>
      <Autocomplete
        multiple
        id="checkboxes-options-generic"
        size="small"
        options={options}
        value={selectedOptions}
        open={open}
        onFocus={() => {
          setOpen(true);
        }}
        onBlur={() => {
          setOpen(false);
        }}
        disableCloseOnSelect
        getOptionLabel={option => option.label}
        onChange={handleOnChange}
        renderOption={(option, { selected }) => {
          return (
            <>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </>
          );
        }}
        renderTags={(value, getTagProps) => {
          return (
            <Typography.p
              style={{
                fontSize: 14,
                margin: 0
              }}
            >{`Filter by tag ${value.length}+`}</Typography.p>
          );
        }}
        closeIcon={<CloseIcon style={{ fontSize: 22 }} />}
        popupIcon={<KeyboardArrowDownIcon style={{ fontSize: 22 }} onClick={() => setOpen(state => !state)} />}
        renderInput={params => <TextField {...params} variant="outlined" label="Tags" />}
        fullWidth
        // the disablePortal option is set to true to
        // solve the issue of changing the list position, once the selected options change
        disablePortal
      />
    </span>
  );
};

export default CheckboxesTagsGeneric;
