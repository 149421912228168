// @flow

import disasterRecovery from './disasterRecovery';
import documentsServiceProvider from './documentsServiceProvider';
import documentsTemplates from './documentsTemplates';
import phishingCampaignScenariosList from './phishingCampaignScenariosList';
import phishingCampaignRecipientsList from './phishingCampaignRecipientsList';
import policies from './policies';
import otherDocuments from './otherDocuments';
import otherPolicies from './otherPolicies';
import sraDocuments from '../myCompany/sraDocuments';
import trackSecurityIncidents from './trackSecurityIncidents';
import trackServerRoomAccess from './trackServerRoomAccess';
import usersList from './usersList';
import usersBPPList from './usersBPPList';
import workPlan from '../myCompany/workPlan';
import workPlanFinding from '../myCompany/workPlanFinding';
import clientNotes from './clientNotes';
import trainingAnnual from "./trainingAnnual"
import trainingUsers from './trainingUsers';
import trainingTrainings from "./trainingTrainings"
import trainingUsersTrainings from './trainingUsersTrainings';
import trainingTrainingsUsers from './trainingTrainingsUsers';
import sra from './sra';
import moveUserClients from './moveUserClients'

const clients = {};

clients.disasterRecovery = disasterRecovery;
clients.documentsServiceProvider = documentsServiceProvider;
clients.documentsTemplates = documentsTemplates;
clients.phishingCampaignScenariosList = phishingCampaignScenariosList;
clients.phishingCampaignRecipientsList = phishingCampaignRecipientsList;
clients.policies = policies;
clients.otherDocuments = otherDocuments;
clients.otherPolicies = otherPolicies;
clients.sraDocuments = sraDocuments;
clients.trackSecurityIncidents = trackSecurityIncidents;
clients.trackServerRoomAccess = trackServerRoomAccess;
clients.usersList = usersList;
clients.usersBPPList = usersBPPList;
clients.workPlan = workPlan;
clients.workPlanFinding = workPlanFinding;
clients.clientNotes = clientNotes;
clients.trainingAnnual = trainingAnnual;
clients.trainingUsers = trainingUsers;
clients.trainingTrainings = trainingTrainings;
clients.trainingUsersTrainings = trainingUsersTrainings;
clients.trainingTrainingsUsers = trainingTrainingsUsers;
clients.sra = sra;
clients.moveUserClients = moveUserClients;

export default clients;
