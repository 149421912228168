import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, DragZone, Button, TextField } from 'components';
import { useNotify } from 'react-admin';
import { dataProvider } from 'helpers';
import { useLocation, useId } from 'hooks';
import { Step, StepLabel } from '@material-ui/core';
import dashboard from 'helpers/apis/Dashboard/dashboard';
import { Stepper } from './ComponentTypes';

const initialState = {
  data: {
    description: '',
    details: '',
    file: { name: '' },
    name: '',
    id: ''
  },
  step: 0,
  disabled: true,
  loading: false
};

let statusMessage = null;
let showCreatedMsg = false;

const PagePoliciesModal = ({ tab, dispatch, refetchOnCreate }) => {
  const app = 'clients';
  const notify = useNotify();
  const [paste, setPaste] = useState(false);
  const [createdData, setCreatedData] = useState(null);
  const modalType = tab === 'policies' ? 'policy' : 'other_policy';
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const [state, setState] = useReducer(reducer, initialState);
  const setId = item || clientId;
  const steps = ['Information', 'Attachment'];

  useEffect(() => {
    return () => {
      if (showCreatedMsg) {
        notify(statusMessage);
        showCreatedMsg = false;
        statusMessage = null;
      }
    };
  }, [notify]);

  const onChange = ({ target: { name, value } }, type) => {
    if (paste) {
      setState({ type, payload: { [name]: value } });
      setPaste(false);
    }
    setState({ type, payload: { [name]: value } });
  };

  const handleNext = e => {
    e.preventDefault();
    setState({ type: 'SETLOADING', payload: true });

    const isEdit =
      createdData &&
      (createdData.name !== state.data.name ||
        createdData.description !== state.data.description ||
        createdData.details !== state.data.details);

    const onError = error => {
      notify(error?.response?.data?.message, 'error');
    };
    const onFinished = () => {
      setState({ type: 'SETLOADING', payload: false });
    };

    if (state.data.id === '') {
      const onCreationSuccess = res => {
        statusMessage = res.data?.status || `New Policy ${state.data.name} was successfully created`;
        showCreatedMsg = true;
        setState({ type: 'NEXTSTEP', payload: { id: res.data?.data?.id, disabled: !state.data.file.name } });
        setCreatedData(res.data?.data);
        refetchOnCreate && refetchOnCreate();
      };

      if (tab === 'policies') {
        dashboard
          .createPolicy({
            app,
            id: setId,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .then(onCreationSuccess)
          .catch(onError)
          .finally(onFinished);
      } else if (tab === 'otherPolicies') {
        dashboard
          .createOtherPolicy({
            app,
            id: setId,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .then(onCreationSuccess)
          .catch(onError)
          .finally(onFinished);
      }
    } else if (isEdit) {
      const onUpdateSuccess = res => {
        statusMessage = `New Policy ${res.data?.data?.name} was successfully created`;
        showCreatedMsg = true;
        setCreatedData(res.data?.data);
        setState({ type: 'NEXTSTEP', payload: { id: res.data?.data?.id, disabled: !state.data.file.name } });
        refetchOnCreate && refetchOnCreate();
      };
      if (tab === 'policies') {
        dashboard
          .updatePolicyInfo({
            app,
            policyID: state.data.id,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .then(onUpdateSuccess)
          .catch(onError)
          .finally(onFinished);
      } else if (tab === 'otherPolicies') {
        dashboard
          .updateOtherPolicyInfo({
            app,
            policyID: state.data.id,
            data: {
              description: state.data.description,
              details: state.data.details,
              name: state.data.name
            }
          })
          .then(onUpdateSuccess)
          .catch(onError)
          .finally(onFinished);
      }
    } else {
      setState({ type: 'SETLOADING', payload: false });
      setState({ type: 'NEXTSTEP', payload: { id: state.data.id, disabled: !state.data.file.name } });
    }
  };

  const handleBack = e => {
    e.preventDefault();
    setState({ type: 'PREVSTEP', payload: null });
  };

  const onSave = () => {
    setState({ type: 'SETLOADING', payload: true });
    showCreatedMsg = false;
    dashboard
      .presignedUpload({
        app,
        id: state.data.id,
        data: {
          filename: state.data.file.name,
          type: modalType
        }
      })
      .then(res => {
        const formData = new FormData();
        formData.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formData.append('key', res.data.fields.key);
        formData.append('policy', res.data.fields.policy);
        formData.append('signature', res.data.fields.signature);
        formData.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formData.append('file', state.data.file);

        dataProvider
          .postUrl(res.data.url, formData, 'multipart/form-data')
          .then(resp => {
            // eslint-disable-next-line no-console
            notify(res.data?.status || statusMessage);
            refetchOnCreate && refetchOnCreate();
            dispatch.onClose();
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          })
          .finally(() => {
            setState({ type: 'SETLOADING', payload: false });
          });
      })
      .catch(err => {
        notify(err && err.response && err.response.data.message, 'warning');
      });
  };

  const onFileUpload = file => {
    setState({ type: 'STEP2', payload: file });
  };

  return (
    <Container.Grid direction="column">
      <Stepper activeStep={state.step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container.Grid item alignItems="center" sm={12} xs={12} xl={12} md={12}>
        {state.step === 0 ? (
          <Container.Grid item direction="column" mb={3} ml={3} mr={3} sm={12} xs={12} xl={12} md={12}>
            <TextField
              fullWidth
              source={state.data.name}
              value={state.data.name}
              id="name"
              label="Name"
              name="name"
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange(e, 'STEP1'),
                maxLength: 200
              }}
            />
            <TextField
              fullWidth
              source={state.data.description}
              value={state.data.description}
              id="description"
              label="Description"
              name="description"
              multiline
              rows={4}
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange(e, 'STEP1')
              }}
            />
            <TextField
              fullWidth
              source={state.data.details}
              value={state.data.details}
              id="details"
              label="Details"
              name="details"
              multiline
              rows={4}
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange(e, 'STEP1')
              }}
            />
          </Container.Grid>
        ) : (
          <Container.Grid item direction="column" ml={3} pb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid direction="row" alignItems="center" sm={4} xs={4} xl={4} md={4}>
              <DragZone
                title="Upload a file*"
                fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls"
                size={12}
                record={state.data}
                dispatch={onFileUpload}
                type="file"
              />
            </Container.Grid>
          </Container.Grid>
        )}
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mr={3} sm={12} xs={12} xl={12} md={12}>
        {state.step > 0 ? (
          <>
            <Button onClick={e => handleBack(e)}>Back</Button>
            <Button ml={2} disabled={state.loading || state.disabled} onClick={onSave}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleNext} disabled={state.loading || state.disabled}>
            Next
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'STEP1': {
      const newData = { ...prevState.data, ...payload };
      const isDisable =
        newData.name.trim().length > 0 && newData.description.trim().length > 0 && newData.details.trim().length > 0;
      return { ...prevState, data: newData, disabled: !isDisable };
    }

    case 'STEP2': {
      return { ...prevState, data: { ...prevState.data, file: payload }, disabled: false };
    }

    case 'SETDATA': {
      return { ...prevState, data: { ...prevState.data, ...payload } };
    }

    case 'NEXTSTEP': {
      return {
        ...prevState,
        step: prevState.step + 1,
        data: { ...prevState.data, id: payload.id },
        disabled: payload.disabled
      };
    }

    case 'PREVSTEP': {
      const { name, description, details } = prevState.data;
      const isDisable = name.trim().length > 0 && description.trim().length > 0 && details.trim().length > 0;
      return { ...prevState, step: prevState.step - 1, disabled: !isDisable, loading: false };
    }

    case 'SETLOADING': {
      return { ...prevState, loading: payload };
    }

    default: {
      return prevState;
    }
  }
}

PagePoliciesModal.propTypes = {
  tab: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    onClose: PropTypes.func.isRequired
  }).isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default PagePoliciesModal;
