import React from 'react';
import { Link, Stack, Typography } from '@trustsecurenow/components-library';

const Footer = () => {
  return (
    <Stack
      px={3}
      py={2.5}
      spacing={9}
      justifyContent="space-between"
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'center', md: 'flex-end' }}
    >
      <Stack spacing={{ xs: 4, md: 5 }} alignItems={{ xs: 'center', md: 'flex-start' }}>
        <Typography variant="h2" color="common.white">
          Contact Us:
        </Typography>
        <Link
          target="_blank"
          variant="link"
          fontSize={{ xs: 20, md: 24 }}
          color="common.white"
          href="mailto:help@breachsecurenow.com"
        >
          help@breachsecurenow.com
        </Link>
      </Stack>
      <Typography variant="body2" color="common.white">
        PII Protect Copyright © 2024
      </Typography>
    </Stack>
  );
};

export default Footer;
