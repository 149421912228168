/* eslint-disable no-console */
// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { Error } from 'react-admin';
import { LoadingStyled } from 'components';

export * from './admin';
export * from './advancedFilter';
export * from './common';
export * from './dashboard';
export * from './forms';
export * from './layout';
export * from './list';
export * from './snackbar';
export * from './tabs';
export * from './input';
export * from './icons';
export * from './tablelist';
export * from './tablelist2';
export * from './charts';
export * from './types';
export * from './modal';
export * from './pages';
export * from './text';
export * from './loadable';
export * from './quiz';
export * from './customTable';
export * from './error';
export * from './newForms';
export * from './buttons';
export * from './textField';

export const LazyComponent = ({ component, ...rest }: Object) => {
  const LoadableTab = Loadable<any>({
    loader: () => import(`./${component}`),
    loading: ({ error }: { error: any }) => {
      if (error) {
        console.group(`%cEROR: LAZY APP`, 'font-size: medium; color: red');
        console.log(error);
        console.groupEnd();
        return <Error />;
      }
      return <LoadingStyled centerAlign />;
    }
  });

  return <LoadableTab {...rest} />;
};
