// @flow
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { format } from 'date-fns';
import newsfeed from 'helpers/apis/newsfeed';
import { LoadingStyled, Button, CustomTooltip, InfoButtonWithPopover } from 'components';
import { Alert2 } from 'components/icons';
import { CustomAvatar, CharacterProgress, FileUpload, AudienceSelect } from '../common';
import { FeedEditMode } from '../post';
import {
  Grid,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
  Hidden
} from '@material-ui/core';
import { Close as CloseIcon, ArrowBackIosRounded as ArrowBackIosRoundedIcon } from '@material-ui/icons';
import { toUTCfromLocal, getStorage, cleanTextFromHtml, sortArrayOfObjectsByKey, unicodeToEmoji, emojiToUnicode, convertStyleAttr } from 'helpers';
import { useBreakpoint } from 'hooks';
import { addFeed, updateFeedId, deleteFeed, updateFeed } from 'helpers/action';
import { formatHashtags, getFilePreview, getAudiencePreview, getRolePreview, getAvatar, getAudienceById, removeEmptyHashtagAndMention, getNotifyAudience } from '../../newsfeedHelpers';
import { AUDIENCE_ORDER, ROLE_ORDER, CONTENT_OPTIONS } from '../../constants';
import { SelectAudience, SelectRole, SelectClients, SelectContent, SelectNotifyType } from './addUpdatePost';
import { ChipOutlineInfo } from '../../ComponentTypes';
import { AUDIENCES } from '../../constants';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  image: {
    maxWidth: '100%'
  },
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 'calc(100% - 62px)',
    marginLeft: 12,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5
    }
  },
  chip: {
    maxWidth: '100%'
  },
  userName: {
    maxWidth: '32%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%'
    }
  },
  audience: {
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'var(--backgroundDefault)'
    },
    '& span': {
      display: 'flex',
      alignItems: 'center'
    },
    '& svg': {
      fontSize: 15,
      marginRight: 10,
      '& ~ svg': {
        marginRight: 0,
        marginLeft: 10
      }
    }
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  backBtn: {
    padding: 8
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  },
  progress: {
    marginLeft: 'auto'
  },
  dialogHeader: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px 10px',
      '& h2 div': {
        marginLeft: 'auto'
      },
      '& h2 div .post-btn': {
        fontSize: '14px',
        lineHeight: 1.5
      }
    },
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  dialogHeaderSm: {
    padding: '16px 16px 10px',
    '& p': {
      marginLeft: 15
    }
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
      minHeight: 200
    }
  },
  dialogActions: {
    padding: '0 8px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 18px 20px'
    }
  },
  loading: {
    position: 'absolute',
    zIndex: '999',
    width: '100%',
    height: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'var(--whiteAndBlack)',
      opacity: 0.64,
      zIndex: -1
    }
  },
  contentBtn: {
    padding: 8,
    '& svg': {
      fontSize: props => (props.mobileView ? 'calc(1.3 * var(--fontSize))' : 'calc(1.6 * var(--fontSize))'),
      color: 'var(--colorIcon)'
    }
  },
  notifyAudience: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    order: 1
  },
  errorMsg: {
    fontSize: 10,
    color: 'var(--colorSystemDanger)',
    width: '100%',
    margin: 0
  }
}));

const editorOptions = [['bold', 'italic', 'underline'], ['custom-emoji']];
const LIMIT = 2000;

const AddUpdatePostModal = ({ open, close, postInfo, title, scrollToFeedsTop }): Element<*> => {
  const mobileView = useBreakpoint('sm');
  const classes = useStyles({ mobileView });
  const dispatch = useDispatch();
  const notify = useNotify();
  const user = { name: getStorage('name') };
  const { profile, theme } = useSelector(state => state.bsn?.user);
  const [postData, setPostData] = useState({
    ...postInfo.post,
    text: unicodeToEmoji(convertStyleAttr(postInfo.post.text, true))
  });
  const [postLoading, setPostLoading] = useState(false);
  const [disablePosting, setDisablePosting] = useState(true);
  const [startUpload, setStartUpload] = useState(false);
  const [startPreview, setStartPreview] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState(
    postData.filePreview ? getFilePreview(postData.filePreview) : null
  );
  const [fileChanged, setFileChanged] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [view, setView] = useState('main'); // views: main, audience, roles, clients, security_newsletter, microtraining, notify_audience
  const [audienceLoading, setAudienceLoading] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [roles, setRoles] = useState([]);
  const [audienceData, setAudienceData] = useState({
    allowed_groups: postInfo.allowed_groups || [],
    included_clients: postInfo.included_clients || [],
    audience: postInfo.audience || null
  });
  const [notifyAudience, setNotifyAudience] = useState({
    push_notifications: postInfo.post.push_notifications || false,
    email_notifications: postInfo.post.email_notifications || false
  });
  const [postContent, setPostContent] = useState({
    security_newsletter: postInfo.security_newsletter || null,
    microtraining: postInfo.microtraining || null
  });
  const cleanPostText = useMemo(() => cleanTextFromHtml(postData.text), [postData.text]);
  const clientsAudience = useMemo(() => audiences?.find(item => item.name === AUDIENCES.clients), [audiences]);
  const isAudienceSelected = audienceData.audience;
  const disableActions = videoProgress > 0;
  const selectedContent = useMemo(() => postContent.security_newsletter || postContent.microtraining, [postContent]);
  const isContentAdmin = profile?.content_admin;
  const disableNotifyAudience = useMemo(() => {
    const isEditMode = postInfo.post.postId;
    const isNotifyAudienceEnabled = postInfo.post.push_notifications || postInfo.post.email_notifications;
    return isEditMode && isNotifyAudienceEnabled;
  }, [postInfo.post]);

  const includesDangerousUrl = () => {
    return postData?.text.includes(localStorage.getItem('prohibitedLink'));
  };

  useEffect(() => {
    if (cleanPostText.trim() === '' || postLoading || cleanPostText.length > LIMIT || !isAudienceSelected || includesDangerousUrl()) {
      setDisablePosting(true);
    } else {
      setDisablePosting(false);
    }
  }, [cleanPostText, isAudienceSelected, postLoading]);

  useEffect(() => {
    setAudienceLoading(true);
    newsfeed
      .getFeedPicklist(['audiences', 'groups'])
      .then(res => {
        const data = res.data;
        const sortedAudiences = sortArrayOfObjectsByKey(data.audiences, AUDIENCE_ORDER, 'name');
        const sortedRoles = sortArrayOfObjectsByKey(data.groups, ROLE_ORDER, 'name');
        setAudiences(sortedAudiences);
        setRoles(sortedRoles);
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => setAudienceLoading(false));
  }, [notify]);

  const postFeed = useCallback(
    (filename = null) => {
      const { postId, filePreview, ...post_data } = { ...postData };
      const postAllowedGroups = audienceData.allowed_groups;
      const postIncludedClients = audienceData.included_clients.map(item => item.id);
      const newFeedTs = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);

      const newPostText = convertStyleAttr(
        emojiToUnicode(formatHashtags(removeEmptyHashtagAndMention(post_data.text)))
      );

      // Post data for sending a request
      const newPostData = {
        post: {
          ...post_data,
          text: newPostText,
          filename,
          expiration_datetime: post_data.expiration_datetime
            ? toUTCfromLocal(post_data.expiration_datetime)
            : '9999-12-31 23:59:59',
          show_datetime: post_data.show_datetime ? toUTCfromLocal(post_data.show_datetime) : '1000-01-01 00:00:00',
          audience_id: audienceData?.audience?.id,
          push_notifications: notifyAudience.push_notifications,
          email_notifications: notifyAudience.email_notifications
        },
        allowed_groups: postAllowedGroups,
        included_clients: postIncludedClients,
        security_newsletter_id: postContent?.security_newsletter?.id || '',
        microtraining_id: postContent?.microtraining?.id || ''
      };

      // Post data for optimistic rendering
      const newFeed = {
        timestamp: format(newFeedTs, 'yyyy-MM-dd HH:mm:ss'),
        text: newPostData.post.text,
        audience: getAudienceById(audiences, newPostData.post.audience_id),
        allowed_groups: postAllowedGroups,
        included_clients: audienceData.included_clients,
        security_newsletter: postContent.security_newsletter,
        microtraining: postContent.microtraining,
        push_notifications: newPostData.post.push_notifications,
        email_notifications: newPostData.post.email_notifications
      };

      // Add post content
      if (filePreviewData) {
        if (filePreviewData.type !== 'video') {
          newFeed.content = {
            data: {
              filename,
              url: `${process.env.NEWSFEED_ASSETS_BASE_URL}assets/newsfeed/${encodeURIComponent(filename)}`
            },
            type: filePreviewData.type
          };
        } else {
          newFeed.content = {
            data: {},
            type: 'video'
          };
          newFeed.file_ready = false;
        }
      } else if (post_data.thumbnail_metadata) {
        newFeed.content = {
          data: {
            ...post_data.thumbnail_metadata
          },
          type: 'thumbnail'
        };
      } else {
        newFeed.content = null;
      }

      if (!postData.postId) {
        const newFeedTempId = Date.now();
        // New added post data for optimistic rendering
        const newFeedAdded = {
          ...newFeed,
          comments_count: 0,
          expiration_datetime: newPostData.post.expiration_datetime,
          id: newFeedTempId,
          liked: false,
          likes_count: 0,
          published: 1,
          favorited: false,
          pinned: false,
          show_datetime: newPostData.post.show_datetime,
          disableActions: true,
          user: {
            client_name: profile?.client_name,
            email: profile?.email,
            first_name: profile?.first_name,
            id: +post_data.user_id,
            last_name: profile?.last_name,
            partner_base_color: theme?.base?.base,
            avatar_filename: profile?.avatar_filename,
            content_admin: profile?.content_admin
          }
        };

        dispatch(addFeed(newFeedAdded));
        setFilePreviewData(null);
        close();
        scrollToFeedsTop();

        // Add post request
        newsfeed
          .addPost(+post_data.user_id, newPostData)
          .then(res => {
            dispatch(updateFeedId(newFeedTempId, res?.data?.data?.post_id));
            notify(res?.data?.message);
          })
          .catch(err => {
            dispatch(deleteFeed(newFeedTempId));
            notify(err?.response?.data?.message || 'Something went wrong', 'error');
          });
      } else {
        !postLoading && setPostLoading(true);

        // Update post request
        newsfeed
          .updatePost(postData.postId, newPostData)
          .then(res => {
            dispatch(updateFeed(postData.postId, newFeed));
            setFilePreviewData(null);
            close();
            notify(res?.data?.message);
          })
          .catch(err => {
            notify(err?.response?.data?.message || 'Something went wrong', 'error');
          })
          .finally(() => setPostLoading(false));
      }
    },
    [
      audienceData.allowed_groups,
      audienceData?.audience?.id,
      audienceData.included_clients,
      close,
      scrollToFeedsTop,
      dispatch,
      filePreviewData,
      notify,
      postContent.microtraining,
      postContent.security_newsletter,
      postData,
      postLoading,
      profile?.client_name,
      profile?.email,
      profile?.first_name,
      profile?.last_name,
      theme?.base?.base,
      notifyAudience
    ]
  );

  const previewFile = useCallback(e => setStartPreview(e.target), []);

  const onSetData = useCallback(newData => {
    setPostData(prevState => {
      return { ...prevState, ...newData };
    });
  }, []);

  const onChangeContent = useCallback(
    (type, obj) => {
      setView('main');
      const newPostContent = { ...postContent };
      Object.keys(newPostContent).forEach(key => newPostContent[key] = null);
      setPostContent({ ...newPostContent, [type]: obj });
      setFilePreviewData(null);
      onSetData({ filename: null, thumbnail_metadata: null });
    },
    [postContent, onSetData]
  );

  // Remove empty hashtag or mention from post text when user changes the view
  useEffect(() => {
    if (view !== 'main') {
      setPostData(prevState => {
        return { ...prevState, text: removeEmptyHashtagAndMention(prevState.text) };
      });
    }
  }, [view]);

  const topInfoMemo = useMemo(
    () => (
      <div className={classes.topInfo}>
        <Box className={classes.userName}>
          <Chip variant="outlined" label={user.name} className={classes.chip} />
        </Box>
        <Hidden smDown>
          <Box ml={{ xs: 0, md: 1.5 }} mt={{ xs: 1.2, md: 0 }}>
            <Chip
              variant="outlined"
              label={
                <>
                  {getAudiencePreview(audienceData).icon} {getAudiencePreview(audienceData).text}
                </>
              }
              className={`${classes.chip} ${classes.audience}`}
              onClick={() => setView('audience')}
              disabled={disableActions}
            />
          </Box>
          <Box ml={{ xs: 0, md: 1.5 }} mt={{ xs: 1.2, md: 0 }}>
            <Chip
              variant="outlined"
              label={
                <>
                  {getRolePreview(audienceData).icon} {getRolePreview(audienceData).text}
                </>
              }
              className={`${classes.chip} ${classes.audience}`}
              onClick={() => setView('roles')}
              disabled={audienceData?.audience?.name === AUDIENCES.partner || disableActions}
            />
          </Box>
        </Hidden>
        <Box mt={1.2} className={classes.notifyAudience}>
          <Box>
            <CustomTooltip title={isAudienceSelected || disableNotifyAudience ? '' : 'Select Audience'} placement="top">
              <span>
                <Chip
                  variant="outlined"
                  label={
                    <>
                      <Alert2 /> Notify Audience?
                      {getNotifyAudience(notifyAudience).icon}
                    </>
                  }
                  className={`${classes.chip} ${classes.audience}`}
                  onClick={() => setView('notify_audience')}
                  disabled={!isAudienceSelected || disableActions || disableNotifyAudience}
                />
              </span>
            </CustomTooltip>
          </Box>
          <Box>
            <InfoButtonWithPopover
              name="notify-audience"
              tooltip="When enabled, an in-portal notification (or optional Email notification) message will be created for the users within the selected audience, directing them to this post"
            />
          </Box>
          {disableNotifyAudience ? (
            <p className={classes.errorMsg}>Additional notifications will not be sent out upon editing this post</p>
          ) : null}
        </Box>
        <Hidden mdUp>
          <AudienceSelect audienceData={audienceData} setView={setView} />
        </Hidden>
      </div>
    ),
    [audienceData, user.name, disableActions, isAudienceSelected, notifyAudience, disableNotifyAudience]
  );

  const postBtnMemo = useMemo(
    () => (
      <CustomTooltip title={isAudienceSelected ? '' : 'Select Audience'} placement="top">
        <span>
          <Button
            disabled={disablePosting || disableActions}
            onClick={() => {
              const hasFile = filePreviewData && (!postData.filename || fileChanged);
              return hasFile ? setStartUpload(true) : postFeed(postData.filename);
            }}
            color="primary"
            variant="contained"
            disableElevation
            className="post-btn"
          >
            Post
          </Button>
        </span>
      </CustomTooltip>
    ),
    [disableActions, disablePosting, fileChanged, filePreviewData, isAudienceSelected, postData.filename, postFeed]
  );

  const feedEditModeMemo = useMemo(
    () => (
      <FeedEditMode
        data={postData}
        setData={onSetData}
        initialData={postInfo.post}
        textType="text"
        loading={postLoading}
        setLoading={setPostLoading}
        startUpload={startUpload}
        setStartUpload={setStartUpload}
        startPreview={startPreview}
        setStartPreview={setStartPreview}
        setFileChanged={setFileChanged}
        onSubmit={postFeed}
        filePreviewData={filePreviewData}
        setFilePreviewData={setFilePreviewData}
        videoProgress={videoProgress}
        setVideoProgress={setVideoProgress}
        disableAddingContent={selectedContent}
        editorData={{
          editorOptions,
          height: '200px',
          placeholder: 'What do you want to talk about? 💬',
          readOnly: disableActions
        }}
      />
    ),
    [
      disableActions,
      filePreviewData,
      onSetData,
      postData,
      postFeed,
      postInfo.post,
      postLoading,
      selectedContent,
      startPreview,
      startUpload,
      videoProgress
    ]
  );

  const dialogTitleMemo = useMemo(
    () => (
      <DialogTitle id="dialog-title" className={classes.dialogHeader}>
        <Hidden mdUp>
          <IconButton className={classes.backBtn} onClick={close}>
            <ArrowBackIosRoundedIcon style={{ fontSize: 20 }} />
          </IconButton>
        </Hidden>
        <Typography className={classes.title}>{title}</Typography>
        <Hidden smDown>
          <IconButton className={classes.closeBtn} id="ModalHeaderButtonClose" onClick={close}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </Hidden>
        <Hidden mdUp>{postBtnMemo}</Hidden>
      </DialogTitle>
    ),
    [close, postBtnMemo, title]
  );

  const progressMemo = useMemo(() => <CharacterProgress length={cleanPostText.length} limit={LIMIT} mr={16} />, [cleanPostText.length]);

  const feedContentOptionsMemo = useMemo(
    () =>
      Object.keys(CONTENT_OPTIONS).map(option => (
        <Grid item key={option}>
          <Box mr={{ xs: 2, md: 1.25 }}>
            <CustomTooltip title={CONTENT_OPTIONS[option].tooltip} placement="top">
              <IconButton
                component="span"
                className={classes.contentBtn}
                onClick={() => setView(CONTENT_OPTIONS[option].view)}
                disabled={disableActions}
              >
                {CONTENT_OPTIONS[option].icon}
              </IconButton>
            </CustomTooltip>
          </Box>
        </Grid>
      )),
    [disableActions]
  );
  
  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm" fullScreen={mobileView}>
        {postLoading && (
          <div className={classes.loading}>
            <LoadingStyled />
          </div>
        )}

        {/* Main content */}
        {view === 'main' && (
          <>
            {dialogTitleMemo}

            <Hidden smDown>
              <Divider />
            </Hidden>

            <DialogContent className={classes.dialogContent}>
              <Box py={{ xs: 0, md: 2 }}>
                <Box pb={{ xs: 1.2, md: 2.5 }} display="flex">
                  <Box>
                    <CustomAvatar 
                    user={{avatar_filename: profile?.avatar_filename}} > 
                      {getAvatar(user.name)}
                    </CustomAvatar>
                  </Box>
                  {topInfoMemo}
                </Box>

                {feedEditModeMemo}

                {selectedContent && (
                  <Box mt={2}>
                    <ChipOutlineInfo
                      label={selectedContent.name}
                      size="small"
                      onDelete={() => setPostContent({ security_newsletter: null, microtraining: null })}
                      deleteIcon={<CloseIcon />}
                      br={20}
                    />
                  </Box>
                )}
              </Box>
            </DialogContent>

            <Hidden smDown>
              <Divider />
            </Hidden>

            <DialogActions className={classes.dialogActions}>
              <Grid container className={classes.padded} alignItems="center">
                <Grid item>
                  <FileUpload
                    type="image"
                    previewFile={previewFile}
                    iconSize={mobileView ? 1.3 : 1.6}
                    disabled={disableActions || selectedContent}
                  />
                </Grid>
                <Grid item>
                  <FileUpload
                    type="video"
                    previewFile={previewFile}
                    iconSize={mobileView ? 1.3 : 1.6}
                    disabled={disableActions || selectedContent}
                  />
                </Grid>
                <Grid item>
                  <FileUpload
                    type="file"
                    previewFile={previewFile}
                    iconSize={mobileView ? 1.3 : 1.6}
                    disabled={disableActions || selectedContent}
                  />
                </Grid>

                {feedContentOptionsMemo}

                <Grid item className={classes.progress}>
                  {cleanPostText.trim().length > 0 && progressMemo}
                </Grid>

                <Hidden smDown>
                  <Grid item>{postBtnMemo}</Grid>
                </Hidden>
              </Grid>
            </DialogActions>
          </>
        )}

        {/* Select Audience */}
        {view === 'audience' && (
          <SelectAudience
            options={audiences}
            setView={setView}
            data={audienceData}
            setAudienceData={setAudienceData}
            audienceLoading={audienceLoading}
            setNotifyAudience={setNotifyAudience}
            isContentAdmin={isContentAdmin}
            disableNotifyAudience={disableNotifyAudience}
          />
        )}

        {/* Select Roles */}
        {view === 'roles' && (
          <SelectRole
            options={roles}
            setView={setView}
            data={audienceData}
            setAudienceData={setAudienceData}
          />
        )}

        {/* Select specific clients */}
        {view === 'clients' && (
          <SelectClients
            setView={setView}
            data={audienceData}
            setAudienceData={setAudienceData}
            clientsAudience={clientsAudience}
          />
        )}

        {/* Select Newsletter or Micro training */}
        {(view === 'security_newsletter' || view === 'microtraining') && (
          <SelectContent
            type={view}
            setView={setView}
            postContent={postContent}
            onChangeContent={onChangeContent}
          />
        )}

        {/* Notify Audience */}
        {view === 'notify_audience' && (
          <SelectNotifyType
            setView={setView}
            notifyAudience={notifyAudience}
            setNotifyAudience={setNotifyAudience}
            isContentAdmin={isContentAdmin}
            audienceData={audienceData}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddUpdatePostModal;
