// @flow
import React, { type ComponentType, useCallback, useState, useRef } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import throttle from 'lodash/throttle';
import 'cropperjs/dist/cropper.css';
import { Container, Typography, LazyIcon } from 'components';
import styled from 'styled-components';
import COMMON_CONST from '../../apps/shared/constants';
import ClientsDeleteLogoModal from '../../apps/clients/ClientsDeleteLogoModal';
import { dataURLtoFile } from 'helpers/utils';

export const DragZone: ComponentType<*> = styled(Box)`
  border: calc(var(--borderSize) * 2) dashed var(--borderDefault);
  border-radius: calc(var(--borderSize) * 10);
  cursor: pointer;
  width: 100%;
`;

export const BoxContainer: ComponentType<*> = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const BoxImage: ComponentType<*> = styled(Box)`
  overflow: hidden;
  & > img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    max-width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;

export const BoxDescription: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h6,
  p {
    margin: 0;
    padding-bottom: 8px;
  }
`;

export const Figure: ComponentType<*> = styled.figure`
  margin: 0;
  & > img {
    max-height: 100px;
    max-width: 200px;
    width: auto;
    height: auto;
  }
`;

export const AttachmentInfo: ComponentType<*> = styled.span`
  color: var(--colorDefault);
  padding-left: var(--spacing);
`;

type Props = {
  logo: string,
  name: string,
  onChange: Function,
  preview: boolean,
  setPreview: Function,
  label: string
};

const DragDropLogo = ({
  logo = '',
  name,
  onChange,
  preview,
  setPreview,
  label = '',
  fileName = '',
  deleteLogo = () => {}
}: Props) => {
  const notify = useNotify();
  const [file, setFile] = useState([{ preview: null, name: '' }]);
  const [cropper, setCropper] = useState({});
  const [openDeleteLogoModal, setOpenDeleteLogoModal] = useState(false);
  const crop = useRef();

  const onFileUploadAccepted = useCallback(acceptedFiles => {
    const acceptedFile = acceptedFiles[0];
    const preview = URL.createObjectURL(acceptedFile);
    setPreview(true);
    setFile([Object.assign(acceptedFile, { preview: preview })]);
  }, []);

  const onFileUploadRejected = useCallback(rejectedFiles => {
    // we are only interested in the first rejected file
    const rejectedFile = rejectedFiles[0]?.file;
    const maxLogoSize = COMMON_CONST.LOGO_SETTINGS.maxLogoSize;

    if (rejectedFile.size > maxLogoSize) {
      notify(`File ${rejectedFile?.name} exceeds maximum allowed file size (${maxLogoSize / 1000000} MB)`, 'warning');
    } else {
      notify(`This type of file is not allowed. Please use only .png, .jpg, .jpeg files`, 'warning');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    multiple: COMMON_CONST.LOGO_SETTINGS.allowedMultipleFiles,
    maxSize: COMMON_CONST.LOGO_SETTINGS.maxLogoSize,
    onDropAccepted: onFileUploadAccepted,
    onDropRejected: onFileUploadRejected,
    disabled: (preview && file[0].preview !== null) || logo
  });

  const onClick = () => {
    const reader = new FileReader();
    const canvas = crop.current.getCroppedCanvas();
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const croppedFile = dataURLtoFile(reader.result, file[0]?.type, file[0]?.name);
        onChange(name, canvas.toDataURL(), croppedFile);
        setPreview(false);
      };
    });

    // Removing focus of Dragzone after attachment
    document.activeElement.blur();
  };

  // Getting the name of file using the logo url to show in UI
  const getLogoFileName = logoData => {
    if (logoData) {
      return logoData.substring(logoData.lastIndexOf('/') + 1).split('?')[0];
    }
  };

  const onDeleteClicked = () => {
    if (logo && file[0].preview == null) {
      setOpenDeleteLogoModal(true);
    } else {
      onChange(name, null);
    }
  };

  return (
    <>
      <Container.Grid direction="column">
        {label && (
          <Typography.h5 mt={0.4} mb={1}>
            {label}
          </Typography.h5>
        )}
        <DragZone display="flex" alignItems="center" p={3} {...getRootProps()}>
          {preview && file[0].preview !== null ? (
            file.map(file => (
              <BoxContainer key={file.name}>
                <BoxImage>
                  <Cropper
                    ref={crop}
                    src={file.preview}
                    style={{ height: 200, width: 200 }}
                    initialAspectRatio={16 / 9}
                    guides={false}
                    onInitialized={setCropper}
                    crop={throttle(setCropper, 500)}
                    value={cropper}
                  />
                  <Button color="primary" onClick={onClick}>
                    Crop and Save
                  </Button>
                </BoxImage>
              </BoxContainer>
            ))
          ) : (
            <>
              {logo ? (
                <Figure>
                  <img src={logo} alt={label} />
                </Figure>
              ) : (
                <>
                  <input {...getInputProps()} />
                  <LazyIcon component="DragDrop" mr={3} size={2.3} color="colorDefault" />
                  <BoxDescription>
                    <Typography.h6>Update Logo</Typography.h6>
                    <Typography.p fontSize={13}>Drag &amp; drop your files or Browse</Typography.p>
                  </BoxDescription>
                </>
              )}
            </>
          )}
        </DragZone>
        {logo ? (
          <Container.Grid container>
            <Container.Grid item justify="space-between" direction="row" alignItems="center" pt={2}>
              <Typography.p color="colorBox" fontSize={10} variant="subtitle1" paragraph mr={2}>
                Current attachment:
                <AttachmentInfo>{fileName || getLogoFileName(logo)}</AttachmentInfo>
              </Typography.p>
              <IconButton onClick={onDeleteClicked}>
                <LazyIcon component="Delete" color="colorDefault" />
              </IconButton>
            </Container.Grid>
          </Container.Grid>
        ) : (
          <>
            <Typography.p fontSize={10} mb={0.01} variant="subtitle1" paragraph>
              Logo should be 200px x 100px / No Larger than 5MB
            </Typography.p>
            <Typography.p fontSize={10} mt={0.01} variant="subtitle1" paragraph>
              Only .png, .jpg, .jpeg files will be accepted / Transparent or white background
            </Typography.p>
          </>
        )}
      </Container.Grid>

      {openDeleteLogoModal && (
        <ClientsDeleteLogoModal
          close={() => setOpenDeleteLogoModal(false)}
          open={openDeleteLogoModal}
          deleteLogo={() => {
            onChange(name, null);
            deleteLogo();
          }}
        />
      )}
    </>
  );
};

export default DragDropLogo;
