// @flow
import React, { Element } from 'react';
import { Container, Tabs } from 'components';

const AdminEdit: Function = (): Element<*> => {
  return (
    <Container.Tab>
      <Tabs />
    </Container.Tab>
  );
};

export default AdminEdit;