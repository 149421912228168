import { Button, alpha, styled } from '@trustsecurenow/components-library';

const CustomButton = styled(Button)(({ theme: { palette }, variant }) => {
  const isOutlined = variant === 'outlined';
  const borderColor = isOutlined ? palette.common.white : 'none';
  const backgroundColor = isOutlined ? 'none' : palette.common.white;
  const color = isOutlined ? palette.common.white : palette.primary.main;
  const activeBackgroundColor = alpha(palette.common.white, 0.6);

  return {
    borderColor,
    color,
    backgroundColor,
    borderRadius: 100,
    ':hover': {
      borderColor,
      backgroundColor
    },
    ':active': {
      backgroundColor: activeBackgroundColor
    }
  };
});

export default CustomButton;
