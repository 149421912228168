// @flow
import React, { useReducer, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
import { InputBase, InputAdornment, Box } from '@material-ui/core';
import { variables } from 'conf';
import { Button, Container, Typography, Switch, LoadingStyled } from 'components';
import { ColorPickup as ColorPickupIcon } from 'components/icons';
import { palleteColors, useApp, getId, isHexColor, trackingUtils } from 'helpers';
import { useId, useDataGet } from 'hooks';
import { StatusCodes } from 'http-status-codes';
import PartnerApi from 'helpers/apis/partner';
import { useThemeContext } from '@trustsecurenow/components-library';
import PartnerInformationLogo from './PartnerInformationLogo';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle } from './ComponentTypes';

const { h6: H6 } = Typography;

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const MainBox = styled.div`
  width: calc((100% - 270px) / 2);
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const InfoButtonsWrap = styled.div`
  width: 270px;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    order: -1;
    > div {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }
`;

const InputBaseShade = styled(InputBase)`
  width: 100%;
`;

const LabelBaseShade = styled.label`
  color: var(--colorDefault);
  border-bottom: calc(var(--borderSize) * 2) solid var(--borderDefault);
  padding-top: calc(var(--spacing) * 0.8);
`;

const InputColorPicker = styled.input`
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  background: none;
  border: none;
  margin-top: 15px;
  &:focus {
    outline: none;
  }
`;

const BoxShade = styled.div`
  display: flex;
  align-items: center;
  margin: 0 calc(var(--spacing) * 5) 0 calc(var(--spacing) * 3);
`;

const ButtonOutlined = styled(Button)`
  && {
    background: transparent;
    border: calc(var(--borderSize) * 2) solid var(--colorBase);
    & > span {
      color: var(--colorBase);
    }

    &:hover {
      background: transparent;
      border: calc(var(--borderSize) * 2) solid var(--colorBase);
    }
  }
`;

const PartnerCustomProfile = () => {
  const notify = useNotify();
  const app = 'partnerProfile';
  const tab = 'customProfile';
  const { setPrimaryColor } = useThemeContext();
  const partner_id = useId({ key: 'partnerId' });
  const item = partner_id;
  const queryClient = useQueryClient();
  const [time, setTime] = useState(Date.now());
  const { data: record, isSuccess: isDataLoaded } = useDataGet({ app, tab, item, params: { _callId: time } });
  const { colorBase = '#082f49', logo = '', enable_dark_mode } = record || { colorBase: '#082f49', logo: '' };
  const {
    color: {
      primary: { base }
    }
  } = variables;
  const { dispatch } = useApp(app, tab);
  const id = getId('partnerId');
  const [state, setState] = useReducer(reducer, {
    inputShade: colorBase,
    picker: colorBase,
    shade: colorBase,
    pallete: palleteColors(colorBase),
    currentPallete: palleteColors(colorBase),
    currentLogo: logo,
    newLogo: null,
    previewLogo: false,
    partnerId: partner_id,
    enableDarkMode: enable_dark_mode
  });
  const initialLogoState = {
    imageFile: null,
    mimeType: null
  };
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(() => initialLogoState);
  const showDarkModeSwitch = useSelector(({ bsn }) => bsn?.user?.access?.features?.show_dark_mode_switch);

  function reducer(previousState, { type, payload }) {
    switch (type) {
      case 'SETDATA': {
        return { ...previousState, [payload.name]: payload.value };
      }
      default: {
        return { ...previousState, ...payload };
      }
    }
  }

  useEffect(() => {
    if (record) {
      dispatch.set(app, tab, record);
      setState({
        type: '',
        payload: {
          inputShade: record.colorBase,
          picker: record.colorBase,
          shade: record.colorBase,
          pallete: palleteColors(record.colorBase),
          currentLogo: record.logo,
          enableDarkMode: record.enable_dark_mode
        }
      });
    }
  }, [record, dispatch]);

  const handleShade = e => {
    setState({ type: '', payload: { picker: e, inputShade: e, shade: e, pallete: palleteColors(e) } });
  };

  const handleResetColor = () => {
    setResetLoading(true);
    dispatch
      .update(app, tab, partner_id, { base })
      .then(({ data }) => {
        dispatch.set('user', 'theme', data.colors);
        setPrimaryColor(null);
        setState({ type: '', payload: { picker: base, inputShade: base, shade: base, pallete: palleteColors(base) } });
      })
      .finally(() => setResetLoading(false));
    queryClient.clear(app, tab, item);
  };

  const uploadLogo = callback => {
    PartnerApi.getPresignedPost(croppedImage?.mimeType, id)
      .then(response => {
        const preSignedPostFields = response.data.logo.fields;
        const formData = new FormData();
        formData.append('AWSAccessKeyId', preSignedPostFields.AWSAccessKeyId);
        formData.append('key', preSignedPostFields.key);
        formData.append('policy', preSignedPostFields.policy);
        formData.append('signature', preSignedPostFields.signature);
        formData.append('x-amz-security-token', preSignedPostFields['x-amz-security-token']);
        formData.append('file', croppedImage.imageFile);
        const logoFileName = preSignedPostFields.key.substring(preSignedPostFields.key.lastIndexOf('/') + 1);
        PartnerApi.uploadLogo(response.data.logo.url, formData, null, 'canceltokensource')
          .then(res => {
            if (res.status == StatusCodes.NO_CONTENT) {
              savePartnerInformation(logoFileName, callback);
            }
          })
          .catch(error => {
            console.error('failed to upload logo using presigned post URL', error);
            setLoading(false);
            notify('Failed To Save Partner Logo', 'error');
          });
      })
      .catch(error => {
        console.error('failed to get logo presigned post', error);
        setLoading(false);
        notify('Failed To Save Partner Logo', 'error');
      });
  };
  const savePartnerInformation = (fileName = null, callback) => {
    const isValidHex = isHexColor(state.picker);
    const obj = {};
    if (state.picker) obj.base = isValidHex ? state.picker : base;
    if (showDarkModeSwitch) obj.enable_dark_mode = +state.enableDarkMode;
    if (state.picker || state.newLogo) {
      const requestData = {
        ...obj,
        colorBase: obj.base,
        ...(fileName ? { fileName: fileName } : {})
      };

      dispatch
        .update(app, tab, partner_id, requestData)
        .then(({ data }) => {
          setLoading(false);
          if (state.picker) {
            dispatch.set('user', 'theme', data.colors);
            const { base: primary } = data.colors;
            setPrimaryColor({
              main: primary.base,
              light: primary.light1,
              lighter: primary.light2,
              lightest: primary.light3,
              dark: primary.dark1,
              darker: primary.dark2,
              darkest: primary.dark3
            });
          }
          if (state.newLogo) {
            setState({ type: '', payload: { newLogo: null, previewLogo: false, currentLogo: data?.logo } });
            if (data?.client_logo_loaded == false) {
              // this means no client logo is loaded and we can do a partner logo update
              dispatch.setItem('user', 'profile', 'logo_partner', data?.logo);
            }
          }

          if (fileName) {
            trackingUtils.customEvent('completed_subtask', {
              sendUserName: true,
              sendPartnerName: true,
              label: 'Upload your logo'
            });
          }

          if (obj.base !== record.colorBase) {
            trackingUtils.customEvent('completed_subtask', {
              sendUserName: true,
              sendPartnerName: true,
              label: 'Select Color Scheme'
            });
          }

          setCroppedImage(null);
          notify('The information was successfully updated');
          callback();
          queryClient.clear(app, tab, item);
        })
        .catch(() => {
          notify('Failed to save information');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleSave = callback => {
    const isValidHex = isHexColor(state.picker);
    if (!isValidHex) {
      notify('Selected color is invalid. The color will go back to default.');
      handleResetColor();
      return;
    }

    setLoading(true);
    if (croppedImage && croppedImage.imageFile != null) {
      uploadLogo(callback);
    } else {
      savePartnerInformation(null, callback);
    }
  };

  const setNewLogo = val => {
    setState({ type: 'SETDATA', payload: { name: 'newLogo', value: val } });
  };

  const setPreviewLogo = val => {
    setState({ type: 'SETDATA', payload: { name: 'previewLogo', value: val } });
  };

  const setDarkMode = e => {
    setState({ type: 'SETDATA', payload: { name: 'enableDarkMode', value: e.target.checked } });
  };

  if (!isDataLoaded) return <LoadingStyled />;

  return (
    <>
      {/* Logo */}
      <Container.Paper mt={2} radius={1}>
        <MainContainer>
          <PartnerInformationLogo
            logo={state.currentLogo}
            newLogo={state.newLogo}
            setLogo={setNewLogo}
            preview={state.previewLogo}
            setPreview={setPreviewLogo}
            setCroppedImage={setCroppedImage}
          />
          <InfoButtonsWrap>
            <ContainerTitle padding="12px 20px 12px 0">
              <InfoButtons name="branding" />
            </ContainerTitle>
          </InfoButtonsWrap>
        </MainContainer>
      </Container.Paper>

      {/* Colors */}
      <Container.Paper mt={2} radius={1} direction="column">
        <MainContainer>
          <MainBox>
            <ContainerTitle padding="10px 20px 6px 20px">
              <H6 mt={0.1} mb={0.1}>
                Select Color Scheme
              </H6>
              <ButtonOutlined onClick={handleResetColor} disabled={resetLoading}>
                Reset color
              </ButtonOutlined>
            </ContainerTitle>
            <Box px={2.5} pb={2}>
              <Container.Paper b={1} p={2} mb={2} radius={1} justify="space-between" alignItems="center">
                <InputBaseShade
                  inputProps={{
                    maxLength: 7
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      {!state.shade ? `Insert main color your brand. Ex:` : `Main color:`}
                    </InputAdornment>
                  }
                  value={state.inputShade}
                  onChange={e => handleShade(e.target.value)}
                  placeholder="#000000"
                />
                <Container.Paper b={1} radius={5} p={1.5} background={state.shade.slice(1)} />
              </Container.Paper>
              <Container.Paper justify="flex-end" alignItems="center">
                <ColorPickupIcon mr={1} />
                <InputColorPicker
                  type="color"
                  id="colorShade"
                  value={state.picker}
                  onChange={e => handleShade(e.target.value)}
                />
                <LabelBaseShade htmlFor="colorShade" bb={2}>
                  or pick up the color here
                </LabelBaseShade>
              </Container.Paper>
            </Box>
          </MainBox>

          <MainBox>
            {state.picker && (
              <>
                <ContainerTitle padding="25px 20px 20px  20px">
                  <H6 title mt={0.1} mb={0.1}>
                    Preview color shades
                  </H6>
                </ContainerTitle>
                <Container.Grid spacing={0}>
                  {state.pallete.map(row => (
                    <Container.Grid item justify="flex-start" xs={4}>
                      <BoxShade>
                        <Container.Paper b={1} radius={5} p={1.5} mr={1} background={row} />
                        <Typography.p>#{row.toUpperCase()}</Typography.p>
                      </BoxShade>
                    </Container.Grid>
                  ))}
                </Container.Grid>
              </>
            )}
          </MainBox>
        </MainContainer>

        {/* Enable Dark Mode */}
        {showDarkModeSwitch && (
          <Box px={2.5} pb={2.5} pt={1}>
            <Switch
              label="Enable Dark Mode"
              name="enable_darkMode"
              value={state.enableDarkMode}
              checked={state.enableDarkMode}
              onChange={setDarkMode}
            />
          </Box>
        )}
      </Container.Paper>

      {/* Action Buttons */}
      <ActionButtons
        hasCancel
        hasSave={state.picker}
        onSave={handleSave}
        isLoading={loading}
        isDisabled={resetLoading}
      />
    </>
  );
};

export default PartnerCustomProfile;
