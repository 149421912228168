import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, CircleAlertIcon, Typography, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import EnableSystemEmailsDialog from '../Modals/EnableSystemEmailsDialog';
import { useLocation } from 'hooks';
import { dataPost } from 'helpers';

function ClientSystemEmailsAlert({ setAppBarState, appBarState, companyName }) {
  const is_enterprise_partner = useSelector(({ bsn }) => bsn?.user?.profile?.is_enterprise_partner);
  const showAlert = appBarState.disabled_system_email && appBarState.is_client_active && is_enterprise_partner;
  const { item } = useLocation();
  const [modalState, setModalState] = useState({
    submitting: false,
    open: false,
    clientId: item,
    clientName: ''
  });

  const handleCloseModal = () => setModalState(prevModalState => ({...prevModalState, open: false}))

  const handleSystemEmailsEnable = () => {
    setModalState({ submitting: true });
    // TODO: refactor this to use apiClient instead of dataPost
    const app = 'clients';
    const tab = 'actions';
    const data = {
      type: 'system_emails'
    };
    dataPost({ app, tab, item, params: {}, data })
      .then(res => {
        setAppBarState(old => {
          return { ...old, disabled_system_email: false };
        });
        enqueueAlertSnackbar(res.data.message || res.data.description, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        setAppBarState(old => {
          return { ...old, disabled_system_email: true };
        });
        enqueueAlertSnackbar(err?.response?.description?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setModalState({ open: false, submitting: false });
      });
  };

  if (!showAlert) return null;

  return (
    <>
      <Alert variant="outlined" severity="error" icon={<CircleAlertIcon />} sx={{ my: 2, mx: 3 }}>
        This client is active with system emails disabled.{' '}
        <Typography
          variant="link"
          color="error"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setModalState({ ...modalState, open: true, clientName: companyName })}
        >
          Click here
        </Typography>{' '}
        to enable.
      </Alert>
      {modalState.open && (
        <EnableSystemEmailsDialog state={modalState} resetModal={handleCloseModal} setState={setModalState} onSubmit={handleSystemEmailsEnable} />
      )}
    </>
  );
}

export default ClientSystemEmailsAlert;
