// @flow
import React, { type Element, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Switch, Typography, LoadingStyled, Modal } from 'components';
import { useLocation, useId } from 'hooks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { LazyIcon } from 'components/icons';
import OrganizationProfile from './organizationProfile';
import sra from '../../../helpers/apis/sra';
import { DialogTitleStyled, IconButton } from '../../modal/ComponentTypes';
import SraQuestions from './SraQuestions';

const useStyles = makeStyles(theme => ({
  fullwidth: {
    width: '100%',
    '&:hover': {
      background: 'var(--backgroundDefault)',
      cursor: 'pointer'
    }
  }
}));
const ContainerTitle: ComponentType<*> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--borderSize) solid var(--borderDefault);
  margin-bottom: calc(var(--spacing) * 1);
`;
const HeaderTitle: ComponentType<any> = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  h1 {
    font-size: 2.5rem;
    text-align: left;
    letter-spacing: 0px;
    color: var(--colorBase);
    margin: 10px 0 0 0;
  }
`;
const SRA = (): Element<*> => {
  const classes = useStyles();
  const userId = useId();
  const { item } = useLocation();
  const clientId = item || userId;
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSra, setOpenSra] = React.useState(false);
  const [openOrg, setOpenOrg] = React.useState(false);
  const [hsn, setHsn] = useState(false);
  const [update, forceUpdate] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const markComplete = () => {
    setOpen(false);
    setLoading(true);
    sra.setClientSraStatus(clientId, { completed: !state.completed }).then(res => {
      setState({ ...state, completed: !state.completed });
      setLoading(false);
    });
  };
  const handleCloseSra = e => {
    setOpenSra(false);
    forceUpdate(!update);
  };

  const handleCloseOrg = e => {
    setOpenOrg(false);
    forceUpdate(!update);
  };

  useEffect(() => {
    if (!hsn) {
      setLoading(true);
      sra.getClientInfo(clientId).then(res => {
        setHsn(res.data.is_hsn);
        setLoading(false);
      });
    }
    if (!state)
      sra
        .getClientSraStatus(clientId)
        .then(res => {
          console.log(res);
          setState(res.data);
        })
        .catch(err => {
          console.clear();
          console.log(err);
        });
  }, [state]);
  console.log(openSra);
  console.log(openOrg);
  if (!state || loading) return <LoadingStyled />;
  return (
    <>
      <HeaderTitle>
        <h1>Perform Security Risk Assessment</h1>
      </HeaderTitle>
      <Container.Paper p={2} mt={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              <Typography.h4
                className={classes.fullwidth}
                onClick={() => setOpenOrg(true)}
                pt={2}
                pb={2}
                mt={0.1}
                mb={0.1}
              >
                Step 01 - Fill out the Organization Profile
              </Typography.h4>
              <Dialog
                open={openOrg}
                onClose={() => setOpenOrg(false)}
                disableBackdropClick
                disableEscapeKeyDown
                fullScreen
                maxWidth="lg"
              >
                <DialogTitleStyled id="dialog-title">
                  <Typography.h3>Organization profiles</Typography.h3>
                  <IconButton onClick={() => setOpenOrg(false)}>
                    <LazyIcon component="Close" />
                  </IconButton>
                </DialogTitleStyled>
                <OrganizationProfile close={handleCloseOrg} hsn={hsn} id={clientId} />
              </Dialog>
            </ContainerTitle>

            <Container.Grid item md={12}>
              <Typography.p fontSize={14} pt={2} pb={2} mt={0.1} mb={0.1}>
                By clicking on the above link you will be brought to a page that will allow you to describe your
                organization. This information along with below Security Risk Assessment questions, will help us perform
                the Security Risk Assessment.
              </Typography.p>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      <Container.Paper p={2} mt={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              <Typography.h4
                className={classes.fullwidth}
                onClick={() => setOpenSra(true)}
                pt={2}
                pb={2}
                mt={0.1}
                mb={0.1}
              >
                Step 02 - Answer The Security Risk Assessment Questions
              </Typography.h4>
              <Dialog
                open={openSra}
                onClose={() => setOpenSra(false)}
                disableBackdropClick
                disableEscapeKeyDown
                fullScreen
                maxWidth="lg"
              >
                <DialogTitleStyled id="dialog-title">
                  <Typography.h3>Security Risk Assessment Questions</Typography.h3>
                  <IconButton onClick={() => setOpenSra(false)}>
                    <LazyIcon component="Close" />
                  </IconButton>
                </DialogTitleStyled>
                <SraQuestions close={handleCloseSra} id={clientId} />
              </Dialog>
            </ContainerTitle>

            <Container.Grid item md={12}>
              <Typography.p fontSize={14} pt={2} pb={2} mt={0.1} mb={0.1}>
                By clicking on the above link you will be brought to a page that has security questions to be answered.
                The questions will provide an insight into how you are currently protecting Personally Identifiable
                Information (PII) and sensitive data. We will use this information to help perform the Security Risk
                Assessment.
              </Typography.p>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      <Container.Paper p={2} mt={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              <Typography.h4  pt={2} pb={2} mt={0.1} mb={0.1}>
                Step 03 - Upload any existing Policy or Procedure that you are already have in place (Optional)
              </Typography.h4>
            </ContainerTitle>

            <Container.Grid item md={12}>
              <Typography.p fontSize={14} pt={2} pb={2} mt={0.1} mb={0.1}>
                <strong>Optional</strong> The next step after you answer the Risk Assessment Questions is to upload any
                existing policies and procedures (P&Ps) you have in place. This step is not required but will provide
                insight into how your existing policy and procedures address protecting data. To upload existing
                policies, click on the &quot;Policies&quot; tab then select &quot;Other Policies&quot; to add a new
                Other Policy. When complete, return back to this SRA section to complete the final steps.
              </Typography.p>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      <Container.Paper p={2} mt={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              {!state.completed && (
                <Typography.h4 pt={2} pb={2} mt={0.1} mb={0.1}>
                  Step 04 - Mark the Security Risk Assessment Complete{' '}
                  <Typography.errorSpan>**Important**</Typography.errorSpan>
                </Typography.h4>
              )}
              {state.completed && (
                <Typography.h4 pt={2} pb={2} mt={0.1} mb={0.1}>
                  <Typography.successSpan>
                    <CheckCircleIcon /> Step 04 - Mark the Security Risk Assessment Complete **Important**
                  </Typography.successSpan>
                </Typography.h4>
              )}
            </ContainerTitle>
            <Container.Grid item md={12}>
              <Typography.p fontSize={14} md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                <Typography.p fontSize={14} pt={2} mt={0.1}>
                  Marked Complete
                </Typography.p>
                <Typography.p fontSize={12} pb={2} mb={0.1}>
                  You have {!state.completed && <Typography.errorSpan>NOT</Typography.errorSpan>} marked it as
                  completed.
                </Typography.p>
              </Typography.p>
              <Typography.p md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                <Switch
                  value={state.completed}
                  checked={state.completed}
                  disabled={state.completed}
                  name="completed"
                  onChange={e => setOpen(true)}
                />
              </Typography.p>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Mark Risk Assessment Complete?'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Container.Grid container>
                      <Container.Grid item md={12}>
                        <Typography.p md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                          Before you Mark the Risk Assessment Complete, please make sure you have completed the
                          following:
                        </Typography.p>
                      </Container.Grid>
                      <Container.Grid item md={12}>
                        <Typography.p md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                          <ul>
                            <li>Completely filled in the Organization info</li>
                            <li>Answered each of the Risk Assessment Questions</li>
                            <li>Uploaded existing Policies and Procedures</li>
                            <li>
                              I certify that I have provided information to the best of my knowledge and have utilized
                              all appropriate resources necessary to complete the Risk Assessment information. I have
                              received the appropriate level of information and I was made aware of the proper
                              requirements necessary to complete the Risk Assessment.
                            </li>
                          </ul>
                        </Typography.p>
                      </Container.Grid>
                      <Container.Grid item md={12}>
                        <Typography.p md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                          If you have completed each of the above, please Mark the Risk Assessment complete
                        </Typography.p>
                      </Container.Grid>
                      <Container.Grid item md={12}>
                        <Typography.p md={6} pt={2} pb={2} mt={0.1} mb={0.1}>
                          If you still have more to complete, please close this dialog box and complete those sections
                          first.
                        </Typography.p>
                      </Container.Grid>
                    </Container.Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Disagree
                  </Button>
                  <Button onClick={markComplete} color="primary" autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </Container.Grid>
            <Container.Grid item md={12}>
              <Container.Grid p={1} item md={12}>
                <Typography.p fontSize={14} pt={2} pb={2} mt={0.1} mb={0.1}>
                  The last step is to Mark the Security Risk Assessment Complete so that you will know you are done and
                  can perform the Security Risk Assessment and produce the Risk Assessment Reports. We will not be able
                  to complete the Security Risk Assessment until you tell us you are done.
                </Typography.p>
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      {/* <Container.Grid id="clients-poducts-buttons" justify="flex-end">
        <Button
          disabled={validateEmail || validateEmails}
          variant="contained"
          color="primary"
          onClick={saveEmails}
          ml={2}
        >
          Save
        </Button>
      </Container.Grid> */}
    </>
  );
};

export default SRA;
