// @flow
import React, { type Element, type ComponentType } from 'react';
import styled from 'styled-components';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import DashboardBoxTitle from '../dashboard/DashboardBoxTitle';

type String = string & { replaceAll: Function };

type ChartRadarTypes = {
  chartData: Array<{
    fullMark: 10,
    name: String,
    value: number
  }>,
  chartLabel: {
    label: boolean | null,
    title: string,
    fontSize: number,
    icon: boolean,
    iconSize: number
  },
  chartSettings: {
    radarChart: {
      cx: number,
      cy: number,
      outerRadius: number,
      width: number,
      height: number
    },
    polarRadiusAxis: {
      angle: number,
      domain: Array<number>
    },
    radar: {
      stroke: string,
      fill: string,
      fillOpacity: number,
      ...
    }
  }
};

const RadarChartContainer: ComponentType<*> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .recharts-polar-angle-axis-tick-value {
    fill: var(--colorDarkGrey);
  }
`;

const RadarChartContent: ComponentType<*> = styled(RadarChart)`
  @media (min-width: 1436px) {
    && {
      width: 100% !important;
      height: 100% !important;
      svg {
        width: 100% !important;
      }
    }
  }
`;

const ChartRadar = ({
  chartData,
  chartLabel,
  chartSettings: { radarChart, polarRadiusAxis, radar }
}: ChartRadarTypes): Element<*> => {
  const { label, title, fontSize, icon, iconSize } = chartLabel;
  const data = chartData.map(c => ({ ...c, name: c.name.replaceAll('_', ' ') }));
  return (
    <>
      {label && <DashboardBoxTitle label={title} fontSize={fontSize} icon={icon} iconSize={iconSize} />}
      <RadarChartContainer>
        <RadarChartContent {...radarChart} data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis {...polarRadiusAxis} />
          <Radar dataKey="value" {...(radar: $Rest<ChartRadarTypes, any>)} />
        </RadarChartContent>
      </RadarChartContainer>
    </>
  );
};

ChartRadar.defaultPops = {
  label: false
};

export default ChartRadar;
