import { Box, DataGrid, Paper } from '@trustsecurenow/components-library';
import { LoadingStyled } from 'components';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useRecommendations } from '../context/recommendationsContext';
import FindingCell from './FindingCell';
import DateCell from './DateCell';
import PriorityCell from './PriorityCell';

const RecommendationsTable = React.memo(({ setCurrentRecommendation }) => {
  const {
    isHSN,
    refetch,
    rows,
    isLoading,
    rowCount,
    pagination,
    onChangePagination,
    sorting,
    onChangeSorting,
    onChangeFilter,
    isLoadingClientInfo
  } = useRecommendations();
  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns = useMemo(
    () => [
      {
        field: 'finding',
        headerName: 'Findings',
        flex: 1,
        minWidth: 200,
        renderCell: FindingCell,
        type: 'singleSelect',
        valueOptions: [
          {
            label: 'Complete',
            value: 'ISNOTNULL'
          },
          {
            label: 'Incomplete',
            value: 'ISNULL'
          }
        ]
      },
      {
        field: 'section',
        headerName: 'Section',
        width: 157,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Administrative', value: 'Administrative' },
          { label: 'Physical', value: 'Physical' },
          { label: 'Technical', value: 'Technical' }
        ]
      },

      {
        field: 'priority',
        headerName: 'Priority',
        width: 157,
        type: 'singleSelect',
        filterOperators: [],
        filterable: isHSN,
        valueOptions: [
          { label: 'Required', value: 'Required' },
          { label: 'Addressable', value: 'Addressable' }
        ],
        renderCell: props => <PriorityCell value={props.value} />
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 145,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'No due date', value: 'ISNULL' },
          { label: 'Overdue', value: 'OVERDUE' },
          { label: 'Has a date', value: 'ISNOTNULL' }
        ],
        renderCell: props => {
          const isOverdue =
            props.value && !props.row.date_completed && differenceInCalendarDays(new Date(), parseISO(props.value)) > 0;
          return <DateCell date={props.value} color={isOverdue ? 'error' : 'inherit'} />;
        }
      },
      {
        field: 'date_completed',
        headerName: 'Completed Date',
        width: 175,
        type: 'singleSelect',
        valueOptions: [
          { label: 'Complete', value: 'ISNOTNULL' },
          { label: 'Incomplete', value: 'ISNULL' }
        ],
        renderCell: props => <DateCell date={props.value} color="success.main" />
      }
    ],
    [isHSN]
  );

  const handleRowSelectionModelChange = selected => {
    setCurrentRecommendation(rows.find(({ id }) => id === selected[0]));
  };

  if (isLoadingClientInfo) return <LoadingStyled />;

  return (
    <Paper component={Box} height="65vh">
      <DataGrid
        columns={columns}
        rows={rows}
        disableColumnReorder
        disableColumnResize
        hideFooter={!rows.length}
        loading={isLoading}
        rowSelection
        pagination
        rowCount={rowCount}
        paginationModel={pagination}
        sortModel={sorting}
        onSortModelChange={onChangeSorting}
        onPaginationModelChange={onChangePagination}
        disableMultipleRowSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        columnVisibilityModel={{ priority: isHSN }}
        onFilterModelChange={onChangeFilter}
      />
    </Paper>
  );
});

RecommendationsTable.propTypes = {
  setCurrentRecommendation: PropTypes.func.isRequired
};

export default RecommendationsTable;
