import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Button, Box, Grid, Typography, Card, CardContent, CardMedia } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Brandfolder, Asset, Section } from './types';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
  folderId: string;
}

interface FolderProps extends RouteComponentProps<MatchParams> { }

const brandfolderUrl = "https://5gelcr6xf5.execute-api.us-east-1.amazonaws.com/playground/brandfolder";

const fetchAssetsByFolder = async ({ folderId }: { folderId: string }): Promise<Asset[]> => {
  const response = await axios.get(`${brandfolderUrl}/brandfolders/${folderId}/assets`);
  return response.data.data;
};

const fetchAssetsBySection= async ({ sectionId }: { sectionId: string }): Promise<Asset[]> => {
  const response = await axios.get(`${brandfolderUrl}/sections/${sectionId}/assets`);
  return response.data.data;
};


const fetchSections = async ({ folderId }: { folderId: string }): Promise<Section[]> => {
  // TODO evaulate if we need to handle pagination
  const response = await axios.get(`${brandfolderUrl}/brandfolders/${folderId}/sections?per=1000`);
  return response.data.data;
}

const Folder: React.FC<FolderProps> = (props) => {
  // TODO handle errors
  const { folderId } = props.match.params;
  const [selectedSection, setSelectedSection] = useState<string | null>(null);

  const onSelectSection = useCallback((sectionId: string) => {
    if (sectionId === selectedSection) {
      setSelectedSection(null);
    } else {
      setSelectedSection(sectionId);
    }
  }, [selectedSection, setSelectedSection]);

  useEffect(() => {
    fetchSections({ folderId }).then(sections => console.log('rjp', { sections }))
  }, [])

  const {
    data: items,
    isLoading: itemsLoading,
    refetch: refetchItems,
    isError: itemsError,
  } = useQuery<Asset[]>({
    queryKey: ['items', folderId, selectedSection],
    queryFn: () => selectedSection ? fetchAssetsBySection({ sectionId: selectedSection }) : fetchAssetsByFolder({ folderId })
  });

  const {
    data: sections,
    isLoading: sectionsLoading,
    refetch: refetchSections,
    isError: sectionsError,
  } = useQuery<Section[]>({
    queryKey: ['sections', folderId],
    queryFn: () => fetchSections({ folderId })
  });

  // TODO add loading animation
  if (itemsLoading || sectionsLoading) return <p>"Loading..."</p>
  // TODO handle errors
  if (itemsError || sectionsError || !sections || !items) return <p>"Error!"</p>

  return (
    <>
      <SectionSelector sections={sections} onSelect={onSelectSection} activeSection={selectedSection} />
      <AssetGrid assets={items} />
    </>
  )
};

function SectionSelector({ sections, onSelect, activeSection }: { sections: Section[], onSelect: (sectionId: string) => void, activeSection: string | null}) {
  console.log('rjp', { sections, activeSection })
  return (
    <Box my={2}>
      <Grid container direction="row" spacing={2}>
        {sections.map(section => (
          <Grid item key={section.id}>
            <Button variant="contained" color={activeSection === section.id ? 'primary' : 'default'} onClick={() => onSelect(section.id)}>
              {section.attributes.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function AssetGrid({ assets }: { assets: Asset[] }) {
  return (
    <Grid container spacing={3}>
      {assets.map(asset => <AssetCard key={asset.id} asset={asset} />)}
    </Grid>
  );
}

function AssetCard({ asset }: { asset: Asset }) {
  return (
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <Card key={asset.id} style={{ height: '100%' }}>
        <CardMedia
          component="img"
          alt={asset.attributes.name}
          height="140"
          image={asset.attributes.thumbnail_url}
          title={asset.attributes.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {asset.attributes.name}
          </Typography>
          {asset.attributes.description && <Typography variant="body2" color="textSecondary">
            {asset.attributes.description}
          </Typography>}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Folder;
