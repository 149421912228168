import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { Typography } from 'components';
import { Success } from 'components/icons';
import sraApi from 'helpers/apis/sra';
import MarkCompleteDialog from '../TaskDetailsModals/MarkCompleteDialog';
import SchedulingDialog from '../TaskDetailsModals/SchedulingDialog';
import SuccessDialog from '../TaskDetailsModals/SuccessDialog';
import { CardStyled, CardTitle, CardBody, CardImg, CardContent, CardFooter, CardActions } from './style';
import { GreenButton } from '../../style';

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid var(--borderDefault)',
    padding: '12px 0',
    color: 'var(--colorDefault)'
  },
  listLabel: {
    width: '45%',
    paddingRight: 20,
    fontSize: 14,
    fontWeight: 500
  },
  listValue: {
    width: 'calc(55% - 35px)',
    fontSize: 14,
    wordBreak: 'break-word'
  }
}));

const initialState = {
  consultationOption: '1', // schedule call
  dateScheduled: false,
  emailCurrentUser: true,
  additionalEmail: ''
};

const MarkComplete = ({ task, clientId, revisionId, getTaskList }) => {
  const classes = useStyles();
  const notify = useNotify();
  const {
    consulting_option_available,
    complete,
    editing_enabled,
    info: { marked_complete_date, consultation_option, additional_email, submitted_by, description }
  } = task || { info: {} };
  const [openMarkCompleteDialog, setOpenMarkCompleteDialog] = useState(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMarkComplete, setLoadingMarkComplete] = useState(false);
  const [state, setState] = useState(initialState);

  const onStateChange = newState => {
    setState(prevState => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const onOpenMarkCompleteDialog = () => {
    setOpenMarkCompleteDialog(true);
    onCloseScheduleDialog();
  };

  const onCloseMarkCompleteDialog = () => {
    setOpenMarkCompleteDialog(false);
  };

  const onOpenScheduleDialog = () => {
    setOpenScheduleDialog(true);
    onCloseMarkCompleteDialog();
  };

  const onCloseScheduleDialog = () => {
    setOpenScheduleDialog(false);
  };

  const onCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    getTaskList();
  };

  const onMarkCompleteRequest = isScheduled => {
    if (editing_enabled) setLoadingMarkComplete(true);
    else setLoading(true);

    if (isScheduled) onStateChange({ dateScheduled: true });

    const data = {
      // eslint-disable-next-line no-nested-ternary
      consultation_option: editing_enabled ? null : isScheduled ? 1 : 2,
      additional_email: editing_enabled ? null : state.additionalEmail,
      email_to_the_user: editing_enabled ? null : state.emailCurrentUser
    };

    sraApi
      .completeSra(clientId, revisionId, data)
      .then(res => {
        onCloseMarkCompleteDialog();
        onCloseScheduleDialog();
        setOpenSuccessDialog(true);
      })
      .catch(err => {
        const field_error = Object.entries(err?.response?.data?.error || {}).map(([key, value]) => value);
        notify(err?.response?.data?.message || field_error[0] || 'Something went wrong', 'warning');
      })
      .finally(() => {
        if (editing_enabled) setLoadingMarkComplete(false);
        else setLoading(false);
      });
  };

  const onComplete = () => {
    // schedule call
    if (state.consultationOption === '1' && consulting_option_available) {
      onOpenScheduleDialog();
    } else {
      onMarkCompleteRequest();
    }
  };

  const handleMarkComplete = () => {
    if (editing_enabled) {
      onMarkCompleteRequest();
    } else {
      onOpenMarkCompleteDialog();
    }
  };

  useEffect(() => {
    if (!openMarkCompleteDialog && !openScheduleDialog)
      setState(prevState => ({ ...initialState, dateScheduled: prevState.dateScheduled }));
  }, [openMarkCompleteDialog, openScheduleDialog]);

  return (
    <CardStyled>
      <CardTitle>Mark Your Risk Assessment Complete</CardTitle>

      <CardBody>
        <CardImg>
          <img src="/media/sra/mark_complete.svg" alt="task" />
        </CardImg>

        {complete ? (
          <CardContent>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Success size={1.7} mr={1.2} color="success" />
                <span className={classes.listLabel}>Marked Complete Date</span>
                <span className={classes.listValue}>{format(new Date(marked_complete_date), 'MM/dd/yyyy')}</span>
              </li>
              <li className={classes.listItem}>
                <Success size={1.7} mr={1.2} color="success" />
                <span className={classes.listLabel}>Submitted by</span>
                <span className={classes.listValue}>{submitted_by}</span>
              </li>
              <li className={classes.listItem}>
                <Success size={1.7} mr={1.2} color="success" />
                <span className={classes.listLabel}>Additional Email(s) to receive report</span>
                <span className={classes.listValue}>{additional_email}</span>
              </li>
              {consultation_option && (
                <li className={classes.listItem}>
                  <Success size={1.7} mr={1.2} color="success" />
                  <span className={classes.listLabel}>Option</span>
                  <span className={classes.listValue}>{consultation_option}</span>
                </li>
              )}
            </ul>
          </CardContent>
        ) : (
          <CardContent>
            <Typography.p fontSize={14} mt="0px" mb="0px">
              {description}
            </Typography.p>

            <CardFooter>
              <CardActions>
                <GreenButton
                  variant="outlined"
                  disableElevation
                  disabled={loadingMarkComplete}
                  startIcon={loadingMarkComplete ? <CircularProgress size={16} /> : <CheckIcon mr={1.2} size={1.3} />}
                  onClick={handleMarkComplete}
                >
                  Mark Complete
                </GreenButton>

                <MarkCompleteDialog
                  open={openMarkCompleteDialog}
                  close={onCloseMarkCompleteDialog}
                  onComplete={onComplete}
                  state={state}
                  setState={onStateChange}
                  loading={loading}
                  consulting_option_available={consulting_option_available}
                />

                <SchedulingDialog
                  open={openScheduleDialog}
                  close={onCloseScheduleDialog}
                  openMarkComplete={onOpenMarkCompleteDialog}
                  onMarkCompleteRequest={onMarkCompleteRequest}
                  setState={onStateChange}
                  loading={loading}
                />

                <SuccessDialog
                  open={openSuccessDialog}
                  close={onCloseSuccessDialog}
                  dateScheduled={state.dateScheduled}
                  editingEnabled={editing_enabled}
                />
              </CardActions>
            </CardFooter>
          </CardContent>
        )}
      </CardBody>
    </CardStyled>
  );
};

MarkComplete.propTypes = {
  task: PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, null]),
    name: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    percent_complete: PropTypes.string.isRequired
  }).isRequired,
  clientId: PropTypes.string.isRequired,
  revisionId: PropTypes.string.isRequired,
  getTaskList: PropTypes.func.isRequired
};

MarkComplete.defaultProps = {};

export default MarkComplete;
