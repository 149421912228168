// @flow
import React from 'react';
import { useNotify } from 'react-admin'; 
import styled from 'styled-components';
import { Star, StarFilled } from 'components/icons';
import { setStorage, trackingUtils } from 'helpers';
import menu from 'helpers/apis/menu';

const FavoriteStar = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ right }) => `${right}px`};
  margin-top: -9px;
  line-height: 1;

  svg {
    cursor: pointer;
    transition: 0.3s;
    width: 18px;
    height: 18px;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const MenuFavorite = ({ name, favoriteApp, setFavoriteApp, right }) => {
  const notify = useNotify();

  const changeFavoriteApp = () => {
    if (name !== favoriteApp) {
      trackingUtils.clickButton(`Favorite - ${name}`);
      setFavoriteApp(name);
      menu
        .setFavoriteApplication(name)
        .then(res => {
          notify('You have successfully updated your favorite application');
          setStorage('favorite', name, true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <FavoriteStar onClick={changeFavoriteApp} right={right}>
      {favoriteApp === name ? (
        <StarFilled size={1.25} color="colorCommonWhite" />
      ) : (
        <Star size={1.25} color="colorCommonWhite" />
      )}
    </FavoriteStar>
  );
};

export default MenuFavorite;
