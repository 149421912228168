import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { IconButton, Popover, useMediaQuery, useTheme } from '@material-ui/core';
import { LazyIcon } from '../../icons';
import { Container, LoadingStyled, Typography } from '../../types';
import ChartHalfCircle from '../../charts/ChartHalfCircle';
import ChartCircle from '../../charts/ChartCircle';
import ChartBar from '../../charts/ChartBar';
import menu from '../../../helpers/apis/menu';

const PopoverContentContainer = styled(Container.Grid)`
  padding: 24px
`

const PopoverContentGrid = styled(Container.Grid)`
  flex-direction: column;
  &:not(:nth-of-type(3n)) {
    padding-right: 15px;
  }

  .chart-circle {
    width: 100px;
    margin: 0 auto;
  }
`
const PopoverTitleWrapper = styled(Container.Grid)``
const EssStatsTitle = styled(Typography.h3)``
const EssChartTitle = styled(Typography.h3)`
  font-size: 14px;

  &.center{
    text-align: center;
  }

  &.chart-bar-title {
    margin-left: 24px;
  }
`

const ChartBarStyled = styled(ChartBar)`
  text {
    font-size: 30px;

    &:last-of-type {
      transform: translate(-30px, 20px);
    }
  }
`

const MiniHalfChartPopover = ({anchorEl, onClose, id, open}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [chartData, setChartData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      if (open) {
        const chartData = await menu.getEssStats();
        setChartData(chartData.data)
        setLoading(false)
      } else {
        setTimeout(() => {
          setLoading(true)
        }, 200)
      }
    })()
  }, [open])

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: mobileView ? 'center' : 'left'
      }}
      PaperProps={{
        style: {
          maxWidth: '679px',
          width: mobileView ? null : '100%',
          right: "16px"
        }
      }}
    >
      <PopoverContentContainer xs={12}>

        <PopoverTitleWrapper xs={12} justifyContent={"space-between"}>
          <EssStatsTitle>
            Your ESS Stats
          </EssStatsTitle>
          <IconButton onClick={onClose}>
            <LazyIcon component="Close" color="colorDefault" />
          </IconButton>
        </PopoverTitleWrapper>

        {
          loading ? (
            <Container.Grid xs={12} alignItems={"center"} justifyContent={"center"}>
              <LoadingStyled/>
            </Container.Grid>
          ) : (
            <>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"center"}>
                  Employee Secure Score (ESS)
                </EssChartTitle>
                <ChartHalfCircle
                  chart={{
                    chartData: [
                      {
                        name: chartData?.ess?.display_value,
                        // eslint-disable-next-line no-nested-ternary
                        value: (chartData?.ess?.value * 100) / 800
                      }
                    ]
                  }}
                  showOf={false}
                  showLegends={false}
                  showTitle={false}
                  labels
                  className="chart-circle"
                />
              </PopoverContentGrid>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"chart-bar-title"}>
                  Phishing Attack Fail Rate
                </EssChartTitle>
                <ChartBarStyled
                  height={"130"}
                  labels
                  invert
                  chart={[
                    {
                      name: chartData?.phishing_attack_fail_rate?.display_value,
                      // eslint-disable-next-line no-nested-ternary
                      value: +chartData?.phishing_attack_fail_rate?.value
                    },
                    {
                      name: "100%",
                      // eslint-disable-next-line no-nested-ternary
                      value: 100
                    }
                  ]}
                />
              </PopoverContentGrid>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"chart-bar-title"}>
                  Average Security Training Score
                </EssChartTitle>
                <ChartBarStyled
                  height={"130"}
                  labels
                  colorType1
                  chart={[
                    {
                      name: chartData?.security_training?.display_value,
                      // eslint-disable-next-line no-nested-ternary
                      value: +chartData?.security_training?.value
                    },
                    {
                      name: "100%",
                      // eslint-disable-next-line no-nested-ternary
                      value: 100
                    }
                  ]}
                />
              </PopoverContentGrid>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"center"}>
                  External Data Breaches
                </EssChartTitle>
                <ChartCircle
                  className="chart-circle"
                  labels
                  chart={{
                    chartData: [
                      {
                        name: chartData?.external_data_breaches?.display_value,
                        // eslint-disable-next-line no-nested-ternary
                        value: +chartData?.external_data_breaches?.value
                      }
                    ]
                  }}
                />
              </PopoverContentGrid>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"chart-bar-title"}>
                Average Micro Quiz Score
                </EssChartTitle>
                <ChartBarStyled
                  height={"130"}
                  labels
                  colorType1
                  chart={[
                    {
                      name: chartData?.avg_micro_quiz?.display_value,
                      // eslint-disable-next-line no-nested-ternary
                      value: chartData?.avg_micro_quiz?.value,
                    },
                    {
                      name: "100%",
                      // eslint-disable-next-line no-nested-ternary
                      value: 100
                    }
                  ]}
                />
              </PopoverContentGrid>
              <PopoverContentGrid md={4} sm={6} xs={12}>
                <EssChartTitle className={"chart-bar-title"}>
                  Micro Quiz Taken
                </EssChartTitle>
                <ChartBarStyled
                  height={"130"}
                  labels
                  colorType1
                  chart={[
                    {
                      name: chartData?.micro_quiz_taken?.taken?.display_value,
                      // eslint-disable-next-line no-nested-ternary
                      value: chartData?.micro_quiz_taken?.taken?.value
                    },
                    {
                      name: chartData?.micro_quiz_taken?.total?.display_value,
                      // eslint-disable-next-line no-nested-ternary
                      value: +chartData?.micro_quiz_taken?.total?.value
                    }
                  ]}
                />
              </PopoverContentGrid>
            </>
          )
        }



      </PopoverContentContainer>

    </Popover>
  )
}

export default MiniHalfChartPopover
