import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${apiGWID}.${base}/${env}`;

const menu = {};

menu.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

menu.setFavoriteApplication = (appName) => {
  const requestOptions = {
    url: `${baseURL}/user/favoriteapp`,
    headers: menu.getRequestHeaders(),
    data: { favourite_application: appName },
  };
  return apiClient.put(requestOptions);
};

menu.getEssStats = () => {
  const userId = getStorage("userId");
  const requestOptions = {
    url: `${baseURL}/user/essStats/${userId}`,
    headers: menu.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
}


export default menu;
