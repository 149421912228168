import React, { memo, useContext, useState } from 'react';
import { hasKey, dataProvider } from 'helpers';
import { useBreakpoint } from 'hooks';
import dashboard from 'helpers/apis/Dashboard/dashboard';
import { TableCellContent, CollapseIcon, TableRowContent } from '../ComponentTypes';
import TypesCell from '../TypesCell';
import { useTableList } from '../TableListContext';
import { TableListModalContext } from '../TableListModal';

const TableListCell = ({
  isOpen,
  openNewModal,
  row,
  index,
  hideColumns,
  isCollapsed,
  handleCollapseClick,
  isSelected
}) => {
  const {
    app,
    tab,
    settings,
    paramKeyForModal,
    keyCheckToHideIcon,
    password,
    customCells,
    dispatch: { setOpen }
  } = useTableList();

  const mobileView = useBreakpoint('sm');
  const tableListModalContext = useContext(TableListModalContext);

  const collapsible = settings?.row?.action === 'collapse';

  const tableCellContentProps = {};

  const nonHiddenCells = settings.cells.filter(cell => !hideColumns.includes(cell.id));
  const record = nonHiddenCells
    .filter(c => !(hasKey(c, 'conditional') && c.conditional))
    .map(({ id, editing, editingValue, align }) => [id, editing, editingValue, align])
    .reduce((result, key) => ({ ...result, [key[0]]: [row[key[0]], key[1], [key[2]], key[3]] }), {});
  const data = [...Object.values(record)];
  const data_keys = [...Object.keys(record)];

  if (hasKey(settings, 'row')) {
    switch (settings.row.click && settings.row.action) {
      case 'modal':
      case 'newModal': {
        // if (!isOpen)
        tableCellContentProps.onClick = () => {
          if (tableListModalContext) {
            tableListModalContext.setRefresh(true);
            tableListModalContext.setRowData({
              rowId: row.id,
              row,
              rowIndex: index,
              modal: settings?.row?.modal
            });
            tableListModalContext.setOpen(true);
          } else {
            settings.row.action === 'newModal' ? openNewModal(row.id) : setOpen(row.id);
          }
        };
        break;
      }

      case 'newWindow': {
        tableCellContentProps.onClick = async () => {
          const windowReference = window.open();
          dashboard.getNewsletter({ app: 'myDashboard', id: row.id }).then(response => {
            windowReference.location = response.data;
          });
        };
        break;
      }
      case 'collapse': {
        tableCellContentProps.onClick = () => handleCollapseClick(index);
        break;
      }
      default: {
        break;
      }
    }
  }

  const isFixedCell = mobileView && app === 'clients' && tab === 'usersList';

  return data.map((cell, i) => {
    return (
      <TableCellContent
        style={
          i === 0 && isFixedCell ? { position: 'sticky', left: 0, background: 'var(--backgroundPaper)', zIndex: 2 } : {}
        }
        component="td"
        scope="row"
        align={Array.isArray(cell) ? cell[3] : 'left'}
        {...tableCellContentProps}
        isCollapsed={isCollapsed}
        collapsible={collapsible}
      >
        {i === 0 && collapsible && <CollapseIcon isCollapsed={isCollapsed} />}
        <TypesCell
          paramIdForModal={paramKeyForModal ? row[paramKeyForModal] : ''}
          hideIcon={keyCheckToHideIcon ? row[keyCheckToHideIcon] : false}
          cell={cell}
          config={nonHiddenCells[i]}
          statePass={password}
          rowId={row.id}
          row={row}
          isSelected={isSelected}
          customCell={customCells?.[data_keys?.[i]]}
        />
      </TableCellContent>
    );
  });
};

export default memo(TableListCell);
