/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { AddIcon, Button, Menu, MenuItem, Tooltip, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { addPluralS } from 'helpers';
import { BULK_ACTIONS, MODALS, PRODUCT_TYPES } from './constants';
import presetsAPI from 'helpers/apis/clientsPresets';
import { useSelector } from 'react-redux';

const bulkActionsSettings = {
  [BULK_ACTIONS.ENABLE_CLIENT]: {
    modalName: MODALS.ACTIVATE_CLIENT,
    actionType: 'openModal',
    usePlural: true,
    checkDisabled: flags => flags.hasActiveClient || flags.hasDeletedClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on clients that are already active. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.DISABLE_CLIENT]: {
    modalName: MODALS.BULK_DISABLE_CLIENT,
    actionType: 'openModal',
    usePlural: true,
    checkDisabled: flags =>
      flags.hasInactiveClient ||
      flags.hasInternalClient ||
      flags.hasHIPAACompClient || flags.hasHIPAABPP || flags.hasEVA_MD,
      getDisabledMessage: () => 'This action is disabled as it cannot be used on inactive clients or internal clients.'
  },
  [BULK_ACTIONS.DELETE_CLIENT]: {
    modalName: MODALS.DELETE_CLIENT,
    actionType: 'openModal',
    usePlural: true,
    checkDisabled: flags => flags.hasActiveClient || flags.hasDeletedClient || flags.hasHIPAACompClient || flags.hasHIPAABPP || flags.hasEVA_MD,
    getDisabledMessage: () => 'This action is disabled as it cannot be used on active client accounts. Unselect these clients and select clients to use this action.',
  },
  [BULK_ACTIONS.DOWNLOAD_CLIENT_REPORT]: {
    actionType: 'directAction',
    usePlural: true,
    checkDisabled: flags => flags.hasInactiveClient || flags.hasHIPAACompClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients or clients with a HIPAA Compliance account. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT]: {
    actionType: 'directAction',
    usePlural: true,
    checkDisabled: flags => flags.hasInactiveClient || flags.hasHIPAACompClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients or clients with a HIPAA Compliance account. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.ENABLE_WELCOME_MESSAGE]: {
    modalName: MODALS.BULK_ENABLE_WELCOME_MESSAGE,
    actionType: 'openModal',
    checkDisabled: flags => flags.hasInactiveClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.SEND_WELCOME_MESSAGE]: {
    modalName: MODALS.SEND_WELCOME_MESSAGE,
    actionType: 'openModal',
    checkDisabled: flags => flags.hasInactiveClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.ENABLE_MT_NL]: {
    modalName: MODALS.BULK_ENABLE_MICRO_TRAINING,
    actionType: 'openModal',
    checkDisabled: flags => flags.hasInactiveClient || flags.hasHIPAACompClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients or clients with a HIPAA Compliance account. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.ENABLE_POSITIVE_OPTIN]: {
    modalName: MODALS.POSITIVE_OPTIN,
    actionType: 'openModal',
    checkDisabled: flags => flags.hasInactiveClient || flags.hasHIPAACompClient,
    getDisabledMessage: () =>
      'This action is disabled as it cannot be used on inactive clients or clients with a HIPAA Compliance account. Unselect these clients to use this action.'
  },
  [BULK_ACTIONS.BOUNCED_EMAILS]: {
    modalName: MODALS.BOUNCED_EMAILS,
    actionType: 'openModal',
    checkDisabled: flags => flags.hasInactiveClient || flags.isClearedBouncedEmailsClientInclueded,
    getDisabledMessage: flags =>
      flags.isClearedBouncedEmailsClientInclueded
        ? 'This action is disabled as it cannot be used on clients with 0% Bounced Emails. Unselect these clients to use this action.'
        : 'This action is disabled as it cannot be used on inactive clients. Unselect these clients to use this action.'
  }
};

const getTotalUserWithNoBouncedEmailsCount = (selectedRows) => {
  const totalUsers = selectedRows
    .map(row => (row.users_email_bounced / 100) * row.users_count)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return totalUsers;
};

const BulkActionsMenu = ({ visibleBulkActions, selectedRowsObj, setModalState, partnerId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userEmail = useSelector(state => state?.bsn?.user?.profile?.email);

  const { disableFlags, selectedClientIds, selectedRows } = React.useMemo(() => {
    const selectedRows = Object.values(selectedRowsObj);
    const noSelectedRows = Boolean(!selectedRows.length);
    const hasActiveClient = selectedRows.some(row => row.active);
    const hasInactiveClient = selectedRows.some(row => !row.active);
    const hasInternalClient = selectedRows.some(row => row.is_internal);
    const hasHIPAACompClient = selectedRows.some(row => row.product_type === PRODUCT_TYPES.HIPAA_COMPLIANCE);
    const hasHIPAABPP = selectedRows.some(row => row.product_type === PRODUCT_TYPES.HIPAA_BPP);
    const hasEVA_MD = selectedRows.some(row => row.product_type === PRODUCT_TYPES.EVA_MD);
    const hasDeletedClient = selectedRows.some(row => row.deleted);
    const isClearedBouncedEmailsClientInclueded = selectedRows.some(row => row.users_email_bounced === 0);
    const disableFlags = {
      noSelectedRows,
      hasActiveClient,
      hasInactiveClient,
      hasInternalClient,
      hasHIPAACompClient,
      hasHIPAABPP,
      hasEVA_MD,
      hasDeletedClient,
      isClearedBouncedEmailsClientInclueded
    };
    const selectedClientIds = Object.keys(selectedRowsObj);
    return { disableFlags, selectedClientIds, selectedRows };
  }, [selectedRowsObj]);

  const handleDownloadReports = useCallback(
    settings => {
      presetsAPI
        .handleClientBulkAction(partnerId, {
          data: { action_type: settings.reportKey, client_ids: selectedClientIds }
        })
        .then(res => {
          const successMsg = `${selectedClientIds.length} ${addPluralS(
            settings.reportName,
            selectedClientIds.length
          )} will be emailed to ${userEmail}`;
          enqueueAlertSnackbar(successMsg || res.statusText || res.data.message || 'Success', {
            props: { severity: 'success' }
          });
        })
        .catch(err => {
          enqueueAlertSnackbar(err?.response?.data?.message || err?.data?.description || err.message || 'Failed', {
            props: { severity: 'error' }
          });
        });
    },
    [selectedClientIds, userEmail]
  );

  const handleActionClick = (props, actionName) => {
    if (props.actionType === 'openModal') {
      setModalState(props.modalName, {
        open: true,
        selectedClientIds,
        totalUsersCount:
          props.modalName == MODALS.BOUNCED_EMAILS && getTotalUserWithNoBouncedEmailsCount(selectedRows)
      });
      return;
    } else {
      // direct action
      const settings = {
        [BULK_ACTIONS.DOWNLOAD_CLIENT_REPORT]: {
          reportKey: 'client_report',
          reportName: 'Client Report'
        },
        [BULK_ACTIONS.DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT]: {
          reportKey: 'ess_report',
          reportName: 'Employee Secure Score Report'
        }
      }[actionName];
      handleDownloadReports(settings);
    }
  };

  return (
    <>
      <Button
        disabled={disableFlags.noSelectedRows}
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={<AddIcon />}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {visibleBulkActions.map(actionName => {
          const action = bulkActionsSettings[actionName];
          const isDisabled = action.checkDisabled(disableFlags);
          return (
            <Tooltip title={isDisabled && action.getDisabledMessage(disableFlags)} placement="top" key={actionName}>
              <span>
                <MenuItem
                  disabled={isDisabled}
                  sx={{ width: '100%', px: 2.5 }}
                  onClick={() => {
                    handleActionClick(action, actionName);
                    setAnchorEl(null);
                  }}
                >
                  {action.usePlural ? addPluralS(actionName, selectedRows.length) : actionName}
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default React.memo(BulkActionsMenu);
