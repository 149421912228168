import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${apiGWID}.${base}/${env}`;

const referralCodeAPI = {};

referralCodeAPI.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

referralCodeAPI.getPromotionClaimVisibility = () => {
  const requestOptions = {
    url: `${baseURL}/PromotionClaims`,
    headers: referralCodeAPI.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
};

referralCodeAPI.sendPromotionClaim = (data) => {
  const requestOptions = {
    url: `${baseURL}/PromotionClaims`,
    headers: referralCodeAPI.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

referralCodeAPI.getPromotion = () => {
  const requestOptions = {
    url: `${baseURL}/Promotion`,
    headers: referralCodeAPI.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
};

export default referralCodeAPI;
