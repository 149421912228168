import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import contentAdmin from 'helpers/apis/contentAdmin';
import { LoadingStyled } from 'components';
import { useNotify } from 'ra-core';
import ForwardEmailDialog from './ForwardEmailDialog';

const Container = styled.div`
  min-width: 90%;
  min-height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
`;

const Body = styled.iframe`
  width: 95%;
  min-height: 650px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 1rem;
`;

const EmailViewer = ({ email }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [emailContents, setEmailContents] = useState(null);

  const getEmailContents = async ({ id }) => {
    try {
      setLoading(true);
      const data = await contentAdmin.getEmailContents({ id });
      setEmailContents(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(error.message, 'error');
    }
  };

  useEffect(() => {
    if (email?.id) getEmailContents({ id: email.id });
  }, [email]);

  if (email === null) {
    return (
      <Container>
        <div
          style={{
            width: '100%',
            textAlign: 'center'
          }}
        >
          <p>Please choose an email to view!</p>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h2 style={{ margin: '0px 0px 3px 0px', padding: 0 }}>{email.subject}</h2>
          <ForwardEmailDialog email={email} loadingEmail={loading} />
        </div>
        <p style={{ margin: 0, padding: 0 }}>{email.from_name}</p>
        {loading ||
          (emailContents && (
            <p style={{ margin: 0, padding: 0 }}>To: {emailContents.to_emails.map(obj => obj.email).join(', ')}</p>
          ))}
        <p style={{ margin: 0, padding: 0 }}>Delivery Date: {email.delivery_date}</p>
      </Header>
      {loading || emailContents === null ? (
        <LoadingStyled text="One moment, the email is loading" />
      ) : (
        <Body
          id="EmailViewerBody"
          title="Email Viewer Frame"
          /**
           * adding <base target='_blank'>
           * attribute to the head tag of the page html string
           * so all anchor tags opens in a new tab
           */
          srcDoc={emailContents.html_content.split('<head>').join("<head>  <base target='_blank'>")}
          sandbox="allow-popups allow-popups-to-escape-sandbox"
        />
      )}
    </Container>
  );
};

EmailViewer.propTypes = {
  email: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default EmailViewer;
