import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import { Container, TextField, Typography, RadioField, ButtonCancel } from 'components';
import {
  ExpandMore as ExpandMoreIcon,
  ErrorOutline as ErrorOutlineIcon,
  ArrowForwardIosSharp
} from '@material-ui/icons';
import { Save2 } from 'components/icons';
import { SystemAccordion, GreenButton, BlueButton } from '../../style';

const OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const LOCATION_CHOICES = [
  { value: 'Onsite', label: 'Onsite' },
  { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
  { value: 'Vendor Hosted', label: 'Vendor Hosted' },
  { value: 'Other', label: 'Other' }
];

const SYSTEMS = [
  {
    name: 'system_1',
    panel: 'panel1',
    number: 1
  },
  {
    name: 'system_2',
    panel: 'panel2',
    number: 2
  },
  {
    name: 'system_3',
    panel: 'panel3',
    number: 3
  },
  {
    name: 'system_4',
    panel: 'panel4',
    number: 4
  },
  {
    name: 'system_5',
    panel: 'panel5',
    number: 5
  }
];

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0
  }
}));

const System = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  };

  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      {/* Information about data */}
      <SystemAccordion
        expanded={expanded === 'panelNetworkSystem'}
        onChange={handleChangeAccordion('panelNetworkSystem')}
      >
        {/* Title */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelNetworkSystembh-content"
          id="panelNetworkSystembh-header"
        >
          <Typography.p className={classes.heading}>
            <ErrorOutlineIcon color="secondary" /> Information about data
          </Typography.p>
        </AccordionSummary>

        {/* Body */}
        <AccordionDetails>
          <Container.Grid container spacing={4}>
            <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Paper fullWidth radius={2} pb={2}>
                <Container.Grid container>
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography.p className="system-accordion-text">
                      Please list any systems that contain {isHSN ? 'PHI' : 'PII'} or Sensitive Data. Keep in mind that{' '}
                      {isHSN ? 'PHI' : 'PII'} might be clients, customers, vendors, employees, etc. Systems may include:
                      Billing Systems, Customer Relationship Management, Order Management Systems, Website(s), Network
                      File Shares, Collaborative Systems (Microsoft SharePoint, Google Docs, etc.), Multi-function
                      copy/print/scanners that contain hard drives, Database Systems, Employee / Human Resource
                      Management Systems, Vendor Management Systems, Accounting and Financial Systems, etc.
                    </Typography.p>
                  </Container.Grid>
                </Container.Grid>
              </Container.Paper>
            </Container.Grid>
          </Container.Grid>
        </AccordionDetails>
      </SystemAccordion>

      {/* Systems */}
      {SYSTEMS.map(item => {
        const { name, panel, number } = item;
        return (
          <SystemAccordion key={name} expanded={expanded === panel} onChange={handleChangeAccordion(panel)}>
            {/* Title */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${name}bh-content`}
              id={`${name}bh-header`}
            >
              <Typography.p className={classes.heading}>
                System {number} {number === 1 && '(Main)'}
              </Typography.p>
            </AccordionSummary>

            {/* Body */}
            <AccordionDetails>
              <Container.Grid container spacing={4}>
                <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Container.Paper fullWidth radius={2} pb={2}>
                    <Container.Grid container>
                      {/* System Name */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          value={state[`${name}_name`]}
                          name={`${name}_name`}
                          label="System Name"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>

                      {/* Operating System */}
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              Operating System
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>

                      {/* Os */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioField
                          label=""
                          name={`${name}_os`}
                          choices={OS_CHOICES}
                          value={state[`${name}_os`]}
                          mt={2}
                          mb={1}
                          row
                          labelPlacement="End"
                          onChange={handleChangeState}
                        />
                      </Container.Grid>

                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              {isHSN
                                ? 'System Vendor and # of ePHI Records (estimate):'
                                : 'System Vendor and # of PII or sensitive data'}
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>

                      {/* System Vendor */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state[`${name}_vendor`]}
                          name={`${name}_vendor`}
                          label="System Vendor"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Container.Grid>

                      {/* ePHI or PII */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={isHSN ? state[`${name}_ephi`] : state[`${name}_pii`]}
                          name={isHSN ? `${name}_ephi` : `${name}_pii`}
                          label={isHSN ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                          fullWidth
                          onChange={handleChangeState}
                          integerOnly
                          inputProps={{ min: 0, step: 1, maxLength: 7 }}
                        />
                      </Container.Grid>

                      {/* System Location */}
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              System Location
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>

                      {/* Location */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioField
                          label=""
                          name={`${name}_location`}
                          choices={LOCATION_CHOICES}
                          value={state[`${name}_location`]}
                          mt={2}
                          mb={1}
                          row
                          labelPlacement="End"
                          onChange={handleChangeState}
                        />
                      </Container.Grid>

                      {/* System Details */}
                      <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container.Grid container spacing={4} justify="space-between">
                          <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography.h3 p={2} m={0.1} fontSize={16}>
                              System Details
                            </Typography.h3>
                          </Container.Grid>
                        </Container.Grid>
                      </Container.Grid>

                      {/* Details */}
                      <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          value={state[`${name}_details`]}
                          name={`${name}_details`}
                          placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChangeState}
                        />
                      </Container.Grid>
                    </Container.Grid>
                  </Container.Paper>
                </Container.Grid>
              </Container.Grid>
            </AccordionDetails>
          </SystemAccordion>
        );
      })}

      {/* Action buttons */}
      <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container.Grid container spacing={4} justify="flex-end">
          <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
            <ButtonCancel variant="text" onClick={e => handleClose()}>
              Exit
            </ButtonCancel>
            <BlueButton startIcon={<Save2 />} variant="outlined" disableElevation onClick={save}>
              Save
            </BlueButton>
            <GreenButton
              startIcon={<ArrowForwardIosSharp />}
              variant="outlined"
              disableElevation
              ml="30px"
              onClick={() => save('next')}
            >
              Save and Next
            </GreenButton>
          </Container.Grid>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

System.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default System;
