import { useReducer } from 'react';
import { useQuery } from 'react-query';
import sraApi from 'helpers/apis/sra';

const initialState = {
  pagination: {
    page: 0,
    perPage: 25
  }
};

const useSraReports = clientId => {
  const [state, setState] = useReducer(reducer, initialState);

  const { isSuccess, isLoading, data: record, refetch, ...query } = useQuery(
    ['srareports', { ...state, clientId }],
    async () => {
      const { data } = await sraApi.getSraReports(state, clientId);
      const { data: result, count } = data;
      return { data: result, total: count || result.length };
    },
    {
      staleTime: 10,
      cacheTime: false,
      notifyOnChangeProps: ['data', 'error'],
      refetchOnWindowFocus: false
    }
  );

  const dispatch = {};

  dispatch.setPage = page => {
    setState({ type: 'SET_PAGE', payload: page });
  };

  dispatch.setPerPage = perPage => {
    setState({ type: 'SET_PERPAGE', payload: perPage });
  };
  dispatch.onLoad = params => {
    setState({ type: 'SET_INITIAL_PARAMS', payload: params });
  };

  dispatch.setNewParam = (name, value) => {
    setState({ type: 'SET_NEW_PARAM', payload: { name, value } });
  };

  const newData = record?.data;

  return {
    ...query,
    data: isSuccess ? newData : null,
    total: isSuccess ? record.total : 0,
    isSuccess,
    isLoading,
    refetch,
    page: state.pagination.page || 0,
    perPage: state.pagination.perPage || 25,
    dispatch,
    state
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_PARAMS':
      return {
        ...state,
        pagination: {
          page: action.payload.page,
          perPage: action.payload.perPage
        }
      };
    case 'SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      };
    case 'SET_PERPAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
          page: 0
        }
      };
    case 'SET_NEW_PARAM':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 0
        },
        [action.payload.name]: action.payload.value
      };
    default:
      return { ...state };
  }
}

export default useSraReports;
