// @flow
import React, { useRef } from 'react';
import { RichTextEditor } from 'components';
import newsfeed from 'helpers/apis/newsfeed';
import useBreakpoint from 'hooks/useBreakpoint';

let timer = 0;

async function getHashtagList(searchTerm) {
  return await newsfeed
    .getHashtags(searchTerm)
    .then(res => {
      const list = res?.data && res.data.map(item => {
        let isNumberOnly = item.tag_name.match(/^[0-9]+$/);
        if(!isNumberOnly) {
          return { id: item.id, value: item.tag_name };
        }
      });
      return list.filter(n => n);
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

async function getMentionUsersList(searchTerm) {
  return await newsfeed
    .getMentionUsers(searchTerm)
    .then(res => {
      const list = res?.data && res.data.map(item => {
        return { 
          id: item.id,
          value: `${item.first_name} ${item.last_name}`,
          snUserId: item.id,
          company: item.client_name,
          initials: `${item.first_name.charAt(0)}${item.last_name.charAt(0)}`,
          background: item.partner_base_color,
        };
      });
      return list;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

const TextEditor = ({ currState, onStateChange, editorOptions, textColor = null, ...rest }) => {
  const mobileView = useBreakpoint('sm');
  const componentRef = useRef(null);

  return (
    <div ref={componentRef}>
      <RichTextEditor
        textColor={textColor}
        toolbarOptions={editorOptions}
        currentState={currState}
        onChangeEditorState={onStateChange}
        format={['bold','italic','underline','mention','emoji','customEmoji']}
        {...rest}
        autoComplete={{
          allowedChars: function (mentionChar) {
            // allowed characters for mention should be letters, numbers and space, for hashtag only letters and numbers
            return mentionChar === '@' ? /^[\w\s]*$/ : /^\w*$/;
          },
          mentionDenotationChars: ['@', '#'],
          minChars: 1,
          maxChars: 50,
          showDenotationChar: false,
          isolateCharacter: true,
          positioningStrategy: mobileView ? 'absolute' : 'fixed',
          dataAttributes: ['snUserId'],
          renderLoading: () => 'Loading...',
          source: async function(searchTerm, renderList, mentionChar) {
            clearTimeout(timer);
            timer = setTimeout(async () => {
              let values = [];
              if (mentionChar === '@') values = await getMentionUsersList(searchTerm);
              else values = await getHashtagList(searchTerm);

              let containerDiv = document.querySelector('.ql-mention-list-container');
              if (containerDiv) renderList(values);

              // Fix mention/hashtag list position issue (when list is positioned far away from the editor)
              if (!mobileView) {
                let listPosTop = containerDiv?.getBoundingClientRect()?.top;
                let listHeight = containerDiv?.offsetHeight;
                let editorPosTop = componentRef?.current?.getBoundingClientRect()?.top;
                
                if (listPosTop + listHeight < editorPosTop) {
                  let windowScrollTop = window?.scrollY;
                  if (containerDiv) containerDiv.style.top = windowScrollTop + editorPosTop - listHeight + 'px'
                }
              }
            }, 500);
          },
          onSelect: function(item, insertItem) {
            item.value = `${item.denotationChar}${item.value}`;
            insertItem(item);
          },
          onOpen: function() {
            document.body.style.overflow = 'hidden';

            let mentionCloseDivExists = document.querySelector('#mention-close-div');
            if (!mentionCloseDivExists && !mobileView) {
              let mentionCloseDiv = document.createElement('div');
              mentionCloseDiv.id = 'mention-close-div';
              mentionCloseDiv.style.cssText = 'position:fixed; top:0; left:0; right:0; bottom:0;';
              document.body.appendChild(mentionCloseDiv);
            }
          },
          onClose: function() {
            document.body.style.overflow = 'visible';
            return document?.querySelector('#mention-close-div')?.remove();
          },
          renderItem: function(item) {
            return item.snUserId
              ? `
                <div class='ql-item-person'>
                  <div class='ql-item-initials' style='background-color: ${item.background}'>${item.initials}</div>
                  <div>
                    <p class='ql-item-name'>${item.value}</p>
                    <span>${item.company}</span>
                  </div>
                </div>
              `
              : `<p>${item.value}</p>`;
          }
        }}
      />
    </div>
  );
};

export default TextEditor;
