import { AccordionSummary as MuiAccordionSummary} from '@material-ui/core';
import styled from 'styled-components';


export const AccordionSummary = styled(MuiAccordionSummary)`
  && {    
    &.Mui-expanded {
      color: var(--colorSystemInfo);
    }
  }
`;