/* eslint-disable react/prop-types */
import {
  ArrowSyncIcon,
  ArrowUndoIcon,
  CloudUploadIcon,
  Link,
  NotesIcon,
  PencilIcon,
  Stack,
  TrashIcon,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import sraApi from 'helpers/apis/sra';
import { SubmissionModal } from 'components';
import { useSRA } from '../context/sraContext';

const ActionsCell = props => {
  const history = useHistory();
  const location = useLocation();
  const { dispatch, clientId } = useSRA();
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);

  const [loadingType, setLoadingType] = useState('');
  const [openPublishConfirmationModal, setOpenPublishConfirmationModal] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const { id, status, can_be_retaken, can_be_edited, is_deleted } = props.row;

  const isAdmin = userRole === 'Administrator';
  const isPartnerAdmin = userRole === 'Partner Administrator';
  const isAdminOrPartnerAdmin = isAdmin || isPartnerAdmin;

  const availableActions = getAvailableActions(
    is_deleted ? 0 : status,
    props.api.getRowIndexRelativeToVisibleRows(props.row.id),
    can_be_retaken,
    isAdminOrPartnerAdmin,
    can_be_edited,
    isAdminOrPartnerAdmin
  );
  const onUpdateWorkPlan = () => {
    sessionStorage.setItem('accessWorkplanSRA', 'true');
    history.push(`${location.pathname}/workPlan`);
  };

  const onReTakeSRA = () => {
    // should I pass sra id to the retake  card button ?
    dispatch.setActiveTask('retake_sra');
  };

  const onEdit = () => {
    sessionStorage.setItem('accessEditSRA', 'true');
    history.push(`${location.pathname}/editSraReport/${id}`);
  };

  const closePublishConfirmationModal = () => {
    setOpenPublishConfirmationModal(false);
    setTimeout(() => {
      setPublishSuccess(false);
    }, 500);
  };

  const onPublishClick = () => {
    setOpenPublishConfirmationModal(true);
  };

  const onPublish = async () => {
    setPublishLoading(true);
    try {
      setLoadingType(ACTION_TYPE.PUBLISH);
      const response = await sraApi.publishSraReport(clientId, id);
      setPublishSuccess(true);
      props.getTaskList(true);
      await props.refetch();
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || error.message, { props: { color: 'error' } });
    } finally {
      setLoadingType('');
      setPublishLoading(false);
    }
  };

  const onRestoreReport = async () => {
    try {
      setLoadingType(ACTION_TYPE.RESTORE);
      const response = await sraApi.restoreSraReports(clientId, id);
      const message = response?.data?.description;
      await props.refetch();
      if (message) enqueueAlertSnackbar(message, { props: { color: 'success' } });
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || error.message, { props: { color: 'error' } });
    } finally {
      setLoadingType('');
    }
  };

  const handleClickAction = type => {
    switch (type) {
      case ACTION_TYPE.UPDATE:
        onUpdateWorkPlan();
        break;

      case ACTION_TYPE.RETAKE:
        onReTakeSRA();
        break;

      case ACTION_TYPE.PUBLISH:
        onPublishClick();
        break;

      case ACTION_TYPE.EDIT:
        onEdit();
        break;

      case ACTION_TYPE.DELETE:
        props.onDeleteReport(id);
        break;

      case ACTION_TYPE.RESTORE:
        onRestoreReport();
        break;

      default:
        break;
    }
  };

  return (
    <Stack direction="row" spacing={3.5}>
      {availableActions.map(action => {
        const isLoading = loadingType === action;
        const button = ACTION_BUTTONS[action];
        return (
          <Link
            key={button.text}
            component="button"
            startIcon={button.icon}
            onClick={() => handleClickAction(action)}
            hoverColor={button.hoverColor || 'info.main'}
            disabled={isLoading}
            loading={isLoading}
          >
            {button.text}
          </Link>
        );
      })}

      <SubmissionModal
        open={openPublishConfirmationModal}
        onClose={closePublishConfirmationModal}
        icon="CloudUploadIcon"
        title="Publish item?"
        color="colorSystemSuccess"
        description="Are you sure you want to publish the SRA?"
        submitButtonTitle="Yes, I Want To Publish"
        cancelButtonTitle="Cancel"
        onSubmit={onPublish}
        disabled={publishLoading}
        iconSize={34}
        showSuccessDialog={publishSuccess}
        successMessage="Published!"
        successDescription="The SRA was published successfully"
      />
    </Stack>
  );
};

export default ActionsCell;

const ACTION_TYPE = {
  UPDATE: 'update',
  RETAKE: 'retake',
  PUBLISH: 'publish',
  EDIT: 'edit',
  DELETE: 'delete',
  RESTORE: 'restore'
};

const ACTION_BUTTONS = {
  [ACTION_TYPE.UPDATE]: {
    text: 'Update Work Plan',
    icon: <NotesIcon />
  },
  [ACTION_TYPE.RETAKE]: {
    text: 'Re-take SRA',
    icon: <ArrowSyncIcon />
  },
  [ACTION_TYPE.PUBLISH]: {
    text: 'Publish',
    icon: <CloudUploadIcon />
  },
  [ACTION_TYPE.EDIT]: {
    text: 'Edit',
    icon: <PencilIcon />
  },
  [ACTION_TYPE.DELETE]: {
    text: 'Delete Reports',
    icon: <TrashIcon />,
    hoverColor: 'error.main'
  },
  [ACTION_TYPE.RESTORE]: {
    text: 'Restore Reports',
    icon: <ArrowUndoIcon />
  }
};

const getAvailableActions = (status, rowIndex, canRetake, canRestore, canEdit, canPublish) => {
  switch (status) {
    case 0: // Archived
      return canRestore ? [ACTION_TYPE.RESTORE] : [];
    case 1: // Pending
      return [...(canPublish ? [ACTION_TYPE.PUBLISH] : []), ...(canEdit ? [ACTION_TYPE.EDIT] : [])];
    case 2:
      return rowIndex === 0
        ? [ACTION_TYPE.UPDATE, ...(canRetake ? [ACTION_TYPE.RETAKE] : [])] // Published and latest
        : [ACTION_TYPE.DELETE]; // Published
    default:
      return [];
  }
};
