import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useId } from 'hooks';
import sraApi from 'helpers/apis/sra';
import { LoadingStyled } from 'components';
import CheckList from './CheckList/CheckList';
import ReportsTable from './Table/Table';
import { useSRA } from './context/sraContext';

const SRAX = ({ hasLegacyDocuments }) => {
  const userId = useId();
  const { item } = useLocation();
  const clientId = item || userId;
  const [tasks, setTasks] = useState(null);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [loadingTaskList, setLoadingTaskList] = useState(false);
  const {
    state: { revisionId }
  } = useSRA();

  const getTaskList = useCallback(
    showTaskListLoading => {
      if (showTaskListLoading) setLoadingTaskList(true);
      else setLoadingTasks(true);
      sraApi
        .getSraTaskList(clientId)
        .then(res => {
          setTasks(res?.data || null);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          if (showTaskListLoading) setLoadingTaskList(false);
          else setLoadingTasks(false);
        });
    },
    [clientId]
  );

  useEffect(() => {
    if (revisionId) getTaskList();
  }, [getTaskList, revisionId]);

  if (loadingTasks) return <LoadingStyled />;

  return (
    <>
      <CheckList tasks={tasks} getTaskList={getTaskList} loadingTaskList={loadingTaskList} />
      <ReportsTable getTaskList={getTaskList} hasLegacyDocuments={hasLegacyDocuments} />
    </>
  );
};

export default SRAX;