// @flow

import {
  Accordion,
  AccordionDetails,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import SendingTimesAccordion from './SendingTimesAccordion';
import { ArrowDown, Button, Container, CustomTooltip, LoadingStyled, TextWithIcon } from 'components';
import pushNotifications from 'helpers/apis/pushNotifications';
import { useId, useLocation, useRedirectionTarget } from 'hooks';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { AccordionSummary } from 'styles/commonStyles';
import ConfigurationModal from './ConfigurationModal';
import { getFrequencyText, APPS } from './helpers';
import { AUTOMATED_REPORTS } from './constants';

const TableStyled = styled(Table)`
  && {
    th,
    td {
      font-size: var(--fontSize);
    }
  }
`;

const ConfigureButton = ({ onClick, isLoading, hoverMessage }) => {
  return (
    <Box display="flex">
      <CustomTooltip hide={!hoverMessage} title={hoverMessage} placement="right" light arrow maxWidth={300}>
        <div>
          <Button variant="contained" color="primary" onClick={onClick} disabled={!!hoverMessage}>
            {isLoading && (
              <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
            )}
            Configure
          </Button>
        </div>
      </CustomTooltip>
    </Box>
  );
};

const ClientsEditNotification = ({ clientId, trackGA, partner_id, isOnPartnerProfile }) => {
  // clientId is null when passed from Partner Profile app
  const notify = useNotify();
  const { app } = useLocation();
  const isPartnerProfileOrAdminApp = app === APPS.partnerProfile || app === APPS.admin;
  const partnerIdFromUser = useId({ key: 'partnerId' });
  const partnerId = partner_id || partnerIdFromUser;
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [configureIdxLoading, setConfigureIdxLoading] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(null);

  const redirectionTarget = useRedirectionTarget();

  React.useEffect(() => {
    if (!redirectionTarget) return;
    else if (redirectionTarget === AUTOMATED_REPORTS) {
      setExpandedPanel(AUTOMATED_REPORTS);
    }
  }, [redirectionTarget]);

  const { data: tableRes, isLoading: isLoadingTable, refetch: refetchTable } = useQuery(
    ['pushNotification', partnerId, clientId],
    () => pushNotifications.getPushNotificationsSubscriptions(partnerId, clientId),
    {
      enabled: !!partnerId,
      keepPreviousData: true,
      cacheTime: false,
      refetchOnWindowFocus: false,
      onError: err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      }
    }
  );

  const handleConfigure = async (notification, index) => {
    try {
      let subscription_id = notification.push_notification_subscription_id;
      if (!subscription_id) {
        setConfigureIdxLoading(index);
        // this should only run one time (the first time ever to subscribe),
        // subscription id will be null, and we will need to set subscription in database, and get an id for it.
        const res = await pushNotifications.createSubscription(partnerId, clientId, {
          push_notification_id: notification.push_notification_subscription.push_notification_id,
          subscription_id // do we need this?
        });
        subscription_id = res.data?.subscription_id;
        await refetchTable();
        setConfigureIdxLoading(null);
      }
      setSubscriptionId(subscription_id);
    } catch (err) {
      notify(err?.response?.data?.message || 'Something went wrong', 'error');
    }
  };

  if (isLoadingTable || !tableRes?.data) {
    return (
      <div style={{ margin: 'auto' }}>
        <LoadingStyled />
      </div>
    );
  }

  return (
    <>
      {isOnPartnerProfile && <SendingTimesAccordion isOnPartnerProfile={isOnPartnerProfile} />}

      {[...new Set(tableRes?.data?.map(pn => pn.push_notification.category))].map(category => {
        return (
          <div key={category}>
            <Accordion
              style={{ marginBottom: 20 }}
              expanded={category === expandedPanel}
              onChange={() => {
                setExpandedPanel(s => (s === category ? null : category));
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDown />}
                aria-controls={`${category}-content`}
                id={`${category}-header`}
              >
                <p> {category} </p>
              </AccordionSummary>
              <AccordionDetails>
                <Container.Paper fullWidth radiusBottom={1} pb={2}>
                  <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* Table */}
                      <TableContainer component={Paper} elevation={0}>
                        <TableStyled aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width="20%">Rule Name</TableCell>
                              <TableCell width="18%">Frequency</TableCell>
                              <TableCell width="25%">Status</TableCell>
                              {!isPartnerProfileOrAdminApp && <TableCell width="22%">Settings</TableCell>}
                              <TableCell width="15%">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableRes?.data?.map((notification, index) => {
                              if (notification.push_notification.category !== category) {
                                return null;
                              }
                              const {
                                enabled, // reflects global level value in case of "Default Settings", and client level value in case of "Custom Settings"
                                running,
                                frequency_days,
                                push_notification_id,
                                active_time_frame,
                                start_time_frame,
                                end_time_frame,
                                day_of_week,
                                day_of_month,
                                next_send,
                                default_setting,
                                hover_message
                              } = notification.push_notification_subscription;

                              const isDefaultSettings = default_setting === 'Default settings';

                              return (
                                <TableRow key={push_notification_id}>
                                  <TableCell>{notification.push_notification.name}</TableCell>
                                  <TableCell>
                                    {getFrequencyText(frequency_days, day_of_week, day_of_month, next_send, running)}
                                  </TableCell>
                                  <TableCell>
                                    <div>{running ? 'Running' : 'Not Running'}</div>
                                    {Boolean(active_time_frame && enabled) && (
                                      <div>{`Active Time Frame: Start ${start_time_frame} End ${end_time_frame}`}</div>
                                    )}
                                  </TableCell>
                                  {!isPartnerProfileOrAdminApp && (
                                    <TableCell>
                                      {!!default_setting && (
                                        <CustomTooltip
                                          title={
                                            isDefaultSettings ? (
                                              <span>
                                                Default settings are currently being used.
                                                <br />
                                                Click configure to override these settings
                                              </span>
                                            ) : (
                                              <span>
                                                Custom settings are being used.
                                                <br />
                                                Click Configure to change these settings.
                                              </span>
                                            )
                                          }
                                          placement="top"
                                          light
                                          arrow
                                          maxWidth={310}
                                          arrowX={5}
                                        >
                                          <TextWithIcon
                                            text={default_setting}
                                            icon={isDefaultSettings ? 'Internet' : 'UserOutline2'}
                                            isUnderlined
                                          />
                                        </CustomTooltip>
                                      )}
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    <ConfigureButton
                                      onClick={() => handleConfigure(notification, index)}
                                      isLoading={configureIdxLoading === index}
                                      hoverMessage={hover_message}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </TableStyled>
                      </TableContainer>

                      {/* Configure modal */}
                    </Container.Grid>
                  </Container.Grid>
                </Container.Paper>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      {!!subscriptionId && (
        <ConfigurationModal
          configurationModalOpen={!!subscriptionId}
          subscriptionId={subscriptionId}
          closeConfigurationModal={() => setSubscriptionId(null)}
          refetchTable={refetchTable}
          clientId={clientId}
          partnerId={partnerId}
          trackGA={trackGA}
        />
      )}
    </>
  );
};

export default ClientsEditNotification;