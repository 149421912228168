// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, IconButton, Drawer, Divider, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { CustomTooltip } from 'components';
import ToastCloseButton from '../notifications/ToastCloseButton';
import { setFeedFilter } from 'helpers/action';
import { FilterDate } from './index';
import { FILTER_OPTIONS } from '../../constants';
import { Button, FilterIcon } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  filterWrap: {
    position: 'relative',
    // height: '100%',
    display: 'inline-block',
    // '& [class*=ContainerButton]': {
    //   height: '100%'
    // }
  },
  filterBtn: {
    padding: '8px 22px !important',
    color: 'var(--colorDefault)',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 15px !important',
      minWidth: 54
    },
    '& svg': {
      fontSize: 22,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    }
  },
  closeBtnWrap: {
    position: 'absolute',
    right: -6,
    top: -6,
    zIndex: 1
  },
  closeBtn: {
    backgroundColor: 'var(--colorGreyLight2) !important',
    color: 'var(--colorCommonWhite)'
  },
  drawer: {
    width: 550,
    height: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: 550
    }
  },
  drawerHeader: {
    fontSize: '18px',
    fontWeight: '700',
    padding: '15px 20px'
  },
  closeDrawer: {
    float: 'right',
    cursor: 'pointer'
  },
  filterContent: {
    padding: '20px 20px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    },
    '& > p': {
      color: 'var(--colorSystemInfo)',
      fontWeight: 500,
      fontSize: 16,
      margin: '0 0 10px'
    },
    '& [class*=MuiFormControlLabel-label]': {
      userSelect: 'none',
      fontSize: 14
    }
  },
  filterfooter: {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  },
  checkbox: {
    '& svg': {
      fontSize: 24
    }
  },
  datesTxt: {
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 10
    }
  }
}));

const Filter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.newsfeed.filter);
  const [isOpen, setIsOpen] = useState(false);
  const [postFilter, setPostFilter] = useState(filter);
  const hasFilter = filter && Object.keys(filter).length > 0;

  const onOpenFilters = () => {
    setIsOpen(true);
    setPostFilter(filter);
  };

  const onCloseFilters = () => {
    setIsOpen(false);
  };

  const onFilterChange = (name, value) => {
    let newFilter = { ...postFilter };
    if (value) newFilter = { ...newFilter, [name]: value };
    else delete newFilter[name];
    setPostFilter({ ...newFilter });
  };

  const filterFeeds = () => {
    dispatch(setFeedFilter(postFilter));
    onCloseFilters();
  };

  const clearFilter = () => {
    setPostFilter({});
    dispatch(setFeedFilter({}));
    onCloseFilters();
  };

  return (
    <div className={classes.filterWrap}>
      <Button
        size='large'
        onClick={onOpenFilters}
      >
        <FilterIcon sx={{fontSize: '2.2rem'}}/>
      </Button>
      {hasFilter && (
        <div className={classes.closeBtnWrap}>
          <CustomTooltip title="Clear Filter" placement="top">
            <IconButton size="small" className={classes.closeBtn} onClick={clearFilter}>
              <CloseIcon />
            </IconButton>
          </CustomTooltip>
        </div>
      )}

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onCloseFilters}
        PaperProps={{
          classes: { root: classes.drawer }
        }}
      >
        <div role="presentation">
          <div className={classes.drawerHeader}>
            Advanced Filter
            <span onClick={onCloseFilters} className={classes.closeDrawer}>
              <ToastCloseButton />
            </span>
          </div>
          <Divider />

          <div>
            <div className={classes.filterContent}>
              <p>Content</p>
              {FILTER_OPTIONS.map(item => {
                const { label, value } = item;
                return (
                  <div key={value}>
                    <FormControlLabel
                      label={label}
                      control={
                        <Checkbox
                          value={postFilter?.[value] || false}
                          checked={postFilter?.[value] || false}
                          onChange={e => onFilterChange(e.target.name, e.target.checked)}
                          name={value}
                          color="primary"
                          className={classes.checkbox}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className={classes.filterContent}>
              <p>Dates</p>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <span className={classes.datesTxt}>Post published from</span>
                <FilterDate
                  name="published_from"
                  postFilter={postFilter}
                  onFilterChange={onFilterChange}
                  maxDate={postFilter.published_to}
                />
                <Box component="span" mx={{ xs: 1, md: 2 }}>to</Box>
                <FilterDate
                  name="published_to"
                  postFilter={postFilter}
                  onFilterChange={onFilterChange}
                  minDate={postFilter.published_from}
                />
              </Box>
            </div>

            <Divider />
            <div className={classes.filterfooter}>
              <Button
                onClick={filterFeeds}
                backgroundColor="colorBaseBase"
                variant="contained"
                disableElevation
                fullwidth
              >
                Filter Now
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Filter;
