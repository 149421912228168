import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Box, Grid, Typography, Card, CardContent, CardMedia } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Brandfolder, Asset } from './types';

const brandfolderUrl = "https://5gelcr6xf5.execute-api.us-east-1.amazonaws.com/playground/brandfolder";


const fetchBrandfolders = async (): Promise<Brandfolder[]> => {
  const response = await axios.get(`${brandfolderUrl}/brandfolders`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
  return response.data.data;
};

const DAM: React.FC = () => {
  const {
    data: brandfolders,
    isLoading,
    isError
  } = useQuery<Brandfolder[]>('brandfolders', fetchBrandfolders);
  // TODO add loading animation
  if (isLoading) return <p>"Loading..."</p>
  // TODO handle errors
  if (isError || !brandfolders) return <p>"Error!"</p>

  return (
    <Box>
      <Typography variant="h3">Digital Asset Manager</Typography>
      <ul>
        {brandfolders.map(folder => (
          <li key={folder.id}>
            <Link to={`dam/folder/${folder.id}`}>
              {folder.attributes.name}
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  );
}

export default DAM;
