// @flow
import React, { type Element } from 'react';
import { Container, Tabs } from 'components';

const DamEdit: Function = (): Element<*> => {
  return (
    <Container.Tab>
      <Tabs />
    </Container.Tab>
  );
};

export default DamEdit;
