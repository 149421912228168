import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Container, TextField, Typography, ButtonCancel } from 'components';
import { Save2 } from 'components/icons';
import {
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  ErrorOutline as ErrorOutlineIcon,
  ArrowForwardIosSharp
} from '@material-ui/icons';
import { OrganisationListItem, GreenButton, BlueButton } from '../../style';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0
  }
}));

const AdditionalInformation = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState({ ...newState });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  const onConfirmChanges = () => {
    const newState = { confirmation_needed: 0 };
    onSave('close', null, newState);
  };

  return (
    <>
      {/* Info */}
      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(value => !value)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelNetworkbh-content"
          id="panelNetworkbh-header"
        >
          <Typography.p className={classes.heading}>
            <ErrorOutlineIcon color="secondary" /> Please provide additional information that will better help us
            understand your network/computer environment
          </Typography.p>
        </AccordionSummary>

        <AccordionDetails>
          <Container.Grid container spacing={4} mt={2}>
            <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Paper fullWidth radius={2} pb={2}>
                <Container.Grid container>
                  <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List>
                      <OrganisationListItem alignItems="flex-start">
                        <ListItemIcon>
                          <InsertDriveFileIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            isHSN
                              ? 'List any additional system that contain patient information or eletronic protected health information (ePHI);'
                              : 'List any additional system that contain PII;'
                          }
                          secondary={
                            <List>
                              <OrganisationListItem>
                                <ListItemText primary="List any known threats or issues that you have experienced in the past (floods, crimes, etc.);" />
                              </OrganisationListItem>
                              <OrganisationListItem>
                                <ListItemText primary="Use this section to provide anything that you feel will help us with the Risk Assessment." />
                              </OrganisationListItem>
                            </List>
                          }
                        />
                      </OrganisationListItem>
                    </List>
                  </Container.Grid>
                </Container.Grid>
              </Container.Paper>
            </Container.Grid>
          </Container.Grid>
        </AccordionDetails>
      </Accordion>

      <Container.Grid container spacing={4} mt={2}>
        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Paper fullWidth radius={2} pb={2}>
            <Container.Grid container>
              {/* Additional Information */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.additional_info}
                  name="additional_info"
                  placeholder="Additional Information"
                  fullWidth
                  rows={4}
                  multiline
                  onChange={handleChangeState}
                />
              </Container.Grid>

              {/* Action buttons */}
              <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="flex-end">
                  <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ButtonCancel variant="text" onClick={e => handleClose()}>
                      Exit
                    </ButtonCancel>
                    <BlueButton startIcon={<Save2 />} variant="outlined" disableElevation onClick={save}>
                      Save
                    </BlueButton>
                    {state?.confirmation_needed ? (
                      <GreenButton
                        startIcon={<ArrowForwardIosSharp />}
                        variant="outlined"
                        disableElevation
                        ml="30px"
                        onClick={onConfirmChanges}
                      >
                        Confirm Changes
                      </GreenButton>
                    ) : null}
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

AdditionalInformation.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default AdditionalInformation;
