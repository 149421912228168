import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2)
    },
    '& ul': {
      justifyContent: 'center'
    },
    width: '100%',
    marginTop: 'auto'
  }
}));

export default function BasicPagination({ dataCount, limitItem, currentPage, onChangePage }) {
  const pageCount = Math.ceil(dataCount / limitItem) || 1;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Pagination
        count={pageCount}
        page={currentPage}
        color="primary"
        shape="rounded"
        onChange={(e, page) => onChangePage(page)}
      />
    </div>
  );
}
