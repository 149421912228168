import React from 'react';
import { Alert, CircleAlertIcon } from '@trustsecurenow/components-library';

function SystemEmailsAlert({ disabledClientsCount }) {
  if (!disabledClientsCount) return null;

  return (
    <Alert variant="outlined" severity="error" icon={<CircleAlertIcon />} sx={{ my: 2 }}>
      {disabledClientsCount === 1 ? 'One client is ' : `${disabledClientsCount} clients are `}
      active with system emails disabled. Go to the System Emails column to view { disabledClientsCount === 1 ? "this client" : "these clients" }  and enable.
    </Alert>
  );
}

export default SystemEmailsAlert;
