import React, { useMemo } from 'react'
import { Chip, IconButton, InputAdornment, Link } from '@material-ui/core';
import {
  Button,
  Container,
  CustomTable,
  CustomSearchField,
  Dialog,
  LazyIcon,
  TableCellField, 
  Typography 
} from 'components';

const ChipSelectedItem = ({ selected, onDelete }) => {
  if (!selected?.name) return null
  
  return (
    <Container.Grid item md={12} pt={2} pb={2}>
      <Chip
        label={selected?.name}
        onDelete={onDelete}
        deleteIcon={
          <LazyIcon
            component="Close"
            color="--colorGreyLight1"
            style={{ width: '12px', padding: '0 8px',  }}
          />
        }
        style={{ 
          backgroundColor: 'var(--backgroundDefault)',
          borderRadius: '5px',
          fontSize: '13px'
        }}
      />
    </Container.Grid>
  )
}

const FindClientModal = ({
  dispatch,
  isLoading,
  options,
  open,
  onClose,
  searchFor,
  searchValue,
  selected
}) => {
  
  const list = useMemo(() => {
    return (
      <Container.Grid fullWidth style={{ height: '320px', overflowX: 'auto' }}>
        <CustomTable
          source={'clients/moveUserClients'}
          data={options}
          total={options?.length}
          loading={isLoading}
          showPagination={false}
          onSelectRow={(checked, id) => dispatch.handleSelectClient(checked, id)}
          selectedRows={[selected?.id]}
        >
          <TableCellField
            renderComponent={({ name }) => (
              <Typography.p m={0.2} fontSize={14}>{name}</Typography.p>
            )}
          />

          <TableCellField
            renderComponent={({ active }) => (
              <LazyIcon
                component={active ? 'Enable' : 'Disable'}
                color={active ? 'colorSystemSuccess': 'colorSystemDanger'}
                size={1.4}
                { ...(active ? { strokeColor: 'colorSystemSuccess' } : {})}
              />
            )}
          />
        </CustomTable>
      </Container.Grid>
    )
  }, [searchFor, isLoading, options, selected])

  return (
    <Dialog
      open={open}
      setOpen={onClose}
      onlyOk
      maxWidth="sm"
      variant="standard"
    >
      <Container.Grid direction="row" justify="space-between" alignItems="center" pb={1} bb={1}>
        <Typography.p bold m={0.2} fontSize={14}>
          Select {searchFor}
        </Typography.p>
        
        <IconButton onClick={dispatch.cancelFindClient}>
          <LazyIcon component="Close" color="colorDefault" size={1} />
        </IconButton>
      </Container.Grid>

      <Container.Grid container pt={2} pb={2}>
        <Container.Grid item md={12} pb={2}>
          <CustomSearchField
            id="search_client_to_move_user"
            value={searchValue}
            onChange={dispatch.setSearchValue}
            fullwidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LazyIcon component="SearchIcon" color="colorDefault" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <Link
                    style={{ color: 'var(--colorDefault)', textDecoration: 'underline' }}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch.setSearchValue('')
                    }}
                  >
                    Clear search
                  </Link> 
                </InputAdornment>
              )
            }}
          />
        </Container.Grid>

        <ChipSelectedItem
          selected={selected}
          onDelete={() => {
            dispatch.onDeleteItemSelected({
              isClient: searchFor === 'Client'
            })
          }}
        />

        <Container.Grid item md={12} pt={4} pb={2}>
          {list}
        </Container.Grid>
      </Container.Grid>

      <Container.Grid container justify="flex-end" alignItems="center" pt={1}>
        <Link
          style={{ color: 'var(--colorDefault)', textDecoration: 'underline' }}
          href="#"
          onClick={e => {
            e.preventDefault()
            dispatch.cancelFindClient()
          }}
          color="inherit"
        >
          Cancel
        </Link> 
        <Button
          backgroundColor="colorSystemInfo"
          backgroundColorHover="colorSystemInfoHover"
          backgroundDisabled="colorSystemInfoDisable"
          disabled={!selected}
          onClick={dispatch.handleNext}
          ml={4}
        >
          Next
        </Button>
      </Container.Grid>
    </Dialog>
  )
}

export default FindClientModal