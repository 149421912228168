// @flow
import React, { memo, type Element, type ComponentType } from 'react';
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import styled from 'styled-components';

const spacing = 12;

export const SwitchStyle: ComponentType<*> = styled(({ color, ...props }) => (
  <MuiSwitch
    {...props}
    classes={{
      root: `switchRoot`,
      switchBase: `switchBase`,
      thumb: `swichThumb`,
      checked: `switchChecked`,
      track: `switchTrack`,
      colorSecondary: `switchColorSecondary`,
      edgeEnd: `switchEndStyle`
    }}
  />
))`
  &.switchRoot {
    width: calc(45px + ${spacing * 2}px);
    height: calc(25px + ${spacing * 2}px);
    && + span {
      font-size: var(--fontSize);
      color: var(--colorDefault);
    }
  }
  && .switchColorSecondary.switchChecked {
    transform: translateX(${spacing + 6}px);
  }

  && .switchColorSecondary.switchChecked + span.switchTrack {
    opacity: 1;
    background-color: var(--colorSystemSuccess) !important;
    border-color: var(--colorSystemSuccess);
  }

  && .switchTrack {
    border-radius: 50px;
    height: 25px;
    width: 100%;
    opacity: 1;
    background-color: var(--backgroundSwitch) !important;
  }

  && .switchBase {
    color: var(--colorCommonWhite);
    padding: 16px 0;
    left: calc(12px + 5px);
  }

  && .switchBase:hover {
    background-color: transparent;
  }

  && .swichThumb {
    width: ${spacing + 5}px;
    height: ${spacing + 5}px;
    box-shadow: none;
  }
`;

type SwitchTypes = {
  label?: string | Object,
  labelPlacement?: 'start' | 'botton' | 'top' | 'end',
  readOnly?: boolean,
  onChange: Function,
  value: boolean | string,
  checked: boolean
};

const Switch = ({
  label,
  sublabel,
  labelPlacement = 'end',
  readOnly,
  onChange,
  value,
  checked,
  ...rest
}: SwitchTypes): Element<*> => {
  const change = readOnly ? e => e : onChange;
  
  const onKeyDown = e => { 
    if (e.keyCode === 32) e.preventDefault()
  }
  
  const Label = !sublabel ? label : <span>{label}<br/><small>{sublabel}</small></span>

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={Label}
      control={
        <SwitchStyle
          {...rest}
          value={String(value)}
          checked={Boolean(checked)}
          color="green"
          onChange={change}
          onKeyDown={onKeyDown}
          classes={{
            root: `switchRoot`,
            switchBase: `switchBase`,
            thumb: `swichThumb`,
            checked: `switchChecked`,
            track: `switchTrack`,
            colorSecondary: `switchColorSecondary`,
            edgeEnd: `switchEndStyle`
          }}
        />
      }
    />
  );
};

Switch.defaultProps = {
  label: null,
  labelPlacement: 'end',
  readOnly: false
};

export default memo<SwitchTypes>(Switch);
