import React, { useEffect, useState } from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import { useApp } from 'helpers';
import FilterModal from './FilterModal';
import { useSelector } from 'react-redux';
import { useLocation } from 'hooks';
import { useTableList } from 'components/tablelist/TableListContext';

const Container = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  height: 75px;
  border-bottom: 1px solid var(--borderLight);
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 2rem;
`;

const HeaderRow = Row;

const SearchRow = styled(Row)`
  padding: 0;
  justify-content: space-around;
`;

const SearchInput = styled(Input)`
  width: 265px;
  height: 52px;
  padding: 12px 0px 12px 12px;
  color: var(--colorLight);
  border: none;
  border-radius: 5px;
  background-color: var(--borderLight);
  & svg {
    font-size: 25px;
    color: var(--colorLight);
  }
  & input {
    box-shadow: 0 0 0 30px var(--borderLight) inset !important;
  }
`;

const UsersListToolbarMobile = ({ enableActions, setDisabeldActions, setIsSelectedDeletedFilter }) => {
  const { app } = useLocation();
  const { dispatch: dispatchApp } = useApp();
  const {
    dispatch: { search: onSearch }
  } = useTableList();
  const tableFilter = useSelector(state => state.bsn.system.tableFilter);
  /**its value is used only to control the search input */
  const [search, setSearch] = useState('');
  /**it holds the values for search and selector filters */
  const [filters, setFilters] = useState({});

  const updateList = filters => {
    dispatchApp.set('system', 'tableFilter', { ...tableFilter, ...filters, deleted: true });
  };

  const onModalFilterChange = async modalFilters => {
    const newFilters = { ...filters, ...modalFilters };
    setFilters(newFilters);
    updateList(newFilters);
    checkDisabledActions(newFilters);
  };

  const checkDisabledActions = (newFilters) => {
    if (newFilters.deleted === 'true') {
      const disabledList = [];
      Object.keys(enableActions).map(action => {
        if (enableActions[action] && action !== 'reset_deleted' && action !== 'permanent_delete') {
          disabledList.push(action);
        }
      });
      setDisabeldActions(disabledList);
    } else {
      setDisabeldActions(['reset_deleted', 'permanent_delete']);
    }
  };

  return (
    <Container>
      <HeaderRow>
        <h1>{app === 'myCompany' ? 'Employees' : 'Users'}</h1>
      </HeaderRow>
      <SearchRow>
        <SearchInput
          disableUnderline
          id="UsersListMobile_searchInput"
          value={search}
          onChange={e => {
            const value = e.target.value;
            setSearch(value);
            // upadting the users list on clearing the search input
            if (value === '' && search.length > 0) {
              onSearch(value);
            }
          }}
          placeholder="search"
          endAdornment={
            <InputAdornment position="start">
              <SearchIcon
                style={{ cursor: 'pointer' }}
                fontSize="inherit"
                onClick={() => {
                  onSearch(search);
                }}
              />
            </InputAdornment>
          }
        />
        <FilterModal onChange={onModalFilterChange} />
      </SearchRow>
    </Container>
  );
};

export default UsersListToolbarMobile;
