// @flow
import React, { useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { getStorage, decodeId } from 'helpers';
import { useLocation } from 'hooks';
import { CustomTooltip } from 'components';
import { Pin } from 'components/icons';
import { ClientsModal } from '../modals';
import UserAvatar from '../common/UserAvatar';
import PostHeaderActions from './PostHeaderActions';
import { getAudienceAndRolePreview } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '100%'
  },
  padded: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  },
  btn: {
    padding: 0,
    border: 0,
    background: 'none',
    outline: 'none !important',
    cursor: 'pointer',
    color: 'var(--colorDark)'
  }
}));

const PostHeader = ({ feed, resetFeedList }) => {
  const classes = useStyles();
  const { item } = useLocation();
  const [openClientsModal, setOpenClientsModal] = useState(false);
  const user = { id: decodeId(getStorage('userId')) };
  const isOwnPost = feed.user.id === Number(user.id);

  const onOpenClientsModal = () => {
    setOpenClientsModal(true);
  };

  const onCloseClientsModal = () => {
    setOpenClientsModal(false);
  };

  const getIcon = () => {
    const data = {
      allowed_groups: feed.allowed_groups,
      included_clients: feed.included_clients,
      audience: feed.audience
    };
    const obj = getAudienceAndRolePreview(data);

    return (
      <CustomTooltip title={obj.text} placement="top">
        {data.included_clients?.length > 0 ? (
          <button type="button" className={classes.btn} onClick={onOpenClientsModal}>
            {obj.icon}
          </button>
        ) : (
          obj.icon
        )}
      </CustomTooltip>
    );
  };

  return (
    <>
      <Box className={classes.padded} display="flex" alignItems="center">
        <UserAvatar 
        user={feed.user} 
        timestamp={feed.timestamp} 
        icon={isOwnPost && getIcon()}
        allowedGroups={feed?.allowed_groups} 
        clickable
        />
        {feed.pinned && !item && (
          <Box px={{ xs: 0.5, md: 2 }}>
            <Pin size={1.7} />
          </Box>
        )}
        <Box mr={-1.5}>
          <PostHeaderActions feed={feed} isOwnPost={isOwnPost} resetFeedList={resetFeedList} />
        </Box>
      </Box>
      {openClientsModal && (
        <ClientsModal close={onCloseClientsModal} open={openClientsModal} data={feed.included_clients} />
      )}
    </>
  );
};

export default React.memo(PostHeader);
