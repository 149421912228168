import { useId, useLocation } from 'hooks';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import sraApi from 'helpers/apis/sra';
import generateApiTableParams from 'utils/generateApiTableParams';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import { SRA_SUB_PAGES } from '../constants';
import { useClientInfo } from '../hooks';

export const RecommendationsContext = createContext(null);

const SRA_RECOMMENDATIONS_TABLE_NAME = 'SRARecommendations';

export const RecommendationsProvider = ({ children }) => {
  const [revisionId, setRevisionId] = useState('');
  const userId = useId();
  const { item } = useLocation();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const clientId = item && !isSraSubPage ? item : userId;
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [isPreferenceFetched, setIsPreferenceFetched] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState([]);
  const { isHSN, isLoadingClientInfo } = useClientInfo(clientId);

  useEffect(() => {
    if (clientId && revisionId === '') {
      // setRevisionId(encodeId(3096)); // use id 3096 with account ryanspa@gmail.com
      sraApi.getRevisionId(clientId).then(res => {
        setRevisionId(res?.data?.id);
      });
    }
  }, [clientId, revisionId]);

  const fetchRecommendations = useCallback(async () => {
    if (!revisionId || !isPreferenceFetched) return;
    try {
      setIsLoading(true);
      const params = generateApiTableParams({
        pagination: paginationModel,
        sorting: sortModel,
        filter: { items: filterModel, fields: { finding: 'date_completed' } }
      });
      userPreferences.setMuiDataGridSettings(SRA_RECOMMENDATIONS_TABLE_NAME, {
        pageSize: paginationModel.pageSize,
        sorting: sortModel
      });
      const response = await sraApi.getWorkplanRecommendations(params, revisionId);
      setRows(response.data.result);
      setRowCount(response.data.count);
    } finally {
      setIsLoading(false);
    }
  }, [isPreferenceFetched, paginationModel, revisionId, sortModel, filterModel]);

  useEffect(() => {
    setIsPreferenceFetched(true);
    const preferences = userPreferences.getMuiDataGridSettings(SRA_RECOMMENDATIONS_TABLE_NAME);
    if (preferences) {
      setPaginationModel(prevState => ({ ...prevState, pageSize: preferences.pageSize || prevState.pageSize }));
      setSortModel(prevState => preferences.sorting || prevState);
    }
  }, []);

  const handleFilterModelChange = filter => {
    const items = filter.items
      .map(filterItem => ({ field: filterItem.field, value: filterItem.value, operator: filterItem.operator }))
      .filter(filterItem => (Array.isArray(filterItem.value) ? filterItem.value.length : filterItem.value));
    const isFilterChanged = JSON.stringify(filterModel) !== JSON.stringify(items);
    if (isFilterChanged) {
      setPaginationModel(prevState => ({ ...prevState, page: 0 }));
      setFilterModel(items);
    }
  };

  const handleSortModelChange = value => {
    setPaginationModel(prevState => ({ ...prevState, page: 0 }));
    setSortModel(value);
  };

  return (
    <RecommendationsContext.Provider
      value={{
        isHSN,
        revisionId,
        rows,
        rowCount,
        isLoading,
        refetch: fetchRecommendations,
        pagination: paginationModel,
        onChangePagination: setPaginationModel,
        sorting: sortModel,
        onChangeSorting: handleSortModelChange,
        onChangeFilter: handleFilterModelChange,
        isLoadingClientInfo
      }}
    >
      {children}
    </RecommendationsContext.Provider>
  );
};

RecommendationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useRecommendations = () => useContext(RecommendationsContext);
