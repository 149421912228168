// @flow
import React from 'react';
import { PagePushNotifications, Container, Typography } from 'components';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle } from './ComponentTypes';

const PartnerPushNotifications = () => {

  return (
    <>
      <Container.Paper mt={2} mb={2}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle noMargin>
              <Typography.h6 mt={0.1} mb={0.1}>
                Notifications
              </Typography.h6>
              <InfoButtons name="automated_reminders_and_reports" />
            </ContainerTitle>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>

      {/* <Container.Paper> */}
      <PagePushNotifications clientId={null} trackGA isOnPartnerProfile />

      {/* Action buttons */}
      <ActionButtons />
    </>
  );
};

export default PartnerPushNotifications;