// @flow
import { createGlobalStyle, css } from 'styled-components';
import { variables, transitions } from 'conf';

const {
  font,
  color,
  transitions: { easing, duration },
  shadows
} = variables;

export const theme = {
  colors: {
    ...color
  }
};

const lightTheme = css`
  --colorBaseBase: ${theme.colors.primary.base};
  --colorBaseDark1: ${theme.colors.primary.dark1};
  --colorBaseDark2: ${theme.colors.primary.dark2};
  --colorBaseDark3: ${theme.colors.primary.dark3};
  --colorBaseLight1: ${theme.colors.primary.light1};
  --colorBaseLight2: ${theme.colors.primary.light2};
  --colorBaseLight3: ${theme.colors.primary.light3};

  --colorSystemDanger: ${theme.colors.system.danger};
  --colorSystemDanger-rgb: ${theme.colors.system['danger-rgb']};
  --colorSystemWarning: ${theme.colors.system.warning};
  --colorSystemSuccess: ${theme.colors.system.success};

  --backgroundDefault: var(--colorBaseLight3);
  --backgroundLight: #e0e0f4;
  --backgroundBaseLight: #EDF1FA;
  --colorDefault: var(--colorGreyLight2);
  --colorDark: var(--colorGreyBase);
  --colorDarkGrey: ${theme.colors.dark.base};
  --colorLight: #94959B;
  --colorBase: var(--colorBaseBase);
  --colorIcon: var(--colorGreyLight1);
  --hoverDefault: var(--colorGreyDark2);
  --borderDefault: var(--colorGreyLight3);
  --borderBase: #ccc;
  --borderPrimary: var(--colorBaseDark2);
  --borderLight: ${theme.colors.grey.light3};
  --borderTableDefault: var(--colorBaseLight3);
  --iconDefaultcolor: var(--colorDefault);
  --colorLightGray: #94959B;

  --colorPrimary: var(--colorBaseDark2);
  --colorFontTableHeader: var(--colorBaseDark3);

  --buttonPrimaryBackground: var(--colorBaseDark2);
  --buttonPrimaryHover: #39596d;

  --buttonSecondaryBackground: var(--colorCommonWhite);
  --buttonSecondaryBorderFont: var(--colorDefault);
  --buttonSecondaryHover: var(--colorBaseBase);
  --buttonSecondaryFontHover: var(--colorCommonWhite);
  --buttonBase: var(--colorBaseBase);
  --buttonTextOpacity: 1;

  --backgroundPaper: var(--colorCommonWhite);
  --backgroundTableHead: var(--colorGreyLight3);
  --backgroundTableHeadDark: var(--colorBaseDark2);
  --backgroundSwitch: var(--colorDefault);
  --backgroundDropdownSelected: rgba(0,0,0,0.08);
  --backgroundDropdownHover: rgba(0,0,0,0.04);
  --backgroundCode: #f0f0f0;
  --backgroundInfo: rgba(25, 148, 230, 0.2);
  --filterImage: none;

  --colorBox: var(--colorSystemInfo);

  --blackAndWhite: var(--colorCommonBlack);
  --whiteAndBlack: var(--colorCommonWhite);
  --backdrop: rgba(0,0,0,0.5);
  --boxShadow: rgba(0,0,0,0.25);
  --backgroundScrollbar: #c1c1c1;

  --loadingNormal: var(--colorBaseLight2);
  --loadingMedium: var(--colorBaseBase);
  --loadingDark: var(--colorBaseDark3);
`;
// TODO: --hoverButtonDefault: #39596d; the hover will be calculated when the color palette is finished
const darkTheme = css`
  --colorBaseBase: ${theme.colors.dark.base};
  --colorBaseDark1: ${theme.colors.dark.dark1};
  --colorBaseDark2: ${theme.colors.dark.dark2};
  --colorBaseDark3: ${theme.colors.dark.dark3};
  --colorBaseLight1: ${theme.colors.dark.light1};
  --colorBaseLight2: ${theme.colors.dark.light2};
  --colorBaseLight3: ${theme.colors.dark.light3};

  --colorSystemDanger: #FF7F85;

  --backgroundDefault: var(--colorBaseDark3);
  --backgroundLight: var(--colorBaseDark3);
  --backgroundBaseLight: var(--colorCommonBlack);
  --colorDefault: #aaa;
  --colorDark: var(--colorBaseLight3);
  --colorDarkGrey: var(--colorBaseLight3);
  --colorLight: var(--colorBaseLight2);
  --colorBase: var(--colorBaseLight3);
  --colorIcon: var(--colorDefault);
  --hoverDefault: var(--colorGreyLight2);
  --borderDefault: var(--colorBaseLight1);
  --borderBase: var(--colorGreyLight1);
  --borderPrimary: var(--colorBaseLight2);
  --borderLight: var(--colorGreyLight2);
  --borderTableDefault: var(--colorBaseDark3);
  --iconDefaultcolor: #adadad;
  --colorLightGray: var(--colorGreyLight1);

  --colorPrimary: var(--colorBaseLight2);
  --colorFontTableHeader: var(--colorBaseLight3);

  --buttonPrimaryBackground: var(--colorBaseLight1);
  --buttonPrimaryHover: #39596d;

  --buttonSecondaryBackground: var(--colorBaseDark2);
  --buttonSecondaryBorderFont: var(--colorDefault);
  --buttonSecondaryHover: var(--colorBaseLight3);
  --buttonSecondaryFontHover: var(--colorBaseBase);
  --buttonBase: var(--colorBaseDark3);
  --buttonTextOpacity: 0.5;

  --backgroundPaper: var(--colorBaseDark2);
  --backgroundTableHead: var(--colorBaseLight1);
  --backgroundTableHeadDark: var(--colorBaseBase);
  --backgroundSwitch: var(--colorBaseLight2);
  --backgroundDropdownSelected: rgba(255,255,255,0.08);
  --backgroundDropdownHover: rgba(255,255,255,0.04);
  --backgroundCode: var(--backgroundDropdownSelected);
  --backgroundInfo: var(--colorBaseLight1);
  --filterImage: brightness(0.9) grayscale(1) invert(1);

  --colorBox: var(--colorGreyLight3);

  --blackAndWhite: var(--colorCommonWhite);
  --whiteAndBlack: var(--colorCommonBlack);
  --backdrop: rgba(255,255,255,0.3);
  --boxShadow: rgba(255,255,255,0.2);
  --backgroundScrollbar: rgba(255,255,255,0.3);

  --loadingNormal: var(--colorBaseLight2);
  --loadingMedium: var(--colorBaseLight1);
  --loadingDark: var(--colorBaseBase);
`;
// TODO: --hoverButtonDefault: #39596d; the hover will be calculated when the color palette is finished
export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;

    /* Spacing */
    --spacing: 8px;
    --marginBoby: 0;
    --paddingBoby: 0;
    --outlineBoby: 0;
    --boxSizingBoby: border-box;
    /* Border */
    --borderSize: 1px;
    --borderRadius: 5px;

    /* Typography */
    --fontFamily: ${font.family};
    --fontSize: ${font.size};
    --fontSizeHtml: ${font.size};
    --fontWeightRegular: ${font.regular};
    --fontWeightMedium: ${font.medium};
    --fontWeightBold: ${font.bold};
    --lineHeight: 1.3;

    /* Color Common */
    --colorCommonWhite: ${color.common.white};
    --colorCommonBlack: ${color.common.black};

    /* Color Grey */
     --colorGreyBase: ${color.grey.base};
    --colorGreyDark1: ${color.grey.dark1};
    --colorGreyDark2: ${color.grey.dark2};
    --colorGreyDark3: ${color.grey.dark3};
    --colorGreyLight1: ${color.grey.light1};
    --colorGreyLight2: ${color.grey.light2};
    --colorGreyLight3: ${color.grey.light3};

    /* Color Primary */
    ${lightTheme}

    /* Color Dark Mode */
    [data-theme="dark"] {
      ${darkTheme}
    }

    /* Color System */
    /* --colorSystemDanger: ${color.system.danger};
    --colorSystemInfo: ${color.system.info};
    --colorSystemSuccess: ${color.system.success};
    --colorSystemWarning: ${color.system.warning}; */

    /* Color System Hover */
    --colorSystemInfoHover: #3387ff;
    --colorSystemSuccessHover: #3dd889;
    --colorSystemWarningHover: #FFB679;
    --colorSystemDangerHover: #ff7881;
    --colorSystemSuccessDisable: #b6f0d3;

    /* Buttons */
    --buttonDefault: var(--colorBaseBase);
    --buttonLight: var(--colorBaseLight3);
    --buttonDark: var(--colorBaseDark3);
    --buttonDanger: var(--colorSystemDanger);
    --buttonInfo: var(--colorSystemInfo);
    --buttonSuccess: var(--colorSystemSuccess);
    --buttonWarning: var(--colorSystemWarning);
    --buttonRadius: 4px;

    /* Layout */
    --layoutSidebarWidth: 292px;
    --layoutHeaderHeight: 75px;

    /* Transitions */
    --easeInOut: ${easing.easeInOut};
    --easeOut: ${easing.easeOut};
    --easeIn: ${easing.easeIn};
    --sharp: ${easing.sharp};

    --shortest: ${duration.shortest};
    --shorter: ${duration.shorter};
    --short: ${duration.short};
    --standard: ${duration.standard};
    --complex: ${duration.complex};
    --enteringScreen: ${duration.enteringScreen};
    --leavingScreen: ${duration.leavingScreen};

    /* Shadows */
    --shadowDefault: ${shadows.default};

  }

  @media (min-width: 1025px) {
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--backgroundDefault);
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: var(--backgroundDefault);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--backgroundScrollbar);
    }
  }

  :root {
    --toastify-toast-width: 370px;
  }

  .Toastify__toast-theme--light {
    background: var(--backgroundPaper);
  }

  #emoji-close-div {
    z-index: -1;
  }

  #emoji-palette {
    #tab-toolbar {
      background: var(--backgroundDefault);
    }
    #tab-panel {
      background: var(--backgroundPaper);
    }
  }

  [class*=MuiAutocomplete-noOptions] {
    font-weight: bold;
  }

  [class*=MuiTableSortLabel-root][class*=MuiTableSortLabel-active][class*=MuiTableSortLabel-root][class*=MuiTableSortLabel-active] [class*=MuiTableSortLabel-icon] {
    color: var(--colorDark) !important;
  }

  /* Rich text editor Mentions and hashtags */
  .mention {
    background: none;
    padding: 0;
    height: auto;
    color: var(--colorSystemInfo);
  }
  .ql-mention-list-container {
    margin-top: 10px;
    background: var(--backgroundPaper);
    border-color: var(--borderDefault);
    .ql-mention-list-item {
      background: none;
      > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
      &:hover {
        background: var(--backgroundDropdownHover);
      }
    }
    .ql-item-person {
      display: flex;
      align-items: center;
      line-height: initial;
      padding-top: 10px;
      padding-bottom: 10px;
      .ql-item-initials {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        margin-right: 20px;
        @media (max-width: 1024px) {
          width: 40px;
          min-width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
        font-weight: 700;
        color: var(--colorGreyLight2);
        word-break: break-word;
      }
      span {
        font-size: 12px;
        color: var(--colorGreyLight2);
        word-break: break-word;
        display: inline-block;
      }
    }
  }

  .cfy-label {
    color: red;
  }

  img:-moz-loading {
    visibility: hidden;
  }

  #walkme-menu,
  #walkme-player {
    padding-right: inherit !important;
  }

  body {
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeight);
    background: var(--backgroundDefault);
    color: var(--colorDefault);
    text-align: left;
    margin: var(--marginBoby);
    padding: var(--paddingBody);
    outline: var(--outlineBody);
    box-sizing: var(--boxSizingBoby);
    /* TODO: Change CSS Variables */
    transition: all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp}, padding 0s;

    a {
      /* TODO: Change CSS Variables */
      transition: all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
    }
  }
`;

export const GlobalStyleBase = createGlobalStyle`
  html {
    --colorBaseBase: ${theme.colors.primary.base};
    --colorBaseDark1: ${theme.colors.primary.dark1};
    --colorBaseDark2: ${theme.colors.primary.dark2};
    --colorBaseDark3: ${theme.colors.primary.dark3};
    --colorBaseLight1: ${theme.colors.primary.light1};
    --colorBaseLight2: ${theme.colors.primary.light2};
    --colorBaseLight3: ${theme.colors.primary.light3};
  }
`;
