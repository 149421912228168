import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import { AUTOMATED_REPORTS } from 'components/pages/pushNotifications/constants';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNA } from '../helpers';

const ESS_REPORT_FREQUENCY = {
  '7': 'weekly',
  '14': 'bi-weekly',
  '28': 'monthly',
  '84': 'quarterly'
};

const AutoESSReportCell = ({ clientId, essEnabled, essFrequency }) => {
  if (isNA(essEnabled)) return <Typography variant="body2">N/A</Typography>;

  const Icon = essEnabled ? CircleCheckIcon : CircleCloseIcon;
  const color = essEnabled ? 'success' : 'error';
  const tooltipTitle = essEnabled
    ? `ESS Report is scheduled to send ${ESS_REPORT_FREQUENCY[essFrequency]}, click here to configure.`
    : 'Click here to configure';

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton
        LinkComponent={Link}
        to={`/clients/notification/${clientId}?redirectionTarget=${AUTOMATED_REPORTS}`}
        color={color}
      >
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

AutoESSReportCell.propTypes = {
  essEnabled: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  essFrequency: PropTypes.string.isRequired
};

export default AutoESSReportCell;
