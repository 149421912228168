import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Link } from '@trustsecurenow/components-library';

const VARIANT = {
  CLIENT: 'client',
  USER: 'user'
};

const DEPLOYMENT_STATE = {
  NOT_DEPLOYED: 'NOT_DEPLOYED',
  PARTIALLY_DEPLOYED: 'PARTIALLY_DEPLOYED',
  DEPLOYED: 'DEPLOYED',
};

const TEAMS_APP_DEPLOYMENT_CONFIG = {
  [VARIANT.USER]: {
    [DEPLOYMENT_STATE.DEPLOYED]: {
      getMessage: userRedirectionUrl => (
        <>
          You can now easily access the portal within Teams using our PII Protect app.{' '}
          <Link
            variant="inherit"
            color="inherit"
            href={userRedirectionUrl}
            target="_blank"
            underline="hover"
            sx={{ verticalAlign: 'baseline', display: 'inline-flex !important', textTransform: 'none' }}
          >
            Click here
          </Link>{' '}
          to access the app for the first time.
        </>
      ),
      severity: 'success'
    }
  },
  [VARIANT.CLIENT]: {
    [DEPLOYMENT_STATE.NOT_DEPLOYED]: {
      getMessage: onClickHere => (
        <>
          The PII Protect Teams app has not been deployed for this client.{' '}
          <Link
            color="inherit"
            underline="hover"
            variant="button"
            component="button"
            onClick={onClickHere}
            sx={{ verticalAlign: 'baseline', display: 'inline-flex !important', textTransform: 'none' }}
          >
            Click here
          </Link>{' '}
          to deploy.
        </>
      ),
      severity: 'error'
    },
    [DEPLOYMENT_STATE.PARTIALLY_DEPLOYED]: {
      getMessage: onClickHere => (
        <>
          The PII Protect Teams app has been deployed; however, some users are not receiving notifications.{' '}
          <Link
            color="inherit"
            underline="hover"
            variant="button"
            component="button"
            onClick={onClickHere}
            sx={{ verticalAlign: 'baseline', display: 'inline-flex !important', textTransform: 'none' }}
          >
            Click here
          </Link>{' '}
          to view these users.
        </>
      ),
      severity: 'warning'
    }
  }
};

const TeamsAppDeploymentAlert = ({ deploymentPercent, variant, onClientClickHere, userRedirectionUrl }) => {
  const { message, severity } = useMemo(() => {
    if (variant === VARIANT.USER) {
      const userConfig = TEAMS_APP_DEPLOYMENT_CONFIG[VARIANT.USER][DEPLOYMENT_STATE.DEPLOYED];
      return {
        message: userConfig.getMessage(userRedirectionUrl),
        severity: userConfig.severity,
      };
    }
    if (variant === VARIANT.CLIENT) {
      const clientConfig =
        deploymentPercent === 0
          ? TEAMS_APP_DEPLOYMENT_CONFIG[VARIANT.CLIENT][DEPLOYMENT_STATE.NOT_DEPLOYED]
          : TEAMS_APP_DEPLOYMENT_CONFIG[VARIANT.CLIENT][DEPLOYMENT_STATE.PARTIALLY_DEPLOYED];
      return {
        message: clientConfig.getMessage(onClientClickHere),
        severity: clientConfig.severity,
      };
    }
  }, [deploymentPercent, onClientClickHere, userRedirectionUrl, variant]);

  return ( 
    <Alert severity={severity} sx={{ width: '100%', textTransform: 'none' }}>
      {message}
    </Alert> 
  );
};

TeamsAppDeploymentAlert.propTypes = {
  deploymentPercent: PropTypes.number,
  variant: PropTypes.oneOf([VARIANT.CLIENT, VARIANT.USER]).isRequired,
  onClientClickHere: PropTypes.func,
  userRedirectionUrl: PropTypes.string,
};

TeamsAppDeploymentAlert.defaultProps = {
  deploymentPercent: null,
  onClientClickHere: () => {},
  userRedirectionUrl: null,
};

export default TeamsAppDeploymentAlert;
