import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAdminNavigate from './useAdminNavigate';
import useAdminSteps from './useAdminSteps';
import { admin, formDataUpload, objectsEqual, dateToISO } from 'helpers';
import { updateNestedMutable } from 'utils/update';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const UPLOAD_STEP_NUMBER = 1;

const useNewslletterAddEdit = (steps) => {
  const { page, method, id } = useParams();
  const { navigateToList, navigateGoBack } = useAdminNavigate();
  const { nextStep, onBack, activeStep, completed } = useAdminSteps();
  
  const [record, setRecord] = useState({
    name: null,
    created_date: dateToISO(new Date()),
    published_date: null,
    publicly_accessible: false,
    description: null,
    hash_tags: null,
    email_body: null,
    quick_tips_body: null,
    is_published: null,
    file: null
  });
  const [successModal, setSuccessModal] = useState({ open: false, title: '' });
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(false);
  
  const createMode = method === 'create' && !id;
  const isLastStep = activeStep === steps.length - 1;

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await admin[page].information(id);
      setInformation(res.data);
      setRecord(res.data);
    } catch (err) {
      navigateToList();
      enqueueAlertSnackbar(
        err?.response?.data?.status || 'Failed on load information',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  const onSuccess = () => { 
    // if last step then display success message on modal
    if (isLastStep) {
      const title = createMode ? `Newsletters Created!` : `Newsletters Updated!`;
      const message = `You have successfully ${createMode ? 'created' : 'updated'} a newsletter`
      openModal(title, message);
      return;
    }
    
    nextStep();
  }

  const create = () => {
    return admin[page].create(record);
  };

  const update = () => {
    return admin[page].update(record.id, record);
  };

  const save = async () => {
    try {
      setLoading(true);
      if (createMode && !record?.id) {
        const resp = await create();
        setRecord(old => updateNestedMutable(old, { id : resp.data.data.id }));
        setInformation(updateNestedMutable(record, { id : resp.data.data.id }));
      } else {
        await update();
      }
      onSuccess();
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.status || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const postUrl = async (res) => {
    try {
      const formData = formDataUpload(res, record.file);
      await admin.postUrl(res.data.url, formData, 'multipart/form-data');
      nextStep();
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async () => {
    try {
      setLoading(true);
      const res = await admin.pressignedUploadFile('newsletters', record.id, record.file.name, 'newsletter');
      postUrl(res);
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.message || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const openModal = (title, message) => {
    setSuccessModal({ open: true, title, message });
  };

  const dispatch = {};

  dispatch.onChange = (name, value) => {
    setRecord(old => updateNestedMutable(old, { [name]: value }));
  };

  dispatch.onFileUpload = (file) => {
    setRecord(old => updateNestedMutable(old, { file: file }));
  };

  dispatch.onCloseModal = () => {
    navigateGoBack();
    setSuccessModal({ open: false, title: '' });
  };

  dispatch.onNext = () => {
    if (objectsEqual(information, record) && !isLastStep) {
      return nextStep();
    }

    if (UPLOAD_STEP_NUMBER === activeStep && information.file !== record.file) {
      return uploadFile();
    }
  
    return save();
  };

  dispatch.onCancel = () => {
    setRecord(id ? information : record);
  };

  dispatch.onBack = () => onBack();

  return {
    activeStep,
    completed,
    dispatch,
    loading,
    page,
    record,
    successModal,
  };
};

export default useNewslletterAddEdit;