import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { useDataGet, useLocation, useId } from 'hooks';
import {
  dataProvider,
  arrayToChoices,
  validURL,
  admin as adminApi,
  isObjectEmpty,
  setStorage,
  objectsEqual
} from 'helpers';
import moveClientApi from 'helpers/apis/UsersAPI/moveClientUserData';
import { useAdminList } from 'apps/admin/hooks';
import useHasInternet from 'hooks/useHasInternet';
import { BSN_SET_CLIENT_NEW_ACCESS } from 'conf';

const accountTypes = [
  { value: 'Unlimited Training', label: 'Unlimited Training (UT)' },
  { value: 'BPP', label: 'Breach Prevention Platform (BPP)' }
];

const UTproductType = [{ value: 'Unlimited Cybersecurity Training', label: 'Unlimited Cybersecurity Training' }];

const useAdminCapabilities = () => {
  const notify = useNotify();
  const { app, item } = useLocation();
  const dispatchRx = useDispatch();
  const hasInternet = useHasInternet();
  const partnerId = useId({ key: 'partnerId' });

  const [record, setRecord] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [dwba, setDwba] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDWBA, setLoadingDWBA] = useState(false);
  const [trainingsOptions, setTrainingsOptions] = useState([]);
  const [productCodeOptions, setProductCodeOptions] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [validLink, setValidLink] = useState(true);
  const [campaignUsed, setCampaignUsed] = useState(0);

  const useNoteList = useAdminList('clientNotes', item);
  const { data: productsData, isSuccess: isSuccessPd, refetch } = useDataGet({
    app: 'admin',
    tab: 'productsOptions',
    item
  });
  const { data: trainingData, isSuccess: isSuccessTd } = useDataGet({ app, tab: 'trainingFilter', item });

  const setClientData = clientData => {
    // There are several fields on clientData not needs to include in the record
    // including in record just needed info
    const data = {
      account_type: clientData.account_type,
      active: clientData.active,
      created: clientData.created,
      is_real: clientData.is_real,
      insurance: clientData.insurance,
      link: clientData.link,
      pax8_customer_id: clientData.pax8_customer_id,
      pax8_partner_id: clientData.pax8_partner_id,
      pax8_product_code: clientData.pax8_product_code,
      pax8_subscription_id: clientData.pax8_subscription_id,
      portal_client_id: clientData.portal_client_id,
      portal_partner_id: clientData.portal_partner_id,
      product_type: clientData.product_type,
      moodle_course_id: clientData.moodle_course_id,
      name: clientData.name,
      no_of_employees: clientData.no_of_employees,
      special_pricing_option: clientData.special_pricing_option,
      user_count: clientData.user_count,
      new_sra: clientData.new_sra,
      ra_consulting: clientData?.ra_consulting,
      ra_date_completed: clientData?.risk_assessment_status
    };
    setRecord(data);
    setClientInfo(data);
  };

  const getDwbaData = useCallback(() => {
    if (!isObjectEmpty(dwba)) return;
    setLoadingDWBA(true);
    adminApi
      .getDwba(item)
      .then(resp => setDwba(resp.data))
      .catch(err => notify(err?.response?.data?.description || 'Something went wrong', 'error'))
      .finally(() => setLoadingDWBA(false));
  }, [item, notify, dwba]);

  const getClientInformation = useCallback(() => {
    setLoading(true);
    dataProvider
      .getOne('clients', `information/${item}`, null)
      .then(resp => setClientData(resp.data))
      .finally(() => setLoading(false));
  }, [item, notify]);

  const getCampaignsUsed = useCallback(() => {
    dataProvider.getOne('clients', `phishingResetCampaign/${item}`, null).then(resp => setCampaignUsed(resp.data));
  }, [record]);

  useEffect(() => {
    getClientInformation();
  }, [getClientInformation]);

  useEffect(() => {
    getDwbaData();
  }, [getDwbaData]);

  useEffect(() => {
    if (trainingData?.type?.annual && isSuccessTd) {
      const trainingChoices = arrayToChoices(trainingData?.type?.annual, 'training_id', 'value');
      setTrainingsOptions(trainingChoices);
    }
  }, [isSuccessTd, trainingData]);

  useEffect(() => {
    if (isSuccessPd) {
      const currentProdType =
        record?.product_type === 'Breach Prevention Platform (BPP)' ? 'BPP' : record?.product_type;
      const codes = productsData.account_type?.[currentProdType]
        ? arrayToChoices(productsData.account_type[currentProdType], 'product_code', 'product_code')
        : [];
      setProductCodeOptions(codes);

      if (!productTypes.length) {
        setProductTypes(
          productsData.product_type.map(key => {
            return { value: key, label: key };
          })
        );
      }
    }
  }, [isSuccessPd, productsData, record?.product_type]);

  useEffect(() => {
    if (record.account_type === 'Unlimited Training') getCampaignsUsed();
  }, [record.account_type, getCampaignsUsed]);

  const dispatch = {};

  dispatch.disableSaveClientInfo = () => {
    return (
      !record?.name?.trim()?.length > 0 ||
      !validLink ||
      (record?.name === clientInfo?.name &&
        record?.active === clientInfo?.active &&
        record?.is_real === clientInfo?.is_real &&
        record?.link === clientInfo?.link)
    );
  };

  dispatch.disableSaveAccountInfo = () => {
    return (
      !record?.product_type ||
      (record?.account_type !== 'Unlimited Training' && !record?.pax8_product_code) ||
      (record?.account_type === clientInfo?.account_type &&
        record?.special_pricing_option === clientInfo?.special_pricing_option &&
        record?.product_type === clientInfo?.product_type &&
        record?.pax8_product_code === clientInfo?.pax8_product_code &&
        record?.pax8_subscription_id === clientInfo?.pax8_subscription_id &&
        record?.pax8_customer_id === clientInfo?.pax8_customer_id &&
        record?.moodle_course_id === clientInfo?.moodle_course_id)
    );
  };

  dispatch.save = () => {
    setLoading(true);
    dataProvider
      .update('clients', `information/${item}`, record)
      .then(res => {
        if (res?.code >= 400) {
          notify(res?.description, 'warning');
          return;
        }
        if (record.notes) {
          useNoteList.refetch();
          setRecord({ ...record, notes: '' });
        }
        setClientInfo(record);
        notify('The information was successfully updated', 'info');

        dataProvider.getOne('clients', `list/${partnerId}`, { _filter: `id:${String(item)}` }).then(result => {
          hasInternet(() => {
            if (result && result.data && result.data.length > 0) {
              dispatchRx({
                type: BSN_SET_CLIENT_NEW_ACCESS,
                payload: { ...result.data[0].access, admin: true }
              });
            }
          });
        });
      })
      .finally(() => setLoading(false));
  };

  dispatch.clearDwba = activity => {
    setLoadingDWBA(true);
    adminApi
      .clearDwba(item, activity)
      .then(resp => {
        setDwba(old => {
          return { ...old, ...(resp.data.data ?? {}) };
        });
        notify(resp.data.message || resp.data.status, 'info');
      })
      .catch(err => notify(err?.response?.data?.status || 'Something went wrong', 'error'))
      .finally(() => setLoadingDWBA(false));
  };

  dispatch.onChange = (name, value) => {
    setRecord({ ...record, [name]: value });
  };

  dispatch.onChangeCourse = (name, value) => {
    const { label: moodle_course_name } = trainingsOptions.find(t => t.value === value);
    setRecord({ ...record, [name]: value, moodle_course_name });
  };

  dispatch.onChangeActiveStatus = (name, value) => {
    setRecord({
      ...record,
      [name]: value,
      account_active: value
    });
  };

  dispatch.onChangeLink = (name, value) => {
    setValidLink(!value || validURL(value));
    dispatch.onChange(name, value);
  };

  dispatch.onChangePrice = checked => {
    if (checked) setOpenDialog(true);
    else dispatch.onChange('special_pricing_option', false);
  };

  dispatch.onConfirmPriceOption = () => {
    dispatch.onChange('special_pricing_option', true);
    setOpenDialog(false);
  };

  dispatch.onChangeAccountType = (name, value) => {
    setRecord({
      ...record,
      [name]: value,
      product_type: null,
      pax8_product_code: null
    });
  };

  dispatch.onChangeProductType = (name, value) => {
    const newvalues = {
      [name]: value,
      pax8_product_code: null
    };

    if (
      (value !== 'HIPAA Compliance' || value !== 'Unlimited Cybersecurity Training') &&
      record?.special_pricing_option
    ) {
      newvalues.special_pricing_option = false;
    }

    setRecord({ ...record, ...newvalues });
  };

  dispatch.setOpenDialog = value => setOpenDialog(value);

  dispatch.setOpenReset = value => setOpenReset(value);

  dispatch.onResetCampaign = () => {
    setOpenReset(false);

    dataProvider.update('clients', `phishingResetCampaign/${item}`, null).then(resp => {
      setCampaignUsed(0);
      notify(resp.data.description, 'info');
    });
  };

  dispatch.onMoveClient = ({ partnerId, partnerName }) => {
    moveClientApi
      .update({
        client_id: item,
        new_partner_id: partnerId
      })
      .then(resp => {
        getClientInformation();
        notify(resp.data?.description || 'The client has been successfully moved', 'success');
        refetch();

        // As client is being moved of partner, mask mode needs to be updated accordingly
        setStorage('maskPartnerId', partnerId, true);
        setStorage('maskPartnerName', partnerName, true);
      })
      .catch(e => {
        notify(e.response.data?.description || 'Failed to move client', 'error');
      });
  };

  dispatch.onRefetchClientInfo = () => {
    getClientInformation();
  };

  return {
    record,
    dwba,
    loading,
    loadingDWBA,
    dispatch,
    trainingsOptions,
    productCodeOptions,
    validLink,
    openDialog,
    openReset,
    useNoteList,
    accountTypes,
    UTproductType,
    productTypes,
    campaignUsed
  };
};

export default useAdminCapabilities;