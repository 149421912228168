import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_18;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const quiz = {};

quiz.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

const requestOptions = (quizId, url = '', data, cancelToken = null) => {
  return {
    url: `${baseURL}quiz/${quizId}/${url}`,
    headers: {
      Authorization: getStorage('quizToken')
    },
    cancelToken,
    data
  };
};

quiz.getQuizInfo = quizId => {
  return apiClient.get(requestOptions(quizId));
};

quiz.endQuiz = quizId => {
  return apiClient.post(requestOptions(quizId, 'end-quiz/'));
};

quiz.setNextQuestion = quizId => {
  return apiClient.post(requestOptions(quizId, 'next-question/'));
};

quiz.getQuestion = quizId => {
  return apiClient.get(requestOptions(quizId, 'questions/get-current-question/'));
};

quiz.getQuizUserState = (quizId, clientId = '') => {
  const params = {};
  if (clientId) {
    params.client_id = clientId;
  }
  const queryParams = new URLSearchParams(params).toString();
  return apiClient.get({
    url: `${baseURL}quiz-user-state/quiz/${quizId}${queryParams.length ? `?${queryParams}` : ''}`,
    headers: quiz.getRequestHeaders()
  });
};

quiz.createQuizRevision = (quiz_id, clientId = '') => {
  const params = {};
  if (clientId) {
    params.client_id = clientId;
  }
  const queryParams = new URLSearchParams(params).toString();
  return apiClient.post({
    url: `${baseURL}quiz-user-revisions/create${queryParams.length ? `?${queryParams}` : ''}`,
    headers: quiz.getRequestHeaders(),
    data: { quiz_id }
  });
};

quiz.resumeQuizRevision = revision_id => {
  return apiClient.post({
    url: `${baseURL}quiz-user-revisions/resume`,
    headers: quiz.getRequestHeaders(),
    data: { revision_id }
  });
};

quiz.getCurrentQuestion = revisionId => {
  return apiClient.get({
    url: `${baseURL}quizzes-questions/revision/${revisionId}/current-question`,
    headers: quiz.getRequestHeaders()
  });
};

quiz.setCurrentQuestion = (revisionId, question_id) => {
  return apiClient.put({
    url: `${baseURL}quizzes-questions/revision/${revisionId}/set-question`,
    headers: quiz.getRequestHeaders(),
    data: { question_id }
  });
};

quiz.nextQuestion = revisionId => {
  return apiClient.put({
    url: `${baseURL}quizzes-questions/revision/${revisionId}/next-question`,
    headers: quiz.getRequestHeaders()
  });
};

quiz.answerQuestion = (revisionId, questionId, answer_id, cancelToken) => {
  return apiClient.post({
    url: `${baseURL}quiz-user-answer/answer-question/${revisionId}/${questionId}`,
    headers: quiz.getRequestHeaders(),
    data: { answer_id },
    cancelToken
  });
};

quiz.getAttachmentUploadPost = (revisionId, filename) => {
  return apiClient.get({
    url: `${baseURL}quiz-attachments/get-attachment-upload-post/revision/${revisionId}?filename=${filename}`,
    headers: quiz.getRequestHeaders()
  });
};

quiz.uploadAttachment = (url, fields, file) => {
  const formData = new FormData();
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('key', fields.key);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('file', file);
  return apiClient.post({ url, headers: { 'Content-Type': 'multipart/form-data' }, data: formData });
};

quiz.saveAttachment = (revisionId, questionId, uploaded_filename, display_name, description) => {
  return apiClient.post({
    url: `${baseURL}quiz-attachments/question/${revisionId}/${questionId}`,
    headers: quiz.getRequestHeaders(),
    data: {
      uploaded_filename,
      display_name,
      description: description || null
    }
  });
};

quiz.deleteAttachment = (revisionId, questionId, attachment_id) => {
  return apiClient.delete({
    url: `${baseURL}quiz-attachments/question/${revisionId}/${questionId}`,
    headers: quiz.getRequestHeaders(),
    data: { attachment_id }
  });
};

quiz.deleteAttachment = (revisionId, questionId, attachment_id) => {
  return apiClient.delete({
    url: `${baseURL}quiz-attachments/question/${revisionId}/${questionId}`,
    headers: quiz.getRequestHeaders(),
    data: { attachment_id }
  });
};

quiz.endSRAQuiz = revision_id => {
  return apiClient.post({
    url: `${baseURL}quiz-user-revisions/end-quiz`,
    headers: quiz.getRequestHeaders(),
    data: { revision_id }
  });
};

export default quiz;
