import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  CloseIcon,
  Stack,
  Link,
  Box,
  CircularProgress
} from '@trustsecurenow/components-library';

const TEAMS_APP_DEPLOYMENT_INSTRUCTIONS_URL =
  'https://help.breachsecurenow.com/hc/en-us/articles/23505565890708-PII-Protect-Microsoft-Teams-Application-Setup';

const TeamsAppDeploymentInstructionsDialog = ({ state, resetModal, onSubmit }) => {
  return (
    <Dialog open={state.open} maxWidth={"md"}>
      <DialogTitle>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Typography variant="h3">PII Protect Teams App Deployment</Typography>
          {!state.loading && (
            <IconButton onClick={resetModal}>
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        {state.loading ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress shaded size={75} />
          </Box>
        ) : (
          <>
            <Typography variant="subtitle2" mb={1.5}>
              Step 1: Deploy The PII Protect Teams App
            </Typography>
            <Typography variant="body2" mb={3.25} minWidth={834}>
              <Link color="info.main" variant="link2" href={TEAMS_APP_DEPLOYMENT_INSTRUCTIONS_URL}>
                Click here
              </Link>{' '}
              to access deployment instructions. Please be aware that the PII Protect app will not <br />
              be marked as &lsquo;deployed&rsquo; on your dashboard until a user from this client accesses the app for
              the first time.
            </Typography>

            <Typography variant="subtitle2" mb={1.5}>
              Step 2: Notify your users that the PII Protect Teams app is now available
            </Typography>
            <Typography variant="body2" mb={2.5} minWidth={834}>
              Notify all users in this client that the PII Protect app is now available in Teams. This will send an
              email to all users <br /> and display a banner on their screen next time they login to the platform.
            </Typography>

            <Stack mb={5}>
              <Button width={223} color="success" onClick={onSubmit} sx={{ textTransform: 'none' }}>
                Click here to notify all users
              </Button>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

TeamsAppDeploymentInstructionsDialog.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  resetModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TeamsAppDeploymentInstructionsDialog;
