// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { Thumbnail, FilePreview } from '../common';
import { VideoPlayer } from 'components';
import { getStorage, decodeId, encodeId } from 'helpers';
import newsfeed from 'helpers/apis/newsfeed';
import { updateFeed } from 'helpers/action';
import { getAssetUrl } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px'
    }
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  videoContainer: {
    width: '100%'
  },
  videoLoading: {
    textAlign: 'center',
    width: '100%',
    '& img': {
      maxWidth: 173,
      width: '100%',
      height: 'auto',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 143
      }
    },
    '& p': {
      fontSize: 16,
      color: 'var(--colorDefault)',
      margin: 0
    }
  }
}));

const FeedContent = (props) => {
  const { content, fileIsReady, postId, currPlayingVideoPostId, setCurrPlayingVideoPostId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const contentData = content?.data;
  const contentType = content?.type;
  const user = { id: decodeId(getStorage('userId')) };  
  const [playVideo, setPlayVideo] = useState(false)

  useEffect(() => {
    let interval;
    if (contentType === 'video' && !fileIsReady) {
      const post_id = encodeId(postId);
      let i = 0;
      interval = setInterval(() => {
        if (i < 15) {
          i++;
          newsfeed
            .getFeedSingle(user.id, post_id)
            .then(res => {
              const post = res?.data[0];
              if (post?.file_ready) {
                dispatch(updateFeed(post.id, { ...post }));
                clearInterval(interval);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      }, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fileIsReady]);

  useEffect(() => {
    if (currPlayingVideoPostId) {
      if (postId !== currPlayingVideoPostId) setPlayVideo(false);
      else setPlayVideo(true);
    }
  }, [currPlayingVideoPostId, postId]);


  return (
    <Grid container item xs={12} className={classes.contentRoot}>
      {{
        image: (
          <div>
            <img className={classes.image} src={getAssetUrl(contentData.url)} alt="post" />
          </div>
        ),
        video:
          fileIsReady ? (
            <div className={classes.videoContainer}>
              <VideoPlayer
                src={`https://videodelivery.net/${contentData.video_id}/manifest/video.m3u8?clientBandwidthHint=4.9`}
                poster={contentData.video_thumbnail}
                controls={true}
                playing={playVideo}
                onPlay={()=> {
                  setCurrPlayingVideoPostId(postId);
                }}
                width="100%"
                height="auto"
              />
            </div>
          ) : (
            <Box className={classes.videoLoading} py={{ xs: 3, md: 7 }} px={{ xs: 2, md: 3 }}>
              <img src='/media/videoLoading.svg' alt='Video loading' />
              <p>Wait a moment to view, the video is loading...</p>
            </Box>
          ),
        thumbnail: <Thumbnail data={contentData} />
      }[contentType] || (
        <div>
          <Box fontWeight={500} mb={1.5}>
            Attachment List (1)
          </Box>
          <FilePreview url={getAssetUrl(contentData.url)} filename={contentData.filename.replace(/.*?_/, '')} />
        </div>
      )}
    </Grid>
  );
};

export default FeedContent;
