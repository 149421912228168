/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack,
  Select,
  MenuItem,
  Divider,
  Button,
  AddIcon,
  GridToolbarQuickFilter,
  Tooltip,
  Box
} from '@trustsecurenow/components-library';
import { TABLE_VIEWS } from './constants';
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro';
import { Link as RouterLink } from 'react-router-dom';
import BulkActionsMenu from './BulkActionsMenu';

const Toolbar = ({
  tableView,
  visibleBulkActions,
  selectedRowsObj,
  setModalState,
  partnerId,
  isEBPP,
  isFetching,
  onChangeTableView
}) => {
  return (
    <Box>
      <Stack p={2.5} direction="row" gap={1} flexWrap="wrap">
        <Tooltip title="Click here to switch columns and explore different statistics below." placement="top">
          <Select
            value={tableView}
            onChange={onChangeTableView}
            sx={{
              width: 305
            }}
            disabled={isFetching}
          >
            {Object.entries(TABLE_VIEWS).map(([viewKey, viewSettings]) => (
              <MenuItem key={viewKey} value={viewKey}>
                {viewSettings.name}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
        <GridToolbarColumnsButton />
        {Boolean(isEBPP) && (
          <BulkActionsMenu
            visibleBulkActions={visibleBulkActions}
            selectedRowsObj={selectedRowsObj}
            setModalState={setModalState}
            partnerId={partnerId}
          />
        )}
        <Button component={RouterLink} to="/clients/create" startIcon={<AddIcon />}>
          New Client
        </Button>
        <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
      </Stack>
      <Divider />
    </Box>
  );
};

export default React.memo(Toolbar);
