// eslint-disable-next-line no-underscore-dangle
const _1 = process.env.REACT_APP_BASE_URL_1;
// eslint-disable-next-line no-underscore-dangle

// eslint-disable-next-line no-underscore-dangle
const _2 = process.env.REACT_APP_BASE_URL_2;

// eslint-disable-next-line no-underscore-dangle
const _3 = process.env.REACT_APP_BASE_URL_3;

// eslint-disable-next-line no-underscore-dangle
const _4 = process.env.REACT_APP_BASE_URL_4;

// eslint-disable-next-line no-underscore-dangle
const _5 = process.env.REACT_APP_BASE_URL_5;

// eslint-disable-next-line no-underscore-dangle
const _6 = process.env.REACT_APP_BASE_URL_6;

// eslint-disable-next-line no-underscore-dangle
const _7 = process.env.REACT_APP_BASE_URL_7;

// eslint-disable-next-line no-underscore-dangle
const _8 = process.env.REACT_APP_BASE_URL_8;

// eslint-disable-next-line no-underscore-dangle
const _9 = process.env.REACT_APP_BASE_URL_9;

// eslint-disable-next-line no-underscore-dangle
const _10 = process.env.REACT_APP_BASE_URL_10;

// eslint-disable-next-line no-underscore-dangle
const _11 = process.env.REACT_APP_BASE_URL_11;

// eslint-disable-next-line no-underscore-dangle
const _12 = process.env.REACT_APP_BASE_URL_12;

// eslint-disable-next-line no-underscore-dangle
const _13 = process.env.REACT_APP_BASE_URL_13;

// eslint-disable-next-line no-underscore-dangle
const _14 = process.env.REACT_APP_BASE_URL_14;

// eslint-disable-next-line no-underscore-dangle
const _15 = process.env.REACT_APP_BASE_URL_15;

// eslint-disable-next-line no-underscore-dangle
const _16 = process.env.REACT_APP_BASE_URL_16;

// eslint-disable-next-line no-underscore-dangle
const _17 = process.env.REACT_APP_BASE_URL_17;

// eslint-disable-next-line no-underscore-dangle
const _18 = process.env.REACT_APP_BASE_URL_18;

// eslint-disable-next-line no-underscore-dangle
const _19 = process.env.REACT_APP_BASE_URL_19;

// eslint-disable-next-line no-underscore-dangle
const _20 = process.env.REACT_APP_BASE_URL_20;

// eslint-disable-next-line no-underscore-dangle
const _21 = process.env.REACT_APP_BASE_URL_21;

const subdomains = {
  myDashboard: {
    default: _1,
    microTrainings: _2,
    microTrainingsQuiz: _2,
    DashboardQuizModal: _2,
    DashboardQuizModal_v2: _2,
    isQuizSubmitted: _2,
    DashboardQuizModalAddMark: _2,
    DashboardCertificateModal: _2,
    trainingCertificate: _2,
    get_signed_cookie: _2,
    trainingDetails: _2,
    ssla: _6
  },
  myCompany: {
    default: _1,
    darkWebMonitoring: _9,
    domainsinfo: _9,
    track: _16,
    users: _5,
    trainingFilter: _16,
    disasterRecovery: _1,
    disasterRecoveryInfo: _1,
    documentsBusinessAssociateAgreement: _1,
    documentsBusinessAssociateAgreementInfo: _1,
    documentsServiceProvider: _1,
    documentsServiceProviderInfo: _1,
    documentsServiceProviderRelationships: _1,
    documentsTemplates: _1,
    individualEssRanking: _1,
    otherDocuments: _1,
    otherDocumentsInfo: _1,
    otherPolicies: _1,
    otherPolicyInfo: _1,
    policies: _1,
    policyInfo: _1,
    presignedUpload: _1
  },
  clients: {
    // default: _3,
    clientsTrainingCertificateViewModal: _2,
    templateCountryList: _15,
    templateDifficultyList: _15,
    domainsinfo: _9,
    information: _16,
    access: _16,
    alerts: _9,
    actions: _16,
    annualTrainingReport: _16,
    company: _16,
    customMessage: _16,
    customMessageToggle: _16,
    complianceDashboard: _1,
    'directorySync-csvBulkDownload': _16,
    directorySync: _16,
    dashboard: _1,
    documents: _1,
    enableNewUi: _16,
    list: _16,
    microTrainingReport: _16,
    productsType: _16,
    sendAutomated: _16,
    testcustomMessage: _16,
    track: _16,
    trackSecurityIncidents: _16,
    trackSecurityIncidentsInfo: _16,
    trackServerRoomAccess: _16,
    trackServerRoomAccessInfo: _16,
    trainingAnnual: _16,
    trainingFilter: _16,
    trainingTrainings: _16,
    trainingTrainingsUsers: _16,
    trainingUsers: _16,
    trainingUsersTrainings: _16,
    policyType: _1,
    DarkWebReport: _9,
    blacklistedDomainsList: _9,
    darkWebMonitoring: _9,
    add: _20,
    products: _20,
    purchaseDWMLicense: _20,
    productsOptions: _20,
    usersListActions: _5,
    addUser: _5,
    resetMFA: _5,
    resetPassword: _5,
    tags: _5,
    user: _5,
    userGroups: _5,
    // the below property is used for the endpoint
    // users/mfa_and_strong_password_status
    users: _5,
    'users-mfa_and_strong_password_status': _5,
    usersList: _5,
    moveUsers: _5,
    autoPhishCampaign: _15,
    autoPhishCampaigns: _15,
    createPhishingCampaign: _15,
    phishingCampaignRecipientsList: _15,
    phishingCampaignRecipientsListFilters: _15,
    phishingCampaignReport: _15,
    phishingCampaignScenariosList: _15,
    phishingFilter: _15,
    phishingReports: _15,
    phishingReportsCampaign: _15,
    phishingReportsRecipients: _15,
    phishingResetCampaign: _16,
    scenarioPreview: _15,
    whitelistInstructions: _15,
    // refactored myCompany subdomains
    disasterRecovery: _1,
    disasterRecoveryInfo: _1,
    documentsBusinessAssociateAgreement: _1,
    documentsBusinessAssociateAgreementInfo: _1,
    documentsServiceProvider: _1,
    documentsServiceProviderInfo: _1,
    documentsServiceProviderRelationships: _1,
    documentsTemplates: _1,
    individualEssRanking: _1,
    otherDocuments: _1,
    otherDocumentsInfo: _1,
    otherPolicies: _1,
    otherPolicyInfo: _1,
    policies: _1,
    policyInfo: _1,
    presignedUpload: _1
    // end of refactored myCompany subdomains
  },
  newsfeed: {},
  contentadmin: {},
  auditlog: {},
  reports: {},
  dam: {},
  partnerProfile: {
    GetAllTmss: _7,
    PickList: _7,
    customMessages: _16,
    billingStatement: _14,
    taxExempt: _14,
    billing: _14,
    payment: _14,
    customProfile: _19,
    information: _19,
    notificationEmails: _19,
    presignedUpload: _19
  },
  getStarted: {
    default: _19
  },
  user: {
    authorize: _4,
    authorize2: _4,
    login: _4,
    default: _5
  },
  admin: {
    default: _19,
    billingStatement: _14,
    productsOptions: _20,
    darkWeb: _9
  },
  instantScan: {
    default: _9
  }
};

// const env = process.env.REACT_APP_BUILD_ENV;
// const base = process.env.REACT_APP_BASE_URL; // execute-api.us-east-1.amazonaws.com

// const getURL = (serviceName) => `https://${serviceName}.${base}/${env}`;
// const getNewURL = (serviceName) => `https://${env}.dev.api.pii-protect.com/${serviceName}`;

// const getServiceName = ({app, tab}) => {
//   const serviceName = subdomains[app][tab]
//   return getURL(serviceName)
// }

export default subdomains;
