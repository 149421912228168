import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${api}.${base}/${env}`;

const userInfo = {};

userInfo.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

userInfo.getData = fields => {
  const requestOptions = {
    url: `${baseURL}/user/get_user_data?fields=${fields.join(',')}`,
    headers: userInfo.getHeaders()
  };
  return apiClient.get(requestOptions);
};

userInfo.getConfig = () => {
  const requestOptions = {
    url: `${baseURL}/user/config`,
    headers: userInfo.getHeaders()
  };
  return apiClient.get(requestOptions);
};

export default userInfo;
