import React, { useRef, useState } from 'react';
import Controller from './Controller';
import Cropper from 'react-cropper';
import { Box, Button, IconButton } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import { throttle } from 'lodash';
// import { LazyIcon } from '../icons';
import { Container, Typography, LazyIcon } from 'components';
import type { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';

const MAX_IMG_SIZE = 10485760; // 10MB

const DangerText = styled(Typography.p)`
  && {
    font-size: 1rem;
    color: var(--colorSystemDanger);
    margin: 8px 0 8px 14px;
  }
`;

export const DragZone: ComponentType<*> = styled(Box)`
  border: calc(var(--borderSize) * 2) dashed var(--colorSystemInfo);
  border-radius: calc(var(--borderSize) * 10);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  outline: none !important;

  ${({ error }) =>
    error &&
    css`
      border-color: var(--colorSystemDanger);
    `}
`;

export const BoxContainer: ComponentType<*> = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const BoxImage: ComponentType<*> = styled(Box)`
  overflow: hidden;
  & > img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    max-width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;

export const BoxDescription: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h6,
  p {
    margin: 0;
    padding-bottom: 8px;
  }
`;

export const Figure: ComponentType<*> = styled.figure`
  margin: 0;
  & > img {
    max-height: 100px;
    max-width: 200px;
    width: auto;
    height: auto;
  }
`;

export const AttachmentInfo: ComponentType<*> = styled.span`
  color: var(--colorDefault);
  padding-left: var(--spacing);
`;

const Title = styled(Typography.h6)`
  color: var(--colorSystemInfo);
  ${({ error }) =>
    error &&
    css`
      color: var(--colorSystemDanger);
    `}
`;

const Description = styled(Typography.p)`
  ${({ error }) =>
    error &&
    css`
      color: var(--colorSystemDanger);
    `}
`;

const Label = styled(Typography.h5)`
  ${({ error }) =>
    error &&
    css`
      color: var(--colorSystemDanger);
    `}
`;

const AttachmentTitle = styled.span`
  color: var(--colorSystemInfo);
  font-size: 14px;
`;

export const InfoText = styled.span`
  font-weight: 600;
  text-decoration: underline;
  color: var(--colorDark);
`;

const dataURLtoFile = async (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];

  const res = await fetch(dataUrl);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mime });
};

const DragDropLogoWrapper = ({ name, logo, label, error, helperText, fileName, onChange, setValue }) => {
  const notify = useNotify();
  const [cropper, setCropper] = useState({});
  const [preview, setPreview] = useState(false);
  const [f, setF] = useState([{ preview: null, name: fileName }]);
  const crop = useRef();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    disabled: (preview && f[0].preview !== null) || logo,
    onDrop: acceptedFiles => {
      const newFile = acceptedFiles[0];
      const fileSize = newFile?.size;
      if (!newFile) {
        return notify('This type of file is not allowed. Please use only .png, .jpg, .jpeg files', 'warning');
      }
      if (fileSize > MAX_IMG_SIZE) {
        return notify(
          'This image exceeds the 10MB maximum image size. Please resize the image and try again',
          'warning',
          undefined,
          false,
          6000
        );
      }
      setF(
        acceptedFiles.map(file => {
          setPreview(true);
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );
    }
  });

  const onClick = async () => {
    const canvas = crop.current.getCroppedCanvas();

    const file = await dataURLtoFile(canvas.toDataURL(), 'cropped.jpg');

    if (onChange) {
      onChange({
        name,
        value: canvas.toDataURL(),
        file
      });
    } else {
      setValue && setValue(name);
    }

    setPreview(false);
  };

  const getLogoFileName = logoData => {
    if (logoData) {
      return logoData.substring(logoData.lastIndexOf('/') + 1).split('?')[0];
    }
  };

  return (
    <Container.Grid direction="column">
      {label && (
        <Label error={error} mt={0.4} mb={1}>
          {label}
        </Label>
      )}
      <DragZone error={error} display="flex" alignItems="center" p={3} {...getRootProps()}>
        {preview && f[0].preview !== null ? (
          f.map(file => (
            <BoxContainer key={file.name}>
              <BoxImage>
                <Cropper
                  ref={crop}
                  src={file.preview}
                  style={{ height: 200, width: 200 }}
                  initialAspectRatio={16 / 9}
                  guides={false}
                  onInitialized={setCropper}
                  crop={throttle(setCropper, 500)}
                  value={cropper}
                />
                <Button color="primary" onClick={onClick}>
                  Crop and Save
                </Button>
              </BoxImage>
            </BoxContainer>
          ))
        ) : (
          <>
            {logo ? (
              <Figure>
                <img src={logo} alt={label} />
              </Figure>
            ) : (
              <>
                <input {...getInputProps()} />
                <LazyIcon
                  component="DragDrop"
                  mr={3}
                  size={2.3}
                  color={error ? 'colorSystemDanger' : 'colorSystemInfo'}
                />
                <BoxDescription>
                  <Title error={error}>Update Logo</Title>
                  <Description error={error} fontSize={13}>
                    Drag &amp; drop here or <InfoText>browse</InfoText>
                  </Description>
                </BoxDescription>
              </>
            )}
          </>
        )}
      </DragZone>
      {logo ? (
        <Container.Grid container>
          <Container.Grid item justify="space-between" direction="row" alignItems="center" pt={2}>
            <Typography.p color="colorBox" fontSize={10} variant="subtitle1" paragraph mr={2}>
              <AttachmentTitle>Current attachment:</AttachmentTitle>
              <AttachmentInfo>{f[0].name || getLogoFileName()}</AttachmentInfo>
            </Typography.p>
            <IconButton onClick={() => onChange({ name, value: null })}>
              <LazyIcon component="Delete" color="colorDefault" />
            </IconButton>
          </Container.Grid>
        </Container.Grid>
      ) : (
        <>
          {error ? (
            <DangerText>{helperText}</DangerText>
          ) : (
            <>
              <Typography.p fontSize={10} mb={0.01} variant="subtitle1" paragraph>
                Logo should be 200px x 100px / No Larger than 10MB
              </Typography.p>
              <Typography.p fontSize={10} mt={0.01} variant="subtitle1" paragraph>
                Only .png, .jpg, .jpeg files will be accepted / Transparent or white background
              </Typography.p>
            </>
          )}
        </>
      )}
    </Container.Grid>
  );
};

const DragDropLogo = ({ onChange, rules, required, name, value, ...props }) => {
  let formContext = {};

  // when we use DragDropLogo outside of Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { setValue, watch } = formContext;

  if (!formContextData) {
    const logo = value?.preview;
    const fileName = value?.fileName;
    return <DragDropLogoWrapper {...props} logo={logo} fileName={fileName} onChange={onChange} name={name} />;
  }

  const dragDropLogoValue = watch(name);

  const logo = dragDropLogoValue?.preview;
  const fileName = dragDropLogoValue?.fileName;

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => {
        const isError = error && !(dragDropLogoValue && dragDropLogoValue.file);
        return (
          <DragDropLogoWrapper
            {...props}
            logo={logo}
            fileName={fileName}
            error={isError}
            helperText={error?.message}
            onChange={onChange}
            name={name}
            setValue={setValue}
          />
        );
      }}
    />
  );
};

export default DragDropLogo;
