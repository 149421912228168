import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const envPrepend = env === 'prod' ? '' : `${env}.`;
const baseURL = `https://${envPrepend}api.pii-protect.com/bsnclientservices`;

const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_16;
const api2 = process.env.REACT_APP_BASE_URL_5;
const baseURL2 = `https://${api}.${base}/${env}`;
const baseURL3 = `https://${api2}.${base}/${env}`;

const clients = {};
const DEFAULT_CONTENT_TYPE = 'application/json';

clients.getRequestHeaders = (includeAuthorization = true, contentType = DEFAULT_CONTENT_TYPE) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
  };
};

clients.getClientsList = async ({ partnerId, ...props }) => {
  const requestOptions = {
    url: `${baseURL}/clients/list/${partnerId}`,
    headers: clients.getRequestHeaders(),
    ...props
  };
  return apiClient.get(requestOptions);
};

clients.activateDeactivateClient = async ({ clientId, ...props }) => {
  const requestOptions = {
    url: `${baseURL2}/clients/actions/${clientId}`,
    headers: clients.getRequestHeaders(),
    ...props
  };

  return apiClient.post(requestOptions);
};

clients.handleClientBulkAction = async (partnerId, { ...props }) => {
  const requestOptions = {
    url: `${baseURL}/clients/bulk-actions/${partnerId}`,
    headers: clients.getRequestHeaders(),
    ...props
  };
  return apiClient.post(requestOptions);
};

clients.deleteClient = async ({ clientId }) => {
  const requestOptions = {
    url: `${baseURL2}/clients/actions/${clientId}`,
    headers: clients.getRequestHeaders()
  };
  return apiClient.delete(requestOptions);
};

clients.enableDisableWelcomeMessage = async ({ clientId, data }) => {
  const requestOptions = {
    url: `${baseURL2}/clients/sendAutomated/${clientId}`,
    headers: clients.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

clients.manageClientActions = (id, data, withModifiedUsers = false, params) => {
  const requestOptions = {
    url: `${withModifiedUsers ? baseURL3 : baseURL}/clients/${
      withModifiedUsers ? 'usersListActions' : 'bulk-actions'
    }/${id}`,
    data,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.post(requestOptions);
};

clients.deleteBulkClient = (partnerId, data, params) => {
  const requestOptions = {
    url: `${baseURL}/clients/bulk-actions/${partnerId}`,
    data,
    headers: clients.getRequestHeaders(true),
    params
  };
  return apiClient.delete(requestOptions);
};

clients.applyClientAction = ({ clientId, action, systemEmailsEnabled }) => {
  const requestOptions = {
    url: `${baseURL}/clients/actions/${clientId}`,
    headers: clients.getRequestHeaders(),
    data: {
      type: action,
      system_emails_status: systemEmailsEnabled
    }
  };
  return apiClient.post(requestOptions);
};

clients.getMTNLData = (id, ...props) => {
  const requestOptions = {
    url: `${baseURL}/clients/bulk-actions/mt-newsletter-engagement-status/${id}`,
    headers: clients.getRequestHeaders(true),
    ...props
  };
  return apiClient.get(requestOptions);
};

clients.enableNewsLetters = (clientId, data) => {
  const requestOptions = {
    url: `${baseURL2}/clients/information/options/${clientId}`,
    headers: clients.getRequestHeaders(true),
    data
  };
  return apiClient.patch(requestOptions);
};

clients.getWelcomeMsgInfo = (id, ...props) => {
  const requestOptions = {
    url: `${baseURL}/clients/directorySync/${id}`,
    headers: clients.getRequestHeaders(true),
    ...props
  };
  return apiClient.get(requestOptions);
};

clients.sendTeamsAppAnnouncement = (clientId, usersList = null) => {
  const requestOptions = {
    url: `${baseURL}/clients/teams-app/notification/${clientId}`,
    headers: clients.getRequestHeaders(),
  };

  if (usersList !== null) {
    requestOptions.data = { selected_users: usersList } 
  }
  return apiClient.post(requestOptions);
};

clients.getTeamsAppUnregisteredUsers = clientId => {
  const requestOptions = {
    url: `${baseURL}/clients/teams-app/unregistered-users/${clientId}`,
    headers: clients.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
};


export default clients;