import React, { useState } from 'react';
import {
  RHFTextField as TextField,
  RHFDragZone as DragZone,
  RHFDragDropLogo as DragDropLogo,
  RichTextEditorController,
  ImportEmail
} from 'components';
import { useFormContext, useWatch } from 'react-hook-form';
import contentAdmin from 'helpers/apis/contentAdmin';
import { useNotify } from 'react-admin';
import { Stack, Typography, getRichTextEdiorConfig, useTheme } from '@trustsecurenow/components-library';
import { updateMutable, updateNestedMutable } from 'utils/update';
import AttachmentList from '../shared/AttachmentList';
import { useContentAdminFormContext } from '../../context';
import { ContentAdminFormGrid, ContentAdminFormRow, ImportButton } from '../../style';

const defaultConfig = getRichTextEdiorConfig();

const disabledRichTextEditorButtons = ['video', 'eraser', 'print', 'copyformat', 'hr', 'fullsize', 'font', 'table'];

const EmailTemplateForm = () => {
  const theme = useTheme();
  const { setValue, control } = useFormContext();

  const { dispatch, bindingParams } = useContentAdminFormContext();
  const notify = useNotify();

  const [fileData, setFileData] = useState('');
  const [disabled, setDisabled] = useState(false);

  const attachments = useWatch({ control, name: 'attachments' });

  const readFile = e => {
    setFileData('');
    setDisabled(true);
    dispatch.disabled(true);
    const file = e.target.files[0];
    const fileType = file.name.match(/[^\.][a-z]+$/)[0];
    const reader = new FileReader();
    let fileValue;

    reader.addEventListener(
      'load',
      async function() {
        fileValue = reader.result.split(',')[1];
        let templateData;
        try {
          templateData = await contentAdmin.parseEmailTemplate({
            file_type: fileType,
            template: fileValue
          });
        } catch (e) {
          if (e?.response?.data?.error) {
            notify(e?.response?.data.error.file_type, 'warning');
          } else {
            notify('Something went wrong', 'warning');
          }
        } finally {
          setDisabled(false);
          dispatch.disabled(false);
        }

        if (templateData) {
          const {
            data: { attachments, body, subject }
          } = templateData;

          setValue('email_subject', subject, { shouldValidate: true });
          setValue('email_html', body);
          setValue(
            'attachments',
            attachments.map(({ filename, attachment, ...params }) => {
              const blob = new Blob([attachment]);
              const file = new File([blob], filename, {
                type: 'text/plain'
              });
              return { file_display_name: filename, isNew: true, attachment: file, ...params };
            })
          );
          setDisabled(false);
          dispatch.disabled(false);
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadLogo = ({ name, value, file }) => {
    setValue(
      name,
      value && {
        preview: value,
        file,
        isNew: true
      }
    );
  };

  const onFileUpload = files => {
    const newFilesData = [
      ...attachments,
      ...files.map(file => {
        return {
          file_display_name: file.name,
          attachment: file,
          isNew: true
        };
      })
    ];
    setValue('attachments', newFilesData);
  };

  const onRemoveFile = (name, index) => {
    if (attachments?.length) {
      const newValues = attachments.filter((item, attachmentIndex) => attachmentIndex !== index);
      setValue('attachments', newValues);
    }
  };

  const atValues = React.useMemo(
    () =>
      bindingParams.map(f => {
        return {
          title: f
            .replace(/_/g, ' ')
            .split(' ')
            .map(word => {
              return word[0].toUpperCase() + word.substring(1);
            })
            .join(' '),
          value: `{{${f}}}`
        };
      }),
    [bindingParams]
  );

  const attachmentsData = attachments.length
    ? attachments.map(item => item?.attachment?.name || item.file_display_name)
    : [];

  const richTextConfig = React.useMemo(() => {
    const config = updateMutable(defaultConfig, ['autocomplete', 'sources', 1], atValues ?? []);
    config.iframe = false;
    config.uploader.insertImageAsBase64URI = false;
    config.buttons = config.buttons.filter(v => !disabledRichTextEditorButtons.includes(v));
    config.toolbarSticky = false;
    config.allowResizeY = true;
    config.maxHeight = undefined;
    config.maxWidth = '100%';
    config.style = { color: theme.palette.text.primary };
    return updateNestedMutable(config, { link: { openInNewTabCheckbox: false, noFollowCheckbox: false } });
  }, [theme.palette.text.primary, atValues]);

  return (
    <>
      <ContentAdminFormRow alignItems="flex-start">
        <ContentAdminFormGrid md={4}>
          <TextField label="Template Name" name="name" fullWidth required />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <TextField label="Email Subject" name="email_subject" fullWidth required />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={2} py={0.8}>
          <ImportButton htmlFor="import-email" disabled={disabled}>
            <input
              accept=".msg, .eml"
              id="import-email"
              type="file"
              onChange={readFile}
              value={fileData}
              hidden
              disabled={disabled}
            />
            <ImportEmail />
            Import Email
          </ImportButton>
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid item lg={4} md={6} sm={12}>
          <DragZone
            title="Attachments"
            fileType=""
            name="attachments"
            size={12}
            type="file"
            multiple
            onChange={onFileUpload}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid item lg={12} md={12} sm={12}>
          <AttachmentList data={attachmentsData} onRemoveFile={onRemoveFile} />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid lg={4} md={12} sm={12}>
          <DragDropLogo label="Update Logo" name="logo" onChange={uploadLogo} required />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid xs={12}>
          <Stack spacing={2.5} width="100%">
            <Typography variant="h4">Plain text</Typography>
            <RichTextEditorController required name="email_html" config={richTextConfig} />
          </Stack>
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
    </>
  );
};

export default EmailTemplateForm;
