import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemSecondaryAction, ListItemText, IconButton } from '@material-ui/core';
import { Delete } from 'components';
import { Typography } from 'components';
import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 372,
    backgroundColor: theme.palette.background.paper
  },
  item: {
    marginBottom: 7,
    paddingLeft: 0
  },
  deleteIcon: {
    fontSize: '1.3em'
  },
  listText: {
    '& > span': {
      textOverflow: "ellipsis",
      overflow: "hidden"
    }
  }
}));

const CurrentAttachmentTitle = styled(Typography.h5)`
  color: var(--colorSystemInfo);
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
  font-weight: 400;
`

export default function AttachmentList({ data, onRemoveFile }) {
  const classes = useStyles();

  const handleToggle = (value, index) => () => {
    onRemoveFile(value, index);
  };

  return (
    <div>
      {data?.length ? (
        <>
        <CurrentAttachmentTitle>Current Attachment:</CurrentAttachmentTitle>
        <List className={classes.root}>
          {
            data.map((value, index) => {

              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem key={`${value}-${index}`} role={undefined} dense button className={classes.item}>
                  <ListItemText id={labelId} primary={`${value}`} className={classes.listText} />
                  <ListItemSecondaryAction onClick={handleToggle(value, index)}>
                    <IconButton edge="end" aria-label="delete">
                      <Delete className={classes.deleteIcon} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>
        </>

      ) : (
        <></>
      )}
    </div>
  );
}
