import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNotify } from 'ra-core';
import axios from 'axios';
import { useApp } from 'helpers';
import { useLocation, useId } from 'hooks';
import useHasInternet from 'hooks/useHasInternet';
import dashboard from 'helpers/apis/Dashboard/dashboard';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import WorkPlanToolbar from './WorkPlanToolbar';
import TableListModal from '../../tablelist/TableListModal';

const WorkPlan = ({ app, isLegacyDocuments }) => {
  const activeTabType = useSelector(rxState => rxState.bsn.system.activeTabType);
  const [value, setValue] = useState(activeTabType || 'sraDocuments');
  const { tab: tabLoc, item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const { dispatch } = useApp();
  const [documents, setDocuments] = useState([]);
  const notify = useNotify();
  const hasInternet = useHasInternet();
  const cancelTokenSource = useRef();
  const preferences = useMemo(() => userPreferences.getTableSettings(app, value), [app, value]);
  const [error, setError] = useState({
    exists: false,
    msg: ''
  });
  const [tableControls, setTableControls] = useState({
    page: 0,
    perPage: preferences.pagination || 25,
    total: 0,
    order: preferences.order || 'asc',
    orderBy: preferences.orderBy || 'name',
    isLoading: false
  });
  const settings = useSelector(state => state.bsn.tables[app][value]);
  const newSettings = useMemo(() => {
    return tabLoc === 'sra' ? { ...settings, actions: [], selecting: false } : settings;
  }, [tabLoc, settings]);

  useEffect(() => {
    // getting new pereferences when user changes the tab
    // which is equal to the value
    setTableControls(prev => {
      return {
        ...prev,
        page: 0,
        perPage: preferences.pagination || 25,
        total: 0,
        order: preferences.order || 'asc',
        orderBy: preferences.orderBy || 'name'
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  const fetchDocuments = useCallback(
    // eslint-disable-next-line no-shadow
    async ({ documentType, app, id, filters, sort, pagination, onSuccess, onError, cancelToken }) => {
      /**
       * used here to fetch documents of type
       * sraDocuments & workPlan
       */
      dashboard
        .getDocuments({ app, documentType, id, filters, sort, pagination, cancelToken })
        .then(onSuccess)
        .catch(onError);
    },
    []
  );

  const fetchDocumentsEffects = useCallback(() => {
    if (value !== null && value && value.length > 0) {
      setTableControls(prev => {
        return { ...prev, isLoading: true };
      });
      setError({
        exists: false,
        msg: ''
      });
      const onSuccess = (response, policyType) => {
        hasInternet(() => {
          setError({
            exists: false,
            msg: ''
          });
          setTableControls(old => {
            return { ...old, total: response.headers['x-total-count'], isLoading: false };
          });
          if (response.data.length === 0) {
            setDocuments([]);
          } else {
            setDocuments(response.data);
          }
        });
      };
      // eslint-disable-next-line no-shadow
      const onError = error => {
        if (axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log('Request canceled', error.message);
        } else {
          setTableControls(prev => {
            return { ...prev, isLoading: false };
          });
          setError({
            exists: true,
            msg: error?.response?.data?.description || error?.response?.data?.message || 'Fetching documents went wrong'
          });
          notify(
            error?.response?.data?.description || error?.response?.data?.message || 'Fetching documents went wrong',
            'error'
          );
        }
      };

      /** cancelling previous not answered requests */
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('cancelling an old api call');
      }

      cancelTokenSource.current = axios.CancelToken.source();
      fetchDocuments({
        documentType: value,
        app,
        id: setId,
        filters: {},
        sort: { field: tableControls.orderBy, order: tableControls.order },
        pagination: {
          page: tableControls.page * tableControls.perPage,
          perPage: (tableControls.page + 1) * tableControls.perPage
        },
        onSuccess,
        onError,
        cancelToken: cancelTokenSource.current.token
      });
    }
  }, [
    value,
    fetchDocuments,
    app,
    setId,
    tableControls.orderBy,
    tableControls.order,
    tableControls.page,
    tableControls.perPage,
    hasInternet,
    notify
  ]);

  useEffect(() => {
    fetchDocumentsEffects();
  }, [fetchDocumentsEffects]);

  useEffect(() => {
    return () => {
      dispatch.set('system', 'activeTabType', '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableOptions = {
    refetchOnWindowFocus: false
  };

  return (
    <TableListModal
      tab={value}
      id={setId}
      app={app}
      tableOptions={tableOptions}
      tableSettings={newSettings}
      toolbar={
        <WorkPlanToolbar
          id={setId}
          dispatchSetValue={setValue}
          value={value}
          refetchOnCreate={() => fetchDocumentsEffects()}
          allowCreate={!isLegacyDocuments}
        />
      }
      resetTable={value}
      refetchOnUpdate={() => fetchDocumentsEffects()}
      data={{
        data: documents,
        page: tableControls.page,
        prePage: tableControls.perPage,
        total: tableControls.total,
        isError: error.exists,
        isFetching: tableControls.isLoading,
        isLoading: tableControls.isLoading,
        // isPreviousData: false,
        order: tableControls.order,
        orderBy: tableControls.orderBy,
        status: error.exists ? 'error' : 'success',
        dispatch: {
          // empty methods for the table
          // to use when when some actions triggered
          // just to prevent errors
          refetch: () => {},
          // if provided, will be fired only when user deletes items from the table
          refetchOnDeletion: () => {
            fetchDocumentsEffects();
          },
          setOrder: order => {
            setTableControls(old => {
              return { ...old, order };
            });
          },
          setOrderBy: orderBy => {
            setTableControls(old => {
              return { ...old, orderBy };
            });
          },
          setPage: page => {
            setTableControls(old => {
              return { ...old, page };
            });
          },
          setPrePage: perPage => {
            setTableControls(old => {
              return { ...old, perPage, page: 0 };
            });
          }
        }
      }}
    />
  );
};

WorkPlan.defaultProps = {
  isLegacyDocuments: false
};

WorkPlan.propTypes = {
  app: PropTypes.string.isRequired,
  isLegacyDocuments: PropTypes.bool
};

export default WorkPlan;
