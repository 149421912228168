import React, { useReducer, useRef, useState } from 'react';
import { Button, LazyIcon, Container, TextField } from 'components';
import { Box, MenuItem, Paper, ClickAwayListener, MenuList, Grow } from '@material-ui/core';
import { ArrowIcon, CloseFilter, ContainerSearch, ContainerMenuFilters, ListItemText } from './filterStyles';
import FilterActive from './FilterActive';


const initialState = {
  filters: {}, // mounted using filtersConfig object for control state of filters E.g { [filter1]: { active, value }, [filter2]: { active, value }, ...}
  anchor: null,
  filterSearch: '', //current filter selected for search
};

const AdvancedFilter = ({ searchComponent, filterBtnComponent, filtersConfig, setFilters, customGrid }) => {
  const initialFilters = {};
  Object.keys(filtersConfig).map((k) => (initialFilters[k] = { active: false, value: '' }));
  const [state, setState] = useReducer(reducer, { ...initialState, filters: initialFilters });
  const ref = useRef(null);
  const allFiltersAdded = Object.keys(state.filters).filter(item => !state.filters[item].active)?.length === 0;

  const setAnchor = (anchor) => {
    setState({ type: 'SETANCHOR', payload: anchor });
  };

  const closeSearchBox = () => {
    setState({ type: 'CLOSESEARCH', payload: null });
  };

  const handleClose = () => {
    if (state.filterSearch) return;
    setState({ type: 'SETANCHOR', payload: null });
  };

  const onClick = (name) => {
    if (filtersConfig[name].hasSearch) {
      setState({ type: 'SETFILTERSEARCH', payload: name });
      return;
    }
    setState({ type: 'SETFILTER', payload: name });
  };

  const handleCloseFilter = (name) => {
    if (state.filters[name].value) setFilters(name, '' );
    setState({ type: 'SETCLOSE', payload: name });
  };

  const handleCloseFilterSearch = (name) => {
    handleCloseFilter(name);
    closeSearchBox(); //close search pop-up
  };

  const onChange = ({ target: { name, value } }) => {
    setState({ type: 'SETVALUE', payload: { name, value } });
    setFilters(name, value); // will fetch with new filter params
  };

  const onChangeSearch = ({ target: { name, value } }) => {
    setState({ type: 'SETVALUE', payload: { name, value } });
  };

  const cleanOthersSearchs = () => {
    Object.keys(filtersConfig)
      .filter((key) => filtersConfig[key]?.hasSearch && key !== state.filterSearch)
      .map((key) => handleCloseFilter(key));
  }

  const applySearch = () => {
    const name = state.filterSearch;
    setFilters(name, state.filters[name]?.value);
    setState({ type: 'SETFILTER', payload: name });
    cleanOthersSearchs();
  };

  return (
    <Container.Grid container md={12} lg={12} alignItems="flex-start">
      {searchComponent}

      {Object.keys(state.filters)
        .filter((name) => state.filters[name].active)
        .map((filter) => {
          const filterSelect = (
            <FilterActive
              key={filter}
              state={filtersConfig[filter]}
              handleClose={handleCloseFilter}
              onChange={onChange}
              value={state.filters[filter].value}
              name={filter}
              readOnly={filtersConfig[filter].hasSearch}
              style={{ width: filtersConfig[filter].width }}
            />
          );

          return customGrid ? (
            <Box ml={3}>{filterSelect}</Box>
          ) : (
            <Container.Grid key={filter} item sm={12} md={4} lg={3} xl={2} pr={2} alignItems="flex-start">
              {filterSelect}
            </Container.Grid>
          );
        })}

      {!allFiltersAdded && (
        <Container.Grid item sm={12} md={4} lg={3} xl={2} pt={1} pb={1}>
          {filterBtnComponent ? (
            React.cloneElement(filterBtnComponent, {
              'aria-controls': state.anchor ? 'filter-menu' : undefined,
              'aria-expanded': state.anchor ? 'true' : undefined,
              onClick: e => setAnchor(e.currentTarget)
            })
          ) : (
            <Button
              id="filter-button"
              backgroundColor="colorBaseBase"
              backgroundColorHover="colorBaseBase"
              style={{ height: '42px' }}
              aria-controls={state.anchor ? 'filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={state.anchor ? 'true' : undefined}
              onClick={e => setAnchor(e.currentTarget)}
            >
              <LazyIcon component="Filter" color="grey" size={1} mr={1} />
              Add Filter
            </Button>
          )}

          <ContainerMenuFilters
            anchorEl={state.anchor}
            open={Boolean(state.anchor)}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow 
                {...TransitionProps } 
                style={{ 
                  transformOrigin: 'left top'
                }}
              >
                <Paper elevation={6}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="filter-menu"
                      aria-labelledby="filter-button"
                      autoFocusItem={Boolean(state.anchor)}
                    >
                      {Object.keys(state.filters)
                        .filter((o) => !state.filters[o].active)
                        .map((option) => (
                          <MenuItem ref={ref} key={option} value={option} id="filter-choice" onClick={() => onClick(option)}>
                            {filtersConfig[option].icon && (
                              <LazyIcon
                                color={state.filterSearch === option ? 'colorBox' : 'colorDefault'}
                                component={filtersConfig[option].icon}
                                mr={1.4}
                                size={1}
                              />
                            )}
                            <ListItemText primary={filtersConfig[option].label} selected={state.filterSearch === option} />
                            {filtersConfig[option].hasSearch && <ArrowIcon active={state.filterSearch === option} />}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </ContainerMenuFilters>

          <ContainerSearch
            role={undefined}
            transition
            disablePortal
            anchorEl={ref.current}
            open={state.filterSearch}
            placement={'right'}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={closeSearchBox}>
                <Box style={{ padding: '16px 18px' }}>
                  <TextField
                    placeholder="Enter your search"
                    name={state.filterSearch}
                    value={state.filters[state.filterSearch]?.value}
                    onChange={onChangeSearch}
                    size="small"
                    InputProps={{
                      startAdornment: <LazyIcon component="Search" color="grey" size={1} mr={1} />,
                      endAdornment: state.filters[state.filterSearch]?.value && (
                        <CloseFilter onClick={() => handleCloseFilterSearch(state.filterSearch)}>
                          <LazyIcon component="Close" mr={0.8} color="colorDefault" size={0.6} />
                        </CloseFilter>
                      )
                    }}
                  />
                  <Button
                    backgroundColor="colorBaseBase"
                    backgroundColorHover="colorBaseBase"
                    mt={1}
                    style={{ width: '100%' }}
                    onClick={applySearch}
                  >
                    Search {filtersConfig[state.filterSearch]?.label}
                  </Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </ContainerSearch>
        </Container.Grid>
      )}

    </Container.Grid>
  );
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'SETVALUE':
      return { 
        ...state,
        filters: {
          ...state.filters,
          [payload.name]: { 
            ...state.filters[payload.name],
            value: payload.value,
          }
        }
      };
    case 'SETCLOSE': 
      return { 
        ...state,
        filters: {
          ...state.filters,
          [payload]: { active: false, value: '' }
        }
      };
    case 'SETFILTER':
      return {
        ...state,
        anchor: null,
        filters: {
          ...state.filters,
          [payload]: { 
            ...state.filters[payload],
            active: true 
          }
        }
      }
    case 'SETANCHOR':
      return { ...state, anchor: payload };
    
    case 'CLOSESEARCH':
      return { ...state, filterSearch: null };
    
    case 'SETFILTERSEARCH':
      return { ...state, filterSearch: payload };
    
    default:
      return { ...state, ...payload };
  }
}

export default AdvancedFilter;