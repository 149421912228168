import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNA } from '../helpers';

const PhishingCampaignUsersCell = ({ usersPercent = '' , isUT, clientId }) => {
  if (isNA(usersPercent))
    return (
      <Tooltip
        placement="top"
        title={isUT ? 'This account does not have recurring phishing' : 'This account does not have phishing'}
      >
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  return (
    <Tooltip title="Click here to navigate to the Phishing tab" placement="top">
      <Link
        component={RouterLink}
        to={`/clients/phishingReports/${clientId}`}
        underline="hover"
        hoverColor="info.main"
        variant="body2"
      >
        {`${usersPercent}%`}
      </Link>
    </Tooltip>
  );
};

PhishingCampaignUsersCell.propTypes = {
  receiversPercent: PropTypes.number.isRequired,
  isUT: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired
};

export default PhishingCampaignUsersCell;
