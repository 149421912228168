import { CustomTooltip, TextWithIcon } from 'components';
import pushNotifications from 'helpers/apis/pushNotifications';
import { useNotify } from 'ra-core';
import React, { useState } from 'react';

function RevertSettingsButton({ subscriptionId, refetchTable, setSubscriptionState, setActionLoading }) {
  const notify = useNotify();
  const [revertSettingsLoading, setRevertSettingsLoading] = useState(false);

  const handleRevertSettings = async () => {
    if (!subscriptionId) return;

    setActionLoading(true);
    setRevertSettingsLoading(true);

    try {
      const res = await pushNotifications.revertSubscription(subscriptionId);
      // reset modal state
      setSubscriptionState(res.data);

      // disable subscription inside modal
      await refetchTable();

      notify('Settings has been reverted successfully', { type: 'success' });
    } catch (err) {
      notify(err?.response?.data?.message || 'Something went wrong', 'error');
    } finally {
      setRevertSettingsLoading(false);
      setActionLoading(false);
    }
  };

  return (
    <div style={{ width: 'fit-content', marginTop: '20px' }}>
        <TextWithIcon
          text="Revert to Default Settings"
          icon="Refresh"
          isUnderlined
          onClick={handleRevertSettings}
          isLoading={revertSettingsLoading}
        />
    </div>
  );
}

export default RevertSettingsButton;
