import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${api}.${base}/${env}`;

const moveClientApi = {};

moveClientApi.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

moveClientApi.update = ({ client_id, new_partner_id }) => {
  const requestOptions = {
    url: `${baseURL}/clients/moveClient/${client_id}`,
    headers: moveClientApi.getHeaders(),
    data: { new_partner_id: new_partner_id }
  };
  return apiClient.put(requestOptions);
};

export default moveClientApi;