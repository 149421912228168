// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import newsfeed from 'helpers/apis/newsfeed';
import {
  Grid,
  Typography,
  makeStyles,
  Link,
  Dialog,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { LoadingStyled, ButtonDownload } from 'components';
import { Flag } from 'components/icons';
import { updateNotifications, deleteFeed, updateFeed, deleteComment } from 'helpers/action';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '30px 20px'
  },
  radioLabel: {
    textAlign: 'left',
    '& svg': {
      width: 22,
      height: 22
    }
  },
  flagIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--backgroundDefault)',
    borderRadius: '50%',
    padding: '21px',
  },
  flagBtn: {
    '& button': {
      minWidth: 110,
      color: 'white !important',
      '&.Mui-disabled': {
        opacity: 0.5
      }
    }
  }
}));

const FlagModal = ({ open, close, type, postId, commentId, commentsCount }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [flaggingReasons, setFlaggingReasons] = useState([]);

  useEffect(() => {
    setLoading(true);
    newsfeed
      .getFlaggingReasons()
      .then(res => {
        setFlaggingReasons(res?.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onReasonChange = e => {
    setReason(e.target.value);
  };

  const onFlag = () => {
    if (type === 'post') {
      dispatch(deleteFeed(postId));
      newsfeed
        .flagPost(postId, reason)
        .then(res => {
          if (res?.status && res.status === 200) dispatch(updateNotifications(true));
          notify(res?.data?.description);
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        });
    } else if (type === 'comment') {
      dispatch(updateFeed(postId, { comments_count: commentsCount - 1 }));
      dispatch(deleteComment(postId, commentId));

      newsfeed
        .flagComment(commentId, reason)
        .then(res => {
          if (res?.status && res.status === 200) dispatch(updateNotifications(true));
          notify(res?.data?.description);
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        });
    }
    close();
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      {loading ? (
        <Box pb={5}>
          <LoadingStyled />
        </Box>
      ) : (
        <Grid className={classes.padded} alignItems="center" style={{ textAlign: 'center' }} container>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <div className={classes.flagIcon}>
              <Flag size={2} color="colorSystemInfo" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" display="block">
              Why is this {type} being flagged?
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: '25px 0' }}>
            <FormControl component="fieldset">
              <RadioGroup name="reason" value={reason} onChange={onReasonChange}>
                {flaggingReasons.map(item => (
                  <Box textAlign="left" key={item}>
                    <FormControlLabel value={item} control={<Radio />} label={item} className={classes.radioLabel} />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <div className={classes.flagBtn}>
              <ButtonDownload color="default" onClick={onFlag} disabled={!reason}>
                Flag
              </ButtonDownload>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Link
              style={{ color: 'var(--colorDefault)', textDecoration: 'underline' }}
              href="#"
              onClick={e => {
                e.preventDefault();
                close();
              }}
              color="inherit"
            >
              Cancel
            </Link>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default FlagModal;
