import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography as TypographyMUI,
  makeStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { ButtonCancel, SelectField, TextField, Typography } from 'components';
import { Save2 } from 'components/icons';
import sraApi from 'helpers/apis/sra';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { BlueButton, ChipStyled } from '../../../style';
import { getRiskLevel, getThreatsChipColorOld } from '../../../utils';
import { useNotify } from 'ra-core';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    padding: '16px 20px'
  },
  dialogContent: {
    padding: 20
  },
  dialogActions: {
    padding: '15px 20px 20px'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  }
}));

const PROBABILITY = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' }
];

const IMPACT = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' }
];

const initialState = {
  name: '',
  probability: '',
  impact: '',
  risk: ''
};

const AddEditThreat = ({ refetch, clientId, revisionId, open, close, currentThreat }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [data, setData] = useState(initialState);
  const [isDisabled, setIsDisabled] = useState(true);
  const isEdit = currentThreat?.id;
  const title = isEdit ? 'Edit Threat' : 'New Threat';

  const riskLevel = useMemo(() => getRiskLevel(data.probability, data.impact), [data.probability, data.impact]);

  useEffect(() => {
    if (open) setData(currentThreat || initialState);
  }, [currentThreat, open]);

  useEffect(() => {
    const isDataChanged = !isEqual(currentThreat, data);
    const isRequiredDataFilled = data.name && data.probability && data.impact;
    setIsDisabled(!(isDataChanged && isRequiredDataFilled));
  }, [currentThreat, data]);

  const onChangeData = (name, value) => {
    setData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsDisabled(true);
    try {
      const threatData = { id: null, ...data, risk: riskLevel };
      await sraApi.setSraThreat(clientId, revisionId, threatData);
      await refetch();
      close();
    } catch (err) {
      notify(err?.response?.data?.message || 'Something went wrong', 'error');
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogHeader}>
          <TypographyMUI className={classes.title}>{title}</TypographyMUI>

          <IconButton className={classes.closeBtn} onClick={close}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </DialogTitle>

        <Divider />

        <form onSubmit={onSubmit}>
          <DialogContent className={classes.dialogContent}>
            {/* Threat */}
            <Box mb={3.5}>
              <TextField
                name="name"
                label="Threat"
                multiline
                rows={4}
                maxRows={4}
                fullWidth
                required
                disabled={currentThreat?.permissions === 'limited'}
                value={data.name}
                onChange={e => onChangeData(e.target.name, e.target.value)}
              />
            </Box>

            <Typography.h4 fontSize={16} mt="0px" mb={4}>
              Select the fields to choose a risk level
            </Typography.h4>

            {/* Probability */}
            <Box mb={4} width={300}>
              <SelectField
                allowEmpty={false}
                fullWidth
                size="small"
                id="probability"
                label="Probability with existing controls"
                choices={PROBABILITY}
                name="probability"
                required
                value={data.probability}
                onChange={e => onChangeData(e.target.name, e.target.value)}
              />
            </Box>

            {/* Impact */}
            <Box mb={4} width={300}>
              <SelectField
                allowEmpty
                fullWidth
                size="small"
                id="impact"
                label="Impact with existing controls"
                choices={IMPACT}
                name="impact"
                required
                value={data.impact}
                onChange={e => onChangeData(e.target.name, e.target.value)}
              />
            </Box>

            {data.probability && data.impact && (
              <>
                <Typography.h5 fontSize={14} mt="0px" mb={2}>
                  Risk with existing Controls
                </Typography.h5>

                <ChipStyled label={riskLevel} variant="outlined" color={getThreatsChipColorOld(riskLevel)} />
              </>
            )}
          </DialogContent>

          <Divider />

          <DialogActions className={classes.dialogActions}>
            <ButtonCancel variant="text" onClick={e => close()}>
              Cancel
            </ButtonCancel>

            <BlueButton type="submit" startIcon={<Save2 />} variant="outlined" disableElevation disabled={isDisabled}>
              Save
            </BlueButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddEditThreat;
