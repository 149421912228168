// @flow
import React, { memo, useEffect, useRef, useState, type ElementRef, type Element } from 'react';
import styled from 'styled-components';
import {
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import TextField from './TextField';
import { Container } from 'components';
import { CircleCheckIcon } from '@trustsecurenow/components-library';

type SelectFieldTypes = {
  label?: string | null,
  id?: string | number,
  choices: Array<{ value: boolean | string | number, label: string }>,
  highlightKey?: string | null,
  allowEmpty?: boolean,
  emptyValue?: string | null,
  fullWidth?: boolean,
  required?: boolean,
  error?: boolean,
  offset?: number,
  name: string,
  mt?: number | null,
  mb?: number | null,
  mr?: number | null,
  ml?: number | null,
  // eslint-disable-next-line react/require-default-props
  value?: boolean | string | number,
  valueKey?: string,
  labelKey?: string,
  onChange?: Function,
  readOnly?: boolean,
  variant?: boolean,
  displayEmpty?: boolean,
  maxWidth?: number,
  deselectValue?: string
};

const FormControl = styled(({ fullWidth, offset, maxWidth, customBg, ...props }) => <MuiFormControl {...props} />)`
  &.FormVariant-outlined {
    ${({ fullWidth }) => fullWidth && `min-width: 100%`};
    ${({ offset }) => offset && `min-width: calc(100% - ${offset}%)`};
    ${({ mt }) => mt && `margin-top: ${mt * 8}px`};
    ${({ mb }) => mb && `margin-bottom: ${mb * 8}px`};
    ${({ mr }) => mr && `margin-right: ${mr * 8}px`};
    ${({ ml }) => ml && `margin-left: ${ml * 8}px`};
  }

  &.FormVariant-standard {
    background-color: var(--backgroundDefault);
    color: var(--colorDefault);
    margin-top: 8px;
    margin-bottom: 8px;
    ${({ mr }) => mr && `margin-right: ${mr * 8}px`};
    ${({ ml }) => ml && `margin-left: ${ml * 8}px`};
    border: none;
    height: 35px;
    width: 100%;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '220px')};
    border-radius: 4px;
  }
  &.FormVariant-standard svg {
    right: 4px;
  }
  &.FormVariant-standard > div {
    padding: 5px 0 0 10px;
    color: var(--colorDefault);
    background-color: transparent;
    & > div {
      font-size: var(--fontSize);
    }
    &:before {
      border: none;
      height: 35px;
    }
    &:after {
      border: none;
    }
    &:hover::before {
      border: none;
    }
  }

  & > div.Mui-disabled > div {
    opacity: 0.6;
    cursor: not-allowed;
  }

  [class*=MuiSelect-root], [class*=MuiSelect-root]:focus{
    ${({ customBg }) => customBg && `background: ${customBg}`};
  }
`;

const SelectField = ({
  id,
  choices,
  highlightKey,
  allowEmpty,
  emptyValue,
  value,
  valueKey,
  labelKey,
  name,
  label,
  displayEmpty,
  onChange,
  fullWidth,
  required,
  error,
  helperText,
  offset,
  mt,
  mb,
  mr,
  ml,
  readOnly,
  variant,
  maxWidth,
  deselectValue,
  customBg,
  customMessage,
  customMessageColor,
  customMessageRightAligned,
  size,
  className,
  ...rest
}: SelectFieldTypes): Element<*> => {
  const inputLabel: ElementRef<typeof InputLabel> = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [newError, setNewError] = useState(error);
  const [errorMessage, setErrorMessage] = useState(helperText);

  useEffect(() => {
    if (inputLabel !== null && inputLabel.current !== null) {
      !readOnly && setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [readOnly]);

  const handleChange = e => {
    if (e.target.value) {
      setNewError(false);
      setErrorMessage('');
    }

    typeof onChange === 'function' && onChange(e);
  };

  const handleInvalid = e => {
    e.preventDefault();
    setNewError(!e.target.validity.valid);
    setErrorMessage(e.target.validationMessage);
  };

  const variantValue = variant ? 'standard' : 'outlined';
  const labels = label
    ? {
        label,
        labelId: `${id || name}__label`,
        labelWidth
      }
    : {};

  const labelValue = label ? {label} : {};

  useEffect(() => {
    setNewError(error)
    setErrorMessage(helperText)
  }, [error, helperText])

  // alert(name + JSON.stringify(choices));
  return (
    <>
      {!readOnly ? (
        <FormControl
          mt={mt}
          mb={mb}
          mr={mr}
          ml={ml}
          variant={variantValue}
          maxWidth={maxWidth}
          classes={{ root: `FormVariant-${variantValue}` }}
          offset={offset}
          fullWidth={fullWidth}
          required={required}
          error={newError}
          customBg={customBg}
          size={size}
        >
          {label && (
            <InputLabel ref={inputLabel} id={`${name}--label`}>
              {label}
            </InputLabel>
          )}

          <Select
            {...rest}
            value={value}
            id={id}
            name={name}
            {...labels}
            className={className}
            onChange={handleChange}
            onInvalid={handleInvalid}
            {...labelValue}
            displayEmpty={displayEmpty}
          >
            {allowEmpty && (
              <>
                {emptyValue ? (
                  <MenuItem value="">
                    <em>{emptyValue}</em>
                  </MenuItem>
                ) : (
                  <MenuItem value="">
                    <em>Please Select</em>
                  </MenuItem>
                )}
              </>
            )}
            {deselectValue ? (
              <MenuItem value="">
                <em>{deselectValue}</em>
              </MenuItem>
            ) : null}
            {choices?.map((k, i) =>
              highlightKey && k[highlightKey] ? (
                <MenuItem value={valueKey ? k[valueKey] : k.value}>
                  <Tooltip title={highlightKey.replace('_', ' ')}>
                    <span>
                      {labelKey ? k[labelKey] : k.label} &nbsp;
                      <CircleCheckIcon sx={{ fontSize: '1.4rem' }} color="success" />
                    </span>
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem
                  value={valueKey ? k[valueKey] : k.value}
                  data-ga-button={rest.GAclick && (`Dropdown - ${labelKey ? k[labelKey] : k.label}`)}
                >
                  {labelKey ? k[labelKey] : k.label}
                </MenuItem>
              )
            )}
          </Select>
          {(error || newError) && <FormHelperText>{errorMessage}</FormHelperText>}
           <Container.Grid justify={customMessage ? "space-between" : "flex-end"}>
             {customMessage && <FormHelperText style={{color: customMessageColor || 'var(--colorBaseBase)'}}>{customMessage}</FormHelperText>}
             {customMessageRightAligned}
           </Container.Grid>
        </FormControl>
      ) : (
        <TextField
          {...rest}
          value={value}
          id={id}
          variant
          name={name}
          {...labelValue}
          mt={mt}
          mb={mb}
          width={1}
          InputProps={{
            readOnly: true
          }}
        />
      )}
    </>
  );
};

SelectField.defaultProps = {
  label: null,
  id: 0,
  allowEmpty: true,
  highlightKey: null,
  emptyValue: '',
  labelKey: '',
  valueKey: '',
  fullWidth: false,
  required: false,
  error: false,
  offset: null,
  onChange: e => e,
  mt: null,
  mb: null,
  mr: null,
  ml: null,
  readOnly: false,
  variant: false,
  displayEmpty: false,
  maxWidth: null,
  deselectValue: '',
  size: 'medium'
};

export default memo<SelectFieldTypes>(SelectField);
