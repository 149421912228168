import DamList from './DamList';
import DamEdit from './DamEdit';
import DAM from './DigitalAssetManager'
// TODO: pick a better icon
import DAMIcon from 'components/icons/Upload';

export default {
  name: 'dam',
  icon: DAMIcon,
  list: DamList,
  edit: DamEdit,
  options: {
    label: 'Digital Asset Manager',
    hasMobileSublist: false,
    hasLeftIcon: true,
    hasRightIcon: true,
  }
};