import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  Stack,
  CircleCheckIcon,
  CloseIcon
} from '@trustsecurenow/components-library';

function TeamsAppUserNotifiedDialog({ state, resetModal }) {
  return (
    <Dialog open={state.open}>
      <DialogTitle display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={resetModal}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pb: 10 }}
      >
        <Stack borderRadius="50%" p={1.25} bgcolor={theme => alpha(theme.palette.success.main, 0.2)}>
          <CircleCheckIcon color="success" sx={{ fontSize: 48 }} />
        </Stack>
        <Typography variant="h2" color="success.main" mb={2.25} mt={1.25}>
          {state.titleText}
        </Typography>
        <Typography textAlign="center" minWidth="279px" px={16} pb={4.25} variant="subtitle1">
          Users have been notified that the <br />
          PII Protect Teams app is now available.
        </Typography>
        <Button onClick={resetModal} width={108} sx={{ height: 40 }}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
}

TeamsAppUserNotifiedDialog.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired
  }).isRequired,
  resetModal: PropTypes.func.isRequired
};

export default TeamsAppUserNotifiedDialog;
