/* eslint-disable react/prop-types */
import { Link, PDFIcon, Stack, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import React, { useState } from 'react';
import sraApi from 'helpers/apis/sra';

const FILE_DOWNLOAD_TYPE = {
  EXECUTIVE_SUMMARY: 'executive_summary',
  DETAILED: 'detailed',
  WORK_PLAN: 'work_plan'
};

const FILES_DOWNLOAD_BUTTONS = {
  [FILE_DOWNLOAD_TYPE.EXECUTIVE_SUMMARY]: {
    text: 'Executive Summary Report',
    icon: <PDFIcon />
  },
  [FILE_DOWNLOAD_TYPE.DETAILED]: {
    text: 'Detailed Report',
    icon: <PDFIcon />
  },
  [FILE_DOWNLOAD_TYPE.WORK_PLAN]: {
    text: 'Work Plan',
    icon: <PDFIcon />
  }
};

const getDownloadRequest = (revision_id, type) => {
  switch (type) {
    case FILE_DOWNLOAD_TYPE.EXECUTIVE_SUMMARY:
      return sraApi.getExecutiveSummaryReport(revision_id);
    case FILE_DOWNLOAD_TYPE.DETAILED:
      return sraApi.getDetailedReport(revision_id);
    case FILE_DOWNLOAD_TYPE.WORK_PLAN:
      return sraApi.getWorkPlanReport(revision_id);
    default:
      return () => {};
  }
};

const ReportFilesCell = props => {
  const [loadingType, setLoadingType] = useState('');

  const { revision_id, show_detailed_report, status, is_deleted } = props.row;
  const isDeleted = is_deleted || [0, 1].includes(status);

  const availableTypes = [
    ...(show_detailed_report ? [FILE_DOWNLOAD_TYPE.DETAILED] : []),
    FILE_DOWNLOAD_TYPE.EXECUTIVE_SUMMARY,
    FILE_DOWNLOAD_TYPE.WORK_PLAN
  ];

  const downloadFile = async type => {
    try {
      setLoadingType(type);
      const response = await getDownloadRequest(revision_id, type);
      const url = response?.data?.url;
      if (url) window.open(url);
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', { props: { color: 'error' } });
    } finally {
      setLoadingType('');
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      {availableTypes.map(type => {
        const button = FILES_DOWNLOAD_BUTTONS[type];
        const isLoading = loadingType === type;
        return (
          <Link
            key={button.text}
            component="button"
            startIcon={button.icon}
            onClick={() => downloadFile(type)}
            disabled={isDeleted || isLoading}
            loading={isLoading}
            hoverColor="info.main"
          >
            {button.text}
          </Link>
        );
      })}
    </Stack>
  );
};

export default ReportFilesCell;
