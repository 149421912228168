import React from 'react';
import {
  HeadsetIcon,
  ShieldIcon,
  Typography,
  IconButton,
  getGridNumericOperators
} from '@trustsecurenow/components-library';
import {
  FEDERATION_SERVICES,
  PRESETS_COLUMNS,
  PRODUCT_TYPES,
  TEAMS_APP_ALLOWED_PRODUCT_TYPES,
  MODALS,
  BULK_ACTIONS,
  CANCELLABLE_HIPAA_PRODUCTS
} from './constants';
import * as Cells from './cells';
import { isNA } from './helpers';
import PercentageInputFilter from './PercentageInputFilter';

export const generateSettings = (cellsProps = {}) => {
  return {
    [PRESETS_COLUMNS.NAME]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.NAME,
        field: 'name',
        filterable: false,
        pinnable: false,
        hideable: false,
        renderCell: props => (
          <Cells.NameCell name={props.row.name} clientId={props.row.id} decodedClientId={props.row.client_id} />
        ),
        minWidth: 250
      },
      fieldDependencies: ['name', 'client_id', 'id']
    },
    [PRESETS_COLUMNS.ACTIVE]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.ACTIVE,
        field: 'active',
        type: 'singleSelect',
        cellClassName: 'alwaysEnabledCell',
        valueOptions: [
          { label: 'Active', value: '1' },
          { label: 'Inactive', value: '0' }
        ],
        filterOperators: [],
        minWidth: 130,
        pinnable: false,
        hideable: false,
        renderCell: props => {
          const clientId = props.row.id;
          const clientName = props.row.name;
          const productType = props.row.product_type;
          return (
            <Cells.ActiveStatusCell
              active={Boolean(props.row.active)}
              isDeleted={Boolean(props.row.deleted)}
              productType={props.row.product_type}
              isInternalClient={props.row.is_internal}
              isEBPP={cellsProps.isEBPP}
              onActivateClick={e => {
                e.stopPropagation();
                cellsProps.setModalState(cellsProps.isEBPP ? MODALS.ACTIVATE_CLIENT2 : MODALS.ACTIVATE_CLIENT, {
                  open: true,
                  clientId,
                  clientName
                });
              }}
              onDeactivateClick={e => {
                e.stopPropagation();
                cellsProps.setModalState(MODALS.DEACTIVATE_CLIENT, { 
                  open: true,
                  clientId,
                  isCancellableHIPAA: CANCELLABLE_HIPAA_PRODUCTS.includes(productType)
                });
              }}
              onDeleteClick={e => {
                e.stopPropagation();
                cellsProps.setModalState(MODALS.DELETE_CLIENT, { open: true, clientId, clientName });
              }}
            />
          );
        }
      },
      fieldDependencies: ['active', 'id', 'is_editable', 'product_type', 'name', 'is_internal'].concat(
        cellsProps.isAdmin ? ['deleted'] : []
      ),
      relatedBulkActions: [BULK_ACTIONS.ENABLE_CLIENT, BULK_ACTIONS.DISABLE_CLIENT]
    },
    [PRESETS_COLUMNS.PRODUCT]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.PRODUCT,
        field: 'product_type',
        minWidth: 200,
        type: 'singleSelect',
        valueOptions: cellsProps.productTypesOptions,
        filterOperators: [],
        pinnable: false,
        hideable: false,
        renderCell: props => <Cells.ProductTypeCell productType={props.row.product_type} />
      },
      fieldDependencies: ['product_type']
    },
    [PRESETS_COLUMNS.TEAMS_APP]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.TEAMS_APP,
        description:
          'PII Protect is our Microsoft Teams application. Click the red X icon below to deploy for your clients.',
        field: 'teams_app_registered_users',
        minWidth: 150,
        pinnable: false,
        type: 'singleSelect',
        valueOptions: [
          {
            label: '0%',
            // 0 means percentage rounds to 0, -1 means not deployed
            value: 'JSON:{"operator":"ISNULL"}'
          },
          {
            label: '>0% - 25%',
            value: 'JSON:{"operator":"BETWEEN","value":[1, 25]}'
          },
          {
            label: '>25% - 75%',
            value: 'JSON:{"operator":"BETWEEN","value":[26, 75]}'
          },
          {
            label: '>75% - <100%',
            value: 'JSON:{"operator":"BETWEEN","value":[76, 99]}'
          },
          {
            label: '100%',
            value: '100'
          }
        ],
        renderCell: props => {
          const teamsAppDeploymentStats = props.row.teams_app_registered_users;
          const productAllowed = Object.values(TEAMS_APP_ALLOWED_PRODUCT_TYPES).includes(props.row.product_type);
          const aadEnabled = Boolean(props.row.azure_ad);
          return (
            <Cells.TeamsAppCell
              teamsAppDeploymentStats={teamsAppDeploymentStats}
              productAllowed={productAllowed}
              aadEnabled={aadEnabled}
              onDeployInstructionsClicked={() => {
                cellsProps.setModalState(MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS, {
                  open: true,
                  clientId: props.row.id
                });
              }}
              onEngagementReminderClicked={() => {
                cellsProps.setModalState(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER, {
                  open: true,
                  loading: true,
                  clientId: props.row.id
                });
              }}
            />
          );
        }
      },
      fieldDependencies: ['teams_app_registered_users', 'product_type', 'azure_ad']
    },
    [PRESETS_COLUMNS.DELETED]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.DELETED,
        field: 'deleted',
        minWidth: 160,
        cellClassName: 'alwaysEnabledCell',
        type: 'singleSelect',
        valueOptions: [
          { label: 'Deleted', value: '1' },
          { label: 'Undeleted', value: '0' }
        ],
        filterOperators: [],
        pinnable: false,
        // hideable: false,
        renderCell: props => (
          <Cells.DeletedCell
            isDeleted={Boolean(props.row.deleted)}
            onClick={e => {
              e.stopPropagation();
              cellsProps.setModalState(MODALS.UNDELETE_CLIENT, {
                open: true,
                clientId: props.row.id
              });
            }}
          />
        )
      },
      fieldDependencies: ['id', 'deleted'],
      hidden: !cellsProps.isAdmin
    },
    [PRESETS_COLUMNS.COMPANY_ESS]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.COMPANY_ESS,
        field: 'average_ess',
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: [
          {
            label: 'High Risk (0-499)',
            value: 'JSON:{"operator":"BETWEEN","value":["0", "499"]}'
          },
          {
            label: 'Medium Risk (500-629)',
            value: 'JSON:{"operator":"BETWEEN","value":["500", "629"]}'
          },
          {
            label: 'Low Risk (630-800)',
            value: 'JSON:{"operator":"BETWEEN","value":["630", "800"]}'
          },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => <Cells.CompanyESSCell averageEss={props.row.average_ess} />
      },
      fieldDependencies: ['average_ess']
    },
    [PRESETS_COLUMNS.USERS_BREACHES]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.USERS_BREACHES,
        description: 'Number of user who have dark web data breaches',
        field: 'dw_breached_employees',
        minWidth: 150,
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.BreachesCell breaches={props.row.dw_breached_employees} clientId={props.row.id} />
      },
      fieldDependencies: ['dw_breached_employees', 'id']
    },
    [PRESETS_COLUMNS.TOTAL_BREACHES]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.TOTAL_BREACHES,
        field: 'dw_total_breaches',
        description: 'Total number of dark web data breaches associated with scanned domains',
        minWidth: 150,
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.BreachesCell breaches={props.row.dw_total_breaches} clientId={props.row.id} />
      },
      fieldDependencies: ['dw_total_breaches', 'id', 'product_type']
    },
    [PRESETS_COLUMNS.AVG_PFR]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.AVG_PFR,
        description: 'Phishing Fail Rate is the % of simulated phishing emails that users have click on',
        field: 'average_pfr',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        minWidth: 130,
        pinnable: false,
        renderCell: props => <Cells.AverageBFRCell averagePFR={props.row.average_pfr} clientId={props.row.id} />,
        filterOperators: getGridNumericOperators()
          .filter((operator)=> operator.value === "=")
          .map((operator) => ({
            ...operator,
            InputComponent: PercentageInputFilter
        })),
      },
      fieldDependencies: ['average_pfr', 'id', 'product_type']
    },
    [PRESETS_COLUMNS.AVG_SECURITY_TRAINING]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.AVG_SECURITY_TRAINING,
        field: 'avg_security_training',
        filterable: false,
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        minWidth: 170,
        pinnable: false,
        renderCell: props => <Cells.TrainingCell value={props.row.avg_security_training} clientId={props.row.id} />
      },
      fieldDependencies: ['avg_security_training', 'id']
    },
    [PRESETS_COLUMNS.SECURITY_TRAINING_COMPLETION]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.SECURITY_TRAINING_COMPLETION,
        description: 'The % of users who have completed all available training courses',
        field: 'user_completed_trainings',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        minWidth: 190,
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.TrainingCell value={props.row.user_completed_trainings} clientId={props.row.id} />
      },
      fieldDependencies: ['user_completed_trainings', 'id']
    },
    [PRESETS_COLUMNS.AVG_MICRO_QUIZ]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.AVG_MICRO_QUIZ,
        field: 'avg_micro_quiz',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        minWidth: 160,
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.MicroTrainingCell value={props.row.avg_micro_quiz} clientId={props.row.id} />
      },
      fieldDependencies: ['avg_micro_quiz', 'id', 'product_type']
    },
    [PRESETS_COLUMNS.MT_COMPLETION]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.MT_COMPLETION,
        field: 'mt_completion',
        minWidth: 160,
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.MicroTrainingCell value={props.row.mt_completion} clientId={props.row.id} />
      },
      fieldDependencies: ['mt_completion', 'id', 'product_type']
    },
    [PRESETS_COLUMNS.AUTOMATED_ESS_REPORT]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.AUTOMATED_ESS_REPORT,
        field: 'ess_pn_enabled',
        minWidth: 160,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => (
          <Cells.AutoESSReportCell
            essEnabled={props.row.ess_pn_enabled}
            clientId={props.row.id}
            essFrequency={props.row.ess_pn_frequency}
          />
        )
      },
      fieldDependencies: ['ess_pn_enabled', 'id', 'ess_pn_frequency']
    },
    [PRESETS_COLUMNS.ESS_REPORT]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.ESS_REPORT,
        field: 'ess_report',
        minWidth: 160,
        filterable: false,
        pinnable: false,
        renderCell: props => <Cells.ESSReportCell clientId={props.row.id} essReport={props.row.ess_report} />
      },
      fieldDependencies: ['ess_report', 'id', 'product_type']
    },
    [PRESETS_COLUMNS.CLIENT_REPORT]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.CLIENT_REPORT,
        field: 'client_report',
        minWidth: 150,
        filterable: false,
        pinnable: false,
        renderCell: props => (
          <Cells.ReportCell
            enabled={Boolean(props.row.users_count)}
            clientId={props.row.id}
            clientReport={props.row.client_report}
          />
        )
      },
      fieldDependencies: ['client_report', 'users_count', 'id']
    },
    [PRESETS_COLUMNS.HSN_BRANDING]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.HSN_BRANDING,
        field: 'branding',
        minWidth: 150,
        valueOptions: [
          { label: 'Yes', value: '1' },
          { label: 'No', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        filterOperators: [],
        type: 'singleSelect',
        pinnable: false,
        renderCell: props => {
          if (isNA(props.row.branding)) return <Typography variant="body2">N/A</Typography>;
          return (
            Boolean(props.row.branding) && (
              <IconButton sx={{ pointerEvents: 'none' }}>
                <ShieldIcon sx={{ fontSize: '2rem' }} />
              </IconButton>
            )
          );
        }
      },
      fieldDependencies: ['branding']
    },
    [PRESETS_COLUMNS.HSN_CONSULTING]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.HSN_CONSULTING,
        field: 'ra_consulting',
        type: 'singleSelect',
        minWidth: 160,
        filterOperators: [],
        valueOptions: [
          { label: 'Yes', value: '1' },
          { label: 'No', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => {
          if (isNA(props.row.ra_consulting)) return <Typography variant="body2">N/A</Typography>;
          return (
            Boolean(props.row.ra_consulting) && (
              <IconButton sx={{ pointerEvents: 'none' }}>
                <HeadsetIcon sx={{ fontSize: '2rem' }} />
              </IconButton>
            )
          );
        }
      },
      fieldDependencies: ['ra_consulting']
    },
    [PRESETS_COLUMNS.ACTIVE_USERS]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.ACTIVE_USERS,
        field: 'users_count',
        minWidth: 140,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          const usersCount = props.row.users_count;
          if (isNA(usersCount)) return <Typography variant="body2">N/A</Typography>;
          return <Typography variant="body2">{usersCount}</Typography>;
        }
      },
      fieldDependencies: ['users_count']
    },
    [PRESETS_COLUMNS.INDUSTRY]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.INDUSTRY,
        field: 'industry_name',
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: cellsProps.industryFilterOptions,
        filterOperators: [],
        pinnable: false,
        renderCell: props => {
          const clientId = props.row.id;
          const clientName = props.row.name;
          const productType = props.row.product_type;
          return (
            <Cells.IndustryCell
              industryId={props.row.industry_id}
              industryName={props.row.industry_name}
              onIndustryButtonClick={() => {
                cellsProps.setModalState(MODALS.SELECT_INDUSTRY, { open: true, clientId, productType, clientName });
              }}
            />
          );
        }
      },
      fieldDependencies: ['industry_name', 'industry_id', 'id', 'name', 'product_type']
    },
    [PRESETS_COLUMNS.SRA_COMPLETION_DATE]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.SRA_COMPLETION_DATE,
        field: 'ra_complete',
        minWidth: 160,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          const sraComplete = props.row.ra_complete;
          if (isNA(sraComplete)) return <Typography variant="body2">N/A</Typography>;
          return Boolean(sraComplete) && <Typography variant="body2">{sraComplete}</Typography>;
        }
      },
      fieldDependencies: ['ra_complete']
    },
    [PRESETS_COLUMNS.SYSTEM_EMAILS]: {
      columnProps: {
        field: 'sys_conf_disable_emails',
        headerName: PRESETS_COLUMNS.SYSTEM_EMAILS,
        description:
          'Identifies clients that have system emails disabled. Systems emails include: All Phishing Campaigns, Client Reminders, Automated Reports and other notifications.',
        minWidth: 150,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          const clientName = props.row.name;
          const clientId = props.row.id;
          return (
            <Cells.SystemEmailsCell
              disabled={props.row.sys_conf_disable_emails}
              onClick={() => {
                cellsProps.setModalState(MODALS.ENABLE_SYSTEM_EMAILS, { open: true, clientId, clientName });
              }}
            />
          );
        }
      },
      hidden: !cellsProps.showSystemEmailsColumn,
      fieldDependencies: ['name', 'sys_conf_disable_emails', 'id']
    },
    [PRESETS_COLUMNS.DIRECTORY_SYNC]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.DIRECTORY_SYNC,
        field: 'dir_sync_service',
        minWidth: 150,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          {
            label: 'Azure Active Directory',
            value: "multitenant" || "AppID" || "sync2"
          },
          { label: 'Google', value: 'g-suite' },
          { label: 'On-Prem', value: 'OnPrem' },
          { label: 'Disabled', value: '' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => (
          <Cells.DirectorySyncCell
            service={props.row.dir_sync_service}
            clientId={props.row.id}
            active={Boolean(props.row.active)}
          />
        )
      },
      fieldDependencies: ['dir_sync_service', 'id']
    },
    [PRESETS_COLUMNS.SINGLE_SIGNON]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.SINGLE_SIGNON,
        field: 'federation_service',
        minWidth: 150,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Microsoft', value: FEDERATION_SERVICES.O365 },
          { label: 'Google', value: FEDERATION_SERVICES.GOOGLE },
          { label: 'Okta', value: FEDERATION_SERVICES.OKTA },
          { label: 'Disabled', value: '' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => (
          <Cells.SingleSignonCell
            enabled={props.row.federation_service}
            service={props.row.federation_service}
            clientId={props.row.id}
          />
        )
      },
      fieldDependencies: ['federation_service', 'id']
    },
    [PRESETS_COLUMNS.WELCOME_MESSAGE]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.WELCOME_MESSAGE,
        description: 'Indicates that Welcome Messages are enabled and being sent to new users',
        field: 'welcome_message_enabled',
        minWidth: 150,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => {
          const clientId = props.row.id;
          return (
            <Cells.WelcomeMessageCell
              enabled={props.row.welcome_message_enabled}
              clientId={props.row.id}
              refetchTable={cellsProps.refetchTable}
              onEnableClick={() => {
                cellsProps.setModalState(MODALS.ENABLE_WELCOME_MESSAGE, { open: true, clientId });
              }}
              onDisableClick={() => {
                cellsProps.setModalState(MODALS.DISABLE_WELCOME_MESSAGE, { open: true, clientId });
              }}
            />
          );
        }
      },
      fieldDependencies: ['welcome_message_enabled', 'active', 'id'],
      relatedBulkActions: [BULK_ACTIONS.ENABLE_WELCOME_MESSAGE]
    },
    [PRESETS_COLUMNS.RECEIVED_WELCOME_MESSAGE]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.RECEIVED_WELCOME_MESSAGE,
        description: 'Percentage of users who have received a Welcome Message',
        field: 'users_received_welcome_message',
        minWidth: 200,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          const clientId = props.row.id;
          const clientName = props.row.name;
          const receivedWelcomeMessagePercent = props.row.users_received_welcome_message;
          const totalUsersCount = props.row.users_count;
          const usersWithNoWelcomeMessage = Math.round(
            totalUsersCount - (receivedWelcomeMessagePercent / 100) * totalUsersCount
          );
          return (
            <Cells.ReceivedWelcomeMessageCell
              receiversPercent={props.row.users_received_welcome_message}
              onClick={() => {
                cellsProps.setModalState(MODALS.SEND_WELCOME_MESSAGE, {
                  open: true,
                  clientId,
                  clientName,
                  usersWithNoWelcomeMessage,
                  totalUsersCount,
                  selectedUsersCount: 0
                });
              }}
            />
          );
        }
      },
      fieldDependencies: ['users_received_welcome_message', 'active', 'users_count', 'id', 'name'],
      relatedBulkActions: [BULK_ACTIONS.SEND_WELCOME_MESSAGE]
    },
    [PRESETS_COLUMNS.USERS_RECEIVING_MTNL]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.USERS_RECEIVING_MTNL,
        field: 'users_received_mt_nl',
        minWidth: 150,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          return (
            <Cells.UsersReceivingMTNLCell
              receiversPercent={props.row.users_received_mt_nl}
              onClick={() => {
                cellsProps.setModalState(MODALS.MICRO_TRAINING, {
                  open: true,
                  loadingModal: true,
                  clientId: props.row.id,
                  clientName: props.row.name,
                  enabledMTNLUsersPercent: props.row.users_received_mt_nl,
                  isPositiveOptInEnabled: props.row.positive_opt_in
                });
              }}
              sysDisableEmails={props.row.sys_conf_disable_emails}
            />
          );
        }
      },
      fieldDependencies: [
        'id',
        'name',
        'users_count',
        'positive_opt_in',
        'users_received_mt_nl',
        'active',
        'product_type'
      ]
    },
    [PRESETS_COLUMNS.USERS_BOUNCED_EMAIL]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.USERS_BOUNCED_EMAIL,
        field: 'users_email_bounced',
        minWidth: 160,
        filterable: false,
        pinnable: false,
        renderCell: props => {
          const clientId = props.row.id;
          const clientName = props.row.name;
          const bouncedEmailPercent = props.row.users_email_bounced;
          return (
            <Cells.UsersBouncedEmail
              usersPercent={bouncedEmailPercent}
              onClick={() => {
                cellsProps.setModalState(MODALS.BOUNCED_EMAILS, {
                  open: true,
                  clientId,
                  clientName,
                  bouncedEmailPercent: props.row.users_email_bounced,
                  totalUsersCount: props.row.users_count,
                  selectedUsersCount: Math.round((props.row.users_email_bounced / 100) * props.row.users_count)
                });
              }}
            />
          );
        }
      },
      fieldDependencies: ['id', 'name', 'users_count', 'users_email_bounced'],
      relatedBulkActions: [BULK_ACTIONS.BOUNCED_EMAILS]
    },
    [PRESETS_COLUMNS.MT_NL]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.MT_NL,
        description:
          'Indicates that Micro Training and Newsletter emails are enabled to be sent to users. Ensure users have been sent a Welcome Message to start receiving these emails.',
        field: 'micro_training_enabled',
        minWidth: 180,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => (
          <Cells.MTNLCell
            enabled={props.row.micro_training_enabled}
            clientId={props.row.id}
            refetchTable={cellsProps.refetchTable}
          />
        )
      },
      fieldDependencies: ['micro_training_enabled', 'active', 'product_type', 'id'],
      relatedBulkActions: [BULK_ACTIONS.ENABLE_MT_NL]
    },
    [PRESETS_COLUMNS.POSITIVE_OPTIN]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.POSITIVE_OPTIN,
        description:
          'Indicates that Positive Opt-in is enabled to prevent users from unsubscribing to Micro Training and Newsletter Emails',
        field: 'positive_opt_in',
        minWidth: 150,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => {
          const clientId = props.row.id;
          const clientName = props.row.name;
          return (
            <Cells.PositiveOptinCell
              enabled={props.row.positive_opt_in}
              onClick={() => {
                cellsProps.setModalState(MODALS.POSITIVE_OPTIN, {
                  open: true,
                  clientId,
                  clientName
                });
              }}
            />
          );
        }
      },
      fieldDependencies: ['positive_opt_in', 'active', 'product_type', 'id', 'name'],
      relatedBulkActions: [BULK_ACTIONS.ENABLE_POSITIVE_OPTIN]
    },
    [PRESETS_COLUMNS.DIRECT_MAIL_DELIVERY]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.DIRECT_MAIL_DELIVERY,
        description:
          'Direct Mail Delivery will help ensure that simulated phishing messages will be successfully delivered by bypassing any spam filters and delivering the message directly to the user’s inbox.',
        field: 'dmd',
        minWidth: 150,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => (
          <Cells.DMDCell
            enabled={props.row.dmd}
            clientId={props.row.id}
            azureEnabled={Boolean(props.row.azure_ad)}
            isHIPAAComp={props.row.product_type === PRODUCT_TYPES.HIPAA_COMPLIANCE}
          />
        )
      },
      fieldDependencies: ['dmd', 'azure_ad', 'product_type', 'id']
    },
    [PRESETS_COLUMNS.PHISHING_CAMPAIGN_USERS]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.PHISHING_CAMPAIGN_USERS,
        description:
          'Represents the percentage of users who are currently enrolled in an active recurring phishing campaign',
        field: 'users_in_phishing_campaign',
        minWidth: 200,
        filterable: false,
        pinnable: false,
        renderCell: props => (
          <Cells.PhishingCampaignUsersCell
            usersPercent={props.row.users_in_phishing_campaign}
            clientId={props.row.id}
            isUT={props.row.product_type === PRODUCT_TYPES.UT}
          />
        )
      },
      fieldDependencies: ['users_in_phishing_campaign', 'product_type', 'id']
    },
    [PRESETS_COLUMNS.DW_MONITORING]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.DW_MONITORING,
        field: 'dwm_enabled',
        minWidth: 160,
        type: 'singleSelect',
        filterOperators: [],
        valueOptions: [
          { label: 'Enabled', value: '1' },
          { label: 'Disabled', value: '0' },
          { label: 'N/A', value: 'N/A' }
        ],
        pinnable: false,
        renderCell: props => <Cells.DWMCell enabled={props.row.dwm_enabled} clientId={props.row.id} />
      },
      fieldDependencies: ['dwm_enabled', 'product_type', 'id']
    },
    [PRESETS_COLUMNS.AUTOMATED_REPORTS_REMINDERS]: {
      columnProps: {
        headerName: PRESETS_COLUMNS.AUTOMATED_REPORTS_REMINDERS,
        description:
          'This indicates the count of enabled Automated Reports and Reminders for this client, out of the total available for activation.',
        field: 'total_pn_enabled',
        minWidth: 160,
        filterable: false,
        pinnable: false,
        renderCell: props => (
          <Cells.AutomatedReportsCell
            enabledCount={props.row.total_pn_enabled}
            totalCount={props.row.total_pn}
            clientId={props.row.id}
          />
        )
      },
      fieldDependencies: ['total_pn', 'total_pn_enabled', 'id']
    }
  };
};

export const settingsByHeaderName = generateSettings();

export const settingsByField = Object.values(settingsByHeaderName).reduce((acc, columnSettings) => {
  acc[columnSettings.field] = columnSettings;
  return acc;
}, {});

/**
 * create a map between columns fields and field dependencies
 * example: {
 *  Name: ['name', 'client_id'],
 *  Active: ['active', 'is_editable', 'product_type'],
 *  ...
 * }
 */
export const columnDependencyMap = Object.entries(settingsByHeaderName).reduce((acc, [headerName, columnSettings]) => {
  acc[headerName] = columnSettings.fieldDependencies;
  return acc;
}, {});