/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, CircularProgress, InputAdornment } from '@material-ui/core';
import { ButtonCancel, SelectField, Button, Union, Disconnect, TextField, NewDownload, Delete } from 'components';
import { useNotify } from 'ra-core';
import clients from 'helpers/apis/clients';
import { useId, useLocation } from 'hooks';
import CopyButton from 'components/buttons/CopyButton';
import { validURL } from 'helpers';
import { updateNestedMutable } from 'utils/update';
import isEqual from 'lodash/isEqual';
import { BSN_SET_ANY } from 'conf';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  actionsContainer: {
    width: '100%'
  },
  formInputsContainer: {
    width: '100%'
  }
}));

const metaDataURLO365IsValid = url => validURL(url) && url.toLowerCase().endsWith('xml');
const metaDataURLOktaIsValid = url => validURL(url) && url.toLowerCase().endsWith('/sso/saml/metadata');

export const PROVIDER_ID = Object.freeze({
  GOOGLE: 10,
  OKTA: 11,
  O365: 9
});

const SingleSignOnForm = ({ provider, onCancel, onDisconnect }) => {
  const classes = useStyles();
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    isError: false,
    msg: ''
  });
  const notify = useNotify();
  const dispatch = useDispatch();
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);

  const getProviderConfig = useCallback(async clientID => {
    return clients.getInformation(clientID, 'federated');
  }, []);

  const getProviderConfigEffect = useCallback(async () => {
    setLoading(true);
    getProviderConfig(setId)
      .then(response => {
        const is_valid_metadata =
          response.data?.client_identity_provider?.id === PROVIDER_ID.OKTA
            ? metaDataURLOktaIsValid(response?.data?.client_identity_provider?.metadata_url)
            : metaDataURLO365IsValid(response?.data?.client_identity_provider?.metadata_url);
        let { data } = response;
        if (!data?.client_identity_provider?.id) {
          data = updateNestedMutable(data, {
            client_identity_provider: {
              id: provider,
              identity_provider_name: data?.identity_providers?.find(v => v.id === provider)?.name ?? null,
              is_multitenant: data?.identity_providers?.find(v => v.id === provider)?.is_multitenant ?? 0
            }
          });
        }
        setState(prev => ({ ...prev, data: { ...data, is_valid_metadata } }));
      })
      .catch(err => {
        setError(err?.response?.data?.description || 'Could not get information settings');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getProviderConfig, setId, provider]);

  useEffect(() => {
    getProviderConfigEffect();
  }, [getProviderConfigEffect]);

  const metaDataUrlChanged = evt => {
    const { value } = evt.target;
    setState(old => {
      return updateNestedMutable(old, {
        data: {
          is_valid_metadata:
            old.data?.client_identity_provider?.id === PROVIDER_ID.OKTA
              ? metaDataURLOktaIsValid(value)
              : metaDataURLO365IsValid(value),
          client_identity_provider: {
            metadata_url: value
          }
        }
      });
    });
  };

  const downloadFile = () => {
    const { data } = state;
    const certificate =
      data?.client_identity_provider?.signing_certificate || data?.pool_parameters?.signing_certificate;
    const content = `-----BEGIN CERTIFICATE-----\n${certificate}\n-----END CERTIFICATE-----`;
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/crt' }));
    a.setAttribute('download', 'signing_certificate.crt');
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  const appIdUrl = state?.data?.client_identity_provider?.urn || state?.data?.pool_parameters?.urn;

  const redirectUri = state?.data?.client_identity_provider?.redirect_uri || state?.data?.pool_parameters?.redirect_uri;

  const identityProviders = React.useMemo(() => {
    const provider_data = state?.data?.identity_providers;
    return provider_data
      ? provider_data.map(({ id, name, is_multitenant }) => {
          return {
            value: id,
            label: name,
            is_multitenant
          };
        })
      : [];
  }, [state?.data?.identity_providers]);

  const handleClientIdentityProviderChanged = React.useCallback(
    evt => {
      const { target } = evt;
      const correspondingIDP = identityProviders.find(idp => idp.value === target.value);
      const clientIdentityProviderNewState = {
        id: target.value,
        identity_provider_name: correspondingIDP.label,
        is_multitenant: correspondingIDP.is_multitenant,
        enabled: true
      };
      if (correspondingIDP.is_multitenant === 0) {
        clientIdentityProviderNewState.metadata_url = '';
      }
      setState(old => {
        return updateNestedMutable(old, {
          data: {
            is_valid_metadata: correspondingIDP.is_multitenant === 0 ? false : old?.data?.is_valid_metadata,
            client_identity_provider: clientIdentityProviderNewState
          }
        });
      });
    },
    [identityProviders]
  );

  const handleBtnStatus = resp => {
    if (resp.status === 200) {
      dispatch({
        type: BSN_SET_ANY,
        payload: {
          user: {
            profile: {
              shared: {
                buttonsStatus: { ...buttonsState, federation_enabled: Boolean(resp.data.federation_enabled) }
              }
            }
          }
        }
      });
    }
  };

  const handleConntecting = async () => {
    setState(old => updateNestedMutable(old, { is_save_pending: true }));
    try {
      const resp = await clients.putInformation(setId, 'federated', {
        client_identity_provider: { ...state?.data?.client_identity_provider, enabled: true }
      });
      notify(`Saved Federated Login Successfully`, 'success');
      handleBtnStatus(resp);
      setState(old => updateNestedMutable(old, { original_data: resp.data, data: resp.data, is_save_pending: false }));
    } catch (err) {
      notify(
        `Failed to save Federated Login:${err?.response?.data?.message ||
          err?.response?.data?.description ||
          'Network Error'}`,
        'error'
      );
      setState(old => {
        return updateNestedMutable(old, { is_save_pending: false });
      });
    }
  };
  const handleDisConnecting = async () => {
    setState(old => updateNestedMutable(old, { is_save_pending: true }));
    try {
      const resp = await clients.putInformation(setId, 'federated', {
        client_identity_provider: { ...state?.data?.client_identity_provider, enabled: false }
      });
      notify(`Saved Federated Login Successfully`, 'success');
      handleBtnStatus(resp);
      onDisconnect && onDisconnect();
      // getProviderConfigEffect();
      setState(old => updateNestedMutable(old, { original_data: resp.data, data: resp.data, is_save_pending: false }));
    } catch (err) {
      notify(
        `Failed to save Federated Login:${err?.response?.data?.message ||
          err?.response?.data?.description ||
          'Network Error'}`,
        'error'
      );
      setState(old => {
        return updateNestedMutable(old, { is_save_pending: false });
      });
    }
  };

  React.useEffect(() => {
    if (provider && state?.data?.client_identity_provider?.enabled === false && identityProviders?.length) {
      handleClientIdentityProviderChanged({ target: { value: provider } });
    }
  }, [
    handleClientIdentityProviderChanged,
    identityProviders?.length,
    provider,
    state?.data?.client_identity_provider?.enabled
  ]);

  return (
    <>
      <div
        style={{
          minHeight: 288,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        {error.isError ? (
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p>{error.msg}</p>
          </span>
        ) : loading ? (
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </span>
        ) : (
          <>
            <SelectField
              fullWidth
              disabled={state?.data.federation_enabled}
              allowEmpty={false}
              id="selectIdentityProviders"
              label="Select Providers"
              choices={state?.data.identity_providers?.map(({ id, name, is_multitenant }) => {
                return {
                  value: id,
                  label: name,
                  is_multitenant
                };
              })}
              name="selectIdentityProviders"
              value={state?.data.client_identity_provider?.id}
              onChange={handleClientIdentityProviderChanged}
            />

            {![null, undefined].includes(state?.data?.client_identity_provider) && (
              <>
                {state?.data?.client_identity_provider?.id !== PROVIDER_ID.GOOGLE && (
                  <TextField
                    value={state?.data?.client_identity_provider?.metadata_url}
                    disabled={state.data.federation_enabled}
                    name="metadata_url"
                    label="Metadata URL"
                    fullWidth
                    onChange={metaDataUrlChanged}
                    error={state?.data?.client_identity_provider?.metadata_url && !state?.data?.is_valid_metadata}
                    helperText={
                      !state?.data?.is_valid_metadata &&
                      state?.data?.client_identity_provider?.metadata_url &&
                      `Please enter a valid metadata URL`
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Delete
                            message="Redirect URL has been successfully copied"
                            onClick={() =>
                              state.data.federation_enabled || metaDataUrlChanged({ target: { value: '' } })
                            }
                            style={{ cursor: `${state.data.federation_enabled ? 'not-allowed' : 'pointer'}` }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                {state?.data?.client_identity_provider?.is_multitenant === 0 && (
                  <>
                    <TextField
                      value={appIdUrl}
                      disabled={state.data.federation_enabled}
                      name="urn"
                      label="Application ID URL"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyButton text={appIdUrl} message="Application ID URL has been successfully copied" />
                          </InputAdornment>
                        )
                      }}
                    />

                    <TextField
                      value={redirectUri}
                      disabled={state.data.federation_enabled}
                      name="redirect_uri"
                      label="Redirect URL"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyButton text={redirectUri} message="Redirect URL has been successfully copied" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </>
                )}
                {state?.data?.client_identity_provider?.id === PROVIDER_ID.OKTA && (
                  <div style={{ width: '100%' }}>
                    <ButtonCancel onClick={downloadFile} height={36} style={{ float: 'right', margin: '5px auto' }}>
                      <NewDownload />
                      &nbsp;&nbsp;Download Certificate
                    </ButtonCancel>
                  </div>
                )}
              </>
            )}

            <div className={classes.actionsContainer}>
              {state?.data.federation_enabled ? (
                <Button
                  backgroundColor="colorSystemDanger"
                  backgroundColorHover="colorSystemDangerHover"
                  backgroundDisabled="colorSystemDangerDisable"
                  onClick={handleDisConnecting}
                  disabled={false}
                  fullwidth
                >
                  <Disconnect />
                  &nbsp;&nbsp; Disconnect
                </Button>
              ) : (
                <Button
                  backgroundColor="colorSystemSuccess"
                  backgroundColorHover="colorSystemSuccessHover"
                  backgroundDisabled="colorSystemSuccessDisable"
                  onClick={handleConntecting}
                  fullwidth
                  disabled={
                    isEqual(state?.original_data ?? {}, state?.data ?? {}) ||
                    state?.is_loading ||
                    state?.is_save_pending ||
                    (!state?.data?.is_valid_metadata &&
                      state?.data?.client_identity_provider?.identity_provider_name !== 'Google') ||
                    JSON.stringify(state?.data ?? {}) === JSON.stringify(state?.initial_data ?? {})
                  }
                >
                  <Union />
                  &nbsp;&nbsp;Connect
                </Button>
              )}
              <ButtonCancel color="default" fullWidth onClick={onCancel}>
                Cancel
              </ButtonCancel>
            </div>
          </>
        )}
      </div>
    </>
  );
};

SingleSignOnForm.propTypes = {
  provider: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default SingleSignOnForm;
