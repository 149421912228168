// @flow
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { makeStyles, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Compressor from 'compressorjs';
import { useNotify } from 'react-admin';
import {
  getCurrentTimeStamp,
  getUrlsInsideText,
  validateFileFormat,
  validateExecutableFile
} from 'helpers';
import { ChartBarUpload } from 'components';
import { Thumbnail, TextEditor } from '../common';
import newsfeed from 'helpers/apis/newsfeed';
import { MAX_IMG_SIZE } from '../../constants';
import { getAssetUrl } from '../../newsfeedHelpers';
import WarningTriangle from 'components/icons/WarningTriangle';


const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '100%'
  },
  closeFileBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: 'var(--backgroundDefault) !important',
    zIndex: '1'
  },
  uploadProgress: {
    background: 'var(--backgroundDefault)'
  }
}));

let cancelTokenSource;

const FeedEditMode = ({
  data,
  setData,
  initialData,
  textType,
  loading,
  setLoading,
  startUpload,
  setStartUpload,
  startPreview,
  setStartPreview,
  setFileChanged,
  onSubmit,
  filePreviewData,
  setFilePreviewData,
  videoProgress,
  setVideoProgress,
  disableAddingContent,
  editorData,
  setDisableCommentBtn
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [deletedThumbnails, setDeletedThumbnails] = useState([]);
  const [dangerousUrl, setDangerousUrl] = useState(data[textType].includes(localStorage.getItem("prohibitedLink")));
  const previewFile = useCallback(
    target => {
	  const filename = target.files[0].name;
      const isValidFormat = validateFileFormat(filename, target.accept);
	  const isExecutableFile = validateExecutableFile(filename);

      if (isValidFormat && !isExecutableFile) {
        const fileSize = target.files[0].size;
        // Add file size limitation for img upload
        if (target.name === 'image' && fileSize > MAX_IMG_SIZE) {
          notify(
            'This image exceeds the 10MB maximum image size. Please resize the image and try again',
            'warning',
            undefined,
            false,
            6000
          );
        } else {
          setFileChanged(true);
          setFilePreviewData({
            preview: target.name === 'data' ? filename : URL.createObjectURL(target.files[0]),
            type: target.name,
            file: target.files[0]
          });
          setData({ thumbnail_metadata: null });
        }
      } else {
        notify(
          `This type of file is not allowed. Please use a ${target.accept.toUpperCase()} file`,
          'warning',
          undefined,
          false,
          6000
        );
      }
      target.value = '';
    },
    [notify, setData]
  );

  const onChangeText = useCallback(
    text => {
      setData({ [textType]: text });
      text.includes(localStorage.getItem('prohibitedLink'))? setDangerousUrl(true) : setDangerousUrl(false);
      if (!filePreviewData && !disableAddingContent) {
        const cleanText = text.replace(/<[^>]*>?/gm, ' ');
        const links = getUrlsInsideText(cleanText);
        const deletedList = links && deletedThumbnails.filter(el => links.includes(el));

        if (deletedList) setDeletedThumbnails([...deletedList]);
        else if (!links) setDeletedThumbnails([]);

        const linkToPreview = links && links.filter(el => !deletedList.includes(el))[0];

        if (
          links &&
          links.length !== 0 &&
          (!data.thumbnail_metadata || data.thumbnail_metadata.url !== linkToPreview)
        ) {
          const uniqueLinks = [...new Set(links)];

          if (uniqueLinks.length !== deletedList.length) {
            setLoading(true);
            newsfeed
              .getLinkPreview(linkToPreview)
              .then(res => {
                const thumbnailData = res.data.thumbnail_metadata;
                setDangerousUrl(res.data.is_dangerous_url);
                res.data.is_dangerous_url && localStorage.setItem("prohibitedLink", linkToPreview) 
                if (thumbnailData === null) setDeletedThumbnails([...deletedList, linkToPreview]);
                setData({ thumbnail_metadata: thumbnailData, [textType]: text });
              })
              .catch(err => {
                console.log(err);
              })
              .finally(() => setLoading(false));
          }
        } else if ((!links || links.length === 0) && data.thumbnail_metadata) {
          setData({ thumbnail_metadata: null });
        }
      }
    },
    [filePreviewData, disableAddingContent, deletedThumbnails, data.thumbnail_metadata, textType, setData]
  );

  const onUploadProgress = useCallback(
    event => {
      if (filePreviewData.type === 'video') {
        const percent = Math.round((100 * event.loaded) / event.total);
        setVideoProgress(percent === 0 ? 0.1 : percent);
        if (percent === 100) setLoading(true);
        else setLoading(false);
      }
    },
    [filePreviewData?.type]
  );

  const uploadFile = useCallback(
    file => {
      const filename = `${getCurrentTimeStamp()}_` + file.name;
      if (filePreviewData.type === 'video') cancelTokenSource = axios.CancelToken.source();
      if (setDisableCommentBtn) setDisableCommentBtn(true);
      newsfeed
        .getPresignedPost(filename)
        .then(res => {
          console.log(res);
          const formDataType = new FormData();
          formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
          formDataType.append('key', res.data.fields.key);
          formDataType.append('policy', res.data.fields.policy);
          formDataType.append('signature', res.data.fields.signature);
          formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
          formDataType.append('file', file);
          newsfeed
            .uploadFile(res.data.url, formDataType, onUploadProgress, cancelTokenSource?.token)
            .then(result => {
              setData({ filename, thumbnail_metadata: null });
              onSubmit(filename);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          if (setDisableCommentBtn) setDisableCommentBtn(false);
        });
    },
    [filePreviewData?.type, onSubmit, setData, onUploadProgress]
  );

  const handleUpload = useCallback(() => {
    if (filePreviewData.type === 'video') setVideoProgress(0.1);
    else setLoading(true);
    switch (filePreviewData.type) {
      case 'image':
        new Compressor(filePreviewData.file, {
          quality: 0.6,
          success: res => {
            uploadFile(res);
          }
        });
        break;
      default:
        uploadFile(filePreviewData.file);
        break;
    }
  }, [filePreviewData?.type, filePreviewData?.file, uploadFile]);

  const onCancelUpload = useCallback(() => {
    if (cancelTokenSource && setVideoProgress) {
      cancelTokenSource.cancel('Upload cancelled');
      cancelTokenSource = null;
      setVideoProgress(0);
      setFilePreviewData(null);
      setLoading(false);
    }
  }, []);

  const deleteFilePreview = useCallback(() => {
    setFilePreviewData(null);
    setData({ filename: null });
  }, [setData]);

  const deleteThumbnail = useCallback(() => {
    setDeletedThumbnails([...new Set([...deletedThumbnails, data.thumbnail_metadata.url])]);
    setData({ thumbnail_metadata: null });
  }, [deletedThumbnails, data.thumbnail_metadata?.url, setData]);

  useEffect(() => {
    return () => onCancelUpload();
  }, [onCancelUpload]);

  useEffect(() => {
    if (initialData) {
      const cleanText = initialData[textType].replace(/<[^>]*>?/gm, ' ');
      const links = getUrlsInsideText(cleanText);
      if (links) {
        const filteredLinks = links.filter(item => item !== initialData.thumbnail_metadata?.url);
        setDeletedThumbnails(filteredLinks);
      }
    }
  }, [initialData, textType]);

  useEffect(() => {
    if (startUpload) {
      handleUpload();
      setStartUpload(false);
    }
  }, [startUpload, handleUpload]);

  useEffect(() => {
    if (startPreview) {
      previewFile(startPreview);
      setStartPreview(false);
    }
  }, [startPreview, previewFile]);

  return (
    <>
      <TextEditor
        name="text"
        currState={data[textType]}
        onStateChange={onChangeText}
        fontSize="16px"
        allowResize
        textColor={dangerousUrl? 'var(--colorSystemDanger)': null}
        {...editorData}
      />
      {dangerousUrl && (
          <div style={{ color: 'var(--colorSystemDanger)' }}>
            <WarningTriangle style={{ verticalAlign: 'middle' }} />
            <span> Potentially dangerous link detected, please try another link. </span>
          </div>
      )}
      {data.thumbnail_metadata && !filePreviewData && (
        <Box mt={2}>
          <Thumbnail data={data.thumbnail_metadata} deleteThumbnail={deleteThumbnail} allowDelete />
        </Box>
      )}

      {videoProgress > 0 && (
        <Box className={classes.uploadProgress} pl={2.5} pr={2} pt={1.8} pb={1} my={2} borderRadius={5}>
          <ChartBarUpload
            title="Uploading video"
            tooltipText="Cancel upload"
            height={5}
            backgroundColor="var(--colorSystemInfo)"
            backgroundMain="var(--whiteAndBlack)"
            progressPercent={videoProgress}
            onCancel={onCancelUpload}
          />
        </Box>
      )}

      {filePreviewData && (
        <Box position="relative" mt={{ xs: 1.5, md: 2 }}>
          <IconButton
            className={classes.closeFileBtn}
            onClick={deleteFilePreview}
            disabled={loading || videoProgress > 0}
          >
            <CloseIcon />
          </IconButton>
          {{
            image: <img className={classes.image} src={getAssetUrl(filePreviewData.preview)} alt="post" />,
            video: (
              <video className={classes.image} controls poster={filePreviewData.poster} key={filePreviewData.preview}>
                <source src={filePreviewData.preview} />
              </video>
            )
          }[filePreviewData.type] || (
            <Box py={1.1} pr={4.5} style={{ wordBreak: 'break-word' }}>
              {filePreviewData.preview.replace(/.*?_/, '')}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default FeedEditMode;
