import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SINGLE_SIGNON_MODALS } from 'apps/shared/users/modals/constants';
import { FEDERATION_SERVICES } from '../constants';
import { isNA } from '../helpers';

const redirectionTargetMap = {
  [FEDERATION_SERVICES.O365]: SINGLE_SIGNON_MODALS.O365,
  [FEDERATION_SERVICES.GOOGLE]: SINGLE_SIGNON_MODALS.GOOGLE,
  [FEDERATION_SERVICES.OKTA]: SINGLE_SIGNON_MODALS.OKTA
};

const DISPALYED_SERVICE_MSG = {
  [FEDERATION_SERVICES.GOOGLE]: 'Google',
  [FEDERATION_SERVICES.OKTA]: 'Okta',
  [FEDERATION_SERVICES.O365]: 'Office O365',
};

const SingleSignonCell = ({ enabled, service, clientId }) => {
  if (isNA(enabled)) return <Typography variant="body2">N/A</Typography>;

  const Icon = enabled ? CircleCheckIcon : CircleCloseIcon;
  const color = enabled ? 'success' : 'error';
  const tooltipTitle = enabled ? DISPALYED_SERVICE_MSG[service] : 'Click here to configure Single Sign-On Login';

  const redirectionTarget = redirectionTargetMap[service] || SINGLE_SIGNON_MODALS.DEFAULT;

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton
        LinkComponent={Link}
        to={`/clients/users/${clientId}?redirectionTarget=${redirectionTarget}`}
        color={color}
      >
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

SingleSignonCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired
};

export default SingleSignonCell;
