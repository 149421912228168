import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_12;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const pushNotifications = {};

pushNotifications.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

pushNotifications.getPushNotificationsSubscriptions = (partnerID, clientID = null) => {
  const requestOptions = {
    url: `${baseURL}PushNotificationsSubscriptions/${partnerID}`,
    headers: pushNotifications.getRequestHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.get(requestOptions);
};

pushNotifications.createSubscription = (partnerID, clientID = null, data) => {
  const requestOptions = {
    url: `${baseURL}PushNotificationsSubscriptions/${partnerID}`,
    data,
    headers: pushNotifications.getRequestHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.post(requestOptions);
};

pushNotifications.enableDisableSubscription = (partnerID, clientID = null, data) => {
  const requestOptions = {
    url: `${baseURL}PushNotificationsSubscriptions/${partnerID}`,
    data,
    headers: pushNotifications.getRequestHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.put(requestOptions);
};

pushNotifications.getSubscription = subscriptionID => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}`,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

pushNotifications.setSubscription = (subscriptionID, subscription) => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}`,
    data: subscription,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

pushNotifications.revertSubscription = subscriptionID => {
  const requestOptions = {
    url: `${baseURL}/Revert/subscription/${subscriptionID}`,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

pushNotifications.testPushNotificationsMessage = (subscriptionID, customMessage) => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}/test_push_notification_message`,
    data: customMessage,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

pushNotifications.getSendingTimes = (partner_id, client_id) => {
  const requestOptions = {
    url: `${baseURL}/Notifications/SendingTime/${partner_id}${client_id == null ? '' : `?client_id=${client_id}`}`,
    headers: pushNotifications.getRequestHeaders(true)
  };
  return apiClient.get(requestOptions);
};

pushNotifications.postSendingTimes = (partner_id, client_id, data) => {
  const requestOptions = {
    url: `${baseURL}/Notifications/SendingTime/${partner_id}${client_id == null ? '' : `?client_id=${client_id}`}`,
    data,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

export default pushNotifications;
