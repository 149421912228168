/* eslint-disable no-nested-ternary */
// @flow
import React, {
  createElement,
  createContext,
  type ComponentType,
  type Element,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { Error, Notification, Sidebar } from 'ra-ui-materialui';
import { useLocation } from 'hooks';
import { theme, BSN_SYSTEM, mobilePagesWhiteList, BSN_SET_ANY } from 'conf';
import styled, { css } from 'styled-components';
import { isMaskModeEnabled, USER_ROLES } from 'helpers';
import { useMediaQuery, useTheme, Hidden, Link } from '@material-ui/core';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { Bell } from 'components/icons';
import { ButtonCancel } from 'components/modal';
import { Typography } from 'components/types';
import { ButtonLink } from 'apps/company/ComponentTypes';
import ClientIndustryModal from 'apps/clients/ClientIndustryModal';
import clients from 'helpers/apis/clients';
import { useNotify } from 'ra-core';
import { NOTIFICATION_CUSTOM_EVENT, DISPATCH_CUSTOM_EVENT } from 'conf/constants';
import { AppBar } from './AppBar';
import { Menu } from './Menu';
import { Toolbar } from './Toolbar';
import { Footer } from '../common';
import { GlobalStyleBase } from './GlobalStyle';
import PaymentAlerts from './PaymentAlerts';
import PositiveOptinAlert from './PositiveOptinAlert';
import MaskModeAlert from './MaskModeAlert';
import MobileHeader from './MobileHeader';
import PageUnavailable from '../mobile/PageUnavailable';
import useWindowSize from '../../hooks/useWindowSize';
import { LayoutContextProvider } from './LayoutContext';
import useLayout from './useLayout';
import IndustryAlert from './IndustryAlert';
import WelcomeMessageAlert from './WelcomeMessageAlert';
import EngagmentDialog from 'apps/clients/presets/Modals/EngagmentDialog';

const sideMenuWidth = '300px';

export const MainContext = createContext(null);

export const Root: ComponentType<*> = styled.div`
  width: 100%;
  min-height: 100vh;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};

  ${props => props.theme.breakpoints.down('sm')} {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const Frame: ComponentType<*> = styled.div`
  display: ${({ centered }) => (centered ? 'flex' : 'block')};
  margin-left: ${({ centered }) => (centered ? 0 : sideMenuWidth)};
  width: ${({ centered }) => (centered ? '100%' : `calc(100% - ${sideMenuWidth})`)};
  ${({ centered }) => (centered ? 'flex-direction: column' : '')};
  ${({ hide }) =>
    hide &&
    css`
      opacity: 0.5;
    `};

  ${props => props.theme.breakpoints.down('sm')} {
    transform: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: initial;
    height: 100%;
    margin-left: 0;
  }

  > header,
  > footer {
    ${({ centered }) => (centered ? 'flex: none;' : '')};
  }
`;
export const Main: ComponentType<*> = styled.main`
  display: ${({ centered }) => (centered ? 'flex' : 'block')};
  ${({ centered, isHaveAlert }) =>
    centered
      ? `
      flex-direction: column;
      flex: 1 0 auto;
    `
      : `
      height: auto;
  `};

  ${props => props.theme.breakpoints.down('sm')} {
    height: auto;
    flex-grow: 1;
    overflow-y: auto;
  }

  position: relative;
  /* padding-top: ${({ centered }) => (centered ? 0 : theme.layout.header.height)}; */
`;

export const SideBar: ComponentType<*> = styled.div`
  display: flex;
  position: fixed;
  height: calc(100vh);
  z-index: ${theme.zIndex.sidebar};
  box-sizing: border-box;
  width: ${sideMenuWidth};
  border-radius: 0 15px 15px 0;

  ${props => props.theme.breakpoints.down('sm')} {
    border-radius: 0 5px 5px 0;
    width: 285px;
    left: ${({ open }) => (open ? 0 : -285)}px;
    transition: 0.4s ease-in-out;
  }

  a {
    color: var(--colorCommonWhite);
    font-size: 1.2rem;
    letter-spacing: 2px;
    align-items: center;
    &:hover {
      background-color: var(--colorBaseDark2);
    }
  }

  .link-wrap {
    position: relative;
    &:hover {
      background-color: var(--colorBaseDark2);
    }
  }

  > div > div {
    margin-top: 0;
  }
`;

export const Content: ComponentType<*> = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: ${({ hasSidebar }) => (hasSidebar ? '0 12' : '0 16')}px;

  width: 100%;
  display: inline-block; /* to make margins work on Safari */
  ${({ sidebar }) =>
    sidebar &&
    css`
      margin-bottom: 60px;
    `}

  ${props => props.theme.breakpoints.down('sm')} {
    overflow: unset;
    padding: 0;
    height: unset;
    ${({ access }) =>
      !access &&
      css`
        min-height: 100%;
        height: unset;
      `}
  }
`;

const sanitizeRestProps: Object = ({ staticContext, history, location, match, ...props }) => props;

type LayoutTypes = {
  appBar?: ComponentType<any>,
  children?: Function | Node | Element<*>,
  customRoutes?: Array<*>,
  dashboard?: Element<*>,
  error?: ComponentType<*>,
  history: Object,
  logout: Node | Function | string,
  menu?: ComponentType<*>,
  minWidth?: string | null,
  maxWidth?: string | null,
  notification?: ComponentType<*>,
  open?: boolean,
  headerTop?: number,
  sidebar?: ComponentType<*>,
  footer?: Element<*> | null,
  title: Node,
  centered?: boolean,
  contentRef: ?HTMLDivElement,
  userProfile: Object,
  dispatch: Function,
  location: Object
};

const Layout = (props: LayoutTypes) => {
  const system = useSelector(state => state.bsn.system);
  const { access } = useSelector(({ bsn }) => bsn.user);
  const app_dispatch = useDispatch();
  const { record, dispatch: dispatchLayout } = useLayout();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const muiTheme = useTheme();
  const mobileView = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const { app, tab } = useLocation();
  const isMaskMode = isMaskModeEnabled(app);
  const contentRef = useRef();
  const [openModalSideBar, setOpenModalSideBar] = useState(false);
  const notify = useNotify();
  const [, height] = useWindowSize();
  const [openEngagmentDialog, setOpenEngagmentDialog] = useState(false);

  const { user_role, client_id, client_industry, product_name, client_name } = useSelector(
    s => s?.bsn?.user?.profile ?? {}
  );

  const { client_industries } = useSelector(s => s?.bsn?.client ?? {});
  React.useEffect(() => {
    const callback = event => {
      const error = event.detail;
      if (!error) {
        notify('Something went wrong', 'error');
        return;
      }
      if (typeof error == 'string') {
        notify(error, 'error');
        return;
      }
      const data = error.response?.data ?? error.response;
      if (!data || error.response.status === 500) {
        notify('Something went wrong', 'error');
        return;
      }
      if (typeof data === 'string') {
        notify(data, 'error');
        return;
      }
      if (data.description || data.message) {
        notify(data.message || data.description || 'Something went wrong', 'error');
        return;
      }
      if (typeof error.description === 'string' || typeof error.message === 'string') {
        notify(error.description || error.message, 'error');
      }
    };
    document?.body && document.body.addEventListener(NOTIFICATION_CUSTOM_EVENT, callback, false);
    return function notification_cleaup() {
      document?.body && document.body.removeEventListener(NOTIFICATION_CUSTOM_EVENT, callback);
    };
  }, [notify]);

  React.useEffect(() => {
    const callback = event => {
      const dispatchArgs = event.detail;
      if (!dispatchArgs?.type || !dispatchArgs?.payload) {
        return;
      }
      app_dispatch(dispatchArgs);
    };
    document?.body && document.body.addEventListener(DISPATCH_CUSTOM_EVENT, callback, false);
    return function notification_cleaup() {
      document?.body && document.body.removeEventListener(DISPATCH_CUSTOM_EVENT, callback);
    };
  }, [app_dispatch]);

  const [layoutState, setLayoutState] = React.useState({
    industryModalOpen: false,
    loading_industry_modal: false,
    selected_industry: { value: '', label: '' },
    pending_industry_save: false
  });

  const {
    appBar,
    children,
    error,
    dashboard,
    logout,
    menu,
    minWidth,
    maxWidth,
    notification,
    sidebar,
    footer,
    title,
    centered,
    dispatch,
    location
  } = props;
  const isApp = !centered;

  const isPageAvailable = (app, tab) => {
    if ((!app || tab === false || tab === 'false') && app !== 'clients') return true;

    if (mobilePagesWhiteList[app]) {
      if (app === 'clients' && !tab) {
        return true;
      }
      return mobilePagesWhiteList[app].includes(tab);
    }
    return false;
  };

  useEffect(() => {
    if (!isApp) return;
    const newSysten = system;
    newSysten.sidebar = sidebar !== null;
    newSysten.app = isApp;
    newSysten.location = app;
    if (system.locationPrevious === null) newSysten.locationPrevious = newSysten.location;
    props.history.listen(() => {
      if (system.hasError) newSysten.hasError = false;
    });
    if (JSON.stringify(system) !== JSON.stringify(newSysten)) {
      dispatch({ type: BSN_SYSTEM, source: 'system', payload: newSysten });
    }
  }, [app, centered, dispatch, isApp, props, sidebar, system]);

  useEffect(() => {
    setOpenModalSideBar(false);
  }, [app, tab]);

  const { hasError, errorMessage, errorInfo, hasSidebar, hasDarkMode } = system;
  const mainRef = useRef();

  const isBaseApp = app === 'login' || app === 'logout' || app === 'signup';

  function openModalIndustries() {
    setLayoutState(old => ({ ...old, loading_industry_modal: true, industryModalOpen: true }));
    if (client_industries && client_industries.length) {
      setLayoutState(old => ({ ...old, loading_industry_modal: false, industryModalOpen: true }));
      return;
    }
    clients
      .getPickList({
        client_id,
        types: 'industries'
      })
      .then(industriesRes => {
        if (industriesRes.data) {
          app_dispatch({
            type: BSN_SET_ANY,
            payload: {
              client: {
                client_industries: industriesRes?.data?.industries?.map(v => ({ value: v.id, label: v.name } ?? []))
              }
            }
          });
        }
      })
      .catch(err => {
        setLayoutState(old => ({ ...old, isIndustryModalOpen: false }));
        notify('failed', 'error');
      })
      .finally(() => {
        setLayoutState(old => ({ ...old, loading_industry_modal: false }));
      });
  }

  function saveSelectedClientIndustry() {
    if (!client_id || client_industry?.id !== null) {
      return;
    }
    setLayoutState(old => ({ ...old, pending_industry_save: true }));
    console.log('Client_ID:', { client_id });
    clients
      .setIndustryToClient(client_id, {
        industry_id: layoutState.selected_industry.value,
        product_type: product_name,
        name: client_name
      })
      .then(r => {
        if (r.status === 200) {
          notify('Successfully added Industry', 'success');
          app_dispatch({
            type: BSN_SET_ANY,
            payload: {
              user: {
                profile: {
                  client_industry: {
                    id: layoutState.selected_industry.value,
                    name: layoutState.selected_industry.label
                  }
                }
              }
            }
          });
          setLayoutState(old => ({
            ...old,
            isIndustryModalOpen: false,
            selected_industry: { label: '', value: '' },
            pending_industry_save: false
          }));
        }
      })
      .catch(err => {
        notify('Failed to add Industry', 'error');
        setLayoutState(old => ({
          ...old,
          selected_industry: { label: '', value: '' },
          pending_industry_save: false
        }));
      });
  }
  // Scroll to the top of the page on location change
  useEffect(() => {
    if (mainRef?.current) {
      mainRef.current.scrollTop = 0;
    }
  }, [location.pathname, mainRef]);

  return (
    <LayoutContextProvider
      value={{
        setPopoverOpen
      }}
    >
      {// TODO: Temporary solution to have color scheme on login page
      isBaseApp && <GlobalStyleBase />}
      <Root
        {...sanitizeRestProps(props)}
        minWidth={minWidth}
        maxWidth={maxWidth}
        centered={centered}
        style={{
          minHeight: '-webkit-fill-available',
          height: '-webkit-fill-available',
          webkitOverflowScrolling: 'touch'
        }}
      >
        {sidebar && (
          <SideBar
            open={openModalSideBar}
            style={{ minHeight: '-webkit-fill-available', height: '-webkit-fill-available' }}
          >
            <Toolbar />
            {menu
              ? createElement(menu, {
                  logout,
                  setOpenModalSideBar,
                  hasDashboard: !!dashboard
                })
              : null}
          </SideBar>
        )}
        <Frame
          hide={popoverOpen && !mobileView}
          centered={centered}
          // style={{minHeight: "-webkit-fill-available", height: "-webkit-fill-available" }}
        >
          {isMobileDevice && mobileView && !isPageAvailable(app, tab) && access ? (
            <Hidden mdUp>
              <PageUnavailable />
            </Hidden>
          ) : (
            <>
              <Hidden mdUp>
                <MobileHeader open={openModalSideBar} setOpen={setOpenModalSideBar} access={access} />
              </Hidden>
              <PaymentAlerts />
              {app == 'clients' && <WelcomeMessageAlert onClick={() => setOpenEngagmentDialog(true)} />}
              {openEngagmentDialog && <EngagmentDialog onClose={() => setOpenEngagmentDialog(false)} />}
              <PositiveOptinAlert />
              {!isBaseApp &&
                client_industry?.id === null &&
                [USER_ROLES.MANGER, USER_ROLES.MANAGER_ADMIN].includes(user_role) && (
                  <>
                    <IndustryAlert
                      onLinkClick={() => {
                        openModalIndustries();
                      }}
                    />
                    <ClientIndustryModal
                      onClose={() => {
                        setLayoutState(old => ({
                          ...old,
                          industryModalOpen: false,
                          selected_industry: { label: '', value: '' }
                        }));
                      }}
                      opened={layoutState.industryModalOpen}
                      choices={client_industries}
                      pendingAction={layoutState.pending_industry_save}
                      loading={layoutState.loading_industry_modal}
                      selectedValue={layoutState.selected_industry.value || client_industry?.id}
                      onChange={ev => {
                        const { value } = ev.target;
                        setLayoutState(old => ({
                          ...old,
                          selected_industry: client_industries?.find(v => v.value == value) ?? old.selected_industry
                        }));
                      }}
                      onSave={() => {
                        saveSelectedClientIndustry();
                      }}
                    />
                  </>
                )}
              {appBar &&
                createElement(appBar, {
                  title,
                  logout,
                  centered,
                  hasDarkMode
                })}
              {isMaskMode && <MaskModeAlert />}
              <Main ref={mainRef} centered={centered} isHaveAlert={access?.alerts}>
                <Content
                  onScroll={e => e}
                  ref={contentRef}
                  hasSidebar={hasSidebar}
                  centered={centered}
                  access={access}
                  sidebar={!!sidebar}
                >
                  <MainContext.Provider value={{ mainRef }}>
                    {hasError && error
                      ? createElement(error, {
                          error: errorMessage,
                          errorInfo,
                          title
                        })
                      : children}
                  </MainContext.Provider>
                </Content>
              </Main>
              {footer || <Footer sidebar={!!sidebar} />}
            </>
          )}
        </Frame>
        {notification && createElement(notification)}
      </Root>
    </LayoutContextProvider>
  );
};

Layout.defaultProps = {
  children: null,
  customRoutes: [],
  dashboard: null,
  open: false,
  appBar: AppBar,
  error: Error,
  menu: Menu,
  minWidth: null,
  maxWidth: null,
  notification: Notification,
  sidebar: Sidebar,
  footer: null,
  headerTop: 0,
  centered: false
};

const EnhancedLayout = compose(
  connect(() => {}),
  withRouter
)(Layout);

export default EnhancedLayout;
