import React from 'react';
import { Info, Typography, DialogModal } from 'components';
import { Link } from '@material-ui/core';
import styled from 'styled-components';
import { useId, useDataGet } from 'hooks';
import profileAPI from 'helpers/apis/UsersAPI/updateProfile';
import { getNameShortcut, getStorage } from 'helpers';

const AlertContainer = styled.div`
  height: 3.6rem;
  padding: 1rem;
  background: var(--colorSystemInfo);
  color: var(--colorCommonWhite);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PositiveOptinAlert = () => {
  const [openOptInModal, setOpenOptInModal] = React.useState(false);
  const userId = useId({ key: 'userId' });
  const userRole = localStorage.getItem('userRole');
  const [queryEnabled, setQueryEnabled] = React.useState(false)

  const isLoggedIn = !!getStorage('idToken', true) && !!userId
  
  const { data, refetch } = useDataGet({
    app: 'user',
    tab: 'profile',
    item: userId,
    options: { refetchOnWindowFocus: false, enabled: queryEnabled }
  });

  React.useEffect(() => {
    if (isLoggedIn) setQueryEnabled(true)
  }, [isLoggedIn])
  
  const isMorMA = ['M', 'MA'].includes(getNameShortcut(userRole));
  const userElectedButDidntOptin = data?.positive_optin === false;
  const showBanner = userElectedButDidntOptin && isMorMA;

  const onEnablePositiveOptin = () => {
    profileAPI
      .update(userId, { ...data, positive_optin: true })
      .then(() => refetch())
      .catch(err => console.error(err))
      .finally(() => setOpenOptInModal(false));
  };

  return (
    showBanner && (
      <>
        <AlertContainer>
          <Info />
          <Typography.p ml={1.5} color="var(--colorCommonWhite)">
            <Link
              style={{
                color: ' var(--colorCommonWhite)',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => setOpenOptInModal(true)}
            >
              Click here
            </Link>{' '}
            to enable Positive Opt-in to all Micro Training/Monthly Newsletters
          </Typography.p>
        </AlertContainer>
        <DialogModal
          title="Enable Positive Opt-In"
          message="Enable my company to be opted in to receive Weekly Micro Training and Monthly Security Newsletter Emails. Employees will not be able to unsubscribe to these emails"
          buttonSubmit="Enable"
          open={openOptInModal}
          handleClose={() => setOpenOptInModal(false)}
          onSubmit={onEnablePositiveOptin}
          buttonProps={{
            backgroundColor: 'colorSystemSuccess',
            backgroundColorHover: 'colorSystemSuccessHover'
          }}
        />
      </>
    )
  );
};

export default PositiveOptinAlert;
