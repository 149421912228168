import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNA } from '../helpers';

const DIR_SYNC_SERVICES = [
  {
    key: ['OnPrem'], 
    value: 'On-Prem AD',
  },
  {
    key: ['g-suite'], 
    value: 'Google Workspace',
  },
  {
    key: ['multitenant', 'AppID', 'sync2'], 
    value: 'Azure AD',
  }
];

const DirectorySyncCell = ({ service, clientId, active }) => {
  const serviceTitle = useMemo(() => {
    return DIR_SYNC_SERVICES.find(serviceItem => serviceItem.key.includes(service))?.value || service;
  }, [service]);

  if (!active) return null;
  if (isNA(service)) return <Typography variant="body2">N/A</Typography>;

  const Icon = service ? CircleCheckIcon : CircleCloseIcon;
  const color = service ? 'success' : 'error';
  
  const tooltipTitle = serviceTitle || 'Click here to configure Directory Sync';

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton LinkComponent={Link} to={`/clients/directorySync/${clientId}`} color={color}>
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

DirectorySyncCell.propTypes = {
  service: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default DirectorySyncCell;
