import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  Tooltip,
  IconButton,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import trainings from 'helpers/apis/TestAuthoringSystem/trainings';
import notifications from 'helpers/apis/ContactUsers/notifications';
import { EmailValidator, Info, ButtonSubmit } from 'components';
import { useNotify } from 'react-admin';
import { decodeId, useCheckAccess } from 'helpers';
import { CONTENT_ADMIN } from '../constants';

const useStyles = makeStyles(theme => ({
  noMaxWidth: {
    maxWidth: 'none'
  }
}));

const Container = styled.div`
  background-color: var(--backgroundPaper);
  margin: 20px 20px;
  padding: 30px;
  border-radius: 8px;
`;

const Select = styled(MuiSelect)`
  width: ${props => (props.width ? props.width : '180px')};
`;

const Element = styled.div`
  margin: 0 30px 30px 0;
  display: inline-block;
`;

const emailInfoText = `Email addresses to receive notifications (separated by semicolon)*`;
const howToSendText = `To Successfully receive notifications please make sure that:
- The emails entered belong to active partners
- The emails entered belong to active/real clients
- The product type of the client is not HIPAA Compliance
\u00A0\u00A0 or Unlimited Cybersecurity Training
- The user is active and not deleted
- The user is not external
- The user has logged in at least once or received the welcome message email`;

const TYPES = {
  mt: 'micro_trainings',
  nl: 'newsletters'
};

const types = [
  {
    label: 'Micro Training',
    value: 'micro_trainings'
  },
  {
    label: 'News Letter',
    value: 'newsletters'
  }
];

const TestMTNL = () => {
  const hasAccess = useCheckAccess('app', CONTENT_ADMIN);
  const muiClasses = useStyles();
  const notify = useNotify();

  const [type, setType] = useState(types[0].value);
  const [names, setNames] = useState({
    options: [],
    selected: null
  });
  const [loadingNames, setLoadingNames] = useState(false);
  const [invalidEmails, setInvalidEmails] = useState(false);
  const [emails, setEmails] = useState([]);
  const [additionalParams, setAdditionalParams] = useState(null);
  const [disableOnSend, setDisableOnSend] = useState(false);

  const validateForm = () => {
    return invalidEmails || emails?.length === 0 || names.selected === null || !type;
  };

  const handleSend = () => {
    const payload = {
      type: type === TYPES.mt ? 'MicroTraining' : 'MonthlyBSN',
      email: emails,
      ...(type === TYPES.mt ? { QUIZCODE: decodeId(names.selected) } : { NEWSLETTERCODE: decodeId(names.selected) }),
      QUICKTIPS: additionalParams?.quicktips ? additionalParams.quicktips : '',
      TOPICTEXT: additionalParams?.topic_text ? additionalParams.topic_text : ''
    };
    setDisableOnSend(true);
    notifications
      .testMTNL(payload)
      .then(res => {
        console.log(res);
        notify('Sent Successfully', 'success');
      })
      .catch(err => {
        notify('Something went wrong while sending notifications', 'error');
      })
      .finally(() => {
        setDisableOnSend(false);
      });
  };
  const reset = () => {
    setNames({
      options: [],
      selected: null
    });
  };

  useEffect(() => {
    if (type) {
      setLoadingNames(true);
      trainings
        .getMTNL(type)
        .then(res => {
          setNames(prev => ({
            ...prev,
            options: res?.data?.quiz_data
          }));
        })
        .finally(() => {
          setLoadingNames(false);
        });
    }
  }, [type]);

  useEffect(() => {
    if (type && names.selected) {
      trainings.getMetaData(type, names.selected).then(res => {
        const quiz_meta_data = res?.data?.quiz_meta_data;
        setAdditionalParams(quiz_meta_data?.find(item => item));
      });
    }
  }, [names.selected]);

  if (!hasAccess) return <div>You don't have access for this page</div>;

  return (
    <>
      <Container>
        <Element>
          <FormControl variant="outlined" size="small">
            <InputLabel id="mt-nl-type-label">{`Type`}</InputLabel>
            <Select
              id="type"
              label="Type"
              value={type}
              onChange={e => {
                reset();
                setType(e.target.value);
              }}
              labelId="mt-nl-type-label"
            >
              {types.map(t => (
                <MenuItem value={t.value}>{t.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Element>
        <Element>
          <FormControl variant="outlined" size="small">
            <InputLabel id="mt-nl-name-label">{`Name - Created`}</InputLabel>
            <Select
              id="name"
              width="500px"
              label={'Name - Created'}
              value={names?.selected}
              disabled={type ? false : true}
              onChange={e => {
                setNames(prev => ({
                  ...prev,
                  selected: e.target.value
                }));
              }}
              labelId="mt-nl-name-label"
            >
              {loadingNames ? (
                <MenuItem value="" disabled>
                  {`Loading...`}
                </MenuItem>
              ) : (
                names?.options?.map(item => <MenuItem value={item?.id}>{`${item?.name} - ${item?.created}`}</MenuItem>)
              )}
            </Select>
          </FormControl>
        </Element>
        <div style={{ marginBottom: '30px' }}>
          <div style={{ width: '90%', display: 'inline-block' }}>
            <EmailValidator
              style={{ width: '90%' }}
              label="Email address*"
              newEmails={emails => {
                setInvalidEmails(!emails.valid);
                setEmails(emails.list);
              }}
            />
          </div>
          <Tooltip style={{ margin: '0 10px', verticalAlign: 'text-top' }} title={emailInfoText} placement="top">
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title={
              validateForm() ? 'you must choose a type, name and at least enter one email to send notification' : ''
            }
            placement="top"
          >
            <span>
              <ButtonSubmit onClick={handleSend} disabled={validateForm() || disableOnSend}>
                {disableOnSend && (
                  <CircularProgress size={14} thickness={2} style={{ color: `black`, marginRight: 10 }} />
                )}
                {'Send Notification'}
              </ButtonSubmit>
            </span>
          </Tooltip>
          <Tooltip
            style={{ margin: '0 10px' }}
            classes={{ tooltip: muiClasses.noMaxWidth }}
            title={<span style={{ whiteSpace: 'pre-line' }}> {howToSendText} </span>}
            placement="right"
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
        </div>
      </Container>
    </>
  );
};

export default TestMTNL;
