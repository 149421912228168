import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNA } from '../helpers';

const AutomatedReportsCell = ({ enabledCount, totalCount, clientId }) => {
  if (isNA(enabledCount)) return <Typography variant="body2">N/A</Typography>;

  return (
    <Tooltip title="Click here to navigate to the Notifications tab" placement="top">
      <Link
        component={RouterLink}
        to={`/clients/notification/${clientId}`}
        variant="body2"
        underline="hover"
        hoverColor="info.main"
      >
        {enabledCount}/{totalCount}
      </Link>
    </Tooltip>
  );
};

AutomatedReportsCell.propTypes = {
  enabledCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  clientId: PropTypes.string.isRequired
};

export default AutomatedReportsCell;
