import { getIdByResource } from 'helpers/utils';
import { decodeId } from 'helpers';
import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const baseURL =
  env === 'prod' ? `https://api.pii-protect.com/dashboard/` : `https://${env}.api.pii-protect.com/dashboard/`;

const dashboard = {};

dashboard.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

/**
 * fetches leaderboard data
 * @param app : currentl app name
 * @param sort : { field: name of sorted field, order: sorting direction }
 * @param pagination : { page: starting page, perPage: number of records in each page }
 * @returns a list of the leaderboard records
 */
dashboard.getLeaderBoard = ({ app, sort, pagination }) => {
  const id = getIdByResource(app, 'leaderboard');
  const requestOptions = {
    url: `${baseURL}${app}/leaderboard/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches leaderboard overall data
 * @param app : currentl app name
 * @param sort : { field: name of sorted field, order: sorting direction }
 * @param pagination : { page: starting page, perPage: number of records in each page }
 * @returns a list of the leaderboard overall records
 */
dashboard.getLeaderBoardOverAll = ({ app, sort, pagination }) => {
  const id = getIdByResource(app, 'leaderboardOverall');
  const requestOptions = {
    url: `${baseURL}${app}/leaderboardOverall/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches dashboard stats
 * @param app : current app name
 * @param customID: string id to be used instead of the default id
 * @param tags: list of tags to filter stats with
 */
dashboard.dashboard = ({ app, customID, tags = [] }) => {
  const id = customID || getIdByResource(app, 'dashboard');
  const filters = encodeURI(
    `{"tags":${JSON.stringify(
      tags.map(tag => {
        if (tag.key === 'None') {
          // '0' is the id of the empty tag in the database
          return '0';
        }
        return decodeId(tag.key);
      })
    )}}`
  );
  const requestOptions = {
    url: `${baseURL}${app}/dashboard/${id}?filters=${filters}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches copmliance dashboard data
 * @param app : current app name
 * @param clientID
 */
dashboard.getComplianceDashboard = ({ app, clientID }) => {
  const requestOptions = {
    url: `${baseURL}${app}/complianceDashboard/${clientID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches individual ess ranking
 * @param app : current app name
 * @param customID: string id to be used instead of the default id
 * @param filter: filtering object, contains filtering tags.
 * @param sort: object constains sort.field and sort.order
 * @param pagination: object contains pagination.page and pagination.perPage
 * @returns axios get response
 */
dashboard.getIndividualEssRanking = ({ app, customID, filters, sort, pagination }) => {
  const id = customID || getIdByResource(app, 'individualEssRanking');
  const requestOptions = {
    url: `${baseURL}${app}/individualEssRanking/${id}?filters=${encodeURI(filters)}&_start=${pagination.page}&_end=${
      pagination.perPage
    }&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

dashboard.getMicroTrainingStats = ({ app, id }) => {
  const requestOptions = {
    url: `${baseURL}${app}/microTrainingsStats/${id}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches policy types for a specific client
 * @param clientID : id of the client to get policy types for
 * @param app : name of the currently viewed app
 * @returns axios get response
 */
dashboard.getPolicyTypes = ({ clientID, app }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policyType/${clientID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches security policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, in myDashboard it is the user id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @implements there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
dashboard.getSecurityPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/securityPolicies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @implements there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
dashboard.getPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * create a new  policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new policy data
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
dashboard.createPolicy = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policies/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * delete  policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of policies IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
dashboard.deletePolicies = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policies/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * create a new other policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new policy data
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
dashboard.createOtherPolicy = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/otherPolicies/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * delete an other policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of policies IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
dashboard.deleteOtherPolicies = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/otherPolicies/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * fetches other policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, in myDashboard it is the user id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @todo there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
dashboard.getOtherPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/otherPolicies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * used to acknowledge the security policies
 * @param userID : id of the current authenticated user
 * @param app : name of the currently viewed app
 * @returns axios put response
 */
dashboard.setSecurityPolicyAcknowledgement = ({ userID, app }) => {
  const requestOptions = {
    url: `${baseURL}${app}/setSecurityPolicyAcknowlegment/${userID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

/**
 * used to acknowledge the other policies
 * @param userID : id of the current authenticated user
 * @param app : name of the currently viewed app
 * @returns axios put response
 */
dashboard.setOtherPolicyAcknowledgement = ({ userID, app }) => {
  const requestOptions = {
    url: `${baseURL}${app}/setOtherPolicyAcknowlegment/${userID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

/**
 * used to fetch the newsletters list
 * @param userID : id of the current authenticated user
 * @param app : name of the current app
 * @param pagination object
 * @param sort object
 * @returns axios get response
 */
dashboard.getNewsletters = ({ userID, app, sort, pagination }) => {
  const requestOptions = {
    url: `${baseURL}${app}/newsletters/${userID}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the url of a specific newsletter
 * @param app: name of the current app
 * @param id : of the newsletter to download
 * @returns axios get response
 */
dashboard.getNewsletter = ({ app, id }) => {
  const requestOptions = {
    url: `${baseURL}${app}/newsletter/${id}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific security policy
 * @param app: name of the current app
 * @param id : of the security Policy to download
 * @returns axios get response
 */
dashboard.getSecurityPolicy = ({ app, policyID }) => {
  const id = getIdByResource(app, 'securityPolicy');

  const requestOptions = {
    url: `${baseURL}${app}/securityPolicy/${id}?policyId=${policyID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific policy
 * @param app: name of the current app
 * @param id : of the Policy to download
 * @returns axios get response
 */
dashboard.getPolicyInfo = ({ app, policyID }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policyInfo/${policyID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to update the details of a specific policy
 * @param app: name of the current app
 * @param id : of the Policy
 * @param data: policy updated data
 * @returns axios get response
 */
dashboard.updatePolicyInfo = ({ app, policyID, data }) => {
  const requestOptions = {
    url: `${baseURL}${app}/policyInfo/${policyID}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

/**
 * used to fetch the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy to download
 * @returns axios get response
 */
dashboard.getOtherPolicy = ({ app, policyID }) => {
  const id = getIdByResource(app, 'otherPolicy');

  const requestOptions = {
    url: `${baseURL}${app}/otherPolicy/${id}?policyId=${policyID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy to download
 * @returns axios get response
 */
dashboard.getOtherPolicyInfo = ({ app, policyID }) => {
  const requestOptions = {
    url: `${baseURL}${app}/otherPolicyInfo/${policyID}`,
    headers: dashboard.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to update the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy
 * @param data: policy updated data
 * @returns axios get response
 */
dashboard.updateOtherPolicyInfo = ({ app, policyID, data }) => {
  const requestOptions = {
    url: `${baseURL}${app}/otherPolicyInfo/${policyID}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

dashboard.presignedUpload = ({ app, id, data }) => {
  const requestOptions = {
    url: `${baseURL}${app}/presignedUpload/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches documents types of a specific client
 * @param app: name of the current app
 * @param id : of the current viewed client
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
dashboard.getDocumentsTypes = ({ app, clientID, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/documents/${clientID}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches Documents
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 *
 * list of endpoints that are called using this function
 * 1. clients/documentsTemplates/:id
 * 2. clients/otherDocuments/:id
 * 3. clients/documentsServiceProvider/:id
 * 4. clients/disasterRecovery/:id
 * 5. myCompany/sraDocuments/:id
 * 6. myCompany/workPlan/:id
 */
dashboard.getDocuments = ({ app, documentType, id, filters, sort, pagination, cancelToken }) => {
  const filtersString = `${filters.categoryID ? `category_id:${filters.categoryID}` : ''}`;
  const requestOptions = {
    url: `${baseURL}${app}/${documentType}/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_filter=${filtersString}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches Documents
 * @param app : name of the currently viewed app
 * @param id : document id
 * @param cancelToken: axios cancel token
 * @returns axios get response
 *
 * list of endpoints that are called using this function
 * 1. clients/disasterRecoveryInfo/:id
 * 2. clients/documentsServiceProviderInfo/:id
 * 3. clients/otherDocumentsInfo/:id
 * 4. myCompany/sraDocumentsInfo/:id
 * 5. myCompany/workPlanFindingInfo/:id
 */
dashboard.getDocumentInfo = ({ app, documentType, id, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/${documentType}/${id}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * creates a new  document
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new document data
 * @param cancelToken: axios cancel token
 * @returns axios post response
 * list of endpoints that are called using this function
 * 1. clients/otherDocuments/:id
 * 2. clients/documentsServiceProvider/:id
 * 3. clients/disasterRecovery/:id
 * 4. myCompany/sraDocuments/:id
 * 5. myCompany/workPlan/:id
 */
dashboard.createDocument = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/${documentType}/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * used to update the details of a specific document
 * @param app: name of the current app
 * @param id : of the document
 * @param data: document updated data
 * @returns axios put response
 *
 * list of endpoints that are called using this function
 * 1. clients/disasterRecoveryInfo/:id
 * 2. clients/documentsServiceProviderInfo/:id
 * 3. clients/otherDocumentsInfo/:id
 * 4. myCompany/sraDocumentsInfo/:id
 * 4. myCompany/workPlanFindingInfo/:id
 */
dashboard.updateDocument = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/${documentType}/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.put(requestOptions);
};

/**
 * fetches Documents Service Provider Relationships
 * @param app : name of the currently viewed app
 * @param id : changes according to tab, usualy client id
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
dashboard.getDocumentsServiceProviderRelationships = ({ app, id, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/documentsServiceProviderRelationships/${id}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * delete  documents
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of documents IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 * 1. clients/otherDocuments/:id
 * 2. clients/documentsServiceProvider/:id
 * 3. clients/disasterRecovery/:id
 * 4. myCompany/sraDocuments/:id
 * 5. myCompany/workPlan/:id
 */
dashboard.deleteDocuments = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/${documentType}/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * fetches workplan findings
 * @param app : name of the currently viewed app
 * @param id : of the selected workplan
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param filter: {type: finding type}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
dashboard.getWorkPlanFindings = ({ app, id, filters, sort, pagination, cancelToken }) => {
  const filtersString = `${filters.type ? `type:${filters.type}` : ''}`;
  const requestOptions = {
    url: `${baseURL}${app}/workPlanFinding/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_filter=${filtersString}&_sort=${sort.field}&_order=${sort.order}`,
    headers: dashboard.getRequestHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * deletes a workplan finding
 * @param app : name of the currently viewed app
 * @param id : of the selected workplan
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
dashboard.deleteWorkPlanFinding = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${baseURL}${app}/workPlanFinding/${id}`,
    headers: dashboard.getRequestHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

export default dashboard;
