import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';

const useTable = ({
  total,
  selectedRows,
  source,
  onSort,
  onChangePage,
  onChangePerPage,
  onSelectAll,
  onSelectRow,
  preferences,
  params,
  isSelectAll = false,
  hideColumns
}) => {
  if (!source) {
    throw new Error('Please add source property for the Custom Table');
  }

  const preferencesData = useMemo(() => {
    return preferences && userPreferences.getTableSettings(preferences.app, preferences.tab);
  }, [preferences]);

  const updatePreferences = value => {
    if (!preferences) return;
    userPreferences.setTableSettings(preferences.app, preferences.tab, { ...record, ...value });
  };

  const getResult = useCallback(
    bsn => {
      const sourceArr = source.split('/');
      let result = bsn.tables;
      sourceArr.forEach(item => {
        result = result[item];
      });
      return result;
    },
    [source]
  );

  const tableData = useSelector(({ bsn }) => {
    return getResult(bsn);
  });

  if (!tableData) {
    throw new Error('Did you wrote source correctly ? If yes check all parameters in config file');
  }

  // Checking if 'orderBy' from preferences is include in hideColumns - if yes need to return default sort value
  const initialSortname = Array.isArray(hideColumns) && hideColumns.includes(preferencesData?.orderBy) 
    ? tableData?.defaultSortValue?.sortname 
    : (preferencesData?.orderBy || tableData?.defaultSortValue?.sortname);

  const initialState = useMemo(
    () => ({
      expandedId: null,
      highlightedId: '',
      isSelectAll: isSelectAll,
      onLoadValues: {
        sortname: initialSortname,
        order: preferencesData?.order || tableData?.defaultSortValue?.order || 'asc',
        page: 0,
        perPage: preferencesData?.pagination?.perPage || 25
      },
      sortname: initialSortname,
      order: preferencesData?.order || tableData?.defaultSortValue?.order || 'asc',
      page: 0,
      perPage: preferencesData?.pagination?.perPage || 25
    }),
    [tableData?.defaultSortValue?.sortname, tableData?.defaultSortValue?.order, preferencesData, isSelectAll]
  );

  const [record, setRecord] = useReducer(reducer, initialState);

  useEffect(() => {
    if (params) {
      setRecord({
        type: 'SET_PARAMS',
        payload: params
      });
    }
  }, [params]);

  useEffect(() => {
    setRecord({ type: 'SET_INITIAL_STATE', payload: initialState });
  }, [source]);

  useEffect(() => {
    if (Array.isArray(hideColumns) && hideColumns.includes(record.sortname)) {
      requestDefaultSort()
    }
  }, [hideColumns, record?.sortname])

  const dispatch = {};

  dispatch.handleRequestSort = (event, property) => {
    const { sortname, order } = record;
    const isAsc = sortname === property && order === 'asc';
    const sortValue = {
      sortname: property,
      order: isAsc ? 'desc' : 'asc'
    };
    onSort(sortValue);
    updatePreferences({
      orderBy: property, // need to fix key name when backend will use the same key for sort name
      order: isAsc ? 'desc' : 'asc'
    });
    setRecord({
      type: 'SET_SORT_VALUE',
      payload: {
        sortname: property,
        order: isAsc ? 'desc' : 'asc'
      }
    });
  };

  dispatch.handleChangePage = page => {
    onChangePage(page);
    setRecord({ type: 'SET_PAGE', payload: page });
  };

  dispatch.handleChangePerPage = perPage => {
    onChangePerPage(perPage);
    updatePreferences({ pagination: { perPage } });
    setRecord({ type: 'SET_PER_PAGE', payload: perPage });
  };

  dispatch.handleSelectAll = event => {
    onSelectAll(event.target.checked);
    setRecord({ type: 'SET_SELECT_ALL', payload: event.target.checked });
  };

  dispatch.handleSelectRow = (event, id, data) => {
    const { checked } = event.target;
    const isSelectingAll = tableData.selectingAll ?? true;

    if (isSelectingAll) {
      if (!checked && isSelectAll && selectedRows.length + 1 === total) {
        onSelectAll(false);
        setRecord({ type: 'SET_SELECT_ALL', payload: false });
        return;
      }

      if (checked && !isSelectAll && selectedRows.length + 1 === total) {
        onSelectAll(true);
        setRecord({ type: 'SET_SELECT_ALL', payload: true });
        return;
      }
    }

    onSelectRow(checked, id, data);
  };

  dispatch.handleRadioSelectRow = (event, id, data) => {
    const { checked } = event.target;

    onSelectRow(checked, id, data);
  };

  dispatch.onClickRow = rowId => {
    if (tableData.collapse) {
      setRecord({ type: 'SET_COLLAPSED_ROW_ID', payload: rowId });
    }
  };

  dispatch.highlightRow = rowId => {
    setRecord({ type: 'SET_HIGHLIGHTED_ROW_ID', payload: rowId });
  };

  const requestDefaultSort = useCallback(() => {
    dispatch.handleRequestSort(null, tableData?.defaultSortValue?.sortname)
  }, [tableData?.defaultSortValue?.sortname])

  return {
    tableData,
    dispatch,
    ...record
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return {
        ...state,
        ...action.payload
      };
    case 'SET_SORT_VALUE':
      return {
        ...state,
        sortname: action.payload.sortname,
        order: action.payload.order,
        expandedId: null,
        page: 0
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload,
        expandedId: null
      };
    case 'SET_PER_PAGE':
      return {
        ...state,
        perPage: action.payload,
        page: 0,
        expandedId: null
      };
    case 'SET_SELECT_ALL':
      return {
        ...state,
        isSelectAll: action.payload
      };
    case 'SET_COLLAPSED_ROW_ID': {
      const rowId = action.payload === state.expandedId ? null : action.payload;
      return {
        ...state,
        expandedId: rowId
      };
    }
    case 'SET_HIGHLIGHTED_ROW_ID': {
      const rowId = action.payload;
      return {
        ...state,
        highlightedId: rowId
      };
    }
    case 'SET_PARAMS':
      return {
        ...state,
        ...action.payload
      };
    default:
      return { ...state };
  }
}

export default useTable;
