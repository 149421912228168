import { useEffect, useState } from 'react';
import sraApi from 'helpers/apis/sra';

const useClientInfo = clientId => {
  const [data, setData] = useState({
    is_hsn: null,
    new_sra: false,
    migrate_sra: null,
    show_legacy_documents_toggle: false
  });
  const [isLoadingClientInfo, setIsLoadingClientInfo] = useState(false);

  useEffect(() => {
    if (data.is_hsn === null) {
      setIsLoadingClientInfo(true);
      sraApi
        .getClientInfo(clientId)
        .then(res => {
          setData(res.data);
        })
        .finally(() => {
          setIsLoadingClientInfo(false);
        });
    }
  }, [clientId, data.is_hsn]);

  return {
    isHSN: data.is_hsn,
    migrateSRA: data.migrate_sra,
    newSRA: data.new_sra,
    hasLegacyDocuments: data.show_legacy_documents_toggle,
    isLoadingClientInfo
  };
};

export default useClientInfo;
