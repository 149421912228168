// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Box,
  makeStyles,
  TextField,
  InputAdornment,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { SearchIcon, ArrowDown } from 'components/icons';
import { Autocomplete } from '@material-ui/lab';
import newsfeed from 'helpers/apis/newsfeed';
import { useDebounce } from 'hooks';
import { SearchBySelect, UserAvatar } from './index';
import { setFeedSearch, setFeedSearchBy, setFeedSearchFilter } from 'helpers/action';
import { Typography, Loading } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    border: '1px solid var(--colorDark)'
  },
  searchInput: {
    '& input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    '& [class*=MuiInputBase-adornedStart]': {
      padding: '9px 20px 9px 80px',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 20px 5px 70px'
      }
    },
    '& [class*=MuiInputAdornment-root]': {
      position: 'relative',
      marginRight: 0,
      '& svg': {
        marginRight: 12
      }
    },
    '& [class*=MuiAutocomplete-clearIndicator]': {
      '& svg': {
        fontSize: 20
      }
    },
    '& [class*=MuiAutocomplete-clearIndicatorDirty]': {
      visibility: 'visible'
    },
    '& [class*=MuiOutlinedInput-notchedOutline]': {
      border: 'none'
    },
    '& [class*=MuiOutlinedInput-input]': {
      paddingLeft: '0px !important'
    }
  },
  dropdown: {
    boxShadow: '0px 20px 20px -20px rgba(8, 47, 73, 0.2)'
  },
  paperMain: {
    flexGrow: 1,
    padding: '15px 20px',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      marginBottom: '10px',
      borderRadius: '10px',
      maxWidth: '95vw',
    }
  },
  chip: {
    margin: theme.spacing(0.5),
    color: 'var(--colorDefault)',
    borderColor: 'var(--colorDefault)'
  },
  selectedChip: {
    backgroundColor: 'var(--buttonInfo) !important',
    borderColor: 'var(--buttonInfo)',
    color: 'var(--whiteAndBlack)'
  },
  selectedHashtag: {
    backgroundColor: 'var(--backgroundDefault) !important'
  },
  accordion: {
    margin: '0 !important',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent' // This Changes the color of Accordion divider
    }
  },
  accordionSummaryContent: {
    alignItems: 'center', // Align items center vertically
    position: 'relative',
    marginRight: '55%',
    paddingLeft: '0'
  },
  accordionDetails: {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap'
  },
  hashtags: {
    borderColor: 'transparent',
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline'
  },
  viewMore: {
    fontWeight: 'bold',
    marginLeft: 'auto'
  }
  // expandIcon: {
  //   marginRight: theme.spacing(1), // Add a little space between the icon and the Typography
  // },
}));

function getType(type, option) {
  switch (type) {
    case 'hashtag':
      return {
        html: `#${option.tag_name}`,
        text: option.tag_name
      };
    case 'user':
      return {
        html: <UserAvatar user={option} />,
        text: `${option.first_name} ${option.last_name}`
      };
    default:
      return {
        html: `#${option.tag_name}`,
        text: option.tag_name
      };
  }
}

const DISPLAYED_HASHTAG_LIMIT = 5;
const MAX_HASHTAG_LIMIT = DISPLAYED_HASHTAG_LIMIT + 1;

const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSelector(state => state.newsfeed.search);
  const searchBy = useSelector(state => state.newsfeed.searchBy);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputDebounce] = useDebounce({ value: searchInput, delay: 500 });
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [popularHashtags, setPopularHashtags] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [loadingAudiences, setLoadingAudiences] = useState(false);
  const [loadingHashtags, setLoadingHashtags] = useState(false);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    const searchValue = searchInputDebounce.value.trim();
    if (searchValue) {
      if (searchBy === 'hashtag') getHashtagList(searchValue);
      if (searchBy === 'user') getUserList(searchValue);
    }
  }, [searchInputDebounce]);

  useEffect(() => {
    setLoading(Boolean(searchInput));
    setOptions([]);
  }, [searchInput]);

  useEffect(() => {
    if (search && getType(searchBy, search).text === searchInput) setMenuOpen(false);
    if (!search) setOptions([]);
  }, [search, searchInput]);

  useEffect(() => {
    getPopularHashtags();
    getUserAudiences();
  }, []);

  const getUserAudiences = () => {
    setLoadingAudiences(true);
    newsfeed
      .getUsersPicklist(['audiences'])
      .then(res => {
        setAudiences(res.data.audiences);
      })
      .catch(err => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoadingAudiences(false);
      });
  };

  const getPopularHashtags = () => {
    setLoadingHashtags(true);
    newsfeed
      .getPopularHashtags(MAX_HASHTAG_LIMIT, popularHashtags.length)
      .then(res => {
        const hashtagResults = res.data;
        hashtagResults.length > DISPLAYED_HASHTAG_LIMIT ? setViewMore(true) : setViewMore(false);
        setPopularHashtags(prevHashtags => {
          return [...prevHashtags, ...hashtagResults.slice(0, DISPLAYED_HASHTAG_LIMIT)];
        });
      })
      .catch(err => {
        console.log('error while getting popular hashtags: ', err);
      })
      .finally(() => {
        setLoadingHashtags(false);
      });
  };

  const getHashtagList = searchValue => {
    newsfeed
      .getHashtags(searchValue)
      .then(res => {
        const list = res?.data;
        setOptions(list || []);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const getUserList = searchValue => {
    newsfeed
      .getNewsfeedUsers(searchValue)
      .then(res => {
        const list = res?.data;
        setOptions(list || []);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSearchChange = (e, val) => {
    dispatch(setFeedSearch(val));
    setSearchInput(val ? getType(searchBy, val).text : '');
  };

  const handleHashtagSelected = val => {
    dispatch(setFeedSearchBy('hashtag'));
    dispatch(setFeedSearch(val));
    setSearchInput(val ? getType(searchBy, val).text : '');
  };

  const handleViewMore = () => {
    getPopularHashtags();
  };

  const handleSearchInputChange = (e, val) => {
    const value = val.length === 1 && !val.trim() ? '' : val;
    setSearchInput(value);
  };

  const searchSelectMemo = useMemo(() => <SearchBySelect />, []);

  const handleToggle = value => {
    if (selected === value) {
      setSelected(null);
      dispatch(setFeedSearchFilter({}));
    } else {
      setSelected(value);
      dispatch(setFeedSearchFilter({ audience_name: value }));
    }
  };

  return (
    <Paper elevation={0} className={classes.paperMain}>
      <Box>
        <Typography variant="h3">Discover More</Typography>
        <Typography mt={1} variant="body2">
          Search for specific topics or content from popular hashtags or your enrolled groups.
        </Typography>
      </Box>
      <Box my={2}>
        <Paper elevation={0} className={classes.paper}>
          {searchSelectMemo}
          <Autocomplete
            value={search}
            onChange={handleSearchChange}
            inputValue={searchInput}
            onInputChange={handleSearchInputChange}
            options={options}
            loading={loading}
            open={menuOpen && Boolean(searchInput)}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            forcePopupIcon={false}
            getOptionSelected={(option, value) => option.id === value.id}
            noOptionsText="No search results found"
            classes={{ paper: classes.dropdown }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search content"
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                className={classes.searchInput}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon size={1.2} />
                    </InputAdornment>
                  )
                }}
              />
            )}
            getOptionLabel={option => getType(searchBy, option).text}
            renderOption={option => getType(searchBy, option).html}
          />
        </Paper>
      </Box>
      <Accordion defaultExpanded className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummaryContent}
          expandIcon={<ArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Explore Your Groups</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {audiences.map(audience => (
            <Chip
              key={audience.id}
              label={audience.name}
              variant="outlined"
              onClick={() => handleToggle(audience.name)}
              className={selected === audience.name ? `${classes.chip} ${classes.selectedChip}` : classes.chip}
            >
              {audience.name}
            </Chip>
          ))}
          {loadingAudiences && <Loading size="small" label={null} />}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummaryContent}
          expandIcon={<ArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" mt={2}>
            Find a Popular Hashtag
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            {popularHashtags.map(hashtag => {
              const isHashtagSelected = searchBy === 'hashtag' && searchInput === hashtag.tag_name;
              return (
                <Chip
                  key={hashtag.id}
                  label={`#${hashtag.tag_name}`}
                  onClick={() => handleHashtagSelected(hashtag)}
                  variant="outlined"
                  className={`${classes.hashtags} ${isHashtagSelected ? classes.selectedHashtag : undefined}`}
                />
              )
            })}
            {loadingHashtags && <Loading size="small" label={null} />}
          </div>
          {viewMore && (
            <Chip
              label="View More"
              onClick={handleViewMore}
              variant="outlined"
              disabled={loadingHashtags}
              className={`${classes.hashtags} ${classes.viewMore}`}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default Search;
