import { useCallback, useEffect, useState } from 'react'
import { useNotify } from 'ra-core';
import { useSelector } from 'react-redux';
import { dataProvider, admin as adminApi } from 'helpers'
import { useDebounce } from 'hooks';

const useMoveUser = ({ dispatchTable, clientId, onClose, onRefetch }) => {
  const notify = useNotify()
  const { tableRowSelectedList, tableRowSelected } = useSelector(state => state?.bsn?.system)
  
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [includeTrainingData, setIncludeTrainingData] = useState(false)
  
  const [partners, setPartners] = useState({
    selected: null,
    search: ''
  })
  
  const [clients, setClients] = useState({
    selected: null,
    search: ''
  })

  const [searchFor, setSearchFor] = useState('Partner') // or 'Client'
  const [searchPartnerDebounce] = useDebounce({ value: partners.search, delay: 500 });
  const [searchClientDebounce] = useDebounce({ value: clients.search, delay: 500 });

  const [openFindClientModal, setOpenFindClientModal] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const searchValue = searchFor === 'Partner' ? partners.search : clients.search
  const selected = searchFor === 'Partner' ? partners.selected : clients.selected


  useEffect(() =>
    //get Partners
    getOptions({
      searchFor,
      searchValue: searchPartnerDebounce.value
    }),
    [searchPartnerDebounce.value, openFindClientModal]
  )

  useEffect(() => 
    //get Clients
    getOptions({
      searchFor,
      searchValue: searchClientDebounce.value,
      partnerId: partners.selected?.id
    }),
    [searchClientDebounce.value, searchFor]
  )

  const getOptions = useCallback(({ searchFor, searchValue, partnerId }) => {
    if (openFindClientModal) {
      setIsLoading(true)

      const filter = searchValue.trim() ? { name: searchValue } : {}
      
      const fetchOptionsRequest = searchFor === 'Partner' 
        ? adminApi.partners.getList({ filters: filter })
        : dataProvider.getList('clients', `list/${partnerId}`, { filter: filter })
      
      fetchOptionsRequest
        .then((resp) => {
          const data = searchFor === 'Partner' ? resp?.data?.data : resp?.data?.clients;
          setOptions(data || [])
        })
        .catch(err => {
          notify(err.response?.data?.description || 'Something went wrong', 'warning');
        })
        .finally(() => setIsLoading(false))
    }
  }, [openFindClientModal])

  const resetPartner = () => {
    setPartners(prev => ({
      ...prev,
      selected: null,
      search: ''
    }))
  }

  const resetClient = () => {
    setClients(prev => ({
      ...prev,
      selected: null,
      search: ''
    }))
  }

  const resetProps = () => {
    resetPartner()
    resetClient()
    setIncludeTrainingData(false)
    setOpenConfirmation(false)
  }

  const dispatch = {}

  dispatch.resetProps = resetProps

  dispatch.setSearchValue = (value) => {
    if (searchFor === 'Partner') {
      setPartners(prev => ({
        ...prev,
        search: value
      }))
    } else {
      setClients(prev => ({
        ...prev,
        search: value
      }))
    }
  }

  dispatch.handleUnselectUser = (unselecteUserId, row) => {
    const event = { target: { checked: false } }
    dispatchTable.handleClick(event, unselecteUserId, row)
  }

  dispatch.handleSelectClient = (_, rowId) => {
    const item = options.filter(({ id }) => id === rowId)[0]
    
    if (searchFor == 'Partner') {
      setPartners(prev => ({
        ...prev,
        selected: item
      }))
    } else {     
      setClients(prev => ({
        ...prev,
        selected: item
      }))
    }
  }

  dispatch.onDeleteItemSelected = ({ isClient = false, cleanSearch = false }) => {
    setClients(prev => ({
      ...prev,
      selected: null,
      search: cleanSearch ? '' : prev.search 
    }))
  
    if (!isClient) {
      setPartners(prev => ({
        ...prev,
        selected: null,
        search: cleanSearch ? '' : prev.search 
      }))
    }
  }

  dispatch.setOpenFindClientModal = (value) => {
    setOpenFindClientModal(value)
    setSearchFor('Partner')
  }

  dispatch.handleNext = () => {
    if (searchFor === 'Partner') setSearchFor('Client')
    else dispatch.setOpenFindClientModal(false)
  }

  dispatch.cancelFindClient = () => {
    dispatch.setOpenFindClientModal(false)
    resetPartner()
    resetClient()
  }

  dispatch.submit = () => {
    setIsLoading(true)

    if (clientId === clients.selected?.id) {
      notify('You have selected the same client to move user(s)', 'warning')
      return;
    }
    
    dataProvider.update('clients', `moveUsers/${clientId}`, {
        new_client_id: clients.selected?.id,
        ids: tableRowSelected,
        include_training_data: includeTrainingData
      })
      .then((resp) => {
        onRefetch()
        notify(resp.data?.description || 'User(s) moved successfully', 'info')
        dispatchTable.setSelected([]);
        onClose(true)
      })
      .finally(() => {
        setIsLoading(false)
        resetProps();
      })
  }

  return {
    clients,
    dispatch,
    includeTrainingData,
    isLoading,
    options,
    openConfirmation,
    openFindClientModal,
    partners,
    searchFor,
    searchValue,
    selected,
    setIncludeTrainingData,
    setOpenConfirmation,
    setOpenFindClientModal,
    tableRowSelectedList
  }
}

export default useMoveUser