import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_2;
const baseURL = `https://${api}.${base}/${env}`;

const localURL = 'http://127.0.0.1:5000';

const trainings = {};

trainings.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

trainings.getMTNL = type => {
  const requestOptions = {
    url: `${baseURL}/test_mt_nl/get_mt_nl?quiz_type=${type}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.getMetaData = (type, id) => {
  const requestOptions = {
    url: `${baseURL}/test_mt_nl/get_mt_nl_meta_data?id=${id}&quiz_type=${type}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.getMultipleTrainings = () => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/trainingCourses`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.isQuizSubmitted = courseId => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/isQuizSubmitted/${courseId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.getQuizStatus = courseId => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/DashboardQuizModal_v2/${courseId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.deleteQuizStatus = courseId => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/DashboardQuizModal_v2/${courseId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.delete(requestOptions);
};

trainings.addQuizMark = payload => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/DashboardQuizModalAddMark/1`,
    headers: trainings.getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

trainings.addQuizStatus = payload => {
  const requestOptions = {
    url: `${baseURL}/myDashboard/DashboardQuizModal/1`,
    headers: trainings.getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

trainings.setCompletedCourse = courseId => {
  const requestOptions = {
    url: `${baseURL}/training/markCompleted/${courseId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.post(requestOptions);
};

trainings.getCertificate = (courseId, user_id) => {
  const requestOptions = {
    url: `${baseURL}/getCertificate/${courseId}?recipe=chrome-pdf${user_id ? `&user_id=${user_id}` : ``}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.sendCertificate = courseId => {
  const requestOptions = {
    url: `${baseURL}/sendCertificate/${courseId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.post(requestOptions);
};

trainings.configurations = (partnerId, clientId = null) => {
  const requestOptions = {
    url: `${baseURL}/training/${clientId ? 'clients' : 'partners'}/${clientId ? clientId : partnerId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.setReleaseDate = (
  package_id,
  release_date,
  partner_id,
  adjust_future_publication = false,
  client_id = null
) => {
  const requestOptions = {
    url: `${baseURL}/training/set-release-date`,
    headers: trainings.getHeaders(),
    data: {
      partner_id: partner_id,
      ...(client_id !== null && { client_id: client_id }),
      release_date: release_date,
      package_id: package_id,
      adjust_future_publication: adjust_future_publication ? 1 : 0
    }
  };
  return apiClient.post(requestOptions);
};

trainings.unpublish = (package_id, partner_id, client_id = null) => {
  const requestOptions = {
    url: `${baseURL}/training/unpublish`,
    headers: trainings.getHeaders(),
    data: {
      partner_id: partner_id,
      ...(client_id !== null && { client_id: client_id }),
      package_id: package_id
    }
  };
  return apiClient.put(requestOptions);
};

trainings.getPackageClients = packageId => {
  const requestOptions = {
    url: `${baseURL}/training/configure?package_id=${packageId}`,
    headers: trainings.getHeaders()
  };
  return apiClient.get(requestOptions);
};

trainings.addExcludedPackageClients = ({ selectedClients, packageId }) => {
  const requestOptions = {
    url: `${baseURL}/training/configure`,
    headers: trainings.getHeaders(),
    data: {
      client_ids: selectedClients,
      package_id: packageId
    }
  };
  return apiClient.post(requestOptions);
};

export default trainings;
