// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import newsfeed from 'helpers/apis/newsfeed';
import { ButtonDownload, ButtonSubmit, ButtonCancel, PDFViewer } from 'components';
import { Download, Done, Warning } from 'components/icons';
import {
  makeStyles,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import useBreakpoint from 'hooks/useBreakpoint';
import { setNewsfeedLicenseAgreed } from 'helpers/action';
import { install } from 'resize-observer';
import 'intersection-observer';

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: 800
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 450,
    color: 'var(--colorDefault)'
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative'
  },
  dialogContent: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0'
    }
  },
  text: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px'
    },
    '& p': {
      margin: '0 0 10px',
      fontSize: 14,
      fontWeight: 500,
      color: 'var(--colorDefault)'
    }
  },
  warning: {
    fontSize: 12,
    fontWeight: 400,
    color: '#0069FF',
    '& svg': {
      marginRight: 10
    }
  },
  pdfContent: {
    height: '100%',
    '& [data-testid="core__inner-pages"]': {
      overflowY: 'auto !important',
      overflowX: 'hidden !important'
    },
    '& .rpv-core__page-layer': {
      width: '100% !important',
      height: '100% !important',
      minHeight: '410px !important',
      [theme.breakpoints.down('sm')]: {
        minHeight: '280px !important'
      }
    },
    '& .rpv-core__svg-layer': {
      width: '100% !important',
      '& > svg': {
        width: '100% !important',
        height: '100% !important'
      }
    },
    '& .viewer-container': {
      height: 410,
      border: '1px solid #F6F6F6',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        height: '70vh',
        minHeight: '100%'
      }
    }
  },
  dialogActions: {
    padding: 20,
    justifyContent: props => (props.licenseAgreed ? 'flex-start' : 'space-between'),
    flexWrap: 'wrap',
    flexDirection: props => (props.licenseAgreed ? 'row-reverse' : 'row'),
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px 20px',
      justifyContent: 'center !important',
      '& button': {
        marginTop: 10
      }
    }
  }
}));

const TermsModal = ({ open, close }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const pdfDocument = useSelector(state => state.newsfeed.license?.license_agreement);
  const licenseAgreed = useSelector(state => state.newsfeed.license?.newsfeed_license_agreed);
  const classes = useStyles({ licenseAgreed });
  const mobileView = useBreakpoint('sm');

  useEffect(() => {
    if (!window?.ResizeObserver) install();
  }, []);

  const downloadFile = () => {
    window.open(pdfDocument);
  };

  const onAgree = () => {
    newsfeed
      .acceptLicenseAgreement()
      .then(res => {
        notify('User accepted Newsfeed Terms');
        dispatch(setNewsfeedLicenseAgreed());
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      fullWidth
      open={open}
      fullScreen={mobileView}
      {...(licenseAgreed ? { onClose: close } : {})}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">
        <Typography className={classes.title}>Terms of Use</Typography>
        <IconButton className={classes.closeBtn} id="ModalHeaderButtonClose" onClick={close}>
          <CloseIcon style={{ fontSize: 25 }} />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {!licenseAgreed && (
          <div className={classes.text}>
            <p>Read and Accept the Terms of Use for engaging in our community</p>
            <Box className={classes.warning} display="flex" alignItems="center">
              <Warning />
              Please note, if you do not accept the Terms of Use, you will not have access to the Newsfeed.
            </Box>
          </div>
        )}

        <div className={classes.pdfContent}>{pdfDocument && <PDFViewer url={pdfDocument} svgLayer />}</div>
      </DialogContent>

      <Divider />

      <DialogActions className={classes.dialogActions}>
        <ButtonDownload color="default" onClick={downloadFile}>
          <Download style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }} />
          &nbsp;&nbsp; Download
        </ButtonDownload>
        <Box display="flex" alignItems="center">
          <ButtonCancel color="default" onClick={close}>
            Cancel
          </ButtonCancel>
          {!licenseAgreed && (
            <ButtonSubmit type="button" onClick={onAgree}>
              <Done style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }} />
              &nbsp;&nbsp; I Agree
            </ButtonSubmit>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TermsModal;
