import {
  CircleCheckIcon,
  CircleCloseIcon,
  IconButton,
  Stack,
  Tooltip,
  TrashIcon
} from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { PRODUCT_TYPES, CANCELLABLE_HIPAA_PRODUCTS } from '../constants';

const ActiveStatusCell = ({
  active,
  isDeleted = false,
  isInternalClient,
  isEBPP,
  productType,
  onDeactivateClick,
  onActivateClick,
  onDeleteClick
}) => {
  const deactivateClientTooltip = CANCELLABLE_HIPAA_PRODUCTS.includes(productType) 
    ? 'Click here to cancel account and deactivate client' 
    : 'Disable Client';
  
  const { Icon, color, tooltipTitle, onClick } = active
    ? {
        Icon: CircleCheckIcon,
        color: 'success',
        tooltipTitle: deactivateClientTooltip,
        onClick: onDeactivateClick
      }
    : {
        Icon: CircleCloseIcon,
        color: 'error',
        tooltipTitle: 'Activate Client',
        onClick: onActivateClick
      };
  const isProductEditable = productType === PRODUCT_TYPES.BPP || CANCELLABLE_HIPAA_PRODUCTS.includes(productType) && active
  const isEditable = !isInternalClient && isEBPP && isProductEditable;
  const isUTClient = productType === PRODUCT_TYPES.UT;

  if (!isEditable && !isUTClient)
    return (
      <Tooltip title={active && 'Contact support to downgrade client'} placement="top">
        <Stack p={1}>
          <Icon color={color} sx={{ fontSize: '2rem' }} />
        </Stack>
      </Tooltip>
    );

  const isDeleteEnabled = !active && [PRODUCT_TYPES.UT, PRODUCT_TYPES.BPP].includes(productType);

  return (
    <Stack direction="row" spacing={0.5}>
      <Tooltip title={tooltipTitle} placement={active ? 'top' : 'left'} sx={{ maxWidth: '176px'}}>
        <IconButton color={color} onClick={onClick}>
          <Icon sx={{ fontSize: '2rem' }} />
        </IconButton>
      </Tooltip>
      {isDeleteEnabled && !isDeleted && (
        <Tooltip title="Delete Client" placement={active ? 'top' : 'right'}>
          <IconButton color="error" onClick={onDeleteClick}>
            <TrashIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

ActiveStatusCell.propTypes = {
  active: PropTypes.bool.isRequired,
  isEBPP: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isInternalClient: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
  onDeactivteClick: PropTypes.func.isRequired,
  onActivateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};
export default ActiveStatusCell;
