// @flow
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ArrowDropUp as ArrowDropUpIcon, ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { useLocation } from 'hooks';
import { useApp } from 'helpers';
import { LazyIcon } from 'components/icons';
import Table from '../types/Table';
import ChartHalfCircle from '../charts/ChartHalfCircle';
import Star from '../icons/Star';
import userPreferences from '../../helpers/apis/UsersAPI/userPreferences';

export const TableResponsive = styled.div`
  width: 100%;
  overflow: scroll;
  max-height: ${({ height }) => height - 6}vh;
`;

const TableHeadCell = styled(TableCell)`
  && {
    border-bottom: none;
    font-weight: var(--fontWeightRegular);
    font-size: var(--fontSize);
    color: var(--colorDefault);
    padding: 6px;
  }
  &:nth-child(1) {
    border-left: var(--borderSize) solid var(--borderDefault);
    border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    justify-content: center;
  }
  &:nth-child(1) div {
    justify-content: center;
  }
  &:nth-child(4) {
    padding-right: 0 !important;
    padding-left: 12px;
  }
  &:nth-child(4) div {
    justify-content: center;
  }
  &:nth-last-child(1) {
    border-right: var(--borderSize) solid var(--borderDefault);
    border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
  }

  @media screen and (min-width: 1616px) {
    &:nth-child(2) {
      border-left: var(--borderSize) solid var(--borderDefault);
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    }
    &:nth-last-child(2) {
      border-right: var(--borderSize) solid var(--borderDefault);
      border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
    }
  }

  background-color: var(--backgroundTableHead);

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 8px 5px !important;

    &:nth-of-type(2) {
      padding-left: 15px !important;
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    }

    &:nth-of-type(4) {
      border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
    }
  }
`;

const TableBodyCell = styled(TableCell)`
  && {
    font-size: var(--fontSize);
    color: ${({ active }) => (active ? `var(--colorSystemSuccess)` : `var(--colorDefault)`)};
    ${({ active }) => active && `color: var(--colorSystemSuccess)`};
    border-bottom: none;
    padding: 4px;

    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
    }

    .chart-root {
      width: calc(var(--spacing) * 6.5);
      height: calc(var(--spacing) * 6);

      path {
        stroke-width: calc(var(--spacing) * 1.3);
      }
    }
    .chart-root-emptyRow {
      width: calc(var(--spacing) * 6.5);
      height: 2px;
      visibility: hidden;
      path {
        stroke-width: calc(var(--spacing) * 1.3);
      }
    }
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: 12px;
      padding: 10px 5px;

      &.ranktable-rank {
        width: 50px;
        text-align: left;
        padding-left: ${({ isStar }) => (!isStar ? '20px' : '15px')};
      }

      &.ranktable-name {
        width: 150px;
      }

      &.ranktable-score {
        width: 50px;
      }
    }
  }

  > svg {
    font-size: calc(var(--fontSize) * 1.5);
  }
`;

const TableBodyRow = styled(TableRow)`
  && {
    > td {
      ${({ active }) => active === '1' && `color: var(--colorSystemSuccess);`}
    }
  }
`;

const SortButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & > div {
      opacity: 1;
    }
  }
`;

const SortButtonContainer = styled.div`
  display: inherit;
  opacity: ${({ show }) => (show ? 1 : 0)};

  & svg {
    font-size: calc(var(--fontSize) * 1.3);
  }
`;

const UserIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & svg {
    font-size: calc(var(--fontSize) * 1);
  }
  small {
    color: var(--colorDefault);
  }
`;

const UserNameContainer = styled.div`
  width: ${({ isYou }) => (isYou ? '100px' : '110px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StarContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.theme.breakpoints.down('sm')} {
      justify-content: left;
    }
  }
`;

const DashboardRankList = ({ data, height, settings, source, tags = null, tagBox = null }) => {
  const { app } = useLocation();
  const { dispatch } = useApp();
  const preferences = userPreferences.getTableSettings(app, source);
  const [order, setOrder] = useState(preferences?.order || settings.sort.order);
  const [orderBy, setOrderBy] = useState(preferences?.orderBy || settings.sort.field);

  const onClick = name => {
    const sort = {
      field: name,
      order: order === 'ASC' ? 'DESC' : 'ASC'
    };

    const newSettings = {
      ...settings,
      sort
    };

    dispatch.setItem('tables', app, source, newSettings);
    userPreferences.setTableSettings(app, source, { order: sort.order, orderBy: sort.field });
    setOrder(sort.order);
    setOrderBy(sort.field);
  };

  const DashboardTableCellIconContainer = styled.div`
    display: flex;
    align-items: center;
    small {
      line-height: 14px;
    }
  `;

  /**
   * this empty row is added to preserve the table style and structure
   *  in case of empty rank list on different screen sizes
   */
  function emptyRow() {
    return (
      <TableBodyRow>
        <TableBodyCell className="ranktable-rank" align="center" />
        <TableBodyCell className="ranktable-name">
          <UserIconContainer>
            <UserNameContainer />
          </UserIconContainer>
        </TableBodyCell>
        {(app === 'clients' || app === 'myCompany') && (
          <TableBodyCell>
            <UserIconContainer>
              <UserNameContainer />
            </UserIconContainer>
          </TableBodyCell>
        )}
        <TableBodyCell className="ranktable-score" />
        {app === 'clients' && (
          <TableBodyCell align="center">
            <ChartHalfCircle
              title="Risk Level"
              chart={{
                chartData: [
                  {
                    name: 'Low',
                    // eslint-disable-next-line no-nested-ternary
                    value: (800 * 100) / 800
                  }
                ]
              }}
              labels
              showOf={false}
              showLegends={false}
              showTitle={false}
              className="chart-root-emptyRow"
            />
          </TableBodyCell>
        )}
      </TableBodyRow>
    );
  }

  const RankList = () => {
    // in case of there are no ranks
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell align="center" colSpan={settings.cells.length}>
            No Results
          </TableCell>
        </TableRow>
      );

    return data.map(row => (
      <TableBodyRow key={row.id} active={row.rank}>
        <TableBodyCell className="ranktable-rank" align="center" isStar={row.rank === '1'}>
          {row.rank === '1' ? (
            <StarContainer>
              <Star />
            </StarContainer>
          ) : (
            row.rank
          )}
        </TableBodyCell>
        <TableBodyCell className="ranktable-name">
          <UserIconContainer>
            <UserNameContainer title={row.username} isYou={row.you}>
              {row.username}
            </UserNameContainer>
            {row.you && (
              <DashboardTableCellIconContainer>
                <LazyIcon color="colorIcon" ml={1} mr={1} component="UserOutline" />
                <small>You</small>
              </DashboardTableCellIconContainer>
            )}
          </UserIconContainer>
        </TableBodyCell>
        {(app === 'clients' || app === 'myCompany') && (
          <TableBodyCell>
            <UserIconContainer>
              <UserNameContainer title={row.tag_name}>{row.tag_name}</UserNameContainer>
            </UserIconContainer>
          </TableBodyCell>
        )}
        <TableBodyCell className="ranktable-score" align={app === 'clients' || app === 'myCompany' ? 'center' : 'left'}>
          {row.ess}
        </TableBodyCell>
        {app === 'clients' && (
          <TableBodyCell align="center">
            <ChartHalfCircle
              title="Risk Level"
              chart={{
                chartData: [
                  {
                    name: row.risk,
                    // eslint-disable-next-line no-nested-ternary
                    value: ((row.risk === 'Low' ? 800 : row.risk === 'Med' ? 500 : 400) * 100) / 800
                  }
                ]
              }}
              labels
              showOf={false}
              showLegends={false}
              showTitle={false}
              className="chart-root"
            />
          </TableBodyCell>
        )}
      </TableBodyRow>
    ));
  };

  return (
    <TableResponsive height={height}>
      <Table.Container size="small" radius={2}>
        <TableHead>
          <TableRow>
            {settings.cells.map(cell => (
              <TableHeadCell>
                {cell.id === 'tag_name' ? (
                  tagBox
                ) : (
                  <SortButton onClick={() => onClick(cell.id)}>
                    {cell.label}
                    <SortButtonContainer show={cell.id === orderBy}>
                      {order === 'DESC' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    </SortButtonContainer>
                  </SortButton>
                )}
              </TableHeadCell>
            ))}
            {app === 'clients' && <TableHeadCell>Risk</TableHeadCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <RankList />
          {emptyRow()}
        </TableBody>
      </Table.Container>
    </TableResponsive>
  );
};

DashboardRankList.defaultProps = {
  data: {},
  height: 27,
  tags: null,
  tagBox: null,
  source: '',
  settings: {}
};

DashboardRankList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  height: PropTypes.number,
  settings: PropTypes.oneOfType([PropTypes.object]),
  source: PropTypes.string,
  tags: PropTypes.oneOfType([PropTypes.array, null]),
  tagBox: PropTypes.node
};

export default memo<DashboardRankListTypes>(DashboardRankList);
