import { Box, Container, Stack, styled } from '@trustsecurenow/components-library';
import React, { useRef } from 'react';
import Header from './Header';
import Intro from './Intro';
import Section from './Section';
import AccountDependence from './AccountDependence';
import Footer from './Footer';

const SECTIONS = [
  {
    id: '01',
    title: 'Dashboard',
    description:
      'Engage with the PII Protect platform through your user dashboard, which includes your Employee Secure Score (ESS), task list, ESS leaderboard, training, dark web status, and policy access. Additionally, you can manage your profile, access the Newsfeed, and submit contact inquiries within this interface.',
    imagePath: '/media/teams/dashboard.svg'
  },
  {
    id: '02',
    title: 'Complete Training',
    description:
      'Access and complete your PII Protect trainings and quizzes (when applicable), including Annual Cybersecurity Courses, Weekly Micro Trainings, Productivity Training, and Newsletters.',
    imagePath: '/media/teams/training.svg'
  },
  {
    id: '03',
    title: 'Receive Notifications',
    description:
      'Receive notifications for new training opportunities and stay informed about activity in the Newsfeed that you have been alerted to.',
    imagePath: '/media/teams/notification.svg'
  },
  {
    id: '04',
    title: 'Mobile Device Access',
    description:
      'Unlock seamless connectivity on the go! Download the Teams app on your mobile device today and enjoy access to your account anytime, anywhere. Stay connected, stay productive!',
    imagePath: '/media/teams/mobile.svg'
  }
];

const TeamsAboutPageRoot = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(216deg, #308C83 0%, #104255 44.08%, ${theme.palette.primary.main} 63.9%)`
}));

const TeamsAboutPage = () => {
  const contactUsRef = useRef(null);
  const sectionsRef = useRef(null);

  const handleLearnMoreClick = () => {
    sectionsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleContactUsClick = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <TeamsAboutPageRoot>
      <Container maxWidth="lg">
        <Header onContactUsClick={handleContactUsClick} />
        <Intro onLearnMoreClick={handleLearnMoreClick} />
        <Stack alignItems={{ xs: 'center', md: 'normal' }} py={11} px={2.5} spacing={9} ref={sectionsRef}>
          {SECTIONS.map(({ description, imagePath, title, id }, index) => (
            <Section
              key={id}
              isReversed={index % 2 !== 0}
              description={description}
              id={id}
              title={title}
              imagePath={imagePath}
            />
          ))}
        </Stack>
        <AccountDependence />
        <Footer />
        <Box ref={contactUsRef} />
      </Container>
    </TeamsAboutPageRoot>
  );
};

export default TeamsAboutPage;
