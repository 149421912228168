import React from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Typography, SelectField, RadioField, ButtonCancel } from 'components';
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';
import { ArrowForwardIosSharp } from '@material-ui/icons';
import { Save2 } from 'components/icons';
import { GreenButton, BlueButton } from '../../style';

const NETWORK_OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const AVAILABLE_OSES = [
  {
    label: 'Windows 11',
    name: 'os_win11'
  },
  {
    label: 'Windows 10',
    name: 'os_win10'
  },
  {
    label: 'Windows 8',
    name: 'os_win8'
  },
  {
    label: 'Windows 7',
    name: 'os_win7'
  },
  {
    label: 'Windows vista',
    name: 'os_winvista'
  },
  {
    label: 'Windows xp',
    name: 'os_winxp'
  },
  {
    label: 'Older Windows',
    name: 'os_winold'
  },
  {
    label: 'Apple/Mac',
    name: 'os_mac'
  }
];

const Network = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  };

  const handleStateBooleanChange = event => {
    const { name, checked } = event.target;
    setState(prevState => {
      return { ...prevState, [name]: checked ? 1 : 0 };
    });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      {/* Section info */}
      <Container.Grid container spacing={4}>
        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Paper fullWidth radius={2}>
            <Container.Grid container>
              <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography.p p={1} pl={2}>
                  In this section we will ask you questions about your network including your servers, desktops,
                  laptops, etc. This will help us get an overview of your network and assist with the Risk Assessment.
                </Typography.p>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>

      <Container.Grid container spacing={4} mt={2}>
        <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container.Paper fullWidth radius={2} pb={2}>
            <Container.Grid container>
              {/* About data */}
              <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      1.About data *
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* How many physical servers do you have? */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.number_of_servers}
                  name="number_of_servers"
                  label="How many physical servers do you have?"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                />
              </Container.Grid>

              {/* Server data */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      {isHSN
                        ? '2.Do you have patient information on any physical servers? *'
                        : '2.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses, or confidential organizational information) on any physical server? *'}
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Servers/pi on device */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                <RadioField
                  label=""
                  name={isHSN ? 'phi_on_servers' : 'pii_on_servers'}
                  choices={YES_NO_CHOICES}
                  value={isHSN ? state.phi_on_servers : state.pii_on_servers}
                  mt={2}
                  mb={1}
                  row
                  labelPlacement="End"
                  onChange={handleChangeState}
                />
              </Container.Grid>

              {/* What is the network operating system? */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      3.What is the network operating system? *
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Network operating system */}
              <Container.Grid item mb={1} pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip title="This is the operating system used on your servers.">
                  <div style={{ width: '100%' }}>
                    <SelectField
                      fullWidth
                      choices={NETWORK_OS_CHOICES}
                      name="network_operating_system"
                      value={state.network_operating_system}
                      onChange={handleChangeState}
                    />
                  </div>
                </Tooltip>
              </Container.Grid>

              {/* Please provide details of your network */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      4.Please provide details of your network
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Network Details */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.network_details}
                  label="Network Details"
                  name="network_details"
                  placeholder="Ex: We have four different network at company. Both use Windows"
                  fullWidth
                  rows={4}
                  multiline
                  onChange={handleChangeState}
                />
              </Container.Grid>
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6} />

              {/* How many workstations(desktops) do you have? */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.number_workstations}
                  name="number_workstations"
                  label="How many workstations(desktops) do you have? *"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                />
              </Container.Grid>

              {/* How many laptops do you have? */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.number_laptops}
                  name="number_laptops"
                  label="How many laptops do you have? *"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                />
              </Container.Grid>

              {/* Workstations information */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      {isHSN
                        ? '5.Do you have patient information on any workstations? *'
                        : '5.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any workstations? *'}
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Workstations/pi on device */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                  <div>
                    <RadioField
                      label=""
                      name={isHSN ? 'phi_on_workstations' : 'pii_on_workstations'}
                      choices={YES_NO_CHOICES}
                      value={isHSN ? state.phi_on_workstations : state.pii_on_workstations}
                      mt={2}
                      mb={1}
                      row
                      labelPlacement="End"
                      onChange={handleChangeState}
                    />
                  </div>
                </Tooltip>
              </Container.Grid>

              {/* Laptops information */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/*  eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      {isHSN
                        ? '6.Do you have patient information on any laptops? *'
                        : '6.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any Laptops? *'}
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Laptops/pi on device */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                  <div>
                    <RadioField
                      label=""
                      name={isHSN ? 'phi_on_laptops' : 'pii_on_laptops'}
                      choices={YES_NO_CHOICES}
                      value={isHSN ? state.phi_on_laptops : state.pii_on_laptops}
                      mt={2}
                      mb={1}
                      row
                      labelPlacement="End"
                      onChange={handleChangeState}
                    />
                  </div>
                </Tooltip>
              </Container.Grid>

              {/* Please check any operating systems your workstations and laptops are running */}
              <Container.Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="space-between">
                  <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography.h3 p={2} m={0.1} fontSize={16}>
                      7.Please check any operating systems your workstations and laptops are running *
                    </Typography.h3>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>

              {/* Oses */}
              <Container.Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormGroup aria-label="position" row>
                  {AVAILABLE_OSES.map(item => {
                    const { label, name } = item;
                    return (
                      <FormControlLabel
                        key={name}
                        value={state[name] === 1}
                        checked={state[name] === 1}
                        control={<Checkbox color="primary" />}
                        name={name}
                        label={label}
                        labelPlacement="end"
                        onChange={handleStateBooleanChange}
                      />
                    );
                  })}
                </FormGroup>
              </Container.Grid>

              {/* Action buttons */}
              <Container.Grid item mt={3} bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid container spacing={4} justify="flex-end">
                  <Container.Grid m={2} justify="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ButtonCancel variant="text" onClick={e => handleClose()}>
                      Exit
                    </ButtonCancel>
                    <BlueButton startIcon={<Save2 />} variant="outlined" disableElevation onClick={save}>
                      Save
                    </BlueButton>
                    <GreenButton
                      startIcon={<ArrowForwardIosSharp />}
                      variant="outlined"
                      disableElevation
                      ml="30px"
                      onClick={() => save('next')}
                    >
                      Save and Next
                    </GreenButton>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </Container.Paper>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

Network.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default Network;
