import React, { useState, useEffect, useCallback } from 'react';
import { DeleteConfirmationModal } from 'components';
import sraApi from 'helpers/apis/sra';
import { useSRA } from 'components/pages/SRA/context/sraContext';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import generateApiTableParams from 'utils/generateApiTableParams';
import { ThreatsTable, AddEditThreat } from './components';

const SRA_THREATS_TABLE_NAME = 'SRAThreats';

const EditThreats = () => {
  const { clientId } = useSRA();

  const [currentThreat, setCurrentThreat] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [threats, setThreats] = useState([]);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [revisionId, setRevisionId] = useState('');
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [rowCount, setRowCount] = useState(0);
  const [isPreferenceFetched, setIsPreferenceFetched] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ quickFilterValues: [''], items: [] });

  const fetchThreats = useCallback(async () => {
    if (!revisionId || !isPreferenceFetched) return;
    try {
      setIsLoading(true);
      const params = generateApiTableParams({
        pagination: paginationModel,
        sorting: sortModel,
        search: { value: filterModel.quickFilterValues[0], items: ['name'] }
      });
      userPreferences.setMuiDataGridSettings(SRA_THREATS_TABLE_NAME, {
        pageSize: paginationModel.pageSize,
        sorting: sortModel
      });
      const response = await sraApi.getSraThreats(clientId, revisionId, params);
      setThreats(response.data.result);
      setRowCount(response.data.count);
    } finally {
      setIsLoading(false);
    }
  }, [revisionId, isPreferenceFetched, paginationModel, sortModel, filterModel.quickFilterValues, clientId]);

  useEffect(() => {
    setIsPreferenceFetched(true);
    const preferences = userPreferences.getMuiDataGridSettings(SRA_THREATS_TABLE_NAME);
    if (preferences) {
      setPaginationModel(prevState => ({ ...prevState, pageSize: preferences.pageSize || prevState.pageSize }));
      setSortModel(prevState => preferences.sorting || prevState);
    }
  }, []);

  useEffect(() => {
    if (!clientId) return;
    sraApi.getRevisionId(clientId).then(res => {
      setRevisionId(res?.data?.id);
    });
  }, [clientId]);

  useEffect(() => {
    fetchThreats();
  }, [fetchThreats]);

  const closeAddEditModal = useCallback(() => {
    setOpenAddEditModal(false);
    setTimeout(() => {
      setCurrentThreat(null);
    }, 500);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setOpenDeleteModal(false);
    setCurrentThreat(null);

    setTimeout(() => {
      setDeleteSuccess(false);
    }, 500);
  }, []);

  const deleteThreat = useCallback(async () => {
    try {
      setIsDeleteLoading(true);
      await sraApi.deleteSraThreat(clientId, revisionId, currentThreat?.id);
      await fetchThreats();
      setCurrentThreat(null);
      setDeleteSuccess(true);
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', { props: { color: 'error' } });
      setDeleteSuccess(false);
    } finally {
      setIsDeleteLoading(false);
    }
  }, [clientId, revisionId, currentThreat?.id, fetchThreats]);

  const handleEditThreat = useCallback(threat => {
    setCurrentThreat(threat);
    setOpenAddEditModal(true);
  }, []);

  const handleDeleteThreat = useCallback(threat => {
    setCurrentThreat(threat);
    setOpenDeleteModal(true);
  }, []);

  return (
    <>
      <ThreatsTable
        rows={threats}
        onAddThreat={() => setOpenAddEditModal(true)}
        onEditThreat={handleEditThreat}
        onDeleteThreat={handleDeleteThreat}
        isLoading={isLoading}
        paginationModel={paginationModel}
        rowCount={rowCount}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
      />

      <AddEditThreat
        open={openAddEditModal}
        close={closeAddEditModal}
        currentThreat={currentThreat}
        clientId={clientId}
        revisionId={revisionId}
        refetch={fetchThreats}
      />

      <DeleteConfirmationModal
        open={openDeleteModal}
        close={closeDeleteModal}
        onDelete={deleteThreat}
        showSuccessDialog={deleteSuccess}
        successMessage="Threat Deleted!"
        title="Delete Threat?"
        description="Are you sure you want to delete this threat?"
        additionalText={false}
        disabledSubmit={isDeleteLoading}
      />
    </>
  );
};

export default EditThreats;
