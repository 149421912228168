// @flow

export const layoutUserMenuData: Array<{ id: string, label: string }> = [
  {
    id: '#',
    label: 'Update profile'
  },
  {
    id: '#',
    label: 'Switch to manager'
  },
  {
    id: '#',
    label: 'Switch to user'
  },
  {
    id: '/logout',
    label: 'Logout'
  }
];

type IconTypes = Array<{
  icon: any,
  label: string,
  color?: string,
  size?: string,
  id: string,
  badge?: boolean,
  link: string
}>;

export const layoutMenuHelpCenter: IconTypes = [
  {
    icon: 'HelpCenterIcon',
    label: 'Help Center',
    id: 'helpCenter',
    link: 'https://help.breachsecurenow.com/hc/en-us'
           
  }
];

export const layoutMenuKit: IconTypes = [
  {
    icon: 'PartnerResource',
    label: 'Partner Resource Kit',
    id: 'partnerResourceKit',
    link: 'https://www.breachsecurenow.com/partner-resources-marketing-content/?pw=rX345)kf$ax3rDfgg!'
  }
];

export const layoutToolbarTopData: IconTypes = [
  {
    icon: 'Home',
    label: 'Home',
    color: 'inherit',
    size: 'medium',
    id: 'homeSibeButton',
    badge: false,
    link: '#'
  }
];

export const layoutToolbarBottomData: IconTypes = [
  // {
  //   icon: 'Notification',
  //   color: 'inherit',
  //   size: 'medium',
  //   id: 'bNotifications',
  //   badge: true,
  //   link: '#'
  // },
  {
    icon: 'Help',
    label: 'Contact Us',
    color: 'inherit',
    size: 'medium',
    id: 'bContactUsIcon',
    badge: false,
    link: '/user/contactUs'
  },
  {
    icon: 'LogOut',
    label: 'Logout',
    color: 'inherit',
    size: 'medium',
    id: 'bPersonIcon',
    badge: false,
    link: '/logout'
  }
];
