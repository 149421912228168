import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${api}.${base}/${env}`;

const profile = {};

profile.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

profile.update = (userId, data) => {
  const requestOptions = {
    url: `${baseURL}/user/profile/${userId}`,
    headers: profile.getHeaders(),
    data: data
  };
  return apiClient.put(requestOptions);
};

export default profile;

