import { Box, CircularProgress, Button as MUIButton } from '@material-ui/core';
import { CustomTooltip } from 'components';
import { trackingUtils } from 'helpers';
import pushNotifications from 'helpers/apis/pushNotifications';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import styled from 'styled-components';

export const EnableButton = styled(MUIButton)`
  && {
    border-color: ${({ isEnabled }) =>
      isEnabled ? 'var(--colorSystemDanger) !important' : 'var(--buttonSecondaryHover)'};
    color: ${({ isEnabled }) => (isEnabled ? 'var(--colorSystemDanger) !important' : 'var(--buttonSecondaryHover)')};
    opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
    text-transform: none;
    font-size: 14px;
    min-width: 110px;
    height: 40px;
    &:hover {
      background-color: ${({ isEnabled }) =>
        isEnabled ? 'var(--colorSystemDanger) !important' : 'var(--buttonSecondaryHover)'};
      color: ${({ isEnabled }) => (isEnabled ? 'var(--colorCommonWhite) !important' : 'var(--whiteAndBlack)')};
    }

    span {
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    }
  }
`;

function EnableDisableButton({
  state,
  refetchSubscription,
  refetchTable,
  isEditedData,
  invalidData,
  setActionLoading,
  partnerId,
  clientId,
  trackGA
}) {
  const notify = useNotify();
  const [enableLoading, setEnableLoading] = useState(false);

  const wasSubscriptionEnabled = state?.subscription?.enabled;

  const handleEnableDisable = async () => {
    setActionLoading(true);
    setEnableLoading(true);
    const saveBeforeEnable = !state.is_saved;

    try {
      if (saveBeforeEnable) {
        await pushNotifications.setSubscription(state?.subscription?.id, state);
      }

      await pushNotifications.enableDisableSubscription(partnerId, clientId, {
        push_notification_id: state.push_notification.id,
        subscription_id: +state.subscription.id,
        enabled: !!wasSubscriptionEnabled ? 0 : 1
      });

      await refetchSubscription(); // refetching modal data
      await refetchTable(); // refetching table data

      notify(
        `${state?.push_notification?.name} Notification Has Been ${wasSubscriptionEnabled ? 'Disabled' : 'Enabled'}`,
        {
          type: 'success'
        }
      );

      if (trackGA && !wasSubscriptionEnabled) {
        trackingUtils.customEvent('completed_subtask', {
          sendUserName: true,
          sendPartnerName: true,
          label: state?.push_notification?.name
        });
      }
    } catch (err) {
      notify(err?.response?.data?.message || 'Something went wrong', 'error');
    } finally {
      setEnableLoading(false);
      setActionLoading(false);
    }
  };

  return (
    <Box ml={3}>
      <CustomTooltip
        title={
          isEditedData || invalidData
            ? `Must save first before you can ${wasSubscriptionEnabled ? 'disable' : 'enable'} the notification`
            : ''
        }
        placement="top"
        arrow
        light
        maxWidth={250}
      >
        <span>
          <EnableButton
            onClick={handleEnableDisable}
            variant="outlined"
            disableElevation
            disabled={enableLoading || isEditedData || invalidData}
            isEnabled={wasSubscriptionEnabled}
          >
            {enableLoading && (
              <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
            )}
            {wasSubscriptionEnabled ? 'Disable' : 'Enable'}
          </EnableButton>
        </span>
      </CustomTooltip>
    </Box>
  );
}

export default EnableDisableButton;
