import { useReducer } from 'react';
import quiz from '../../helpers/apis/quiz';
import axios from 'axios';

const useQuiz = () => {
  const initialData = {
    loading: false,
    selectedAnswerId: null,
    isQuizFinished: false,
    disabled: true,
    isAnswerSaved: false,
    source: null,
    progress: 0,
  }

  const [record, setRecord] = useReducer(reducer, initialData)
  const dispatch = {}

  dispatch.getQuestion = async (quizId) => {
    await quiz.setNextQuestion(quizId);
    return await quiz.getQuestion(quizId);
  }

  dispatch.getInitialData = async (quizId) => {
    setRecord({ type: "REQUEST" })
    try {
      const quizInfoData = await quiz.getQuizInfo(quizId);
      const questionData = await quiz.getQuestion(quizId);

      if (questionData.status === 204) {
        setRecord({
          type: "END_QUIZ",
        })
      } else {
        setRecord({
          type: "SET_ANSWER_ID",
          payload: questionData?.data?.user_answer_id
        })

        setRecord({
          type: "GET_INITIAL_DATA",
          payload: {
            ...quizInfoData?.data,
            ...questionData?.data
          }
        })
      }
    } catch (e) {
      console.log("error", e)
    }
  }
  dispatch.setAnswerId = async (quizId, questionId, answerId) => {
    if (record.source) {
      record.source.cancel('Operation canceled by the user.');
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setRecord({
      type: "SET_SOURCE",
      payload: source
    })

    const cancelToken = source.token
    setRecord({
      type: "SET_ANSWER_ID",
      payload: answerId
    })
    setRecord({
      type: "SET_ANSWER_SAVE_STATUS",
      payload: false
    })
    await quiz.answerQuestion(quizId, questionId, answerId, cancelToken);
    setRecord({
      type: "SET_ANSWER_SAVE_STATUS",
      payload: true
    })
  }

  dispatch.submitAnswer = async (quizId, questionId, answerId, onFinishQuiz = () => {}, redirect) => {
    setRecord({ type: "REQUEST" })
    try {
      if (!record?.isAnswerSaved) {
        await quiz.answerQuestion(quizId, questionId, answerId);
      }
      if (!record?.question?.is_last) {
        const questionData = await dispatch.getQuestion(quizId);
        setRecord({
          type: "SET_NEXT_QUESTION_DATA",
          payload: questionData?.data
        })
      } else {
        await quiz.endQuiz(quizId);
        onFinishQuiz();
        setRecord({
          type: "END_QUIZ",
        })
      }
    } catch (e) {
      redirect();
      console.log("error", e)
    }
  }

  dispatch.closeModal = () => {
    setRecord({
      type: "CLOSE_MODAL"
    })
  }

  return {
    record,
    dispatch
  }
}

function reducer (state, action) {
  switch (action.type) {
    case "REQUEST": {
      return {
        ...state,
        loading: true,
        selectedAnswerId: null,
        disabled: true
      }
    }
    case "GET_INITIAL_DATA": {
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    }
    case "SET_NEXT_QUESTION_DATA": {
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    }
    case "SET_ANSWER_ID": {
     return {
       ...state,
       disabled: !action.payload,
       selectedAnswerId: action.payload
     }
    }
    case "END_QUIZ": {
      return {
        ...state,
        loading: false,
        isQuizFinished: true,
        progress: 100,
      }
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        isQuizFinished: false
      }
    }
    case "SET_DISABLED": {
      return {
        ...state,
        disabled: action.payload
      }
    }
    case "SET_ANSWER_SAVE_STATUS": {
      return {
        ...state,
        isAnswerSaved: action.payload
      }
    }
    case "SET_SOURCE": {
      return {
        ...state,
        source: action.payload
      }
    }
    default: return state;
  }
}

export default useQuiz
