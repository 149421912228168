import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_3;
const apiGWIDUser = process.env.REACT_APP_BASE_URL_5;
const baseURL = `https://${apiGWID}.${base}/${env}`;
const baseURLUser = `https://${apiGWIDUser}.${base}/${env}`;

const darkModeApi = {};

darkModeApi.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

darkModeApi.manageUserDarkModeData = (isDarkMode) => {
  const requestOptions = {
    url: `${baseURLUser}/user/darkmode`,
    headers: darkModeApi.getRequestHeaders(),
    data: { dark_mode: isDarkMode },
  };
  return apiClient.put(requestOptions);
};


export default darkModeApi;