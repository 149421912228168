import React from 'react';
import { Grid, getRichTextEdiorConfig, RichTextEditor, Typography } from '@trustsecurenow/components-library';
import ContainerStepContent from './ContainerStepContent';
import { ADMIN_NEWSLETTERS } from '../../constants';
import { updateNestedMutable } from 'utils/update';

const RICHTEXT_BUTTONS_CONFIG = {
  uploader: {
    insertImageAsBase64URI: false
  },
  buttons: [
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'eraser',
    '|',
    'print',
    'source',
  ]
};

const DEFAULT_CONFIG = getRichTextEdiorConfig();
const editor_config = updateNestedMutable(DEFAULT_CONFIG, RICHTEXT_BUTTONS_CONFIG);

const CompleteStep = ({ stepNumber, dispatch, record, page, createMode }) => {
  const email_body_name = page === ADMIN_NEWSLETTERS ? 'email_body' : 'email_text';
  const quick_tips_name = page === ADMIN_NEWSLETTERS ? 'quick_tips_body' : 'quick_tips';
  return (
    <ContainerStepContent
      headerTitle={`${stepNumber}. Complete`}
      onSubmit={dispatch.onNext}
      onBack={dispatch.onBack}
      submitLabel={createMode ? 'Create' : 'Update'}
    >
      <Grid container borderTop={6} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
        <Grid item p={2} xs={12} borderBottom={1} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
          <Typography variant="h5">
            This Week’s Training
          </Typography>
        </Grid>
        <Grid item p={2} xs={12}>
          <RichTextEditor
            value={record[email_body_name]}
            config={editor_config}
            onBlur={value => dispatch.onChange(email_body_name, value)}
          />
        </Grid>
      </Grid>

     
      <Grid container borderTop={6} borderBottom={6} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
        <Grid item xs={12} p={2} borderBottom={1} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
          <Typography variant="h5">
            Quick Tips
          </Typography>
        </Grid>
        <Grid item p={2} xs={12}>
          <RichTextEditor
            config={editor_config}
            value={record[quick_tips_name]}
            onBlur={(value) => dispatch.onChange(quick_tips_name, value)}
          />
        </Grid>
      </Grid>
    </ContainerStepContent>
  );
};

export default CompleteStep;